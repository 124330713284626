// import customHeaders, { getSpace } from '../../common/headers';
// import all_tab from './img/all_tab.svg';
// import active_tab from './img/active_tab.svg';
// import disactive_tab from './img/disactive_tab.svg';
// import { OlegHttps, RavshanHttps } from '../../fetchUrls';
// import { useDataTable } from '../../common/hooks';
// import { FilterContainer } from '../FilterContainer/FilterContainer';
// import { useParams, useNavigate } from 'react-router-dom';
// import { useState, useEffect, useRef } from 'react';
// import { TableBox, Tabs, DropDownSelector } from '@lk-gtcom/ecomlab-components';
// import {
//   fetchApiFilter,
//   fetchBrandFilter,
//   fetchCategoryFilter,
// } from '../../common/filtersFunc';
// import useGeneralStore from '../../store/general';
// import './ListPromotionVer2.scss';

// const options_prop = [
//   {
//     label: 'Ozon',
//     value: 1,
//   },
//   {
//     label: 'Wildberries',
//     value: 3,
//   },
//   {
//     label: 'Yandex',
//     value: 2,
//   },
// ];

// const btn_tab = [
//   {
//     label: 'Все',
//     value: 'all',
//     icon: all_tab,
//   },
//   {
//     label: 'Участвуют',
//     value: 'Да',
//     icon: active_tab,
//   },
//   {
//     label: 'Не участвуют',
//     value: 'Нет',
//     icon: disactive_tab,
//   },
// ];

// const ListPromotionVer2 = ({ filtersOut }) => {
//   const selectedSpace = useGeneralStore((state) => state.selectedSpace);
//   const headers = { ...customHeaders, ...getSpace(selectedSpace) };
//   const [apiData, setApiData] = useState([]);
//   const [categoryData, setCategoryData] = useState([]);
//   const [brandData, setBrandData] = useState([]);

//   const [selectedMarketplace, setSelectedMarketplace] = useState([]);
//   const [selectedApi, setSelectedApi] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState([]);
//   const [selectedBrand, setSelectedBrand] = useState([]);

//   const [apiPage, setApiPage] = useState(1);
//   const [categoryPage, setCategoryPage] = useState(1);
//   const [brandPage, setBrandPage] = useState(1);

//   const [apiQuery, setApiQuery] = useState('');
//   const [categoryQuery, setCategoryQuery] = useState('');
//   const [brandQuery, setBrandQuery] = useState('');

//   const activeTab = useParams()['*'];
//   const navigate = useNavigate();

//   const [pageValue, setPageValue] = useState(btn_tab[0]);

//   const paginatorRef = useRef();
//   const [setTable, tableProps, setTableFuncs] = useDataTable(
//     paginatorRef.current?.setTotal,
//     paginatorRef
//   );
//   const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;
//   const [date, setDate] = useState([
//     new Date().getTime() - 604800000,
//     new Date().getTime(),
//   ]);

//   const onRedirect = (e) => {
//     window.open(e[1], '_blank');
//   };

//   const fetchTableData = (params) => {
//     if (!pageValue) return;
//     if (
//       !selectedApi.length ||
//       !selectedCategory.length ||
//       !selectedBrand.length
//     )
//       return;

//     setLoading(true);

//     let sort, filtersParam;
//     if (params) {
//       [sort, filtersParam] = params;
//     }

//     // const url = `${RavshanHttps}/api/action/get_action`;
//     const url = `${OlegHttps}/api/v2/marketing/action/table${
//       sort?.length > 0
//         ? '&order_param=' + sort[0] + '&order_param_direction=' + sort[1]
//         : ''
//     }`;

//     let api_id_list;
//     let brand_id_list;
//     let category_id_list;

//     if (selectedApi?.length > 0) {
//       api_id_list = selectedApi?.map((el) => el.value);
//     }
//     if (selectedBrand?.length > 0) {
//       brand_id_list = selectedBrand?.map((el) => el.value);
//     }
//     if (selectedCategory?.length > 0) {
//       category_id_list = selectedCategory?.map((el) => el.value);
//     }

//     let [date_from, date_to] = date;
//     date_from = new Date(date_from + 10)?.toISOString().split('T')?.[0];
//     date_to = new Date(date_to)?.toISOString().split('T')?.[0];

//     const marketplace =
//       selectedMarketplace?.[0]?.label === 'Ozon' ? 'Озон' : 'Яндекс';

//     // const body = JSON.stringify({
//     //   filters: filtersParam,
//     //   category_id_list,
//     //   brand_id_list,
//     //   api_id_list,
//     //   date_from,
//     //   date_to,
//     //   page: paginatorRef.current.page,
//     //   limit: paginatorRef.current.limit,
//     //   order_param: sort?.[0],
//     //   order_param_direction: sort?.[1],
//     //   participating_filter: pageValue,
//     // });

//     const body = JSON.stringify({
//       filters: filtersParam,
//       page: paginatorRef.current.page,
//       limit: paginatorRef.current.limit,
//       api_id_list,
//       order_param: sort?.[0],
//       order_param_direction: sort?.[1],
//       marketplace: marketplace,
//       status: pageValue == 'all' ? null : pageValue,
//     });

//     fetch(url, { method: 'POST', headers, body })
//       .then(async (res) => {
//         if (res.ok) {
//           return res.json();
//         } else {
//           const err = await res.json();
//           throw new Error(JSON.stringify(err));
//         }
//       })
//       .then((json) => {
//         setTable(json);
//       })
//       .catch((e) => console.error(e))
//       .finally(() => setLoading(false));
//   };

//   const resetTable = () => {
//     paginatorRef.current.reset();
//     paginatorRef.current.setTotal(0);
//     setSort({});
//     setHeaders([]);
//     setFetchedData([]);
//   };

//   const actionHandler = (e) => {
//     const actionId = e?.['action_id'];
//     const actionName = e?.['Название акции'];
//     const apiNames = selectedApi?.map((el) => el?.label);
//     const apiIds = selectedApi?.map((el) => el?.value);

//     navigate(
//       `/marketing-place/joined-promotions/all?actionId=${actionId}&actionName=${actionName}&api_name=${apiNames?.join(
//         ','
//       )}&api_id=${apiIds?.join(',')}&mp_id=${selectedMarketplace?.value}`
//     );
//   };

//   useEffect(() => {
//     resetTable();
//     if (
//       selectedApi.length > 0 &&
//       selectedBrand.length > 0 &&
//       selectedCategory.length > 0
//     ) {
//       fetchTableData();
//     }
//   }, [
//     selectedSpace,
//     selectedApi,
//     selectedBrand,
//     selectedCategory,
//     pageValue,
//     selectedMarketplace,
//   ]);

//   useEffect(() => {
//     setPageValue(activeTab);
//   }, [activeTab]);

//   useEffect(() => {
//     fetchApiFilter({
//       selectedSpace: selectedSpace?.value,
//       page: apiPage,
//       setApiData: (e) => setApiData(e),
//       searchParam: apiQuery,
//       type: selectedMarketplace?.value,
//       selectedShop: selectedMarketplace,
//     });
//   }, [selectedSpace, apiPage, apiQuery, selectedMarketplace]);

//   useEffect(() => {
//     fetchCategoryFilter({
//       selectedSpace: selectedSpace?.value,
//       page: categoryPage,
//       apiData,
//       selectedApi,
//       setCategoryData: (e) => setCategoryData(e),
//       searchParam: categoryQuery,
//     });
//   }, [selectedSpace, apiData, selectedApi, categoryPage, categoryQuery]);

//   useEffect(() => {
//     fetchBrandFilter({
//       selectedSpace: selectedSpace?.value,
//       page: brandPage,
//       apiData,
//       categoryData,
//       selectedCategory,
//       searchParam: brandQuery,
//       selectedApi,
//       setBrandData: (e) => setBrandData(e),
//     });
//   }, [
//     selectedSpace,
//     categoryData,
//     selectedApi,
//     selectedCategory,
//     brandPage,
//     brandQuery,
//   ]);

//   return (
//     <div className="list-promotion-ver2">
//       <h1 className="title_main">Список акций</h1>

//       <div className="filter-and-btn">
//         <div className="filter-group">
//           <FilterContainer
//             onReset={(e) => {
//               setSelectedApi([]);
//               setSelectedMarketplace([]);
//               setSelectedCategory([]);
//               setSelectedBrand([]);
//             }}
//           >
//             <DropDownSelector
//               style={{ maxWidth: '210px' }}
//               options_prop={options_prop}
//               state={selectedMarketplace}
//               // setState={(e) => setSelectedMarketplace(e)}
//               setState={(e) => {
//                 if (!Array.isArray(e)) {
//                   setSelectedMarketplace([e]);
//                 } else {
//                   setSelectedMarketplace(e);
//                 }
//               }}
//               className="connections_page_selector"
//               placeholder="Площадка"
//               defaultValue={[{ label: 'Ozon', value: '1' }]}
//             />

//             <DropDownSelector
//               style={{ maxWidth: '210px' }}
//               options_prop={apiData}
//               state={selectedApi}
//               setState={(e) => setSelectedApi(e)}
//               className="connections_page_selector"
//               placeholder="Магазин"
//               fetchCallback={(e) => setApiPage(e)}
//               setInputChange={(e) => setApiQuery(e)}
//               multi
//             />
//             <DropDownSelector
//               style={{ maxWidth: '210px' }}
//               options_prop={categoryData}
//               state={selectedCategory}
//               setState={(e) => setSelectedCategory(e)}
//               className="connections_page_selector"
//               placeholder="Категория"
//               fetchCallback={(e) => setCategoryPage(e)}
//               setInputChange={(e) => setCategoryQuery(e)}
//               defaultValue={[{ label: 'Все', value: 'all' }]}
//               multi
//             />
//             <DropDownSelector
//               state={selectedBrand}
//               options_prop={brandData}
//               setState={(e) => setSelectedBrand(e)}
//               className="connections_page_selector"
//               placeholder="Бренд"
//               fetchCallback={(e) => setBrandPage(e)}
//               setInputChange={(e) => setBrandQuery(e)}
//               defaultValue={[{ label: 'Все', value: 'all' }]}
//               multi
//             />
//           </FilterContainer>
//         </div>
//       </div>

//       <Tabs tabs={btn_tab} pageValue={pageValue} />

//       <TableBox
//         {...tableProps}
//         ref={paginatorRef}
//         paginator={true}
//         onRedirect={onRedirect}
//         fetchCallback={fetchTableData}
//         onAction={(e) => actionHandler(e)}
//       />
//     </div>
//   );
// };

// export { ListPromotionVer2 };
import customHeaders, { getSpace } from '../../common/headers';
import all_tab from './img/all_tab.svg';
import active_tab from './img/active_tab.svg';
import disactive_tab from './img/disactive_tab.svg';
import { OlegHttps } from '../../fetchUrls';
import { useDataTable } from '../../common/hooks';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { TableBox, Tabs } from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../store/general';
import './ListPromotionVer2.scss';

const btn_tab = [
  {
    label: 'Все',
    value: 'all',
    icon: all_tab,
  },
  {
    label: 'Участвуют',
    value: 'participate',
    icon: active_tab,
  },
  {
    label: 'Не участвуют',
    value: 'not_participate',
    icon: disactive_tab,
  },
];

const ListPromotionVer2 = ({
  selectedMarketplace,
  selectedApi,
  selectedCategory,
  selectedBrand,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const activeTab = useParams()['*'];
  const navigate = useNavigate();

  const [pageValue, setPageValue] = useState(btn_tab[0]);

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;
  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const onRedirect = (e) => {
    window.open(e[1], '_blank');
  };

  const fetchTableData = (params) => {
    if (!pageValue) return;
    if (
      !selectedApi.length ||
      !selectedCategory.length ||
      !selectedBrand.length
    )
      return;

    setLoading(true);

    let sort, filtersParam;
    if (params) {
      [sort, filtersParam] = params;
    }

    const url = `${OlegHttps}/api/v2/marketing/action/table${
      sort?.length > 0
        ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
        : ''
    }`;

    let api_id_list = selectedApi?.map((el) => el.value);
    let brand_id_list = selectedBrand?.map((el) => el.value);
    let category_id_list = selectedCategory?.map((el) => el.value);

    const marketplace = selectedMarketplace?.[0]?.value;
    // const marketplace =
    //   selectedMarketplace?.[0]?.label === 'Ozon' ? 'Озон' : 'Яндекс';

    const body = JSON.stringify({
      filters: filtersParam,
      page: paginatorRef.current.page,
      limit: paginatorRef.current.limit,
      api_id_list,
      order_param: sort?.[0],
      order_param_direction: sort?.[1],
      marketplace: marketplace,
      status: pageValue == 'all' ? null : pageValue,
    });

    fetch(url, { method: 'POST', headers, body })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setTable(json);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  const resetTable = () => {
    paginatorRef.current.reset();
    paginatorRef.current.setTotal(0);
    setSort({});
    setHeaders([]);
    setFetchedData([]);
  };

  const actionHandler = (e) => {
    const actionId = e?.['action_id'];
    const actionName = e?.['Название акции'];
    const apiNames = selectedApi?.map((el) => el?.label);
    const apiIds = selectedApi?.map((el) => el?.value);

    navigate(
      `/marketing-place/joined-promotions/all?actionId=${actionId}&actionName=${actionName}&api_name=${apiNames?.join(
        ','
      )}&api_id=${apiIds?.join(',')}&mp_id=${selectedMarketplace?.value}`
    );
  };

  useEffect(() => {
    resetTable();
    if (
      selectedApi.length > 0 &&
      selectedBrand.length > 0 &&
      selectedCategory.length > 0
    ) {
      fetchTableData();
    }
  }, [
    selectedSpace,
    selectedApi,
    selectedBrand,
    selectedCategory,
    pageValue,
    selectedMarketplace,
  ]);

  useEffect(() => {
    setPageValue(activeTab);
  }, [activeTab]);

  return (
    <div className="list-promotion-ver2">
      <Tabs tabs={btn_tab} pageValue={pageValue} />

      <TableBox
        {...tableProps}
        ref={paginatorRef}
        paginator={true}
        onRedirect={onRedirect}
        fetchCallback={fetchTableData}
        onAction={(e) => actionHandler(e)}
      />
    </div>
  );
};

export { ListPromotionVer2 };
