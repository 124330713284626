import React, { useState, useEffect } from 'react'
import up_icon from './img/bxs-up-arrow.svg'
import down_icon from './img/bxs-down-arrow.svg'
import './DynamicElement.scss'

const DynamicElement = ({ data }) => {

    return (
        <div className={`dynamic-element ${data > 0 ? 'dynamic-element__top' : 'dynamic-element__bottom'}`}>
            <img src={data > 0 ? up_icon : down_icon} />
            {data}
        </div>
    )
}

export { DynamicElement }