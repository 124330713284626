import customHeaders, { getSpace } from '../../../common/headers';
import nodata from './img/nodata.svg';
import { MoonLoader } from 'react-spinners';
import { ModalProductImprovement } from '../ModalProductImprovement/ModalProductImprovement';
import { RavshanHttps, RavshanHttp } from '../../../fetchUrls';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useState, useEffect, useMemo } from 'react';
import { ProductImprovement_EnhancePhoto } from '../../../pages/ProductImprovement_EnhancePhoto/ProductImprovement_EnhancePhoto';
import { ProductImprovement_EnhanceVideo } from '../../../pages/ProductImprovement_EnhanceVideo/ProductImprovement_EnhanceVideo';
import { ProductImprovement_EmojiAddition } from '../../../pages/ProductImprovement_EmojiAddition/ProductImprovement_EmojiAddition';
import { ProductImprovement_CreateRICHContent } from '../../../pages/ProductImprovement_CreateRICHContent/ProductImprovement_CreateRICHContent';
import {
  DropDownSelector,
  ButtonBasic,
  Tabs,
  Checkbox,
} from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../../store/general';
import './ModalMassImprovement.scss';

const btn_tab = [
  {
    label: 'Эмоджи',
    value: 'emoji',
  },
  {
    label: 'Улучшить фото',
    value: 'enhance_photo',
  },
  {
    label: 'Улучшить видео',
    value: 'enhance_video',
  },
  {
    label: 'Создать RICH content',
    value: 'create_RICH_content',
  },
];

const ProductItem = ({ id, img, title, product, brand, onClick, isActive }) => {
  return (
    <div
      className="product_list_item"
      onClick={onClick}
      style={isActive ? { border: '1.5px solid #66cff1' } : {}}
    >
      {img ? (
        <img
          src={img}
          alt="product image"
          className="product_list_item_image"
        />
      ) : (
        <img
          src={nodata}
          alt="product image"
          className="product_list_item_image"
        />
      )}
      <div className="product_list_item_info_container">
        <h3 className="product_list_item_header">{title}</h3>
        <p className="product_list_item_substr">{product}</p>
        <p className="product_list_item_substr">{brand}</p>
      </div>
    </div>
  );
};

const ModalMassImprovement = ({ setIsModal, checkedProducts }) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const navigate = useNavigate();
  const [isAll, setIsAll] = useState(false);
  const [isModalParams, setIsModalParams] = useState(false);
  const [pageValue, setPageValue] = useState(btn_tab?.[0]?.value);
  const [productIds, setProductIds] = useState([]);
  const [isStatus, setIsStatus] = useState(false);
  const [statusKey, setStatusKey] = useState('');
  const [status, setStatus] = useState({});

  const [oldDesc, setOldDesc] = useState('');
  const [newDesc, setNewDesc] = useState('');

  const [oldImages, setOldImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [oldVideo, setOldVideo] = useState([]);
  const [newVideo, setNewVideo] = useState([]);

  const [categoryName, setCategoryName] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState([]);
  const [productsId, setProductsId] = useState([]);

  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(-1);

  const getCategoriesByProdId = (product_id_list) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/products/get_category_by_products`;
    } else {
      url = `${RavshanHttp}/api/products/get_category_by_products`;
    }

    const body = JSON.stringify({
      product_id_list,
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        const categories = json.map(({ category_name }) => ({
          label: category_name,
          value: category_name,
        }));
        setCategoryName(categories);
        setSelectedCategoryName(categories?.[0]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getProductListByCategory = (product_id_list, category_name) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/products/get_product_by_category`;
    } else {
      url = `${RavshanHttp}/api/products/get_product_by_category`;
    }

    const body = JSON.stringify({
      product_id_list,
      category_name,
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setProductList(
          json.map(({ product_id, product_name }) => ({
            id: product_id,
            title: product_name,
          }))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onSaveHandler = () => {
    saveNewDesc();
    saveNewImages();
    saveNewVideo();
    createNewVideo();
  };

  const fetchStatus = () => {
    // if (pageValue === btn_tab[0].value) {
    const url = `${RavshanHttps}/api/products/get_improvement_progress`;

    const body = JSON.stringify({
      key: statusKey,
      name:
        pageValue === 'emoji'
          ? 'description'
          : pageValue === 'enhance_photo'
          ? 'photo'
          : 'video',
      ...(!isAll && { product_id: selectedProduct }),
    });

    fetch(url, { body, method: 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        if (
          (!isAll && json !== 'в работе') ||
          (isAll &&
            (json?.current >= json?.total || json?.status !== 'в работе'))
        ) {
          setIsStatus(false);
          setStatus({});
          setStatusKey('');
        }

        setStatus(json);
      })
      .catch((err) => console.error(err));
    // }
  };

  const getOldDesc = () => {
    if (pageValue === btn_tab[0].value) {
      const url = `${RavshanHttps}/api/products/get_old_product_description`;

      const body = JSON.stringify({
        product_id: selectedProduct,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          setOldDesc(json);
        })
        .catch((err) => console.error(err));
    }
  };

  const getNewDesc = () => {
    if (pageValue === btn_tab[0].value) {
      const url = `${RavshanHttps}/api/products/get_new_product_description`;

      const body = JSON.stringify({
        product_id: selectedProduct,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          setNewDesc(json ? json : '');
        })
        .catch((err) => {
          setNewDesc('');
          console.error(err);
        });
    }
  };

  const saveNewDesc = () => {
    if (pageValue === btn_tab[0].value) {
      const url = `${RavshanHttps}/api/products/save_new_description`;

      const body = JSON.stringify({
        product_id: selectedProduct,
        new_description: newDesc,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          console.log(json);
        })
        .catch((err) => console.error(err));
    }
  };

  const getOldImages = () => {
    if (pageValue === btn_tab[1].value) {
      const url = `${RavshanHttps}/api/products/get_product_images`;

      const body = JSON.stringify({
        product_id: selectedProduct,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          setOldImages(json);
        })
        .catch((err) => console.error(err));
    }
  };

  const getNewImages = () => {
    if (pageValue === btn_tab[1].value) {
      const url = `${RavshanHttps}/api/products/get_new_product_images`;

      const body = JSON.stringify({
        product_id: selectedProduct,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          setNewImages(json);
        })
        .catch((err) => {
          setNewImages([]);
          console.error(err);
        });
    }
  };

  const saveNewImages = () => {
    if (pageValue === btn_tab[1].value) {
      const url = `${RavshanHttps}/api/products/save_new_photo_product`;

      const body = JSON.stringify({
        product_id: selectedProduct,
        new_description: newImages,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          console.log(json);
        })
        .catch((err) => console.error(err));
    }
  };

  const getOldVideo = () => {
    if (pageValue === btn_tab[2].value) {
      const url = `${RavshanHttps}/api/products/get_old_video_product`;

      const body = JSON.stringify({
        product_id: selectedProduct,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          setOldVideo(json);
        })
        .catch((err) => console.error(err));
    }
  };

  const getNewVideo = () => {
    if (pageValue === btn_tab[2].value) {
      const url = `${RavshanHttps}/api/products/get_new_video_product`;

      const body = JSON.stringify({
        product_id: selectedProduct,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          setNewVideo(json);
        })
        .catch((err) => {
          setNewVideo([]);
          console.error(err);
        });
    }
  };

  const saveNewVideo = () => {
    if (pageValue === btn_tab[2].value) {
      const url = `${RavshanHttps}/api/products/save_new_video_product`;

      const body = JSON.stringify({
        product_id: selectedProduct,
        new_description: newVideo,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          console.log(json);
        })
        .catch((err) => console.error(err));
    }
  };

  const createNewVideo = () => {
    if (pageValue === btn_tab[2].value) {
      const url = `${RavshanHttps}/api/products/generate_new_product_video`;

      const body = JSON.stringify({
        product_id_list: [selectedProduct],
        // attribute_list: selectedAttributes,
        // emoji_code: selectedEmoji.join(''),
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          const { key } = json;
          setIsStatus(true);
          setStatusKey(key);
          // sessionStorage.setItem(productId, key)
          // setIsModal(false)
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      getOldDesc();
      getNewDesc();
      getOldImages();
      getNewImages();
      getOldVideo();
      getNewVideo();
    }
  }, [selectedProduct, pageValue]);

  useEffect(() => {
    if (productsId?.length > 0) {
      getCategoriesByProdId(productsId);
    }
  }, [productsId, selectedSpace]);

  useEffect(() => {
    if (selectedCategoryName?.value) {
      getProductListByCategory(productsId, selectedCategoryName?.value);
    }
  }, [selectedCategoryName, selectedSpace]);

  useMemo(() => {
    setProductsId(checkedProducts?.map((el) => el?.product_id));
  }, [checkedProducts]);

  useEffect(() => {
    if (statusKey?.length > 0) {
      fetchStatus();
      const interval = setInterval(() => {
        fetchStatus();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [statusKey, selectedSpace]);

  return (
    <div
      className="blackout"
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setIsModal(false);
          navigate('./');
        }
      }}
    >
      <div className="modal-mass-improvement">
        {isModalParams && (
          <ModalProductImprovement
            page={pageValue}
            setIsModal={setIsModalParams}
            productId={selectedProduct}
            setIsStatus={setIsStatus}
            setStatusKey={setStatusKey}
            productList={productList}
            isAll={isAll}
          />
        )}

        <div className="modal-mass-improvement__header">
          <h1 className="title_main">Массовое улучшение товаров</h1>
          <DropDownSelector
            options_prop={categoryName}
            state={selectedCategoryName}
            setState={(e) => setSelectedCategoryName(e)}
            placeholder="Категория"
          />
        </div>

        <div className="modal-mass-improvement__content">
          <div className="toolbar">
            <Tabs
              tabs={btn_tab}
              pageValue={pageValue}
              setPageValue={(e) => setPageValue(e)}
            />
            <div style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
              <ButtonBasic
                width="300px"
                green
                onClick={(e) => setIsModalParams(true)}
                text={
                  <p className="content-btn">
                    {/* <img src={spakler_icon} /> */}
                    Изменить параметры
                  </p>
                }
              />
              <ButtonBasic
                green
                // style={{ marginTop: '12px' }}
                text="Сохранить"
                width="162px"
                onClick={(e) => onSaveHandler()}
              />
            </div>
          </div>
          <div className="list_container">
            <div className="product_list">
              <div className="product_list_header">
                <p className="product_list_header_text">
                  Товаров: {productList?.length}
                </p>
                <p className="product_list_header_checkbox">
                  <Checkbox
                    onChange={(value) => {
                      setIsAll(value);
                    }}
                  />{' '}
                  Для всех
                </p>
              </div>
              <div className="product_list_container">
                {productList?.map((el) => {
                  const id = el?.id;
                  return (
                    <ProductItem
                      key={id}
                      {...el}
                      isActive={selectedProduct === id}
                      onClick={(e) => setSelectedProduct(id)}
                    />
                  );
                })}
              </div>
            </div>
            <div className="product-improvement-inside-content__changed-content">
              {!isStatus ? (
                selectedProduct !== -1 ? (
                  <Routes>
                    <Route
                      path="/emoji"
                      element={
                        <ProductImprovement_EmojiAddition
                          oldDesc={oldDesc}
                          newDesc={newDesc}
                        />
                      }
                    />
                    <Route
                      path="/create_RICH_content"
                      element={
                        <ProductImprovement_CreateRICHContent
                          setIsModal={(e) => {
                            setIsModal(e);
                            navigate('./');
                          }}
                          product_id={selectedProduct}
                        />
                      }
                    />
                    <Route
                      path="/enhance_photo"
                      element={
                        <ProductImprovement_EnhancePhoto
                          oldImages={oldImages}
                          newImages={newImages}
                        />
                      }
                    />
                    <Route
                      path="/enhance_video"
                      element={
                        <ProductImprovement_EnhanceVideo
                          oldVideo={oldVideo}
                          newVideo={newVideo}
                        />
                      }
                    />
                  </Routes>
                ) : (
                  <div className="nodata_wrapper">
                    <div className="container">
                      <h3 className="container_header">
                        Выберете товар из списка
                      </h3>
                      <p className="container_description">
                        Выберите товар из списка который вы хотите улучшить
                      </p>
                      <img
                        className="nodata_image"
                        src={nodata}
                        alt="no data image"
                      />
                    </div>
                  </div>
                )
              ) : (
                <div className="status_wrapper">
                  <div className="container">
                    <div className="spinner_container">
                      <MoonLoader color="#7b7b7b" speedMultiplier={0.5} />
                      <div className="counter">
                        {isAll ? (
                          <>
                            {status?.current} из{' '}
                            <span className="status_total">
                              {status?.total}
                            </span>
                          </>
                        ) : (
                          status === 'в работе' && <span>{status}</span>
                        )}
                        {/* {status?.current} из{' '}
                        <span className="status_total">{status?.total}</span> */}
                      </div>
                    </div>
                    <h3 className="container_header">≈40 минут</h3>
                    <p className="container_description">
                      по завершению мы вас оповестим, можете заняться своими
                      делами
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ModalMassImprovement };
