import { useEffect, useMemo, useState } from "react";
import { useRef } from "react";
import { EventElement } from "../EventItem/EventElement";

const days_week = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']
const CalendarWeek = ({
  fetchEventData,
  setIsModal,
  setAddEvent,
  setReadOnly,
  setNewEvent,
  setEventInfo,
  eventsList = [],
  dateNow,
  timeDay,
  refHeaderTop,
  weekHeader = { weekHeader } }) => {

  const timeNow = new Date().getHours()
  const [startDate, setStartDate] = useState(new Date());
  const [headers, setHeaders] = useState([])
  const [events, setEvents] = useState([])
  const [positionElement, setPositionElement] = useState(0)
  const dayNow = new Date().getDate()
  const refContainer = useRef()
  const refHeader = useRef()

  const height_header = refHeaderTop?.current?.getBoundingClientRect()?.height

  const days = [
    {
      date: '2024-06-13',
      events: [
        {
          time: 0,
          eventsList: [
            {
              type: 'event',
              date_from: '2023-11-24 00:30',
              date_to: '2023-11-24 01:00',
              title: 'Title',
              author: { name: 'name', login: 'login@gmail.com', color: 'rgb()', id: 15 },
              executors: [
                { name: 'name', login: 'login@gmail.com', color: 'rgb()', id: 15 },
                { name: 'name2', login: 'login2@gmail.com', color: 'rgb()', id: 16 },
              ],
              description: 'Desc',
            }
          ]
        }
      ]
    },
    {
      date: '2024-06-14',
      events: [
        {
          time: 0,
          eventsList: []
        }
      ]
    },
    {
      date: '2024-06-15',
      events: [
        {
          time: 0,
          eventsList: []
        }
      ]
    },
    {
      date: '2024-06-16',
      events: [
        {
          time: 0,
          eventsList: [
            {
              type: 'event',
              date_from: '2023-11-24 02:30',
              date_to: '2023-11-24 02:50',
              title: 'Событие лала',
              author: { name: 'name', login: 'login@gmail.com', color: 'rgb()', id: 15 },
              executors: [
                { name: 'name', login: 'login@gmail.com', color: 'rgb()', id: 15 },
                { name: 'name2', login: 'login2@gmail.com', color: 'rgb()', id: 16 },
              ],
              description: 'Desc',
            }
          ]
        }
      ]
    },
    {
      date: '2024-06-17',
      events: [
        {
          time: 0,
          eventsList: []
        }
      ]
    },
    {
      date: '2024-06-18',
      events: [
        {
          time: 0,
          eventsList: []
        }
      ]
    },
    {
      date: '2024-06-19',
      events: [
        {
          time: 0,
          eventsList: []
        }
      ]
    }
  ]

  // useEffect(() => {
  //   let eventsObj = {}
  //   let headers = []

  //   eventsList.forEach(({ date, events }) => {
  //     headers.push(date)
  //     events?.forEach(({ time, eventList }) => {
  //       let key = eventsObj[time]
  //       if (key) {
  //         eventsObj[time] = [...key, eventList]
  //       } else {
  //         eventsObj[time] = [eventList]
  //       }
  //     })
  //   })

  //   setHeaders(headers)
  //   setEvents(eventsObj)

  // }, [eventsList])

    // Скролл для элемента
    const smoothLinks = () => {
      const elements = [...document.querySelectorAll('td')]
      for (let smoothLink of elements) {
        const id = smoothLink.getAttribute('id')
        if (id) {
          if (id == timeNow) {
            let element = document.querySelector(`[id="${id}"]`)
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
        }
      };
    }
  
    useEffect(() => {
      smoothLinks()
    }, []);
  

  const dateHeaders = useMemo(() => {
    return (
      <>
        {
          days?.map(({ date, day, week, month }, ind) => {


            // const [day, month, year] = el.split("-").map(Number)
            // const dateObject = new Date(year, month - 1, day)

            const day_now = new Date(date).getDate()
            const week_now = new Date(date).getDay()
            const year_now = new Date(date).getFullYear()

            return (
              <th key={date} className={((day_now === new Date().getDate()) && (week_now === new Date().getDay()) && (year_now === new Date().getFullYear())) ?
                'calendar-grid__header_item-active' : 'calendar-grid__header_item'}>
                <div className='date-box'>
                  <p className='week_text'>{days_week[week_now]}</p>
                  <div className='number-box'>
                    <p className='text_number'>{day_now}</p>
                  </div>
                </div>
              </th>
            )
          })
        }
      </>
    )
  }, [weekHeader])

  const renderedEvents = useMemo(() => {
    return (
      <>
        {
          timeDay.map((time) => {
            const eventList = events[time]
            return <tr key={time} className="row-week">
              <td  className="time-box" id={time}>
                <div className='hour-box'>
                  <p className='hour-text'>{time + ':00'}</p>
                </div>
              </td>
              <>
                {
                  days?.map(({ date, events }, ind) => {
                    return <td
                      key={JSON.stringify(events) + ind}
                      className='row-week__item'
                      onClick={(e) => {
                        const date_now = new Date(date)
                        const day = date_now.getDay()
                        const month = date_now.getMonth()
                        const year = date_now.getFullYear()
                        const selectDate = new Date(new Date(date_now).setHours(time - 1, 0, 0))

                        setIsModal(true);
                        if (!e.target.closest(".event-item")) {
                          setIsModal(true);
                          setNewEvent(true);
                          setAddEvent(true);
                          setEventInfo({
                            dateFrom: selectDate,
                            dateTo: new Date(selectDate.getTime() + 3600000),
                          });
                          setReadOnly(false);
                        }
                      }}
                    >
                      {
                        events.map(({ eventsList }) => {
                          return eventsList?.map((props) => {
                            const { date_from, date_to, id } = props
                            const height = (new Date(date_to) - new Date(date_from)) / 60000 / 0.6

                            if (time == new Date(date_from).getHours()) {
                              return <EventElement
                                {...props}
                                key={JSON.stringify(props)}
                                fetchEventData={(e) => (e)
                                  // fetchEventData(id)
                                }
                                dateFrom={new Date(date_from)}
                                dateTo={new Date(date_to)}
                                setReadOnly={(e) => setReadOnly(e)}
                                style={{
                                  top: `${new Date(date_from).getMinutes() / 0.6}%`,
                                  height: `${height < 30 ? 30 : height}%`,
                                  zIndex: 1,
                                }}
                              />
                            }

                          })
                        })
                      }
                    </td>
                  })
                }

                {dateNow.map(({ day_now, hours_now, minutes_now }) => {
                  if (day_now === dayNow
                    && time === hours_now
                  ) {
                    return <div className='time-now-line'
                      style={{
                        width: `${101 / 7}%`,
                        top: `${minutes_now / 0.6}%`,
                        //  left: `${3 + (2 * ((100 - 4) / 7))}%`,
                        left: '64px'
                      }}
                    ></div>
                  }
                }
                )}
              </>

            </tr>
          })
        }
      </>
    )
  }, [events])

  const handleScroll = () => {
    const container = refContainer.current
    const header = refHeader.current
    const height_header = refHeaderTop?.current?.getBoundingClientRect()?.height
    const top = container.getBoundingClientRect().top
    const bottom = container.getBoundingClientRect().top

    let height;
    if (header) {
      height = header.getBoundingClientRect().height
    }

    if (top - height_header < 0) {
      setPositionElement(height_header - top)
    } else {
      setPositionElement(0)
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className='calendar-grid'>
      <table className='table-week' ref={refContainer}>
        <thead ref={refHeader} style={{
          transform: `translateY(${positionElement}px)`,
          boxShadow: positionElement > 0 ? `0px 2px 8px 0px rgba(236, 234, 249, 0.60)` : ''
        }}>
          <tr>
            <th className="calendar-grid__header_item"></th>
            {dateHeaders}
          </tr>
        </thead>

        <tbody>
          {renderedEvents}
        </tbody>
      </table>

    </div>
  );
};

export default CalendarWeek;


// {[...Array(24)].map((_, rowIndex) => (
//   <tr key={rowIndex} className="row-week">
//     <th className="time-box">
//       <div className='hour-box'>
//         <p className='hour-text'>{rowIndex}</p>
//       </div>
//     </th>
//     {eventsList.map(({ date, events }) => {
//       const [day, month, year] = date.split("-").map(Number);
//       const dateObject = new Date(year, month - 1, day);
//       const event = events?.find((e) => e.time === rowIndex);
//       const hasEvent = event && event.eventList && event.eventList.length > 0;

//       return (
//         <td
//           key={date}
//           className='row-week__item'
//           onClick={(e) => {
//             const selectDate = new Date(dateObject.setHours(0, 0));
//             if (!e.target.closest(".event-item")) {
//               setIsModal(true);
//               setNewEvent(true);
//               setAddEvent(true);
//               setEventInfo({
//                 dateFrom: selectDate,
//                 dateTo: new Date(selectDate.getTime() + 3600000),
//               });
//               setReadOnly(false);
//             }
//           }}
//         >
//           {/* {   hasEvent ? events.map((event) => { //todo сделать отображение event'ов в календаре
//               return <div key={event}>
//                   <EventElement
//                     fetchEventData={(e) => fetchEventData(event.id)}
//                     id={event.id}
//                     text={event.title}
//                     dateFrom={new Date(event.date_from)}
//                     dateTo={new Date(event.date_to)}
//                     setReadOnly={(e) => setReadOnly(e)}
//                     style={{
//                       top: `${new Date(event.date_from).getMinutes() / 0.6}%`,
//                       height: `${
//                         (new Date(event.date_to) -
//                           new Date(event.date_from)) /
//                         60000 /
//                         0.6
//                       }%`,
//                       zIndex: 1,
//                     }}
//                   />
//                 </div>
//             })
//               : null
//           } */}
//         </td>
//       );
//     })}

// {dateNow.map(({ day_now,hours_now, minutes_now }) =>
//   (day_now === dayNow && rowIndex === hours_now) ? (
//     <div className='time-now-line'
//       style={{
//         width: `${101 / 7}%`,
//         top: `${minutes_now / 0.6}%`,
//         left: `${3 + (2 * ((100 - 4) / 7))}%`,
//       }}
//     ></div>
//   ) : null
// )}


//   </tr>
// ))}