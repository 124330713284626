import customHeaders, { getSpace } from '../../../common/headers';
import { useState, useEffect } from 'react';
import close_icon from './img/bx-x.svg'
import { ButtonBasic, InputDinamycPlaceholder } from '@lk-gtcom/ecomlab-components';
import './ModalAddLink.scss';

const ModalAddLink = ({ setIsModal }) => {

    return (
        <div className='blackout blackout-modal-2'
            style={{ zIndex: 12 }}
            onClick={(e) => {
                let el = e.target
                if (el.classList.contains('blackout-modal-2')) {
                    setIsModal(false)
                }
            }}>
            <div className="modal-add-link">
                <header className="modal-add-link__header">
                    <h1 className="modal-add-link__title">Добавить ссылку</h1>
                    <button 
                    className="modal-add-link__btn-close"
                    onClick={(e) => setIsModal(false)}
                    >
                        <img src={close_icon} alt='Закрыть' />
                    </button>
                </header>

                <InputDinamycPlaceholder
                    placeholder="Введите ссылку на товар"
                    onChange={(e) => (e)}
                    size={'s'}
                />

                <ButtonBasic
                    green
                    width='100%'
                    text={'Добавить'}
                />
            </div>
        </div>
    );
};

export { ModalAddLink };
