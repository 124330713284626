import plug from './img/plug_icon.svg'
import avatar from './img/Avatar.svg'
import customHeaders, { getSpace } from '../../common/headers';
import StarIcon from '@mui/icons-material/Star'
import info_icon from './img/info_icon.svg'
import back_icon from './img/back_icon.svg'
import date_sort from './img/date_sort.svg'
import arrow_top from './img/arrow_top.svg'
import edit_icon from './img/edit_icon.svg'
import brand_icon from './img/brand_icon.svg'
import rating_sort from './img/rating_sort.svg'
import no_data_icon from './img/no_data_icon.svg'
import category_icon from './img/category_icon.svg'
import arrow_top_grey from './img/arrow_top_grey.svg'
import { Link } from 'react-router-dom';
import { Rating } from '@mui/material';
import { MoonLoader } from 'react-spinners';
import { SendMessage } from '../SendMessage/SendMessage';
import { TabsGreyBtn } from '../TabsGreyBtn/TabsGreyBtn';
import { InputDynamicWidth } from "@lk-gtcom/ecomlab-components";
import { useEffect, useState, useRef } from 'react';
import { KonstantinFeedbackHttps, KonstantinFeedbackHttp, KonstantinMasterHttps, KonstantinMasterHttp } from '../../fetchUrls';
import useGeneralStore from '../../store/general';
import './ChatReview.scss'

export const getFormatedDate = (date, format = 'date') => {
    switch (format) {
        case 'date-2dgt':
            return `${new Date(date)?.toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: '2-digit' })}`
        case 'date':
            return `${new Date(date)?.toLocaleDateString('ru-RU')}`
        case 'fullDate':
            return `${new Date(date)?.toLocaleDateString('ru-RU')} ${new Date(date)?.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}`
        case 'time':
            return `${new Date(date)?.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}`
    }
}



export const CardMessageChat = ({
    data,
    path,
    currentMessage,
    setCurrentMessage,
    setCurrentShop,
    setCurrentMessageProduct,
    setCurrentMpId,
    setCurrentImg,
    setDate,
    setRating
}) => {

    const {
        primary_image, created_at, product_name, link, product_valuation, store_name, feedback_text, answer_text, photo_links,
        category_name, brand_name, id, api_id, mp_id
    } = data


    return <div className={currentMessage == id ? 'message_active' : 'message'}
        key={JSON.stringify(data)}
        onClick={(e) => {
            setRating(product_valuation)
            setCurrentMessage(id)
            setCurrentShop(api_id)
            setCurrentMessageProduct([product_name, link])
            setCurrentImg(primary_image)
            setDate(created_at)
            setCurrentMpId(mp_id)
        }}
    >
        <div className='icon-container'>
            <img src={primary_image} onError={e => e.target.src = plug} loading='lazy' />
            <div className={answer_text ? 'answered' : 'not-answered'}>{answer_text ? 'Отвечен' : 'Не отвечен'}</div>
        </div>
        <div className='info-content'>
            <h3 className='title'>{product_name ? product_name : 'Нет названия'}</h3>

            <div className='category-name'>
                <img src={category_icon} className='icon' />
                <p className='text'>{category_name ? category_name : 'Нет названия'}</p>
            </div>

            <div className='brand-name'>
                <img src={brand_icon} className='icon' />
                <p className='text'>{brand_name ? brand_name : 'Нет названия'}</p>
            </div>

            <p className='text_feedback'>
                {feedback_text}
            </p>
        </div>
        <div className='rating-and-date'>
            {product_valuation &&
                <div className='rating-color-box'>
                    <Rating
                        precision={1}
                        defaultValue={product_valuation}
                        max={1}
                        readOnly
                        emptyIcon={<StarIcon style={{ opacity: '.1' }} />}
                        icon={<StarIcon style={{ fill: '#faaf00' }} />}
                        size="large" />
                    <p className='text_rating'>{product_valuation}</p>
                </div>
            }

            {
                created_at &&
                <p className='text_date'>{getFormatedDate(created_at, 'date')} <span>{getFormatedDate(created_at, 'time')}</span></p>
            }
        </div>
    </div>

}

export const ChatMessage = ({
    message_id,
    data,
    created_at,
    setIsEdit,
    setAnswer,
    is_read,
    date }) => {

    return (
        <div className='message-item' style={{ marginLeft: 'auto' }}>
            <div className='message-and-icon'>
                <div className='message-user' key={message_id}>
                    <p className='text'>{data ? data : 'Ответ отсутствует'}</p>
                    {created_at && <p className='date_create'>{getFormatedDate(created_at)}</p>}
                </div>
                <div className='img-box'>
                    <img src={avatar} />
                </div>
            </div>
            <div className='info-box' style={{ marginLeft: 'auto', marginRight: '48px' }}>
                <button className='btn-edit'
                    onClick={(e) => {
                        setAnswer(data)
                        setIsEdit(true)
                    }}
                >Редактировать <img src={edit_icon} /></button>
                <p className='date'>Ответ продавца</p>
            </div>
        </div>
    )
}

export const UserMessage = ({
    message_id,
    data,
    created_at,
    is_read,
    rating,
    path,
    currentImg,
    author,
    images
}) => {

    return (
        <div className='message-item  message-item-user'>
            <div className='message-and-icon'>
                <div className='img-box'>
                    <img src={currentImg ? currentImg : avatar} style={{ objectFit: 'contain' }} />
                </div>
                <div className='message-support' key={message_id}>
                    <p className='text'>
                        {data ? data : `Поставлена оценка: `}
                        {!data && rating &&
                            <div className='rating-content'> {rating}
                                <Rating
                                    precision={1}
                                    defaultValue={rating}
                                    max={1}
                                    readOnly
                                    emptyIcon={<StarIcon style={{ opacity: '.1' }} />}
                                    icon={<StarIcon style={{ fill: '#faaf00' }} />}
                                    size="small" />
                            </div>
                        }
                    </p>
                    <div className='bottom-content'>
                        {data && <div className='rating-content'> {rating}
                            <Rating
                                precision={1}
                                defaultValue={rating}
                                max={1}
                                readOnly
                                emptyIcon={<StarIcon style={{ opacity: '.1' }} />}
                                icon={<StarIcon style={{ fill: '#faaf00' }} />}
                                size="small" />
                        </div>}
                        {created_at && <p className='date_create'>{getFormatedDate(created_at)}</p>}
                    </div>

                </div>
            </div>
            {images?.length > 0 &&
                <div className='img-group'>
                    {images?.map(({ miniSize, fullSize }, ind) =>
                        <div className='img-box'>
                            <img key={miniSize + ind} src={miniSize ? miniSize : plug} />
                        </div>
                    )}
                </div>
            }

            <div className='info-box'>
                <p className='date'>{`Отзыв от ${author ? author : 'Неизвестный автор'}`}</p>
            </div>

        </div>

    )
}

const sortDirection = {
    ASC: 'DESC',
    DESC: 'ASC'
}

const ChatReview = ({ ...props }) => {
    const {
        btns,
        path,
        pageValue,
        isSearchLastPage = false,
        resetHeight,
        setResetHeight,
        status,
        setStatus,
        tableBody,
        _date,
        selectedMarketplace,
        allPeriod,
        isCheckWithText
    } = props
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }

    const [fetchedData, setFetchedData] = useState([])
    const [total, setTotal] = useState(0)
    const [sort, setSort] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)

    const [currentImg, setCurrentImg] = useState([])
    const [currentMessage, setCurrentMessage] = useState()
    const [currentShop, setCurrentShop] = useState()
    const [currentMessageProduct, setCurrentMessageProduct] = useState([])
    const [chatMessages, setChatMessages] = useState([])
    const [chatInfo, setChatInfo] = useState({})
    const [currentMpId, setCurrentMpId] = useState(-1)
    const [rating, setRating] = useState()
    const [date, setDate] = useState('')
    const [answerValue, setAnswerValue] = useState('')
    const [answer, setAnswer] = useState('')
    const [searchInput, setSearchInput] = useState('')
    const [author, setAuthor] = useState('')
    const [isEdit, setIsEdit] = useState(false)

    const [productGlobalInfo, setProductGlobalInfo] = useState({})

    const listRef = useRef()
    const [pageHeight, setPageHeight] = useState(0)
    const [scrollTop, setScrollTop] = useState(-1)

    const [isShowInformation, setIsShowInformation] = useState(false)


    const fetchChatList = (abortController = new AbortController()) => {
        if (tableBody) {
            setLoading(true)
            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${KonstantinMasterHttps}/wb/${path}/chat-list?with_text=${isCheckWithText}${sort?.length > 0 ? '&order_param=' + sort[0] + '&order_param_direction=' + sort[1] : ''}`
            } else {
                url = `${KonstantinMasterHttp}/wb/${path}/chat-list?with_text=${isCheckWithText}${sort?.length > 0 ? '&order_param=' + sort[0] + '&order_param_direction=' + sort[1] : ''}`
            }


            fetch(url, { body: JSON.stringify({ ...JSON.parse(tableBody), search_param: searchInput, limit: 10, status, page }), method: 'POST', headers, signal: abortController.signal })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(({ items, total }) => {
                    if (page > 1) {
                        setFetchedData(prev => [...prev, ...items])
                    } else {
                        setFetchedData([...items])
                    }
                    setTotal(total)
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    if (abortController.signal.aborted) return
                    setLoading(false)
                })
        }
    }

    const fetchChat = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantinMasterHttps}/wb/${path}/chat?api_id=${currentShop}&mp_id=${currentMpId}&${path === 'feedbacks' ? 'feedback_id' : 'question_id'}=${currentMessage}`
        } else {
            url = `${KonstantinMasterHttp}/wb/${path}/chat?api_id=${currentShop}&mp_id=${currentMpId}&${path === 'feedbacks' ? 'feedback_id' : 'question_id'}=${currentMessage}`
        }


        fetch(url, { method: 'GET', headers })
            .then(res => res.json())
            .then(json => {
                const { api_id, chat_id, customer_name, messages, opened } = json
                if (messages) setChatMessages(messages)
                setAuthor(customer_name)
                setChatInfo({ api_id, id: chat_id, name: customer_name, opened })
                setAnswerValue('')
                setIsEdit(false)
            })
            .catch(err => {
                console.error(err)
            })
    }

    const fetchChatInfo = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantinMasterHttps}/wb/${path}/chat/product-info`
        } else {
            url = `${KonstantinMasterHttps}/wb/${path}/chat/product-info`
        }

        const chat_id = path === 'feedbacks' ? { feedback_id: currentMessage } : { question_id: currentMessage }
        const [date_from, date_to] = _date

        const body = JSON.stringify({
            api_id: currentShop,
            mp_id: currentMpId,
            date_from: allPeriod ? null : new Date(date_from + 10)?.toISOString().split('T')?.[0],
            date_to: allPeriod ? null : new Date(date_to)?.toISOString().split('T')?.[0],
            ...chat_id
        })

        fetch(url, { body, method: 'POST', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setProductGlobalInfo(json)
                setIsShowInformation(true)
            })
            .catch(err => {
                setProductGlobalInfo({ error: true })
                console.error(err)
            })
    }

    const fetchAnswer = () => {
        // setLoading(true)

        if (answerValue) {
            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${KonstantinFeedbackHttps}/wildberries/${path}/answer`
            } else {
                url = `${KonstantinFeedbackHttp}/wildberries/${path}/answer`
            }

            const messageId = path === 'feedbacks' ? { feedback_id: currentMessage } : { question_id: currentMessage }

            const body = JSON.stringify({
                api_id: currentShop,
                text: answerValue,
                ...messageId,
            })

            fetch(url, { body, method: 'POST', headers })
                .then(res => alert(res.json()))
                .catch(err => {
                    console.error(err)
                })
                .finally(() => setLoading(false))
        }

    }

    const sortOptions = [
        { label: <img src={date_sort} />, value: ['Дата', (sort?.length > 0 & sort[0] === 'Дата') ? sortDirection[sort[1]] : 'DESC'], img: arrow_top },
        { label: <img src={rating_sort} />, value: ['Оценка', (sort?.length > 0 & sort[0] === 'Оценка') ? sortDirection[sort[1]] : 'DESC'], img: arrow_top },
    ]


    const messageTypeChecker = (message, path) => {
        const { message_id, user_type, images } = message

        return (
            user_type === 'seller' ?
                <ChatMessage
                    setIsEdit={(e) => setIsEdit(e)}
                    setAnswer={(e) => setAnswer(e)}
                    key={message_id}
                    {...message}
                    date={date} />
                :
                <UserMessage
                    author={author}
                    rating={rating}
                    key={message_id}
                    currentImg={currentImg}
                    {...message}
                    path={path ? path : false} />
        )
    }

    useEffect(() => {
        setDate(null)
        setRating(null)
        setIsEdit(false)
        setCurrentImg([])
        setFetchedData([])
        setAnswerValue('')
        setChatMessages([])
        setCurrentShop(null)
        setCurrentMpId(null)
        setCurrentMessage(null)
        setCurrentMessageProduct([])
        setIsShowInformation(false)
    }, [pageValue]);

    useEffect(() => {
        const abortController = new AbortController()
        fetchChatList(abortController)
        return () => abortController.abort()
    }, [tableBody, pageValue, page, selectedSpace])

    useEffect(() => {
        setFetchedData([])
        if (page > 1) {
            setPage(1)
        } else {
            const abortController = new AbortController()
            fetchChatList(abortController)
            return () => abortController.abort()
        }
    }, [sort])

    useEffect(() => {
        if (currentMessage) {
            fetchChat()
            if (isShowInformation) {
                fetchChatInfo()
            }
        }
    }, [currentMessage])


    useEffect(() => {
        const abortController = new AbortController()
        fetchChatList(abortController)

        return () => {
            abortController.abort()
        }
    }, [searchInput, isCheckWithText])

    const onScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = listRef.current
        const bottomHeight = scrollHeight - clientHeight
        if (bottomHeight) {
            setPageHeight(bottomHeight)
        }
        setScrollTop(scrollTop)
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            const currentPageHeight = pageHeight
            if (!isSearchLastPage && (Math.ceil(scrollTop) >= currentPageHeight) && (fetchedData?.length > 0)) {
                setPage(prev => prev + 1)
                return
            }
        }, 500)
        return () => clearTimeout(timeout)
    }, [scrollTop, pageHeight, isSearchLastPage])

    useEffect(() => {
        if (listRef.current) {
            if (resetHeight) {
                setPageHeight(0)
                listRef.current.scrollTop = -1
                setScrollTop(-1)
                setPage(1)
                setResetHeight(false)
            }
        }
    }, [fetchedData, isSearchLastPage, resetHeight])

    useEffect(() => {
        setFetchedData([])
        const abortController = new AbortController()
        fetchChatList(abortController)
        return () => abortController.abort()
    }, [status])

    return (
        <div className='tabs-and-chat'>
            <TabsGreyBtn
                btns={btns}
                currentBtn={status}
                setCurrentBtn={(e) => setStatus(e)}
                checkValue={true}
            />

            <div className='chat-review'>
                <div className='chat-review__content'>
                    <div className='chat-review__left-content'>
                        <div className='toolbar-panel'>
                            <div className='toolbar-panel__bottom'>
                                <div className='btn-group-sort'>
                                    {
                                        sortOptions?.map(({ label, value, img }) => {
                                            if (path === 'questions' & value?.[0] === 'Оценка') return
                                            return (
                                                <button className={`btn-sort ${value?.[0] === sort?.[0] ? 'active' : ''}`}
                                                    style={{ color: value?.[0] === sort?.[0] ? '#4750A6' : '#a4a5b5' }}
                                                    onClick={e => {
                                                        setSort(value)
                                                    }}
                                                >
                                                    {label}
                                                    {value?.[0] === sort?.[0] ? <img src={img} style={(sort[1] === 'DESC' & sort[0] === value[0]) ? { transform: 'rotate(180deg)', transition: '.4s' } : { transform: 'rotate(0deg)', transition: '.4s' }} /> : <img src={arrow_top_grey} />}
                                                </button>
                                            )
                                        })
                                    }
                                </div>

                                <InputDynamicWidth
                                  //  isFeedback
                                    position='left'
                                    maxWidth='100%'
                                    drop={false}
                                    value={searchInput}
                                    onChange={e => setSearchInput(e)}
                                />
                            </div>
                        </div>
                        <div className='list-message'>
                            {
                                loading ?
                                    <div className='loader-content'>
                                        <MoonLoader
                                            size={40}
                                            color="#7b7b7b"
                                            speedMultiplier={0.5}
                                        />
                                    </div>
                                    :
                                    <div className='list-message__content' ref={listRef} onScroll={e => onScroll(e)}>
                                        {
                                            fetchedData?.length > 0 ?
                                                fetchedData?.map((el) => {
                                                    return <CardMessageChat
                                                        key={JSON.stringify(el)}
                                                        data={el}
                                                        path={path}
                                                        currentMessage={currentMessage}
                                                        setCurrentMessage={(e) => setCurrentMessage(e)}
                                                        setCurrentMpId={e => setCurrentMpId(e)}
                                                        setCurrentShop={(e) => setCurrentShop(e)}
                                                        setCurrentMessageProduct={(e) => setCurrentMessageProduct(e)}
                                                        setCurrentImg={(e) => setCurrentImg(e)}
                                                        setDate={(e) => setDate(e)}
                                                        setRating={(e) => setRating(e)}
                                                    />
                                                })
                                                :
                                                <div className='chat-review__no-data'>
                                                    Здесь пока ничего нет
                                                    <img src={no_data_icon} />
                                                </div>
                                        }
                                    </div>
                            }
                        </div>
                    </div>

                    <div className='chat-review__right-content'>
                        <div className='chat'>
                            <div className='chat__header'>
                                <div className='title-and-rating'>
                                    <Link className='title_product'
                                        onClick={e => window.open(currentMessageProduct?.[1], '_blank')}>
                                        {currentMessageProduct?.[0]}
                                    </Link>

                                    {rating && <div className='rating-and-date'>
                                        <div className='rating-color-box'>
                                            <Rating
                                                precision={1}
                                                defaultValue={rating}
                                                max={1}
                                                readOnly
                                                emptyIcon={<StarIcon style={{ opacity: '.1' }} />}
                                                icon={<StarIcon style={{ fill: '#faaf00' }} />}
                                                size="large" />
                                            <p className='text_rating'>{rating}</p>
                                        </div>
                                    </div>}
                                </div>

                                <div className='bottom-info'>
                                    {date && <p className='text_date'>{getFormatedDate(date, 'date')}
                                        <span> {getFormatedDate(date, 'time')}</span>
                                    </p>}
                                    {currentMessageProduct?.[0] && <button className='btn-info'
                                        onClick={(e) => {
                                            fetchChatInfo()
                                        }}
                                    ><img src={info_icon} />Подробнее о товаре</button>}
                                </div>
                            </div>
                            <div className='chat__content'>
                                {
                                    chatMessages.map((el, ind) => {
                                        return ind === 0 ?
                                            messageTypeChecker(el, rating, author, date, currentImg, path)
                                            :
                                            messageTypeChecker(el, rating, author, date, currentImg, path)
                                    })
                                }
                            </div>
                            <SendMessage
                                frequentAnswers
                                isEdit={isEdit}
                                setIsEdit={(e) => setIsEdit(e)}
                                disabled={!chatInfo?.opened}
                                message={answer}
                                value={answerValue}
                                onChange={e => setAnswerValue(e)}
                                onSendAction={e => fetchAnswer()} />
                        </div>
                    </div>
                </div>

                <div className={isShowInformation ? 'chat-review__general-information' : 'chat-review__general-information_hide'}>
                    <button className='btn-back'
                        onClick={(e) => setIsShowInformation(false)}
                    >
                        <img src={back_icon} />
                    </button>
                    <h3 className='title_info'>ОБЩАЯ ИНФОРМАЦИЯ</h3>
                    {
                        productGlobalInfo?.error ?
                            'Что-то пошло не так. Попробуйте еще раз'
                            :
                            <>
                                <div className='info-content'>
                                    <div className='info-content__item'>
                                        <p className='text_label'>Категория</p>
                                        <p className='text_value-blue'>{productGlobalInfo?.category_name}</p>
                                    </div>
                                    <div className='info-content__item'>
                                        <p className='text_label'>Бренд</p>
                                        <p className='text_value-blue'>{productGlobalInfo?.brand_name}</p>
                                    </div>
                                    <div className='info-content__item'>
                                        <p className='text_label'>Магазин</p>
                                        <p className='text_value-blue'>{productGlobalInfo?.store_name}</p>
                                    </div>
                                </div>

                                <h3 className='title_info'>ДИНАМИКА РЕЙТИНГА</h3>
                                <div className='info-content'>
                                    <div className='info-content__item'>
                                        <p className='text_label'>Кол-во отзывов по товару</p>
                                        <p className='text_value'>{productGlobalInfo?.feedback_count}</p>
                                    </div>
                                    <div className='info-content__item'>
                                        <p className='text_label'>Количество плохих отзывов</p>
                                        <p className='text_value'>{productGlobalInfo?.bad_values}</p>
                                    </div>
                                    <div className='info-content__item'>
                                        <p className='text_label'>Количество хороших отзывов</p>
                                        <p className='text_value'>{productGlobalInfo?.good_values}</p>
                                    </div>

                                    <div className='info-content__item'>
                                        <p className='text_label'>Средний рейтинг по товару</p>
                                        <p className='text_value'>{productGlobalInfo?.avg_product_valuation}</p>
                                    </div>
                                    <div className='info-content__item'>
                                        <p className='text_label'>Средний рейтинг по товару за 30 дней</p>
                                        <p className='text_value'>{productGlobalInfo?.avg_product_valuation_30d}</p>
                                    </div>
                                    <div className='info-content__item'>
                                        <p className='text_label'>Средний рейтинг по товару за все время</p>
                                        <p className='text_value'>{productGlobalInfo?.avg_product_valuation_all}</p>
                                    </div>
                                    <div className='info-content__item'>
                                        <p className='text_label'>Изменение среднего рейтинга</p>
                                        <p className='text_value'>{productGlobalInfo?.avg_product_valuation_change}</p>
                                    </div>

                                    <div className='info-content__item'>
                                        <p className='text_label'>Дней без плохих отзывов</p>
                                        <p className='text_value'>{productGlobalInfo?.date_count_without_bad}</p>
                                    </div>
                                </div>

                                <h3 className='title_info'>ОПИСАНИЕ ТОВАРА</h3>
                                <div className='info-content'>
                                    <div className='info-content__item'>
                                        <p className='text_label' style={{ textAlign: 'left', lineHeight: '1.1em' }}>{productGlobalInfo?.description}</p>
                                    </div>
                                </div>

                                <h3 className='title_info'>КАТЕГОРИЗАЦИЯ</h3>
                                <div className='info-content'>
                                    <div className='info-content__item'>
                                        <p className='text_value' style={{ textAlign: 'left', lineHeight: '1.2em', fontSize: 12 }}>{productGlobalInfo?.attributes?.join(', ')}</p>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}

export { ChatReview }