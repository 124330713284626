import { Link } from 'react-router-dom';
import "./SideTabs.scss"

const SideTabs = ({ ...props }) => {

    const {
        title,
        tabs,
        pageValue,
        setPageValue,
        stopRedirect = false,
        disabled = false,
        mp = false,
        query
    } = props


    return (
        <div className='side-tabs'>
            {title && <h3 className='side-tabs__title'>{title}</h3>}
            {tabs?.map(({ label, value }) => {
                return (
                    <Link
                        onClick={(e) => {
                            if (stopRedirect) e.preventDefault()
                            if (!disabled) {
                                if (!pageValue) {
                                    e.preventDefault()
                                }
                                if (setPageValue && value) {
                                    setPageValue(query ? value + query : value)
                                }
                            }
                        }}
                        to={!stopRedirect ? mp ? `${mp}/${query ? value + query : value}` : query ? value + query : value : false}
                        key={value}
                     >
                        <button className={value === pageValue ? 'side-tabs__btn_active' : 'side-tabs__btn'}>{label}</button>
                    </Link>
                )
            }
            )}
        </div>
    )
}

export { SideTabs }