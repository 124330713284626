import customHeaders, {getSpace} from '../../common/headers';
import { LineChart } from '../../charts/LineChart/LineChart';
import { useEffect, useState } from 'react';
import { TableBox, DropContainer } from '@lk-gtcom/ecomlab-components';
import { AndreySkuHttps, AndreySkuHttp } from '../../fetchUrls';
import useGeneralStore from '../../store/general';
import './ProductCardExtended_AdvertisingPlace.scss';

const ProductCardExtended_AdvertisingPlace = ({
  tableData,
  chartData,
  tableCallback,
  setSelectedTableMetric,
  selectedTableMetric,
  setSelectedSearchQuery,
  selectedSearchQuery,
  selectedMetric,
  setSelectedMetric,
}) => {
  const selectedSpace = useGeneralStore(state => state.selectedSpace)
  const headers = {...customHeaders, ...getSpace(selectedSpace)}
  const [showDropBox, setShowDropBox] = useState(true);
  const [metrics, setMetrics] = useState([]);

  const fetchMetrics = (abortController = new AbortController()) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v1/ecomru/sku/advertisin_place/metric_list/`;
    } else {
      url = `${AndreySkuHttp}/api/v1/ecomru/sku/advertisin_place/metric_list/`;
    }

    fetch(url, { method: 'GET', headers, signal: abortController.signal })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setMetrics(json);
        setSelectedTableMetric(json.map((metric) => metric.value));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onCheck = (obj) => {
    const querySearch = obj.map((item) => item?.['Поисковой запрос']?.[0]);
    setSelectedSearchQuery(querySearch);
  };

  const handleMetricClick = (metric) => {
    if (selectedTableMetric.includes(metric)) {
      setSelectedTableMetric((prev) => prev.filter((el) => el !== metric));
    } else {
      setSelectedTableMetric((prev) => [...prev, metric]);
    }
  };

  useEffect(() => {
    fetchMetrics();
  }, [selectedSpace]);

  return (
    <section className="advertising-place-inside-content">
      <DropContainer
        showDropBox={showDropBox}
        setShowDropBox={(e) => setShowDropBox(e)}
      >
        <LineChart {...chartData} 
         metricsList={metrics}
         selectedMetric={selectedMetric}
         setSelectedMetric={setSelectedMetric}
        />
      </DropContainer>

      <TableBox
        {...tableData}
        paginator={true}
        fetchCallback={tableCallback}
        onCheck={onCheck}
        child={
          <div style={{ display: 'flex', gap: '.5em' }}>
            {metrics?.map((el) => (
              <div
                key={el.value}
                onClick={() => handleMetricClick(el?.value)}
                style={{
                  padding: '.5em 1em',
                  border: '1px solid lightgrey',
                  cursor: 'pointer',
                  fontSize: '12px',
                  background: selectedTableMetric.includes(el?.value)
                    ? 'lightgrey'
                    : '',
                }}
              >
                {el.label ? el.label : el.value}
              </div>
            ))}
          </div>
        }
      />
    </section>
  );
};

export { ProductCardExtended_AdvertisingPlace };
