import { useState, useEffect } from "react"
import { useChartData } from "../../../common/hooks";
import headers, { getSpace } from "../../../common/headers";
import { LineChart } from "../../LineChart/LineChart";
import useGeneralStore from "../../../store/general";
import "./WrapperLineChart.scss"

const WrapperLineChart = ({
    url,
    body,
    nameChart = '',
    dependence
}) => {
    const selectedSpace = useGeneralStore((state) => state.selectedSpace);
    const [setChartData, chartData, setChartFuncs] = useChartData();
    const { setChartLoading, resetChart } = setChartFuncs

    const fetchChartData = (abortController = new AbortController()) => {
        setChartLoading(true)

        fetch(url, {
            body: body,
            method: 'POST',
            headers: {...headers, ...getSpace(selectedSpace)},
            signal: abortController.signal,
        })
            .then(async (res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    const err = await res.json();
                    throw new Error(JSON.stringify(err));
                }
            })
            .then((json) => {
                setChartData(json);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setChartLoading(false)
                if (abortController.signal.aborted) {
                    return;
                }
            });
    };


    useEffect(() => {
        if(body) {
           fetchChartData() 
        }
    }, [body]);

    return (
        <LineChart
            nameChart={nameChart}
            {...chartData}
        />
    )
}

export { WrapperLineChart }