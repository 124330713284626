import React, { useState, useEffect } from 'react'
import close from './img/close.svg'
import file_icon from './img/bx-file-blank.svg'
import download_icon from './img/bx-download.svg'
import dayjs from 'dayjs'
import { ButtonActionDrop } from '../Buttons/ButtonActionDrop/ButtonActionDrop'
import './FileItemComponent.scss'

const FileItemComponent = ({
    created_at,
    file_size_kb,
    new_filename,
    url,
    authorEmail,
    filesdata,
    setFilesData,
    isEdit,
    positionDrop
}) => {

    const email = localStorage.getItem('email')
    const btns_author = [
        {
            btn: 'Удалить',
            func: () => setFilesData(filesdata?.filter((el) => el.new_filename !== new_filename)),
        },
    ];

    const btns = [
        {
            btn: 'Копировать ссылку',
            func: () => { },
        }
    ];

    return (
        <div className='file-item-component' key={created_at + url}>
            <p className='file-item-component__link-file'>
                <img className='file-item-component__link-file-img' src={file_icon} />
                <span>{new_filename}</span>
            </p>

            <div className='file-item-component__hide-box'>
                <a className='file-item-component__link-btn'
                    target='_blank'
                    href={url}
                    download={'download'}
                   // onClick={(e) => e.stopPropagation()}
                >
                    <img className='file-item-component__link-file-img' src={download_icon} /> Скачать файл
                </a>

                <ButtonActionDrop
                    id={url}
                    btns={((email == authorEmail) && isEdit) ? btns : btns_author}
                    color={'#F3F4F6'}
                    size={'m'}
                    positionDrop={positionDrop}
                />
            </div>

            <div className='file-item-component__link-info'>
                <span>{(file_size_kb / 1024).toFixed(2)} МБ</span>
                <span>{dayjs(created_at).format('DD.MM.YY HH:mm')}</span>
            </div>
        </div>
    )
}

export { FileItemComponent }