const transliter = (str) => {

    const toFirstUpperCase = (str) => str?.length > 0 ? str[0]?.toUpperCase() + str?.slice(1, str?.length) : str?.toUpperCase()

    var ru = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
        'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i',
        'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
        'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
        'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
        'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'u', 'я': 'ya'
    }, n_str = [];

    str = str.replace(/[ъь]+/g, '').replace(/й/g, 'i');

    for (const i of str) {
        n_str.push(
            ru[i]
            || ru[i.toLowerCase()] == undefined && i
            || toFirstUpperCase(ru[i.toLowerCase()])
        );
    }

    return n_str.join('')
}

export { transliter }

export const handleApiResponseOrThrow = async response => {
    if (response.status !== 200) {
        const errorBody = await response.json()
        throw new Error(errorBody?.reason)
    }
    return response.json()
}



// Filters query logic
const filterKeys = ['mp_id', 'mp_label', 'api_id', 'api_label', 'category_id', 'category_label', 'brand_id', 'brand_label', 'warehouse_id', 'warehouse_label',
    'offer_id', 'offer_label', 'period_label', 'period_value', 'query', 'date_from', 'date_to', 'fbofbs', 'category_breadcrumb_label', 'category_breadcrumb_id', 'search', 'author', 'co_executor', 'executor', 'status_label', 'status_id', 'sort_label', 'sort_id', 'direction' ]

export const getFiltersByUrlQuery = (searchParams) => {

    let dataObj = {}

    filterKeys.forEach((key) => {
        const query = searchParams.get(key)
        if (query) {
            dataObj[key] = query
        } else {
            delete dataObj?.[key]
        }
    })

    return dataObj
}

export const getParamsQuery = (string) => {

    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const paramObject = {};
    queryParams.forEach((value, key) => {
        paramObject[key] = value;
    });
    if (paramObject?.[string]) {
        console.log(paramObject?.[string])
        return paramObject?.[string]
    }
}

export const addFiltersToUrlQuery = (dataObj, setSearchParams) => {
    let queryString = ''
    const keys = Object.keys(dataObj)

    keys?.forEach((key, ind) => {
        if (dataObj?.[key]) {
            queryString += `${ind === 0 ? '?' : '&'}${key}=${dataObj?.[key]}`
        }
    })

    setSearchParams(queryString)
    return queryString
}

export const getDataObjByFilters = (
    {
        selectedMp,
        selectedApi,
        selectedCategory,
        selectedBrand,
        selectedWarehouse,
        selectedOffer,
        date,
        selectedPeriod,
        query,
        fbofbs,
        selectedCategoryBreadCrumbs,
        rest,
        searchValue,
        selectedAuthor,
        selectedCoExecutor,
        selectedExecutor,
        selectedStatus,
        currentSort,
        sort1
    }
) => {
    let dataObj = {}

    if (selectedMp) {
        if (selectedMp?.length > 0) {
            dataObj['mp_label'] = selectedMp?.map(el => el?.label)?.join(';')
            dataObj['mp_id'] = selectedMp?.map(el => el?.value)?.join(';')
        }
        if (selectedMp?.value) {
            dataObj['mp_label'] = selectedMp?.label
            dataObj['mp_id'] = selectedMp?.value
        }
        // dataObj.mp_label = mp_label
        // dataObj.mp_id = mp_id
    }

    if (selectedApi) {
        if (selectedApi?.length > 0) {
            dataObj['api_label'] = selectedApi?.map(el => el?.label)?.join(';')
            dataObj['api_id'] = selectedApi?.map(el => el?.value)?.join(';')
        }
        if (selectedApi?.value) {
            dataObj['api_label'] = selectedApi?.label
            dataObj['api_id'] = selectedApi?.value
        }
        // dataObj.api_label = api_label
        // dataObj.api_id = api_id
    }

    if (selectedCategory) {
        if (selectedCategory?.length > 0) {
            dataObj['category_label'] = selectedCategory?.map(el => el?.label)?.join(';')
            dataObj['category_id'] = selectedCategory?.map(el => el?.value)?.join(';')
        }
        if (selectedCategory?.value) {
            dataObj['category_label'] = selectedCategory?.label
            dataObj['category_id'] = selectedCategory?.value
        }
        // dataObj.category_label = category_label
        // dataObj.category_id = category_id
    }

    if (selectedBrand) {
        if (selectedBrand?.length > 0) {
            dataObj['brand_label'] = selectedBrand?.map(el => el?.label)?.join(';')
            dataObj['brand_id'] = selectedBrand?.map(el => el?.value)?.join(';')
        }
        if (selectedBrand?.value) {
            dataObj['brand_label'] = selectedBrand?.label
            dataObj['brand_id'] = selectedBrand?.value
        }
        // dataObj.brand_label = brand_label
        // dataObj.brand_id = category_id
    }

    if (selectedWarehouse) {
        if (selectedWarehouse?.length > 0) {
            dataObj['warehouse_label'] = selectedWarehouse?.map(el => el?.label)?.join(';')
            dataObj['warehouse_id'] = selectedWarehouse?.map(el => el?.value)?.join(';')
        }
        if (selectedWarehouse?.value) {
            dataObj['warehouse_label'] = selectedWarehouse?.label
            dataObj['warehouse_id'] = selectedWarehouse?.value
        }
        // dataObj.warehouse_label = warehouse_label
        // dataObj.warehouse_id = category_id
    }

    if (selectedOffer) {
        if (selectedOffer?.length > 0) {
            dataObj['offer_label'] = selectedOffer?.map(el => el?.label)?.join(';')
            dataObj['offer_id'] = selectedOffer?.map(el => el?.value)?.join(';')
        }
        if (selectedOffer?.value) {
            dataObj['offer_label'] = selectedOffer?.label
            dataObj['offer_id'] = selectedOffer?.value
        }
        // dataObj.offer_label = warehouse_label
        // dataObj.offer_id = category_id
    }

    if (date?.length > 0) {
        const [dateFrom, dateTo] = date
        if (dateFrom) {
            dataObj['date_from'] = new Date(dateFrom).getTime()
        }
        if (dateTo) {
            dataObj['date_to'] = new Date(dateTo).getTime()
        }
    }

    if (selectedPeriod?.value) {
        dataObj['period_label'] = selectedPeriod?.label
        dataObj['period_value'] = selectedPeriod?.value
    }

    if (query) {
        dataObj['query'] = query
    }

    if (fbofbs) {
        dataObj['fbofbs'] = fbofbs
    }

    if (selectedCategoryBreadCrumbs?.length > 0) {
        dataObj['category_breadcrumb_label'] = selectedCategoryBreadCrumbs?.map(el => el?.label)?.join(';')
        dataObj['category_breadcrumb_id'] = selectedCategoryBreadCrumbs?.map(el => el?.value)?.join(';')
    }

    // TaskList

    if (searchValue) {
        dataObj['search'] = searchValue
    }

    if (selectedAuthor?.length > 0) {
        dataObj['author'] = selectedAuthor?.map(el => el)?.join(';')
    }

    if (selectedCoExecutor?.length > 0) {
        dataObj['co_executor'] = selectedCoExecutor?.map(el => el)?.join(';')
    }

    if (selectedExecutor?.length > 0) {
        dataObj['executor'] = selectedExecutor?.map(el => el)?.join(';')
    }

    if (sort1) {
        dataObj['direction'] = sort1
    }

    if (selectedStatus?.length > 0) {
        if (selectedStatus?.length > 0) {
            dataObj['status_label'] = selectedStatus?.map(el => el?.label)?.join(';')
            dataObj['status_id'] = selectedStatus?.map(el => el?.value)?.join(';')
        }
    }

    if (currentSort?.value) {
        if (currentSort?.value) {
            dataObj['sort_label'] = currentSort?.label
            dataObj['sort_id'] = currentSort?.value
        }
    }

    return rest ? { ...rest, ...dataObj } : dataObj
}

export const addFiltersByDataObj = (
    {
        setSelectedMp, setSelectedApi, setSelectedCategory, setSelectedBrand, setSelectedWarehouse, setSelectedOffer, setDate, setSelectedPeriod, setQuery,
        setFbofbs, setSelectedCategoryBreadCrumbs, setRest, dataObj,
        setSelectedAuthor,
        setSelectedExecutor,
        setSelectedCoExecutor,
        setCurrentSort,
        setSelectedStatus,
        setSearchValue,
        setSort1
    }
) => {
    const {
        mp_label, mp_id, api_label, api_id, category_label, category_id,
        brand_label, brand_id, warehouse_label, warehouse_id, offer_label,
        offer_id, date_from, date_to, period_label, period_value, query, fbofbs,
        category_breadcrumb_label, category_breadcrumb_id, search, author, co_executor, executor, status_label, status_id, sort_label, sort_id, direction
    } = dataObj

    if (author) {
        let data = author?.split(';')
        if (typeof setSelectedAuthor !== 'undefined') {
            const current_data = data.map((el) => el = parseInt(el))
            setSelectedAuthor(current_data)
        }
    }

    if (co_executor) {
        let data = co_executor?.split(';')
        if (typeof setSelectedCoExecutor !== 'undefined') {
            const current_data = data.map((el) => el = parseInt(el))
            setSelectedCoExecutor(current_data)
        }
    }

    if (executor) {
        let data = executor?.split(';')
        if (typeof setSelectedExecutor !== 'undefined') {
            const current_data = data.map((el) => el = parseInt(el))
            console.log(data)
            setSelectedExecutor(current_data)
        }
    }

    if (typeof search === 'string') {
        let data = search
        if (typeof setSearchValue !== 'undefined') {
            setSearchValue(data)
        }
    }

    if (typeof direction === 'string') {
        let data = direction
        if (typeof setSort1 !== 'undefined') {
            setSort1(data)
        }
    }

    if (typeof sort_label === 'string') {
        if (typeof  setCurrentSort !== 'undefined') {
            setCurrentSort({label: sort_label, value: sort_id})
        }
    }

    if(typeof status_label === 'string') {
        let data
        let mpArray = status_label?.split(';')
        let mpValueArray = status_id?.split(';')
        data = mpArray?.map((key, ind) => ({ label: key, value: mpValueArray?.[ind] }))

        if (typeof setSelectedStatus !== 'undefined') {
            setSelectedStatus(data)
        }
    }

    if (typeof mp_label === 'string') {
        let data
        let mpArray = mp_label?.split(';')

        if (Array.isArray(mp_label)) {
            let mpValueArray = api_id?.split(';')
            data = mpArray?.map((key, ind) => ({ label: key, value: mpValueArray?.[ind] }))
        } else {
            data = [{ label: mp_label, value: mp_id }]
        }
        if (typeof setSelectedMp !== 'undefined') {
            setSelectedMp(data)
        }
    }

    if (typeof api_label !== 'undefined') {
        let data
        let apiArray = api_label?.split(';')

        if (apiArray?.length > 0) {
            let apiValueArray = api_id?.split(';')
            data = apiArray?.map((key, ind) => ({ label: key, value: apiValueArray?.[ind] }))
        } else {
            data = [{ label: api_label, value: api_id }]

        }
        if (typeof setSelectedApi !== 'undefined') {
            setSelectedApi(data)
        }
    }

    if (typeof category_label !== 'undefined') {
        let data
        let categoryArray = category_label?.split(';')
        if (categoryArray?.length > 0) {
            let categoryValueArr = category_id?.split(';')
            data = categoryArray?.map((key, ind) => ({ label: key, value: categoryValueArr?.[ind] }))
        } else {
            data = [{ label: category_label, value: category_id }]
        }
        if (typeof setSelectedCategory !== 'undefined') {
            setSelectedCategory(data)
        }
    }

    if (typeof brand_label !== 'undefined') {
        let data
        let brandArray = category_label?.split(';')
        if (brandArray?.length > 0) {
            let brandValueArray = brand_id?.split(';')
            data = brandArray?.map((key, ind) => ({ label: key, value: brandValueArray?.[ind] }))
        } else {
            data = [{ label: brand_label, value: brand_id }]
        }
        if (typeof setSelectedBrand !== 'undefined') {
            setSelectedBrand(data)
        }
    }

    if (typeof warehouse_label !== 'undefined') {
        let data
        let warehouseArray = warehouse_label?.split(';')
        if (warehouseArray?.length > 0) {
            let warehouseValueArray = warehouse_id?.split(';')
            data = warehouseArray?.map((key, ind) => ({ label: key, value: warehouseValueArray?.[ind] }))
        } else {
            data = [{ label: warehouse_label, value: warehouse_id }]
        }
        if (typeof setSelectedWarehouse !== 'undefined') {
            setSelectedWarehouse(data)
        }
    }


    if (typeof offer_label !== 'undefined') {
        let data
        let offerArray = warehouse_label?.split(';')
        if (offerArray?.length > 0) {
            let offerValueArray = offer_id?.split(';')
            data = offerArray?.map((key, ind) => ({ label: key, value: offerValueArray?.[ind] }))
        } else {
            data = [{ label: offer_label, value: offer_id }]
        }
        if (typeof setSelectedOffer !== 'undefined') {
            setSelectedOffer(data)
        }
    }

    if (typeof date_from === 'string') {
        let date = []
        if (typeof date_from === 'string') {
            date.push(new Date(+date_from))
        }
        if (typeof date_to === 'string') {
            date.push(new Date(+date_to))
        }

        setDate(date)
    }

    if (typeof period_label !== 'undefined') {
        if (typeof setSelectedPeriod !== 'undefined') {
            setSelectedPeriod({ label: period_label, value: period_value })
        }
    }

    if (typeof query !== 'undefined') {
        if (typeof setQuery !== 'undefined') {
            setQuery(query)
        }
    }

    if (typeof fbofbs !== 'undefined') {
        if (typeof setFbofbs !== 'undefined') {
            setFbofbs(fbofbs)
        }
    }

    if (category_breadcrumb_label?.length > 0) {
        const labels = category_breadcrumb_label
        const values = category_breadcrumb_id
        if (typeof setSelectedCategoryBreadCrumbs !== 'undefined') {
            // setSelectedCategoryBreadCrumbs(labels?.map((el, ind) => ({label: el, value: values?.[ind]})))
        }
    } else {
        if (typeof setSelectedCategoryBreadCrumbs !== 'undefined') {
            // setSelectedCategoryBreadCrumbs([])
        }
    }

}