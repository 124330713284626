import { useState } from 'react'
import { TableBox, LineChart, ButtonBasic } from '@lk-gtcom/ecomlab-components'


const Customization = () => {
    const [currentItem, setCurrentItem] = useState({})
    const [data, setData] = useState([
        [
            {
                type: 'chart',
                id: 54235,
                width: 500,
                height: 400
            },
            {
                type: 'chart',
                id: 542355,
                width: 500,
                height: 400
            },
        ],
        {
            type: 'chart',
            id: 5423512,
            width: 500,
            height: 400
        },
        {
            type: 'table',
            id: 5544235,
            width: 900,
            height: 800
        },
    ])

    const isArrRow = (item, place) => {
        if(Array.isArray(item)){
            return <div 
                className='row' style={{display: 'flex', width: '100%', gap: '1em'}}
            >
                {item.map((elem, place) => typeChecker(elem, place))}
            </div>
        } else {
            return typeChecker(item, place)
        }
    }

    const onDrop = (index) => {
        setData(prev => {
            const newArr = prev.filter(({id}) => id !== currentItem.id)
            return [...newArr.slice(0, index), currentItem, ...newArr.slice(index, newArr.length)]
        })
    }

    const typeChecker = (elem, place) => {
        if(elem?.id){
            const { type, id, height, width } = elem
            switch(type){
                case 'chart':
                    return (
                        <div 
                            key={id}
                            draggable={true}
                            onDragStart={e => setCurrentItem(elem)}
                            onDrop={e => onDrop(place)}
                            onDragOver={e => e.preventDefault()}
                            style={{
                                border: '1px solid grey',
                                position: 'relative', resize: 'both',
                                overflow: 'auto',
                                height, width

                            }}
                        >
                            <div 
                                style={{
                                    position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                                    background: 'rgba(0,0,0,.2)', zIndex: 100,
                                }}
                            ></div>
                            <LineChart 
                            
                            />
                        </div>
                    )
                case 'table':
                    return (
                        <div 
                            key={id}
                            draggable={true}
                            onDragStart={e => setCurrentItem(elem)}
                            onDrop={e => onDrop(place)}
                            onDragOver={e => e.preventDefault()}
                            style={{
                                border: '1px solid grey', position: 'relative', 
                                resize: 'both', overflow: 'auto',
                                height, width
                            }}
                        >
                            <div 
                                style={{
                                    position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                                    background: 'rgba(0,0,0,.2)', zIndex: 100,
                                }}
                            ></div>
                            <TableBox 
                            
                            />
                        </div>
                    )
            }
        }
    }

    const createItem = (type) => {
        const size = {
            chart: {
                height: 400,
                width: 500
            },
            table: {
                width: 900,
                height: 800
            }
        }

        setData(prev => [...prev, {id: new Date().getTime(), type, ...size[type]}])
    }


  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '1em', width: '100%'}}>
        <div style={{display: 'flex', gap: '1em'}}>
            <ButtonBasic 
                text='Добавить таблицу'
                onClick={e => createItem('table')}
            />

            <ButtonBasic 
                text='Добавить график'
                onClick={e => createItem('chart')}
            />
        </div>
        {data.map((elem, place) => isArrRow(elem, place))}
    </div>
  )
}

export default Customization