import { useState, useEffect } from 'react';
import banner_no_data from './img/banner_no_data.svg';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { SceletonDoughnutChart } from './SceletonDoughnutChart/SceletonDoughnutChart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const colors = [
  '#3300FF',
  '#FFCC00',
  '#FF3366',
  '#339933',
  '#3399FF',
  '#FF8C22',
  '#9933CC',
  '#16B2A0',
  '#664EC5',
  '#FCD331',
  '#C91C47',
  '#11BE11',
  '#1477DA',
  '#E36D00',
  '#9000D8',
  '#00DCC2',
];

const DoughnutChart = ({
  nameChart = false,
  labels_prop = [],
  datasets_prop = [],
  loading = false,
  unit = '',
  maxHeight = false,
  lang = 'ru'
}) => {

  const translateNoData = () => {
    if (lang == 'ru') return 'Выберите фильтры для отображения графика ';
    if (lang == 'en') return 'Select filters to display the graph';
    if (lang == 'tr') return 'Grafiği görmek için filtreleri seçin';
    return 'Выберите фильтры для отображения графика ';
  };


  const [labels, setLabels] = useState([])
  const [datasets, setDatasets] = useState([])
  const [data, setData] = useState({ labels, datasets })
  const [options, setOptions] = useState({
    type: 'doughnut',
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index'
    },
    plugins: {
      tooltip: {
        backgroundColor: 'white',
        borderColor: '#53616E',
        titleColor: '#53616E',
        bodyColor: '#8F99A3',
        usePointStyle: 'circle',
        bodySpacing: 10,
        bodyFont: {
          font: {
            size: 14,
            family: 'IBMPlexSans',
          },
        },
        displayColors: true,
        padding: 12,
        footerSpacing: 4,
        caretPadding: 6,
        boxPadding: 10,
        caretSize: 8,
        borderColor: '#E0E7F2',
        borderWidth: 1,
        borderDash: [2, 2],
        borderRadius: 2,
        displayColors: true,
        boxPadding: 8,
      },
      legend: {
        display: true,
        position: 'right',
        labels: {
          boxWidth: 6,
          boxHeight: 6,
          usePointStyle: true
        },
      },
      title: {
        display: false,
        text: '',
      },
    },
  })

  useEffect(() => {

    if (datasets_prop.length > 0) {
      setDatasets([
        {
          data: datasets_prop,
          backgroundColor: colors,
          hoverOffset: 4
        }
      ])
    }
  }, [labels_prop, datasets_prop])

  useEffect(() => {
    setLabels(labels_prop)
  }, [labels_prop]);

  useEffect(() => {
    setData({
      labels,
      datasets
    })
  }, [datasets])

  return (
    <div className={`line_chart`} >
      {nameChart && <h3 className='title'>{nameChart}</h3>}
      <div className='chart-box' style={{ maxHeight: maxHeight ? maxHeight : '', minHeight: maxHeight ? maxHeight : '' }}>
        {loading ?
          <SceletonDoughnutChart />
          :
          ((data?.datasets?.length > 0)
            ?
            <Doughnut
              type='doughnut'
              options={options}
              data={data}
            />
            :
            <div className="line_chart__no-data">
              <img className="line_chart__no-data-img" src={banner_no_data} />
              <p className="line_chart__no-data-text">{translateNoData()}</p>
            </div>)
        }
      </div>
    </div>
  )
}

export { DoughnutChart }
