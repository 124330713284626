import customHeaders, { getSpace } from '../../../common/headers';
import blank_image from './img/blank_image.svg'
import { createPortal } from 'react-dom'
import { OlegHttps, OlegHttp } from '../../../fetchUrls';
import { useState, useEffect, useRef } from 'react';
import { DropDownSelector, ButtonBasic } from "@lk-gtcom/ecomlab-components";
import useGeneralStore from '../../../store/general';
import "./ModalAutorization.scss"

const ModalAutorization = ({
    setIsModalFeedback,
    setIsModalActive,
    fetchDetailedServiceData,
    isRequestFailed,
    isSocialNetwork,
    connectionIds = [],
    setSelectedSpace,
    optionList
}) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const [categoryOptions, setCategoryOptions] = useState([])
    const [serviceOptions, setServiceOptions] = useState([])

    const [selectedCategory, setSelectedCategory] = useState(isSocialNetwork ? { label: 'Социальная сеть' } : null)
    const [selectedService, setSelectedService] = useState(null)
    const serviceSelectorRef = useRef()

    const [serviceBtnArr, setServiceBtnArr] = useState([])
    const [selectedServiceItemId, setSelectedServiceItemId] = useState(-1)
    const [servicesList, setServicesList] = useState([])


    const fetchFilterAndServiceData = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/shop/info/all/services`
        } else {
            url = `${OlegHttp}/api/v1/shop/info/all/services`
        }

        fetch(url, { method: 'GET', headers })
            .then(res => res.json())
            .then(json => {
                let {
                    name_for_portal,
                    group,
                    group_by_portal,
                    icon_link,
                    id,
                    mp_name
                } = json

                const uniqueArray = (arr) => {
                    return arr.filter((value, index) => {
                        const _value = JSON.stringify(value.label);
                        return index === arr.findIndex(obj => {
                            return JSON.stringify(obj.label) === _value;
                        });
                    });
                }

                id = id.map((el, ind) =>
                    ({ id: el, mp_name: typeof name_for_portal[ind] !== 'string' ? mp_name[ind] : name_for_portal[ind], icon_link: icon_link[ind] }))
                group_by_portal = group_by_portal.filter(el => typeof el === 'string').map(el => ({ label: el, value: el }))
                group = group.map((el, ind) => ({ label: el, value: el, portal_group: 'All' })).filter(el => typeof el.label === 'string')



                setCategoryOptions([{ label: 'Все', value: 'All' }, ...uniqueArray(group)])
                // setServiceOptions([{ label: 'Все', value: 'All' }, ...group_by_portal])
                // setServiceBtnArr([...id])

            })
            .catch(err => console.error(err))
    }

    const fetchServiceData = (group = 'All', group_by_portal = 'All') => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${OlegHttps}/api/v1/shop/info/portal/group`
        } else {
            url = `${OlegHttp}/api/v1/shop/info/portal/group`
        }

        const headers_curr = { ...headers, space: selectedSpace?.value }

        const body = JSON.stringify({
            group: [group],
            group_by_portal: [group_by_portal],
            services_ids: connectionIds
        })

        fetch(url, { body, method: 'POST', headers: headers_curr })
            .then(res => res.json())
            .then(json => {
                let {
                    name_for_portal,
                    icon_link,
                    id,
                    mp_name,
                    group,
                    group_by_portal
                } = json


                group_by_portal = group_by_portal.filter(el => typeof el === 'string').map(el => ({ label: el, value: el }))
                id = id.map((el, ind) => ({ id: el, mp_name: mp_name[ind], icon_link: icon_link[ind] }))

                setServiceOptions([{ label: 'Все', value: 'All' }, ...group_by_portal])
                setServiceBtnArr([...id])
            })
            .catch(err => console.error(err))
    }

    useEffect(() => {
        fetchFilterAndServiceData()
    }, [selectedSpace])

    useEffect(() => {
        if (categoryOptions.length === 0) { return }
        if (isSocialNetwork) {
            setSelectedCategory(categoryOptions.find(option => option.value === 'Социальная сеть'))
        }
    }, [categoryOptions, selectedSpace])

    useEffect(() => {
        setServicesList([
            ...serviceBtnArr.map((el, ind) => {
                const {
                    mp_name,
                    id,
                    icon_link,
                } = el

                return (
                    <div className={`${(selectedServiceItemId === id ? 'notification-item_active' : 'notification-item')}`}
                        key={ind + id + mp_name}
                        onDoubleClick={e => {
                            if (id) {
                                fetchDetailedServiceData(selectedServiceItemId)
                                setSelectedServiceItemId(id)
                            }
                        }}
                        onClick={e => {
                            setSelectedServiceItemId(id)
                        }}
                    >
                        <img src={icon_link ? icon_link : blank_image}
                            onLoad={e => {
                                e.target.style.backgroundImage = 'none'
                            }}
                        />
                        <div className='changing-content'>
                            <p className='text'>{mp_name}</p>
                        </div>

                    </div>
                )
            })
        ])
    }, [serviceBtnArr, selectedServiceItemId])

    const handleCategoryChange = e => {
        setSelectedCategory(e)
        if (serviceSelectorRef.current) {
            serviceSelectorRef.current.clearValue()
        }
        // fetchServiceData(e?.value)
    }

    const handleServiceChange = e => {
        setSelectedService(e)
        // fetchServiceData(selectedCategory?.value, e?.value)
    }

    useEffect(() => {
        fetchServiceData(selectedCategory?.value, selectedService?.value)
    }, [selectedCategory, selectedService, selectedSpace]);

    return (
        <div className='modal-add-connection'>
            <div className='modal-add-connection__header'>
                <h2 className='title'>Выберите сервис для подключения в пространство</h2>
                <DropDownSelector
                    options_prop={optionList}
                    state={selectedSpace}
                    setState={e => setSelectedSpace(e)}
                    placeholder={'Выбор пространства'}
                    multi={false}
                />
            </div>

            <div className='select-group'>
                <DropDownSelector
                    options_prop={categoryOptions}
                    state={selectedCategory}
                    setState={e => handleCategoryChange(e)}
                    placeholder={'Выберите категорию'}
                    multi={false}
                    width='100%'
                    defaultValue={isSocialNetwork ? { label: 'Социальная сеть' } : undefined}
                    isDisabled={isSocialNetwork}
                />
                <DropDownSelector
                    ref={serviceSelectorRef}
                    state={selectedService}
                    options_prop={serviceOptions}
                    setState={e => handleServiceChange(e)}
                    placeholder={'Выберите сервис'}
                    width='100%'
                    multi={false}
                />
            </div>

            {isRequestFailed && <span className="error_warning active">
                В данный момент к этому сервису подключиться нельзя
            </span>}

            <div className='modal-add-connection__content'>
                {servicesList}
            </div>

            <div className='modal-add-connection__bottom-content'>
                <ButtonBasic
                    disabled={selectedServiceItemId == -1}
                    violet
                    width='auto'
                    size='56px'
                    text='Далее'
                    onClick={(e) => fetchDetailedServiceData(selectedServiceItemId)}
                />

                <p className='text'>Не нашли нужный сервис? <a className='link__blue' to='#'
                    onClick={(e) => {
                        e.preventDefault()
                        setIsModalActive(false)
                        setIsModalFeedback(true)
                    }}
                >&nbsp;Напишите нам</a>
                </p>
            </div>
        </div>
    )
}

export { ModalAutorization }
