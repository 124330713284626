import { useState, useEffect, useRef } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { ArticleMain } from '../../components/ArticleMain/ArticleMain';
import { Article } from '../../components/Article/Article';
import {
  MultilevelSidebar,
  Breadcrumb,
  ViewSwitch,
  DropDownSelector,
  InputDynamicWidth,
  ButtonBasic,
  usePagination,
} from '@lk-gtcom/ecomlab-components';
import { RavshanArticlesHttps } from '../../fetchUrls';
import { BASE_ROUTE } from '../../App';
import customHeaders, { getSpace } from '../../common/headers';
import { useResetOnBaseURL } from '../../common/useResetOnBaseURL';
import { handleApiResponseOrThrow } from '../../common/utils';
import menu_icon from './img/bx-menu.svg';
import { ModalChatBot } from '../../components/Modal/ModalChatBot/ModalChatBot';
import { ModalAddChatBot } from '../../components/Modal/ModalAddChatBot/ModalAddChatBot';
import { ModalMiltilevelSidebarAdd } from '../../components/Modal/ModalMiltilevelSidebarAdd/ModalMiltilevelSidebarAdd';
import { ModalMiltilevelSidebarSettings } from '../../components/Modal/ModalMiltilevelSidebarSettings/ModalMiltilevelSidebarSettings';
import useGeneralStore from '../../store/general';
import './Main.scss';

const SORT_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC',
};

const SORTING_OPTIONS = [
  {
    label: 'По дате: сначала новые',
    headerField: 'Дата публикации',
    direction: SORT_DIRECTIONS.DESC,
    //  radioGroup: (value, onChange) =>  <RadioGroup fromLabel='Сначала старые' toLabel='Сначала новые' value={value} onChange={onChange}/>
  },
  {
    label: 'По дате: сначала старые',
    headerField: 'Дата публикации',
    direction: SORT_DIRECTIONS.ASC,
    //  radioGroup: (value, onChange) =>  <RadioGroup fromLabel='Сначала старые' toLabel='Сначала новые' value={value} onChange={onChange}/>
  },
  {
    label: 'По фамилии автора: От а до я',
    headerField: 'Автор',
    direction: SORT_DIRECTIONS.DESC,
    //   radioGroup: (value, onChange) => <RadioGroup fromLabel='От а до я' toLabel='От я до а' value={value} onChange={onChange}/>
  },
  {
    label: 'По фамилии автора: От я до а',
    headerField: 'Автор',
    direction: SORT_DIRECTIONS.ASC,
    //   radioGroup: (value, onChange) => <RadioGroup fromLabel='От а до я' toLabel='От я до а' value={value} onChange={onChange}/>
  },
  {
    label: 'По названию: От а до я',
    headerField: 'Название',
    direction: SORT_DIRECTIONS.ASC,
    //   radioGroup: (value, onChange) => <RadioGroup fromLabel='От а до я' toLabel='От я до а' value={value} onChange={onChange}/>
  },
  {
    label: 'По названию: От я до а',
    headerField: 'Название',
    direction: SORT_DIRECTIONS.DESC,
    //   radioGroup: (value, onChange) => <RadioGroup fromLabel='От а до я' toLabel='От я до а' value={value} onChange={onChange}/>
  },
  {
    label: 'По времени чтения: Сначала короткие',
    headerField: 'Время чтения',
    direction: SORT_DIRECTIONS.DESC,
    //   radioGroup: (value, onChange) => <RadioGroup fromLabel='Сначала короткие' toLabel='Сначала длинные' value={value} onChange={onChange}/>
  },
  {
    label: 'По времени чтения: Сначала длинные',
    headerField: 'Время чтения',
    direction: SORT_DIRECTIONS.ASC,
    //   radioGroup: (value, onChange) => <RadioGroup fromLabel='Сначала короткие' toLabel='Сначала длинные' value={value} onChange={onChange}/>
  },
];

const STATUS_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Redact', value: 'redact' },
  { label: 'Update', value: 'update' },
];

const Main = ({
  activeLang,
  pathBread,
  setCurrentArticle,
  currentArticle,
  pathsWebName,
  setPathsWebName,
  setTitle,
  setDescription,
  title,
  description,
  paths,
  setPaths,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const paginatorRef = useRef();
  const paginatorCallback2 = useRef();

  const [
    page,
    limit,
    total,
    setTotal,
    Paginator,
    resetPaginator,
    paginatorTransition,
  ] = usePagination(
    paginatorCallback2.current,
    [],
    paginatorRef.current?.params
  );

  const navigate = useNavigate();
  const path = useParams()['*'];
  const [hideMenu, setHideMenu] = useState(true);
  // const [currentArticle, setCurrentArticle] = useState({})
  const [initialRender, setInitialRender] = useState(true);

  const [selectedTopLevelValue, setSelectedTopLevelValue] = useState(0);
  const [selectedEndLevelValue, setSelectedEndLevelValue] = useState(0);

  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [activePopularTags, setActivePopularTags] = useState(new Set([]));
  const [total2, setTotal2] = useState(0);

  const [selectView, setSelectView] = useState('Tile-short');
  const [sidebarState, setSidebarState] = useState([]);

  const [sortingOption, setSortingOption] = useState(null);
  const sortingRef = useRef();
  const [serch, setSearch] = useState('');

  const [selectedState, setSelectedState] = useState([]);
  const [isArticle, setIsArticle] = useState(false);

  const [isModalAddChatBot, setIsModalAddChatBot] = useState(false);
  const [isModalChatBot, setIsModalChatBot] = useState(false);

  const fetchSidebar = () => {
    setSidebarState([]);
    const url = `${RavshanArticlesHttps}/get_portals_by_lang`;

    const body = JSON.stringify({
      language: activeLang.toLowerCase(),
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => handleApiResponseOrThrow(res))
      .then((json) => {
        if (json?.length > 0) {
          setSidebarState((prev) => [...prev, ...json]);
        }
      })
      .catch((err) => console.error(err));
  };

  useResetOnBaseURL(BASE_ROUTE, () => {
    setSelectedEndLevelValue(0);
    setSelectedTopLevelValue(0);
  });

  useEffect(() => {
    if (initialRender) {
      fetchSidebar();
      setInitialRender(false);
    }
  }, []);

  // useEffect(() => {
  //   if (initialRender) return;
  //   navigate(BASE_ROUTE);
  //   fetchSidebar();
  //   setSelectedEndLevelValue(0);
  //   setSelectedTopLevelValue(0);
  // }, [activeLang]);

  useEffect(() => {
    setTotal(null);
    if (typeof +path?.at(-1) === 'number' && !isNaN(+path?.at(-1))) {
      setIsArticle(true);
    } else {
      setIsArticle(false);
    }
  }, [path]);

  const saveWidthColumn = () => {
    if (selectView == 'Table') {
      const colWidth = [...document.querySelectorAll('.col-th-border')];
      if (colWidth.length > 0) {
        const arrWidth = colWidth.map((el) =>
          el.style.width ? el.style.width : '247px'
        );
        sessionStorage.setItem('col-width', arrWidth);
      }
    }
  };

  useEffect(() => {
    saveWidthColumn();
  }, [selectedEndLevelValue]);

  // const [paths, setPaths] = useState([])
  // const [pathsWebName, setPathsWebName] = useState([])
  // const [title, setTitle] = useState('')
  // const [description, setDescription] = useState('')
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [isModalSettings, setIsModalSettings] = useState(false);

  const [selectedItemId, setSelectedItemId] = useState();

  return (
    <>
      {isModalAddChatBot && (
        <ModalAddChatBot setIsModal={(e) => setIsModalAddChatBot(e)} />
      )}

      {isModalChatBot && (
        <ModalChatBot setIsModal={(e) => setIsModalChatBot(e)} />
      )}

      <div className="article-main-container">
        {isModalAdd && (
          <ModalMiltilevelSidebarAdd setIsModal={(e) => setIsModalAdd(e)} />
        )}
        {isModalSettings && (
          <ModalMiltilevelSidebarSettings
            data={sidebarState}
            setIsModal={(e) => setIsModalSettings(e)}
          />
        )}

        {/* <div className="breadcrumb-and-select-view-box">
          <nav className="breadcrumb__nav">
            <Breadcrumb
              //   localizedPath={langPath}
              breadcrumbs={
                path == 'results-found' ? false : currentArticle?.breadcrumbs
              }
              elementTitle={
                path == 'results-found'
                  ? 'Результат поиска'
                  : currentArticle?.title
              }
              baseRoute={path == 'results-found' ? false : BASE_ROUTE}
              noPortal={path == 'results-found' ? false : true}
              newsCount={total2}
              // hideTitle={path == 'results-found' ? false : !(path?.split('/')?.indexOf(BASE_ROUTE) >= 0)}
              hideTitle={true}
              mainRoute={path == 'results-found' && false}
              paths={paths}
              setPaths={(e) => setPaths(e)}
              pathsWebName={pathsWebName}
              setPathsWebName={(e) => setPathsWebName(e)}
              mainPath="/wiki/articles/main" // для wiki
              pathBread="/wiki" // для wiki
            />
            <div className="header-breadcrumb__btn-group">
              <ButtonBasic
                width="146px"
                green
                text="Создать чат-бота"
                onClick={(e) => setIsModalAddChatBot(true)}
              />
              <ButtonBasic
                width="170px"
                whiteGreen
                onClick={(e) => setIsModalChatBot(true)}
                text={
                  <div className="header-breadcrumb__btn">
                    <img src={menu_icon} />
                    Список чат ботов
                  </div>
                }
              />
            </div>
          </nav>
        </div> */}

        <div className="settings__content">
          {/* <PopularTags tags={popular_tags} activePopularTags={activePopularTags} setActivePopularTags={(e) => selectedPopularTag(e)} /> */}
          <div className="flex">
            {/* <MultilevelSidebar
              setData={(e) => setSidebarState(e)}
              data={sidebarState}
              maxDepth={3}
              BASE_ROUTE={BASE_ROUTE}
              endLevelValue={selectedEndLevelValue}
              topLevelValue={selectedTopLevelValue}
              onPortalClick={(id) => {
                setSelectedTopLevelValue(id);
                setSelectedEndLevelValue(null);
              }}
              onLevelClick={(id) => {
                setSelectedEndLevelValue(id);
                setSelectedTopLevelValue(null);
              }}
              setPaths={(e) => setPaths(e)}
              setPathsWebName={(e) => setPathsWebName(e)}
              setTitle={(e) => {
                setTitle(e);
              }}
              setDescription={(e) => setDescription(e)}
              isBtnAdd={true}
              isBtnSwttings={true}
              setIsModalAdd={(e) => setIsModalAdd(e)}
              setIsModalSettings={(e) => setIsModalSettings(e)}
            /> */}

            <ModalMiltilevelSidebarSettings
              setTitle={(e) => {
                setTitle(e);
              }}
              setPathsWebName={(e) => setPathsWebName(e)}
              onPortalClick={(id) => {
                setSelectedTopLevelValue(id);
                setSelectedEndLevelValue(null);
              }}
              setPaths={(e) => setPaths(e)}
              selectedItemId={selectedItemId}
              setSelectedItemId={setSelectedItemId}
            />

            <div className="main-content">
              {path == 'main' ||
              path == 'Kontakty' ||
              path == 'contacts' ||
              Object.keys(currentArticle)?.length > 0 ||
              path == 'level/sublevel/cpu/new' ? null : (
                <div className="toolbar-info">
                  {path == 'articles' ? (
                    <h1 className="title_main">Подборки</h1>
                  ) : (
                    <h1 className="title_main">
                      {path == 'results-found'
                        ? 'Результат поиска'
                        : paths?.at(-1)}
                    </h1>
                  )}
                  {path != 'articles/Вопрос-ответ' &&
                    path != 'articles' &&
                    !path.includes('article/') && (
                      <ViewSwitch
                        selectView={selectView}
                        setSelectView={(e) => setSelectView(e)}
                      />
                    )}
                </div>
              )}

              {path == 'main' ||
              path == 'Kontakty' ||
              path == 'contacts' ||
              Object.keys(currentArticle)?.length > 0 ||
              path == 'level/sublevel/cpu/new'
                ? null
                : selectView !== 'Table' &&
                  path != '/contacts' && (
                    <div className="article-main__header">
                      {selectView !== 'Table' && (
                        <DropDownSelector
                          style={{ maxWidth: '360px' }}
                          isClearable={false}
                          options_prop={SORTING_OPTIONS.map((so) => ({
                            label: so.label,
                            value: {
                              headerField: so.headerField,
                              direction: so.direction,
                            },
                          }))}
                          state={sortingOption}
                          setState={(e) => {
                            setSortingOption(e);
                            sortingRef.current = {
                              param: e.value?.headerField,
                              direction: e.value?.direction,
                            };
                          }}
                          defaultValue={{
                            label: SORTING_OPTIONS[0].label,
                            value: {
                              param: SORTING_OPTIONS[0].headerField,
                              direction: SORTING_OPTIONS[0].direction,
                            },
                          }}
                        />
                      )}

                      <DropDownSelector
                        isClearable={false}
                        options_prop={STATUS_OPTIONS}
                        multi={false}
                        state={selectedState}
                        setState={(e) => setSelectedState(e)}
                        defaultValue={STATUS_OPTIONS[0]}
                      />

                      <InputDynamicWidth
                        onChange={(e) => setSearch(e)}
                        position="left"
                        maxWidth="100%"
                        drop={false}
                      />
                    </div>
                  )}

              <div
                className="settings__main-box_white"
                style={{
                  overflow: selectView == 'Table' ? 'hidden' : 'visible',
                }}
              >
                <Routes>
                  {!isArticle && (
                    <Route
                      path={`/:level/:sublevel?/:sublevel2?`}
                      element={
                        <ArticleMain
                          selectedState={selectedState}
                          sortingRef={sortingRef}
                          setSelectedTags={(e) => setSelectedTags(e)}
                          selectedTags={selectedTags}
                          setTags={(e) => setTags(e)}
                          activeLang={activeLang}
                          selectedEndLevelValue={selectedEndLevelValue}
                          selectedPortalValue={selectedTopLevelValue}
                          pathBread={pathBread}
                          selectView={selectView}
                          setTotalParent={setTotal2}
                          paths={paths}
                          setIsArticle={(e) => setIsArticle(e)}
                        />
                      }
                    />
                  )}

                  <Route
                    path={`/:level/:sublevel?/:cpu?/new`}
                    element={
                      <Article
                        isNew={true}
                        pathBread={'wiki'}
                        activeLang={activeLang}
                        setCurrentArticle={setCurrentArticle}
                        setTags={(e) => setTags(e)}
                        tags={tags}
                        pathsWebName={pathsWebName}
                        setPathsWebName={(e) => setPathsWebName(e)}
                        setTitle={(e) => setTitle(e)}
                        setDescription={(e) => setDescription(e)}
                        selectedItemId={selectedItemId}
                      />
                    }
                  />

                  <Route
                    path="/:lang/:level/:cpu/:id"
                    // path='/:lang/article/:cpu/:id'
                    element={
                      <Article
                        isNew={false}
                        activeLang={activeLang}
                        pathBread={'wiki'}
                        setCurrentArticle={setCurrentArticle}
                        setTags={(e) => setTags(e)}
                        tags={tags}
                        setPaths={(e) => setPaths(e)}
                        pathsWebName={pathsWebName}
                        setPathsWebName={(e) => setPathsWebName(e)}
                        setTitle={(e) => setTitle(e)}
                        setDescription={(e) => setDescription(e)}
                      />
                    }
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Main };
