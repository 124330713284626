import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import "./SkeletonImg.scss"

const SkeletonImg = ({ height = '400' }) => {

    return (
        <div className='sceleton-img-product'>
            <Stack className='sceleton-img-product__content'>
                <Skeleton
                    variant="rectangular"
                    sx={{ height: '352px', width: '300px' }} />
            </Stack>
        </div>
    )
}

export { SkeletonImg }