import icon from './img/icon.svg'
import { useState, useEffect } from 'react';
import { DropDownSelector, ButtonBasic, TextareaDynamicPlaceholder } from "@lk-gtcom/ecomlab-components";
import './ModalMassEditing.scss'

const ModalMassEditing = ({
    setIsModal,
    createMassTemplate,
    selectedGroup,
    groupList,
    setSelectedGroupId,
    createTemplate,
    selectedGroupId
}) => {

    const [value, setValue] = useState('')
    const options_prop = groupList.map(el => ({ label: el?.group_name, value: el?.id }))
    const current_group = options_prop.filter(({ value }) => value == selectedGroupId)
    const [selectedGroupFilter, setSelectedGroupFilter] = useState(current_group ? current_group[0] : [])


    const onSaveHandler = () => {

        if (value?.length > 0) {
            if (!selectedGroupFilter?.value) {
                const body = value?.split(/\n\n/).map((el) => el)
                createTemplate(body)
            } else {
                createMassTemplate(value, selectedGroupFilter?.value)
            }
        }
        setIsModal(false)
    }

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='modal-mass-editing'>
                <div className='modal-mass-editing__header'>
                    <h1 className='title_main'>Массовое добавление</h1>
                    <DropDownSelector
                        options_prop={options_prop}
                        state={selectedGroupFilter}
                        setState={e => setSelectedGroupFilter(e)}
                        placeholder='Выберите группу'
                    />
                </div>

                <div className='modal-mass-editing__content'>
                    <div className='toolbar'>
                        <p className='text'>Подсказка “разделение шаблонов ответов происходит при нажатии дважды на enter”</p>
                        <div className='right-content'>
                            {/* <button className='btn'><img src={icon} />Выгрузить</button> */}
                            <button className='btn'><img src={icon} />Загрузить</button>
                        </div>
                    </div>

                    <TextareaDynamicPlaceholder
                        width='100%'
                        changeValue={''}
                        placeholder="Введите текст"
                        onChange={(e) => setValue(e)}
                    />
                    <ButtonBasic
                        green
                        style={{ marginTop: '12px' }}
                        size='56px'
                        text='Сохранить'
                        width='162px'
                        onClick={e => onSaveHandler()}
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalMassEditing }