import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import "./Skeleton.scss"

const SkeletonRight = ({ height = '400' }) => {

    return (
        <div className='sceleton-product-data' style={{
            marginTop: '-10px'
        }}>
            <Stack className='sceleton-product-data__content'>
            {/* <Skeleton variant="circular" width={160} height={160} /> */}
                {[...Array(4)].map((el, ind) => {
                    return <div className='sceleton-product-data__col'>
                       <Skeleton 
                       key={el + ind}
                       variant="rectangular" 
                       sx={{height: '42px', width: ind === 0 ? '40%' : '100%'}} />
                    </div>
                })}
            </Stack>
        </div>
    )
}

export { SkeletonRight }