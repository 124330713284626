import React, { useEffect, useState } from 'react';
import { SearchGlobalInput } from '@lk-gtcom/ecomlab-components';
import { Andrey2Https, Andrey2Http } from '../../fetchUrls';
import headers from '../../common/headers';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from '../../hooks/useDebounce';

const SkuSearch = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const mp = params.get('mp');

  useEffect(() => {
    setSearchValue('');
  }, [mp]);

  const fetchSearchValue = (
    [search, page = 1],
    abortController = new AbortController()
  ) => {
    if (search?.length >= 3) {
      setSearchData([]);
      // let url;
      // if (!window.location.hostname.match('localhost')) {
      //   url = `${Andrey2Https}/api/v1/ecomru/competitors/ozon_analysis/sku/search_string/`;
      // } else {
      //   url = `${Andrey2Http}/api/v1/ecomru/competitors/ozon_analysis/sku/search_string/`;
      // }

      let url;
      if (mp === 'ozon') {
        url = window.location.hostname.match('localhost')
          ? `${Andrey2Http}/api/v1/ecomru/competitors/ozon_analysis/sku/search_string/`
          : `${Andrey2Https}/api/v1/ecomru/competitors/ozon_analysis/sku/search_string/`;
      } else if (mp === 'wb') {
        url = window.location.hostname.match('localhost')
          ? `${Andrey2Http}/api/v1/ecomru/sku/search_string/`
          : `${Andrey2Https}/api/v1/ecomru/sku/search_string/`;
      } else {
        console.error('Unknown marketplace');
        return;
      }

      let [date_from, date_to] = date;

      date_from = new Date(date_from + 86401000)
        ?.toISOString()
        ?.split('T')?.[0];
      date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

      const body = JSON.stringify({
        // date_from,
        // date_to,
        page,
        limit: 15,
        ...(mp === 'ozon'
          ? { search_filter: search }
          : { product_search: search }),
      });

      fetch(url, {
        body,
        method: 'POST',
        headers,
        signal: abortController.signal,
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error(JSON.stringify(res.json()));
          }
        })
        .then((json) => {
          const product_list = json?.Product.map(
            ({
              image,
              product,
              product_name,
              category,
              brand,
              product_id,
              nm_id,
            }) => {
              return {
                image: image,
                product: mp === 'ozon' ? product : product_name,
                category: category,
                brand: brand,
                offer_id: mp === 'ozon' ? product_id : nm_id,
              };
            }
          );
          setSearchData({ product: product_list });
        })
        .catch((err) => {
          if (abortController.signal.aborted) return;
          console.error(err);
        });
    }
  };

  const fetchSearchHandler = useDebounce(fetchSearchValue, 1000);

  useEffect(() => {
    if (search) {
      navigate(`/analysis-by-SKU/general?nmid=${search}`);
    }
  }, [search]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '1em',
      }}
    >
      <h2>Найдите продукт по названию или SKU</h2>
      <SearchGlobalInput
        {...searchData}
        activeLang="ru"
        setSearch={({ offer_id }) => {
          setSearch(offer_id);
        }}
        fetchSearch={fetchSearchHandler}
        setSearchData={(e) => setSearchData(e)}
        value={searchValue}
      />
    </div>
  );
};

export default SkuSearch;
