import customHeaders, { getSpace } from '../../common/headers';
import rules_tab from './img/rules_tab.svg';
import refresh_icon from './img/refresh_icon.svg';
import setting_balances_tab from './img/setting_balances_tab.svg';
import { useDataTable } from '../../common/hooks';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef, useCallback } from 'react';
import {
  DropDownSelector,
  SearchComponent,
  ButtonBasic,
  TableBox,
} from '@lk-gtcom/ecomlab-components';
import {
  fetchApiFilter,
  fetchCategoryFilter,
  fetchBrandFilter,
  fetchWarehouseFilter,
} from '../../common/filtersFunc';
import {
  KonstantinCreateProductHttps,
  KonstantinMasterHttps,
  KonstantinMasterHttp,
} from '../../fetchUrls';
import useGeneralStore from '../../store/general';
import './SetRemainingGoods.scss';

const options_period = [
  {
    label: 'дням',
    value: 'day',
  },
  {
    label: 'неделям',
    value: 'week',
  },

  {
    label: 'месяцам',
    value: 'month',
  },
];

const btn_tab = [
  {
    label: 'Установка остатков',
    value: 'setting-balances',
    icon: setting_balances_tab,
  },
  {
    label: 'Правила установки остатков',
    value: '',
    icon: rules_tab,
  },
];

const SetRemainingGoods = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [pageValue, setPageValue] = useState('product');

  const [apiData, setApiData] = useState([]);
  const [warehouseData, setWarehouseData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [searchData, setSearchData] = useState([]);

  const [shopPage, setShopPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [apiPage, setApiPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');

  const [warehousePage, setWarehousePage] = useState(1);
  const [warehouseQuery, setWarehouseQuery] = useState('');

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [dateFrom, dateTo] = date;

  const [isDisabledBtn, setIsDisabledBtn] = useState(true);

  const activeTab = useParams()['*'];

  const paginatorRef = useRef();
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;

  const fetchTableData = (params, abortController = new AbortController()) => {
    let sort;
    let filtersParam;

    if (params) {
      [sort, filtersParam] = params;
    }

    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/mp-products/tables/stocks${sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
        }`;
    } else {
      url = `${KonstantinMasterHttp}/mp-products/tables/stocks${sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
        }`;
    }

    // let brand_id_list
    // let category_id_list
    // let offer_id_name_search = searchValue

    // if (selectedBrand?.length > 0) {
    //     brand_id_list = selectedBrand?.map(el => el.value)
    // }

    // if (selectedCategory?.length > 0) {
    //     category_id_list = selectedCategory?.map(el => el.value)
    // }

    // const body = JSON.stringify({
    //     category_id_list,
    //     brand_id_list,
    //     page: paginatorRef.current.page,
    //     limit: paginatorRef.current.limit,
    //     filters: filtersParam,
    //     offer_id_name_search
    // })

    const body = JSON.stringify({
      page: paginatorRef.current.page,
      limit: paginatorRef.current.limit,
      filters: filtersParam,
      api_id_list: selectedApi?.map((el) => el?.value),
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });
  };

  const fetchSearch = (
    [search, page = 1],
    abortController = new AbortController()
  ) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/mp-products/filters/offer-id-name`;
    } else {
      url = `${KonstantinMasterHttp}/mp-products/filters/offer-id-name`;
    }

    // let [date_from, date_to] = date
    // date_from = new Date(date_from+10)?.toISOString().split('T')?.[0]
    // date_to = new Date(date_to)?.toISOString().split('T')?.[0]

    let api_id_list;
    let brand_id_list;
    let category_id_list;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    const body = JSON.stringify({
      api_id_list,
      category_id_list,
      brand_id_list,
      page: page,
      limit: 10,
      search_param: search,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        let result = [];
        console.log(json);
        if (json) {
          result = json?.map(({ id, name, image }, ind) => {
            return { label: name, value: id, image: image };
          });
        }
        setSearchData(result);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    setPageValue(activeTab);
  }, [activeTab]);

  useEffect(() => {
    fetchApiFilter({
      selectedSpace: selectedSpace?.value,
      page: apiPage,
      setApiData: (e) => setApiData(e),
      searchParam: apiQuery,
    });
  }, [selectedSpace, apiPage, apiQuery]);

  useEffect(() => {
    fetchCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      brandData,
      selectedBrand,
      selectedApi,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
  }, [
    selectedSpace,
    apiData,
    dateFrom,
    dateTo,
    selectedApi,
    categoryPage,
    categoryQuery,
    selectedBrand,
  ]);

  useEffect(() => {
    fetchBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      selectedApi,
      setBrandData: (e) => setBrandData(e),
    });
  }, [
    selectedSpace,
    categoryData,
    selectedApi,
    selectedCategory,
    brandPage,
    brandQuery,
  ]);

  useEffect(() => {
    fetchWarehouseFilter({
      selectedSpace: selectedSpace?.value,
      page: warehousePage,
      apiData,
      categoryData,
      selectedApi,
      selectedCategory,
      selectedBrand,
      brandData,
      dateFrom,
      dateTo,
      searchParam: warehouseQuery,
      setWarehouseData: (e) => setWarehouseData(e),
    });
  }, [
    selectedSpace,
    brandData,
    selectedApi,
    selectedCategory,
    selectedBrand,
    warehousePage,
    warehouseQuery,
    selectedWarehouse,
  ]);

  useEffect(() => {
    const abortController = new AbortController();

    fetchTableData([], abortController);

    return () => abortController.abort();
  }, [selectedSpace, selectedApi, selectedBrand, selectedCategory]);

  const handleSearch = useCallback((e) => {
    const abortController = new AbortController();
    fetchSearch(e, abortController);
    return () => abortController.abort();
  });

  const fetchUpdateOneStock = (
    apiId,
    mpId,
    warehouseId,
    offerId,
    stock,
    setStatus
  ) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinCreateProductHttps}/mp_products/stocks`;
    } else {
      url = `${KonstantinCreateProductHttps}/mp_products/stocks`;
    }

    const stocks = [
      {
        offer_id: offerId,
        warehouse_id: warehouseId,
        stock: stock,
      },
    ];
    const body = JSON.stringify({
      mp_id: mpId,
      api_id: apiId,
      stocks,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
    })
      .then((res) => {
        if (res.ok) {
          setStatus('success');
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        console.log(json);
        // const abortController = new AbortController();
        // fetchTableData([], abortController);
        // return () => abortController.abort();
      })
      .catch((err) => {
        setStatus('error');
        console.error(err);
      });
  };

  const handleSaveOneRemainder = (e) => {
    const { inputEvent, actionEvent, setStatus } = e;
    const offerId = inputEvent?.id;
    const stock = +inputEvent?.value;
    const mpId = actionEvent?.[0]?.mp_id;
    const apiId = actionEvent?.[0]?.api_id;
    const warehouseId = actionEvent?.[0]?.warehouse_id;

    if (offerId && stock && apiId && mpId && warehouseId) {
      setStatus('loading');
      fetchUpdateOneStock(apiId, mpId, warehouseId, offerId, stock, setStatus);
    } else {
      setStatus('error');
    }
  };

  return (
    <div className="set-remaining-goods">
      <h1 className="title_main">Установка остатков</h1>

      <div className="toolbar-top">
        <FilterContainer
          onReset={(e) => {
            setSelectedWarehouse([]);
            setSelectedCategory([]);
            setSelectedBrand([]);
          }}
        >
          <DropDownSelector
            options_prop={apiData}
            state={selectedApi}
            setState={(e) => setSelectedApi(e)}
            placeholder="Площадка"
            fetchCallback={(e) => setApiPage(e)}
            setInputChange={(e) => setApiQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
            multi={true}
          />

          <DropDownSelector
            options_prop={categoryData}
            state={selectedCategory}
            setState={(e) => setSelectedCategory(e)}
            className="connections_page_selector"
            placeholder="Категория"
            fetchCallback={(e) => setCategoryPage(e)}
            setInputChange={(e) => setCategoryQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
            multi
          />
          <DropDownSelector
            options_prop={brandData}
            state={selectedBrand}
            setState={(e) => setSelectedBrand(e)}
            className="connections_page_selector"
            placeholder="Бренд"
            fetchCallback={(e) => setBrandPage(e)}
            setInputChange={(e) => setBrandQuery(e)}
            defaultValue={[{ label: 'Все', value: 'all' }]}
            multi
          />

          <DropDownSelector
            options_prop={warehouseData}
            state={selectedWarehouse}
            setState={(e) => setSelectedWarehouse(e)}
            className="connections_page_selector"
            placeholder="Склад"
            defaultValue={[{ label: 'Все', value: 'all' }]}
            multi
          />
          <SearchComponent
            searchValue={searchValue}
            errSearch={false}
            placeholder="Поиск товара"
            errText="Неверный товар"
            setSearchValue={setSearchValue}
            fetchData={fetchTableData}
            fetchCallback={handleSearch}
            data={searchData}
          />
        </FilterContainer>

        <ButtonBasic
          violet
          disabled={isDisabledBtn}
          text={
            <div className="content-btn">
              Обновить остатки
              <img src={refresh_icon} />
            </div>
          }
          width="auto"
          size="40px"
        />
      </div>

      <div className="toolbar-medium"></div>

      <TableBox
        {...tableProps}
        paginator={true}
        ref={paginatorRef}
        fetchCallback={fetchTableData}
        tableUrl={tableUrl}
        tableBody={tableBody}
        onInputAction={(e) => {
          handleSaveOneRemainder(e);
        }}
      />
    </div>
  );
};

export { SetRemainingGoods };
