import customHeaders, { getSpace } from '../../common/headers';
import add_icon from './img/add.svg';
import { useEffect, useState } from 'react';
import { ButtonBasic, TableBox } from '@lk-gtcom/ecomlab-components';
import { ArtemBilling2Https, ArtemBilling2Http } from '../../fetchUrls';
import AddBalanceModal from '../../components/AddBalanceModal';
import useGeneralStore from '../../store/general';
import './Payment.scss';

const Payment = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [isModalBalance, setIsModalBalance] = useState(false);
  const [balance, setBalance] = useState(0);
  const [currency, setCurrency] = useState('₽');

  console.log(headers.space, 'headers');

  const fetchBalanceCount = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${ArtemBilling2Https}/balance`;
      // url = `${ArtemBilling2Https}/api/v2/get-space-balance?space_id=${headers?.space}`;
    } else {
      url = `${ArtemBilling2Http}/balance`;
      // url = `${ArtemBilling2Http}/api/v2/get-space-balance?space_id=${headers?.space}`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then(({ balance, currency }) => {
        setBalance(balance?.toLocaleString());
        setCurrency(currency === 'RUB' ? '₽' : currency);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchBalanceCount();
  }, [selectedSpace]);

  return (
    <div className="payment">
      {isModalBalance && (
        <AddBalanceModal setIsModalActive={(e) => setIsModalBalance(false)} />
      )}
      <div className="payment__header">
        <h1 className="payment__title">Оплата</h1>
      </div>

      <div className="payment__content">
        <div className="payment__balance">
          <h3 className="payment__title-min">Баланс</h3>
          <div className="payment__balance-content">
            <p className="payment__sum">
              {balance} {currency}
            </p>
            <ButtonBasic
              green
              width="190px"
              text={
                <div className="content-btn">
                  <img src={add_icon} alt="icon" />
                  Пополнить баланс
                </div>
              }
              onClick={(e) => setIsModalBalance(true)}
            />
          </div>
        </div>

        <div className="payment__promocode">
          <h3 className="payment__title-min">Есть промокод?</h3>

          <div className="payment__promocode-content">
            <span className="payment__placeholder">Введите промокод</span>
            <div className="payment__input-box">
              <input className="payment__input" />
              <ButtonBasic
                width="150px"
                lilac
                size="32px"
                text={
                  <div className="content-btn">
                    <img src={add_icon} alt="icon" />
                    Активировать
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Payment };
