import { Link } from 'react-router-dom';
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import { useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import "./Invoice.scss"

const header_table = [
    '№',
    'Наименование работ, услуг',
    'Кол-во',
    'Ед.',
    'Цена',
    'Сумма',
]

const body_table = [
    [
        1,
        'Услуга 1 за период дд.мм.ггг - дд.мм.гггг',
        '',
        'Услуга',
        800,
        800
    ],
    [
        1,
        'Услуга 1 за период дд.мм.ггг - дд.мм.гггг',
        '',
        'Услуга',
        800,
        800
    ]
]

const Invoice = (props) => {

    const {
        bik,
        bank_client,
        number,
        date,
        executor,
        client,
        inn_client,
        account_client,
        kpp_client,
        initials_company_director,
        initials_accountant,
    } = props

    const reportTemplateRef = useRef(null);

    const handleGeneratePdf = async () => {
        const canvas = await html2canvas(reportTemplateRef.current)
        const imgData = canvas.toDataURL("image/png")
        const doc = new jsPDF({
            format: 'a4',
            unit: 'px',
        });

        const width = doc.internal.pageSize.getWidth()
        const height = (canvas.height * width) / canvas.width
        doc.addImage(imgData, "PNG", 0, 0, width, height)
        doc.save('document')
    };

    return (
        <div className='document-page'>
            <ButtonBasic
                onClick={handleGeneratePdf}
                green
                text='Сгенерировать PDF'
            />
            <div className='invoice' ref={reportTemplateRef}>
                <table className='invoice__table-requisites'>
                    <tbody>
                        <tr>
                            <td colSpan={2}>ФИЛИАЛ "ХАБАРОВСКИЙ" АО "АЛЬФА-БАНК" г. Хабаровск</td>
                            <td>БИК</td>
                            <td>{bik ? bik : '[-]'}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>Банк получателя</td>
                            <td>Сч. №</td>
                            <td>{bank_client ? bank_client : '[-]'}</td>
                        </tr>
                        <tr>
                            <td>ИНН {inn_client ? inn_client : '[-]'}</td>
                            <td>КПП {kpp_client ? kpp_client : '[-]'}</td>
                            <td>Сч. №</td>
                            <td>{account_client ? account_client : '[-]'}</td>
                        </tr>
                        <tr>
                            <td>ООО "ЕКОМСЕЛЛЕР" <br /> Получатель</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

                <h1 className='invoice__title'>Счет на оплату № <span>{number || 'ХХ'}</span> от <span>{date || 'дд.мм.гггг.'}</span></h1>

                <div className='invoice__executor'>
                    <p className='invoice__placeholder'>Поставщик<br /> (Исполнитель):</p>
                    <p className='invoice__text-bold'>{client || '[Реквизиты исполнителя]'}</p>
                </div>

                <div className='invoice__executor'>
                    <p className='invoice__placeholder'>Покупатель <br />(Заказчик):</p>
                    <p className='invoice__text-bold invoice__text-blue'>{executor || '[Реквизиты заказчика]'}</p>
                </div>

                <div className='invoice__executor'>
                    <p className='invoice__placeholder'>Основание:</p>
                </div>

                <table className='invoice__table'>
                    <thead>
                        <tr>
                            {header_table.map((el, ind) => {
                                return <th key={el + ind}>{el}</th>
                            })}
                        </tr>
                    </thead>

                    <tbody>
                        {body_table.map((el, ind) => {
                            return <tr key={el + ind}>
                                {el.map((el, ind) => <td key={el + ind} >{el}</td>)}
                            </tr>
                        })}
                        <tr className='tr-total'>
                            <td colSpan={4}></td>
                            <td>Итого</td>
                            <td>{body_table.reduce((acc, num) => acc + num[5], 0)}</td>
                        </tr>
                        <tr className='tr-total'>
                            <td colSpan={4}></td>
                            <td>Без налога</td>
                            <td>-</td>
                        </tr>
                        <tr className='tr-total'>
                            <td colSpan={4}></td>
                            <td>Всего к оплате:</td>
                            <td>{body_table.reduce((acc, num) => acc + num[5], 0)}</td>
                        </tr>
                    </tbody>
                </table>

                <p className='invoice__text-9'>
                    Всего наименований <span>{body_table?.length}</span>, на сумму <span>{body_table.reduce((acc, num) => acc + num[5], 0)?.toLocaleString()}</span> руб.
                </p>
                <p className='invoice__text-9 invoice__text-bold'>
                    <span>{'[Сумма прописью]'}</span>
                </p>

                <div className='act-service__delimetr'></div>

                <div className='invoice__place-signature-group'>
                    <div className='invoice__place-signature'>
                        <p className='invoice__text-9  invoice__text-bold'>Руководитель</p>
                        <p className='invoice__text-8 invoice__text-nowrap'>{initials_company_director || '[Инициалы руководителя]'}</p>
                    </div>

                    <div className='invoice__place-signature'>
                        <p className='invoice__text-9  invoice__text-bold'>Бухгалтер</p>
                        <p className='invoice__text-8 invoice__text-nowrap'><span>{initials_accountant || '[Инициалы бухгалтера]'}</span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Invoice }