import customHeaders, { getSpace } from "../../../common/headers";
import { KonstantinMasterHttps, KonstantinMasterHttp } from "../../../fetchUrls";
import { TableBox } from "@lk-gtcom/ecomlab-components";
import { useState, useEffect, useRef } from 'react';
import useGeneralStore from "../../../store/general";
import { TableTop5 } from "../../TableTop5/TableTop5";
import './ModalUnansweredFeedback.scss'

const ModalUnansweredFeedback = ({
    setIsModal, setIsAllUnanswered,
    tableBody, totalCounter = { feedbacks: 0, questions: 0 },
    date
}) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const [fetchedData, setFetchedData] = useState([])
    const [loading, setLoading] = useState(false)
    const paginatorRef = useRef()

    const fetchTableData = (params, resetPage = false, allUnswered = true) => {
        let sort;
        let filtersParam = [];

        if (params) {
            [sort, filtersParam] = params
        }

        setFetchedData([])
        let url
        if (!window.location.hostname.match('localhost')) {
            if (allUnswered) {
                url = `${KonstantinMasterHttps}/feedbacks-and-questions/tables/v2/unanswered-feedbacks-questions?order_param_direction=ASC`
            }
        } else {
            if (allUnswered) {
                url = `${KonstantinMasterHttp}/feedbacks-and-questions/tables/v2/unanswered-feedbacks-questions?order_param_direction=ASC`
            }
        }

        const [date_from, date_to] = date
        const body = JSON.stringify({
            page: paginatorRef.current?.page,
            limit: 5,
            filters: filtersParam,
            // api_id_list: ['all'],
            // category_id_list: ['all'],
            // brand_id_list: ['all'],
            // product_valuation_list: ['all'],
            // status: 'Все',
            // date_from: new Date(date_from + 10)?.toISOString().split('T')?.[0],
            // date_to: new Date(date_to)?.toISOString().split('T')?.[0]
        })


        fetch(url, { body, method: 'POST', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setFetchedData(json)
            })
            .catch(err => {
                setLoading(false)
                console.error(err)
            })
    }


    useEffect(() => {
        fetchTableData([], true, true)
    }, [date, tableBody, selectedSpace])

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
                setIsAllUnanswered(false)
            }
        }}>
            <div className='modal-unanswered-Feedbackmodal'>

                <TableTop5
                    title={<>Всего неотвеченных отзывов <b>{totalCounter?.feedbacks}</b> и вопросов <b>{totalCounter?.questions}</b></>}
                    data={fetchedData}
                    height={'480px'}
                />
            </div>
        </div>
    )
}

export { ModalUnansweredFeedback }