import ru from 'date-fns/locale/ru'
import icon from './img/icon.svg'
import customHeaders, { getSpace } from '../../../common/headers'
import Cookies from 'universal-cookie'
import doc_icon from './img/doc_icon.svg'
import time_icon from './img/time_icon.svg'
import text_icon from './img/text_icon.svg'
import file_icon from './img/file_icon.svg'
import close_icon from './img/close_icon.svg'
import time_repeate_icon from './img/time_repeate_icon.svg'
import { user_icon } from '../../../common/svg_img'
import { SingleDatePicker } from '../../SingleDatePicker/SingleDatePicker'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { RavshanHttps, RavshanHttp } from '../../../fetchUrls'
import { OptionsExecutor, ExecutorPlaceholder } from '../../OptionsExecutor/OptionsExecutor'
import { ButtonBasic, Tabs, DropDownSelector, InputDinamycPlaceholder, TextareaDynamicPlaceholder, Checkbox } from "@lk-gtcom/ecomlab-components"
import useGeneralStore from '../../../store/general'
import './ModalAddTask.scss'


const btn_tab = [
    {
        label: 'Напоминание',
        value: `notification`
    },
    {
        label: 'Задачи',
        value: `task`
    },
    {
        label: 'Встреча',
        value: `event`
    }
]

const btn_tab_2 = [
    {
        label: 'Не повторять',
        value: `no-repeat`
    },
    {
        label: 'Повторять',
        value: `repeat`
    }
]

const ModalAddTask = ({ ...props }) => {
    const {
        setIsModal, setActiveContent, currentDate, addEvent = false, setAddEvent, setNewEvent, eventInfo, readOnly = false, setEventInfo,
        setModalInfo, id
    } = props

    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const [selectedProject, setSelectedProject] = useState([])
    const [selectedStatus, setSelectedStatus] = useState([])
    const [selectedPriority, setSelectedPriority] = useState([])
    const [selectedRepeat, setSelectedRepeat] = useState([])

    const client_id = localStorage.getItem('client_id')
    const auth = new Cookies().get('auth')


    const [startDate, setStartDate] = useState(eventInfo ? new Date(eventInfo?.dateFrom) : new Date())
    const [endDate, setEndDate] = useState(eventInfo ? new Date(eventInfo?.dateTo) : new Date())

    const [executorSearchValue, setExecutorSearchValue] = useState('')
    const [executors, setExecutors] = useState([])
    const [executorOptions, setExecutorOptions] = useState([])
    const [showOption, setShowOptions] = useState(false)

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')

    const email = localStorage.getItem('email')
    const currentUserColor = localStorage.getItem('currentUserColor')
    const currentUserName = localStorage.getItem('currentUserName')
    const [authorName, setAuthorName] = useState(currentUserName)
    const [authorEmail, setAuthorEmail] = useState(email)
    const [authorColor, setAuthorColor] = useState(currentUserColor)

    const [readonly, setReadonly] = useState(readOnly)


    const [pageValue, setPageValue] = useState(btn_tab[0].value)
    const path = useParams()
    const navigate = useNavigate()
    const activeTab = useParams()['*']

    useEffect(() => {
        const hideOptionsBox = (e) => {
            if (!e.target.closest('.search-box-executor')) {
                setShowOptions(false)
            }
            setExecutorSearchValue('')
        }
        document.addEventListener('click', (e) => hideOptionsBox(e))
        return () => document.removeEventListener('click', (e) => hideOptionsBox(e));
    }, [])

    useEffect(() => {
        if (currentDate) {
            setEndDate(currentDate)
        } else {
            setEndDate(new Date())
        }
    }, [currentDate])

    useEffect(() => {
        if (eventInfo?.type) {
            navigate(`./${eventInfo?.type}`)
        }

        if (eventInfo?.title) {
            setTitle(eventInfo?.title)
        }
        if (eventInfo?.description) {
            setDescription(eventInfo?.description)
        }
        setActiveContent('Мероприятие')
        if (eventInfo?.dateFrom) {
            const dateFrom = new Date(eventInfo?.dateFrom)
            setEndDate(dateFrom)
            setStartDate(dateFrom)
        }
        if (eventInfo?.dateTo) {
            setEndDate(new Date(eventInfo?.dateTo))
        }
    }, [eventInfo])

    useEffect(() => {
        fetchPossibleAttendees()
    }, [selectedSpace])

    const fetchPossibleAttendees = () => {
        const url = `${RavshanHttps}/api/v2/tasks/get_client_team`
        fetch(url, { headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad attendees response')
                }
            })
            .then(json => {
                setExecutorOptions(json)
            })
            .catch((e) => console.error(e))
    }


    // Calendar Events
    const fetchAddEvent = (item, day) => {

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/calendar/post_calendar_event`
        } else {
            url = `${RavshanHttps}/api/calendar/post_calendar_event`
        }

        const timezoneOffset = new Date().getTimezoneOffset() / 60 * 36000000

        const startTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours() - timezoneOffset, startDate.getMinutes()).toUTCString()
        const endTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours() - timezoneOffset, endDate.getMinutes()).toUTCString()

        const body = JSON.stringify({
            "attendees_ids": executors.map(e => e.id),
            description: description,
            start_time: activeTab === 'task' || activeTab === 'project_task' || activeTab === 'notification' ? endTime : startTime,
            end_time: endTime,
            organizer: client_id,
            title: title,
            type: activeTab
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => {
                setNewEvent(true)
            })
            .catch(err => console.error(err))
    }

    const fetchEditEvents = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/calendar/put_calendar_event`
        } else {
            url = `${RavshanHttp}/api/calendar/put_calendar_event`
        }
        const timezoneOffset = new Date().getTimezoneOffset() / 60


        const body = JSON.stringify({
            "attendees_ids": executors.map(e => e.value),
            description: description,
            start_time: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours() - timezoneOffset, startDate.getMinutes()).toUTCString(),
            end_time: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours() - timezoneOffset, endDate.getMinutes()).toUTCString(),
            event_id: id,
            title: title,
        })

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        }

        fetch(url, { body, method: 'PUT', headers })
            .then(res => res.json())
            .then(json => {
                setNewEvent(true)
            })
            .catch(err => console.error(err))
    }

    const fetchDeleteEvents = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/calendar/delete_calendar_event`
        } else {
            url = `${RavshanHttp}/api/calendar/delete_calendar_event`
        }

        const body = JSON.stringify({
            event_id: id
        })

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth}`
        }

        fetch(url, { body, method: 'DELETE', headers })
            .then(res => res.json())
            .then(json => {
                setNewEvent(true)
            })
            .catch(err => console.error(err))
    }


    useEffect(() => {
        setPageValue(activeTab)
    }, [activeTab])

    useEffect(() => {
        if (path['*'] === '') {
            navigate('notification')
        }
    }, [])

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
                setModalInfo(false)
                setEventInfo(undefined)
                setAddEvent(false)
            }
        }}>

            <div className={activeTab === "event" ? 'modal-add-event_tall' : 'modal-add-event'}>
                {/* <TitleContentEditable
                    setTitle={(e) => {
                        setTitle(e)
                    }}
                    title={title}
                    placeholder='Добавьте название'
                /> */}

                <div className='modal-add-event__content'>
                    <InputDinamycPlaceholder
                        onChange={(e) => {
                            setTitle(e)
                        }}
                        changeValue={title}
                        type="text"
                        placeholder='Добавьте название'
                        autoComplete={false}
                        size='s'
                        disabled={readonly}
                    />

                    <Tabs tabs={btn_tab} pageValue={pageValue} setPageValue={(e) => setPageValue(e)} stopRedirect />

                    <div className='select-group'>
                        <img src={time_icon} />

                        {pageValue == "task" &&
                            <SingleDatePicker
                                date={endDate}
                                setDate={(e) => setEndDate(e)}
                                disabled={readonly ? true : false}
                                locale={ru}
                                minDate={new Date()}
                            //  time
                            />}

                        {pageValue == 'notification' &&
                            <SingleDatePicker
                                date={startDate}
                                setDate={(e) => setStartDate(e)}
                                disabled={readonly ? true : false}
                                locale={ru}
                                minDate={new Date()}
                                time
                            />
                        }

                        {pageValue == "task" &&
                            <div className='author-box'>
                                <p className='text'>Исполнитель:</p>
                                <div className='executor'>
                                    {executors?.length > 0 ? executors?.map(({ img, name, email }) =>
                                        <div className='tooltip-executors-box' key={name}>
                                            {
                                                img
                                                    ?
                                                    <img src={img} />
                                                    :
                                                    <ExecutorPlaceholder name={name} />
                                            }
                                            <button className="close-executor"
                                                onClick={() => {
                                                    const executorFilter = executors?.filter((item) => !item.name.toLowerCase().includes(name.toLowerCase()))
                                                    setExecutors(executorFilter)
                                                }}
                                            ></button>
                                            <div className='tooltip-executors'>
                                                <div className="text-box">
                                                    <p className="text">{name}</p>
                                                    <p className="text_grey">{email}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) :
                                        <div className='search-box-executor' >
                                            <input
                                                className={!showOption ? 'search_add-executor' : 'search_add-executor_active'}
                                                placeholder='Добавить исполнителя'
                                                value={executorSearchValue}
                                                onClick={(e) => {
                                                    setShowOptions(true)
                                                }}
                                                onChange={(e) => {
                                                    setExecutorSearchValue(e.target.value)
                                                }}
                                            />
                                            {showOption ? <div className='options-box_active'>
                                                {
                                                    executorOptions.length > 0
                                                        ?
                                                        executorOptions.filter(e => {
                                                            const nameLowerCased = e.name.toLowerCase()
                                                            return (
                                                                !executors.map(e => e.name.toLowerCase()).includes(nameLowerCased)
                                                                &&
                                                                (executorSearchValue ? nameLowerCased.includes(executorSearchValue.toLowerCase()) : true)
                                                            )
                                                        })
                                                            ?.map(({ id, name, login, img, color }) => {
                                                                return (<OptionsExecutor
                                                                    onClick={(e) => setExecutors(prev => [...prev, e])}
                                                                    key={id}
                                                                    id={id}
                                                                    name={name}
                                                                    email={login}
                                                                    img={img}
                                                                    color={color}
                                                                />)
                                                            }
                                                            )
                                                        :
                                                        <p className='text-no-data'>По вашему запросу ничего не найдено</p>
                                                }
                                            </div> : null}
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {pageValue == "event" &&
                            <>
                                <SingleDatePicker
                                    date={startDate}
                                    setDate={(e) => setStartDate(e)}
                                    disabled={readonly ? true : false}
                                    locale={ru}
                                    style={{ maxWidth: '165px', minWidth: 'unset' }}
                                    time
                                />
                                -
                                <SingleDatePicker
                                    date={endDate}
                                    setDate={(e) => setEndDate(e)}
                                    disabled={readonly ? true : false}
                                    locale={ru}
                                    style={{ maxWidth: '165px', minWidth: 'unset' }}
                                    time={startDate}
                                />
                            </>
                        }


                    </div>


                    {pageValue == "task" &&
                        <div className='filter-group'>
                            <DropDownSelector
                                placeholder='Проект'
                                options_prop={[]}
                                isClearable={false}
                                state={selectedProject}
                                setState={(e) => setSelectedProject(e)}
                            />

                            <DropDownSelector
                                placeholder='Статус'
                                options_prop={[]}
                                isClearable={false}
                                state={selectedStatus}
                                setState={(e) => setSelectedStatus(e)}
                                width='100%'
                            />

                            <DropDownSelector
                                placeholder='Приоритет'
                                options_prop={[]}
                                isClearable={false}
                                state={selectedPriority}
                                setState={(e) => setSelectedPriority(e)}
                                width='100%'
                            />
                        </div>
                    }

                    {pageValue !== "task" && <div className='select-group'>
                        <img src={time_repeate_icon} />
                        <DropDownSelector
                            isClearable={false}
                            options_prop={btn_tab_2}
                            state={selectedRepeat}
                            setState={(e) => setSelectedRepeat(e)}
                            defaultValue={btn_tab_2[0]}
                        />
                        <label className='checkbox-label'>
                            <Checkbox />
                            <p className='text'>Весь день</p>
                        </label>
                    </div>}

                    {pageValue == "event" && <div className='executor-content'>
                        <img src={user_icon} />

                        <div className='exexutor-checkbox'>
                            <div className='author-box'>
                                <p className='text'>Участники:</p>
                                <div className='executor'>
                                    {executors?.length > 0 ? executors?.map(({ img, name, email }) =>
                                        <div className='tooltip-executors-box' key={name}>
                                            {
                                                img
                                                    ?
                                                    <img src={img} />
                                                    :
                                                    <ExecutorPlaceholder name={name} />
                                            }
                                            <button className="close-executor"
                                                onClick={() => {
                                                    const executorFilter = executors?.filter((item) => !item.name.toLowerCase().includes(name.toLowerCase()))
                                                    setExecutors(executorFilter)
                                                }}
                                            ></button>
                                            <div className='tooltip-executors'>
                                                <div className="text-box">
                                                    <p className="text">{name}</p>
                                                    <p className="text_grey">{email}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ) :
                                        <div className='search-box-executor' >
                                            <input
                                                className={!showOption ? 'search_add-executor' : 'search_add-executor_active'}
                                                placeholder='Добавить исполнителя'
                                                value={executorSearchValue}
                                                onClick={(e) => {
                                                    setShowOptions(true)
                                                }}
                                                onChange={(e) => {
                                                    setExecutorSearchValue(e.target.value)
                                                }}
                                            />
                                            {showOption ? <div className='options-box_active'>
                                                {
                                                    executorOptions.length > 0
                                                        ?
                                                        executorOptions.filter(e => {
                                                            const nameLowerCased = e.name.toLowerCase()
                                                            return (
                                                                !executors.map(e => e.name.toLowerCase()).includes(nameLowerCased)
                                                                &&
                                                                (executorSearchValue ? nameLowerCased.includes(executorSearchValue.toLowerCase()) : true)
                                                            )
                                                        })
                                                            ?.map(({ id, name, login, img, color }) => {
                                                                return (<OptionsExecutor
                                                                    onClick={(e) => setExecutors(prev => [...prev, e])}
                                                                    key={id}
                                                                    id={id}
                                                                    name={name}
                                                                    email={login}
                                                                    img={img}
                                                                    color={color}
                                                                />)
                                                            }
                                                            )
                                                        :
                                                        <p className='text-no-data'>По вашему запросу ничего не найдено</p>
                                                }
                                            </div> : null}
                                        </div>
                                    }
                                </div>
                            </div>

                            <label className='checkbox-label'>
                                <Checkbox />
                                <p className='text'>Выслать напоминание участникам за 10 минут</p>
                            </label>
                        </div>
                    </div>}

                    <div className='textarea-box'>
                        <img src={text_icon} />
                        <TextareaDynamicPlaceholder
                            width='100%'
                            changeValue={description}
                            placeholder={'Описание'}
                            onChange={(e) => setDescription(e)}
                        />
                    </div>

                    {pageValue !== "notification" &&
                        <>
                            <div className='file-container'>
                                <img src={file_icon} />
                                <div className='add_file'>
                                    <p className='text'>Добавить файл</p>
                                    <input className='input-file' type='file' />
                                </div>
                            </div>

                            <div className='files-group'>
                                <div className='file-item'>
                                    <img src={icon} />
                                    <p className='text'>IMG_654765545456</p>
                                    <button className='btn-close'><img src={close_icon} /></button>
                                </div>

                                <div className='file-item'>
                                    <img src={doc_icon} />
                                    <p className='text'>IMG_654765545456</p>
                                    <button className='btn-close'><img src={close_icon} /></button>
                                </div>
                            </div>
                        </>
                    }

                    {/* <Editor
                        bounds='.main-info'
                        title='Описание'
                        placeholder='Добавьте описание задачи...'
                        description={description}
                        setDescription={e => setDescription(e)}
                        hideModules
                    /> */}


                    {/*<div className='additional-information'>
                         <div className='top-panel'>
                            <p className='text_date'>
                                Дата создания {endDate.toLocaleDateString('ru')} в {endDate.toLocaleTimeString('ru', { timeStyle: 'short' })}
                            </p>
                            <div className='tooltip-box'>
                                <button className='btn-link' onClick={e => navigator.clipboard.writeText(window.location)}></button>
                                <div className='tooltip_black'>Ссылка задачи</div>
                            </div>
                        </div> 
                    </div>*/}

                </div>
                <div className='modal-add-event__btn-group'>
                    {!addEvent ?
                        <div className='select-group'>
                            <button className='btn__blue'
                                onClick={(e) => {
                                    setReadonly(!readonly)
                                    if (!readonly) {
                                        fetchEditEvents()
                                        setIsModal(false)
                                    }
                                }}
                                readonly={readonly ? true : false}>
                                {readonly ? 'Редактировать' : 'Сохранить'}
                            </button>
                            {readOnly ?
                                <button className='btn__blue'
                                    onClick={(e) => {
                                        fetchDeleteEvents()
                                        setEventInfo(undefined)
                                        setIsModal(false)
                                    }}
                                >
                                    Удалить
                                </button>
                                :
                                null
                            }
                        </div>
                        :
                        <ButtonBasic
                            key={'basic-btn_blue'}
                            width='162px'
                            style={{ marginLeft: 'auto', marginTop: 'auto' }}
                            green
                            text={'Создать'}
                            onClick={() => {
                                fetchAddEvent()
                                setEventInfo(undefined)
                                setAddEvent(false)
                                setIsModal(false)
                            }}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export { ModalAddTask }