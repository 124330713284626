import customHeaders, { getSpace } from '../../common/headers';
import { useRef } from 'react';
import CalendarDay from './CalendarDay/CalendarDay';
import CalendarWeek from './CalendarWeek/CalendarWeek';
import CalendarMonth from './CalendarMonth/CalendarMonth';
import ModalInfoEvent from '../../components/Modal/ModalInfoEvent/ModalInfoEvent';
import CalendarToolBarTop from './CalendarToolbarTop/CalendarToolbarTop';
import { ModalAddTask } from '../../components/Modal/ModalAddTask/ModalAddTask';
import { RavshanHttps } from '../../fetchUrls';
import { useState, useEffect } from 'react'
import useGeneralStore from '../../store/general';
import "./Calendar.scss"

const options_prop = [
    {
        label: 'Мероприятие',
        value: 'Мероприятие'
    },
    {
        label: 'Задача',
        value: 'Задача'
    },
]

const Calendar = () => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const [isModal, setIsModal] = useState(false)
    const [isModalInfo, setIsModalInfo] = useState(false)

    const [modalInfo, setModalInfo] = useState(false)

    const [eventInfo, setEventInfo] = useState({})

    const [activeContent, setActiveContent] = useState('Мероприятие')

    const [eventsList, setEventsList] = useState([])
    const [eventsListObj, setEventsListObj] = useState([])

    const [selectView, setSelectView] = useState('Calendar')
    const [startDate, setStartDate] = useState(new Date())
    const [currentDate, setCurrentDate] = useState(new Date())
    const [newEvent, setNewEvent] = useState(false)
    const [readOnly, setReadOnly] = useState(false)
    const [addEvent, setAddEvent] = useState(false)
    const [selectedPeriod, setSelectedPeriod] = useState({
        label: '7 Д',
        value: 'week'
    })

    const refHeaderTop = useRef()

    const timeDay = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]

    const timeMonth = [1, 2, 3, 4, 5, 6]
    const [id, setId] = useState('')

    const dateNow = [{
        day_now: new Date(startDate.getTime()).getDate(),
        week_now: new Date(startDate.getTime()).getDay(),
        month_now: new Date(startDate.getTime()).getMonth(),
        hours_now: new Date(startDate.getTime()).getHours(),
        minutes_now: new Date(startDate.getTime()).getMinutes(),
    }]

    const [currentDay, setCurrentDay] = useState({
        date: new Date(),
        minute: new Date().getMinutes(),
        hours: new Date().getHours(),
        day: new Date().getDate(),
        week: new Date().getDay(),
        month: new Date().getMonth(),
    });

    useEffect(() => {
        setCurrentDay({
            date: new Date(currentDate),
            minute: new Date().getMinutes(),
            hours: new Date().getHours(),
            day: new Date(currentDate).getDate(),
            week: new Date(currentDate).getDay(),
            month: new Date(currentDate).getMonth(),
        });
    }, [currentDate]);


    const weekHeader = [
        {
            date: new Date((startDate.getTime() - 86400000 * 2)),
            day: new Date((startDate.getTime() - 86400000 * 2)).getDate(),
            week: new Date((startDate.getTime() - 86400000 * 2)).getDay(),
            month: new Date((startDate.getTime() - 86400000 * 2)).getMonth(),
        },
        {
            date: new Date((startDate.getTime() - 86400000)),
            day: new Date((startDate.getTime() - 86400000)).getDate(),
            week: new Date((startDate.getTime() - 86400000)).getDay(),
            month: new Date((startDate.getTime() - 86400000)).getMonth()
        },
        {
            date: new Date(startDate.getTime()),
            day: new Date(startDate.getTime()).getDate(),
            week: new Date(startDate.getTime()).getDay(),
            month: new Date(startDate.getTime()).getMonth(),
        },
        {
            date: new Date((startDate.getTime() + 86400000)),
            day: new Date((startDate.getTime() + 86400000)).getDate(),
            week: new Date((startDate.getTime() + 86400000)).getDay(),
            month: new Date((startDate.getTime() + 86400000)).getMonth()
        },
        {
            date: new Date((startDate.getTime() + 86400000 * 2)),
            day: new Date((startDate.getTime() + 86400000 * 2)).getDate(),
            week: new Date((startDate.getTime() + 86400000 * 2)).getDay(),
            month: new Date((startDate.getTime() + 86400000 * 2)).getMonth(),
        },
        {
            date: new Date((startDate.getTime() + 86400000 * 3)),
            day: new Date((startDate.getTime() + 86400000 * 3)).getDate(),
            week: new Date((startDate.getTime() + 86400000 * 3)).getDay(),
            month: new Date((startDate.getTime() + 86400000 * 3)).getMonth()
        },
        {
            date: new Date((startDate.getTime() + 86400000 * 4)),
            day: new Date((startDate.getTime() + 86400000 * 4)).getDate(),
            week: new Date((startDate.getTime() + 86400000 * 4)).getDay(),
            month: new Date((startDate.getTime() + 86400000 * 4)).getMonth()
        },
    ]


    const fetchEventData = (id) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/calendar/get_calendar_event`
        } else {
            url = `${RavshanHttps}/api/calendar/get_calendar_event`
        }



        fetch(url, { method: 'GET', headers: { ...headers, 'event-id': id } })
            .then(res => res.json())
            .then(json => {
                const { event } = json
                if (event) {
                    const eventInfo = {
                        id: event[1][0],
                        title: event[1][1],
                        dateFrom: event[1][3],
                        dateTo: event[1][4],
                        description: event[1][5],
                        type: event[1][2]
                    }
                    setEventInfo(eventInfo)
                    setId(id)
                    setIsModal(true)
                }
            })
            .catch(err => console.error(err))
    }

    const fetchGetEvents = () => {
        let url;
        let newDate = new Date()
        let hours = newDate.getHours() * 3600000
        let min = newDate.getMinutes() * 60000
        let hours2 = 24 * 3600000
        let min2 = 60 * 59000

        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/calendar/get_events_by_client`
        } else {
            url = `${RavshanHttps}/api/calendar/get_events_by_client`
        }

        const body = JSON.stringify({
            date_from: new Date((startDate.getTime() - (86400000 + hours + min))),
            date_to: new Date((startDate.getTime() + (86400000 * 4 + (hours2 - hours) + (min2 - min)))),
            type: ["event", "notification", "task", "project_task"]
        })


        fetch(url, { method: 'POST', headers, body })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw Error(JSON.stringify(res.json()))
                }
            })
            .then(json => {
                if (Array.isArray(json)) {
                    setEventsList(json)
                }

            })
            .catch(err => console.error(err))
    }


    useEffect(() => {
        if (currentDate) {
            setStartDate(currentDate)
        }
    }, [currentDate])

    useEffect(() => {
        if (newEvent || startDate) {
            setTimeout(() => {
                fetchGetEvents()
            }, 200)
            setNewEvent(false)
        }
    }, [startDate, newEvent, selectedSpace])

    let calendarContent = null;
    if (selectedPeriod.value == 'month') {
        calendarContent = (
            <CalendarMonth
                refHeaderTop={refHeaderTop}
                setAddEvent={setAddEvent}
                setReadOnly={setReadOnly}
                setNewEvent={setNewEvent}
                eventsListObj={eventsListObj}
                setEventInfo={setEventInfo}
                time={timeMonth}
                fetchEventData={fetchEventData}
                dateNow={dateNow}
                weekHeader={weekHeader}
                isModal={isModal}
                setIsModal={(e) => setIsModal(e)}
                selectView={selectView}
            />
        )
    }
    else if (selectedPeriod.value == 'day') {
        calendarContent = (
            <CalendarDay
                refHeaderTop={refHeaderTop}
                setAddEvent={setAddEvent}
                setReadOnly={setReadOnly}
                setNewEvent={setNewEvent}
                eventsListObj={eventsListObj}
                eventsList={eventsList}
                setEventInfo={setEventInfo}
                currentDay={currentDay}
                time={timeDay}
                fetchEventData={fetchEventData}
                dateNow={dateNow}
                weekHeader={weekHeader}
                isModal={isModal}
                setIsModal={(e) => setIsModal(e)}
            />
        )
    }
    else {
        calendarContent = (
            <CalendarWeek
                refHeaderTop={refHeaderTop}
                setAddEvent={setAddEvent}
                setReadOnly={setReadOnly}
                setNewEvent={setNewEvent}
                eventsList={eventsList}
                setEventInfo={setEventInfo}
                fetchEventData={fetchEventData}
                weekHeader={weekHeader}
                dateNow={dateNow}
                isModal={isModal}
                setIsModal={setIsModal}
                timeDay={timeDay}
            />
        )
    }

    return (
        <div className='calendar-page'>
            {isModalInfo && <ModalInfoEvent
                eventInfo={eventInfo}
                setIsModalInfo={(e) => setIsModalInfo(e)}
            />}

            {isModal ?
                <ModalAddTask
                    id={id}
                    addEvent={addEvent}
                    setAddEvent={(e) => setAddEvent(e)}
                    modalInfo={modalInfo}
                    setModalInfo={(e) => setModalInfo(e)}
                    eventInfo={eventInfo}
                    setEventInfo={(e) => setEventInfo(e)}
                    setNewEvent={(e) => setNewEvent(e)}
                    setCurrentDate={(e) => setCurrentDate(e)}
                    fetchEventData={(e) => fetchEventData(e)}
                    currentDate={currentDate}
                    activeContent={activeContent}
                    setActiveContent={(e) => setActiveContent(e)}
                    setIsModal={(e) => setIsModal(e)}
                    readOnly={readOnly}
                    options={options_prop}
                />
                : null}

            <div className='calendar-page__content'>
                <CalendarToolBarTop
                refHeaderTop={refHeaderTop}
                    selectView={selectView}
                    setSelectView={(e) => setSelectView(e)}
                    currentDate={currentDate}
                    weekHeader={weekHeader}
                    selectedPeriod={selectedPeriod}
                    setSelectedPeriod={setSelectedPeriod}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    setCurrentDate={setCurrentDate}
                    setEventInfo={setEventInfo}
                    setReadOnly={setReadOnly}
                    setAddEvent={setAddEvent}
                    setIsModal={setIsModal}
                />
                {calendarContent}
            </div>
        </div>
    )
}

export { Calendar }