import customHeaders, { getSpace } from '../../../common/headers'
import disabled from './img/Disabled.svg'
import { Editor } from '../../Editor/Editor'
import { useNavigate } from 'react-router-dom'
import { RangeBoxSingle } from '../../RangeBoxSingle/RangeBoxSingle'
import { ModalCoverProject } from '../ModalCoverProject/ModalCoverProject'
import { useState, useEffect } from 'react'
import { RavshanHttp, RavshanHttps } from '../../../fetchUrls'
import { InputDinamycPlaceholder, ButtonBasic, SelectExecutor } from "@lk-gtcom/ecomlab-components"
import { OptionsExecutor, ExecutorPlaceholder } from '../../OptionsExecutor/OptionsExecutor'
import useGeneralStore from '../../../store/general'
import './ModalAddProject.scss'


const ModalAddProject = ({
    setIsModal,
    isInfoProject,
    setIsInfoProject,
    selectedProject
}) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const email = localStorage.getItem('email')
    const currentUserColor = localStorage.getItem('currentUserColor')
    const currentUserName = localStorage.getItem('currentUserName')
    const [endDate, setEndDate] = useState(new Date())
    const [startDate, setStartDate] = useState(new Date())
    const navigate = useNavigate()

    const [executorSearchValue, setExecutorSearchValue] = useState('')
    const [executors, setExecutors] = useState([])
    const [executorOptions, setExecutorOptions] = useState([])
    const [date, setDate] = useState([new Date()?.getTime()])
    const [isEdit, setIsEdit] = useState(false)

    const [title, setTitle] = useState('')
    const [titleDefault, setTitleDefault] = useState('')
    const [description, setDescription] = useState('')
    const [authorName, setAuthorName] = useState(currentUserName)
    const [authorEmail, setAuthorEmail] = useState(email)
    const [authorColor, setAuthorColor] = useState(currentUserColor)

    const [isFavorite, setIsFavorite] = useState(false)

    const [isDropMenu, setIstDropMenu] = useState(false)

    const [showOption, setShowOptions] = useState(false)
    const img_arr = [
        'https://apps1.ecomru.ru:9001/developerimages/banner_1.svg',
        'https://apps1.ecomru.ru:9001/developerimages/banner_2.svg',
        'https://apps1.ecomru.ru:9001/developerimages/banner_3.svg',
        'https://apps1.ecomru.ru:9001/developerimages/banner_4.svg',
        'https://apps1.ecomru.ru:9001/developerimages/banner_5.svg',
        'https://apps1.ecomru.ru:9001/developerimages/banner_6.svg',
        'https://apps1.ecomru.ru:9001/developerimages/banner_7.svg',
        'https://apps1.ecomru.ru:9001/developerimages/banner_8.svg',
        'https://apps1.ecomru.ru:9001/developerimages/banner_9.svg',
    ]
    const [currentCover, setCurrentCover] = useState(img_arr[0])
    const [isModalAddCover, setIsModalAddCover] = useState(false)

    const addNewProject = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/v2/tasks/post_project`
        } else {
            url = `${RavshanHttp}/api/v2/tasks/post_project`
        }

        const [date_from, date_to] = date
        const img = document.getElementById('img')?.src

        const body = JSON.stringify({
            name: title,
            text: description,
            end_time: new Date(date_to),
            start_time: new Date(date_from),
            previewimage: img ? img : '',
            attendee: executors.map(({ id }) => id),
            is_favorite: isFavorite
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => {
                if (res.ok) {
                    setIsModal(false)
                    sessionStorage.removeItem('selectedProject')
                    navigate('/tasks/project-for-tasks/')
                } else {
                    throw new Error('bad post project response')
                }
            })
            .catch(err => console.error(err))
    }

    const editProject = (e) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/tasks/put_project`
        } else {
            url = `${RavshanHttp}/api/tasks/put_project`
        }

        const [date_from, date_to] = date
        const img = document.getElementById('img')?.src

        const body = JSON.stringify({
            name: title,
            text: description,
            end_time: new Date(date_to),
            start_time: new Date(date_from),
            task_id: selectedProject?.taskId,
            previewimage: img ? img : '',
            attendee: executors.map(({ id }) => id),
            is_favorite: isFavorite
        })

        fetch(url, { body, method: 'PUT', headers })
            .then(res => {
                if (res.ok) {
                    setIsModal(false)
                    sessionStorage.removeItem('selectedProject')
                    navigate('/tasks/project-for-tasks/')
                } else {
                    throw new Error('bad put project response')
                }
            })
            .catch(err => console.error(err))
    }

    const deleteProject = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/tasks/delete_project`
        } else {
            url = `${RavshanHttp}/api/tasks/delete_project`
        }

        const body = JSON.stringify({
            task_id: selectedProject?.taskId
        })

        fetch(url, { body, method: 'DELETE', headers })
            .then(res => {
                if (res.ok) {
                    setIsModal(false)
                    sessionStorage.removeItem('selectedProject')
                    setIstDropMenu(false)
                } else {
                    throw new Error('bad post project response')
                }
            })
            .catch(err => console.error(err))
    }

    const fetchPossibleAttendees = () => {
        const url = `${RavshanHttps}/api/v2/tasks/get_client_team`
        const newHeaders = {
            ...headers
        }
        fetch(url, { headers: newHeaders })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad attendees response')
                }
            })
            .then(json => {
                setExecutorOptions(json?.filter(e => {
                    const nameLowerCased = e.name.toLowerCase()
                    return (
                        !executors?.map(e => e.name.toLowerCase()).includes(nameLowerCased)
                    )
                }))
            })
            .catch((e) => console.error(e))
    }

    const executorList = () => {
        const executor_list = executorOptions.filter(e => {
            const nameLowerCased = e.name.toLowerCase()
            return (
                !executors.map(e => e.name.toLowerCase()).includes(nameLowerCased)
                &&
                (executorSearchValue ? nameLowerCased.includes(executorSearchValue.toLowerCase()) : true)
            )
        })?.map(({ id, name, login, img, color }) => {
            return (<OptionsExecutor
                onClick={(e) => setExecutors(prev => [...prev, e])}
                key={id}
                id={id}
                name={name}
                email={login}
                img={img}
                color={color}
            />)
        }
        )

        return executor_list
    }

    useEffect(() => {
        fetchPossibleAttendees()
        if (isInfoProject) {
            if (selectedProject) {
                console.log(selectedProject)
                const { name, text, attendee, author, startTime, endTime, previewImage, isFavorite } = selectedProject
                setIsInfoProject(selectedProject)
                setTitleDefault(name)
                setTitle(name)
                setDescription(text)
                setExecutors(attendee)
                setCurrentCover(previewImage)
                setIsFavorite(isFavorite)
                if (author) {
                    const { name, login, color } = author
                    setAuthorColor(color)
                    setAuthorEmail(login)
                    setAuthorName(name)
                }
                setDate([new Date(startTime ? startTime : endTime ? endTime : ''), new Date(endTime ? endTime : startTime ? startTime : '')])
            }
        }
    }, [isInfoProject, selectedProject, selectedSpace])

    useEffect(() => {
        if (selectedProject) {
            setIsEdit(true)
        }
    }, [selectedProject]);

    useEffect(() => {
        return () => {
            setIsInfoProject(null)
        }
    }, [])

    useEffect(() => {
        const hideOptionsBox = (e) => {
            if (!e.target.closest('.search-box-executor')) {
                setShowOptions(false)
            }
            setExecutorSearchValue('')

            if (!e.target.closest('.context-more')) {
                setIstDropMenu(false)
            }
        }
        document.addEventListener('click', (e) => hideOptionsBox(e))
        return () => document.removeEventListener('click', (e) => hideOptionsBox(e));
    }, [])

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsEdit(false)
                setIsModal(false)
                sessionStorage.removeItem('selectedProject')
                navigate(`/tasks/project-for-tasks`)
            }
        }}>
            {
                isModalAddCover &&
                <ModalCoverProject
                    setIsModal={(e) => setIsModalAddCover(e)}
                    imgArr={img_arr}
                    currentCover={currentCover}
                    setCurrentCover={(e) => setCurrentCover(e)}

                />
            }
            {
                <div className='modal-add-project'>
                    <div className='modal-add-project__header'>
                        <h1 className='title'>{isEdit ? 'Редактирование проекта' : 'Новый проект'}</h1>

                        <div className='right-content'>
                            {
                                isEdit &&
                                <>
                                    <p className='text_date'>
                                        Дата создания {startDate.toLocaleDateString('ru')} в {startDate.toLocaleTimeString('ru', { timeStyle: 'short' })}
                                    </p>

                                    <div className='top-panel'>
                                        <div className='tooltip-box'>
                                            <button className='btn-link' onClick={e => navigator.clipboard.writeText(`${window.location.origin}/tasks/task-list/${selectedProject?.taskId}`)}></button>
                                            <div className='tooltip_black'>Ссылка на проект</div>
                                        </div>
                                    </div>
                                </>
                            }



                            {((email == authorEmail) &&
                                <div className='context-more'>
                                    <button className='btn-more'
                                        onClick={(e) => {
                                            setIstDropMenu(!isDropMenu)
                                        }}
                                    ></button>
                                    <div className={isDropMenu ? 'drop-menu_active' : 'drop-menu'}>
                                        <button className='drop-menu__btn'
                                            onClick={(e) => deleteProject()}
                                        >
                                            Удалить
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>

                    <div className='modal-add-project__content'>
                        <div className='main-info'>
                            <InputDinamycPlaceholder
                                onChange={(e) => setTitle(e)}
                                classname='autorization__input-field'
                                placeholder='Название проекта'
                                changeValue={titleDefault}
                                setChangeValue={(e) => setTitleDefault(e)}
                                autoComplete={false}
                            />

                            <ButtonBasic
                                violet={!isFavorite}
                                red={isFavorite}
                                text={isFavorite ? 'Убрать из избранного' : 'Добавить в избранное'}
                                onClick={(e) => setIsFavorite(!isFavorite)}
                            />

                            <div className='selected-photo-container'>
                                <p className='text'>Обложка</p>
                                <div className='img-box' onClick={(e) => setIsModalAddCover(true)}>
                                    <img className='img' id='img' src={currentCover ? currentCover : img_arr[1]} onError={e => e.target.src = img_arr[1]} />
                                    <div className='img-box_blackout'>
                                        <span>Изменить обложку</span>
                                    </div>
                                </div>
                            </div>

                            <Editor bounds='.main-info' title='Описание' placeholder='Опишите проект' description={description} setDescription={e => setDescription(e)} />
                        </div>

                        <div className='additional-information'>
                            {/* <div className='top-panel'>
                                <div className='tooltip-box'>
                                    <button className='btn-link' onClick={e => navigator.clipboard.writeText(`${window.location.origin}/tasks/task-list/${selectedProject?.taskId}`)}></button>
                                    <div className='tooltip_black'>Ссылка на проект</div>
                                </div>
                            </div> */}

                            <RangeBoxSingle date={date} setDate={e => setDate(e)} />

                            <SelectExecutor
                                executors={executors}
                                setExecutors={(e) => setExecutors(e)}
                                executorOptions={executorOptions}
                                multi
                                title='Участники'
                                placeholder='Добавить участника'
                            />

                            <div className='btn-group'>
                                <ButtonBasic
                                    key={'basic-btn_blue'}
                                    width='162px'
                                    style={{ marginLeft: 'auto', marginTop: 'auto' }}
                                    blue
                                    text={isEdit ? 'Сохранить' : 'Создать'}
                                    onClick={(e) => {
                                        if (title) {
                                            isEdit ? editProject() : addNewProject()
                                            setIsEdit(true)
                                        } else return
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export { ModalAddProject }