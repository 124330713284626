import { useState, useEffect } from 'react';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import './ModalConfirmingGroupDeletion.scss'

const ModalConfirmingGroupDeletion = ({
    setIsModal,
    deleteGroup,
    s
}) => {

    return (
        <div className='blackout'
            onClick={(e) => {
                let el = e.target
                if (el.classList.contains('blackout')) {
                    setIsModal(false)
                }
            }}>
            <div className='modal-confirming-group-deletion'>
                <h1 className='modal-confirming-group-deletion__title'>Подтвердите удаление</h1>
                <div className='modal-confirming-group-deletion__group-btn'>
                    <ButtonBasic
                        red
                        text='Удалить группу'
                        width='100%'
                        onClick={(e) => {
                            deleteGroup()
                            setIsModal(false)
                        }}
                    />

                    <ButtonBasic
                        grey
                        text='Отмена'
                        width='100%'
                        onClick={(e) => setIsModal(false)}
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalConfirmingGroupDeletion }