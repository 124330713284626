import Cookies from "universal-cookie"
const auth = new Cookies().get('auth')

export const getSpace = (selectedSpace) => {
    return {space: selectedSpace?.value}
}



export default {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${auth}`,
}

