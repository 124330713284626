import customHeaders, { getSpace } from '../../common/headers'
import { useState, useEffect } from 'react'
import { TabsGreen } from '../TabsGreen/TabsGreen'
import { ReactComponent as ArrowDown } from './img/arrow_down.svg';
import { TemplateGroupItem } from '../AutomationResponses/AutomationResponses';
import { TemplateMessage } from '../AutomationResponses/AutomationResponses';
import { ModalGroupAnswer } from './ModalGroupAnswer/ModalGroupAnswer';
import xls from './img/xls.svg';
import {
    InputDynamicWidth,
    ButtonBasic
} from '@lk-gtcom/ecomlab-components';
import plus_icon from './img/bx-plus.svg'
import './AnswerTemplates.scss'

const tabs = [
    { label: 'Группы ответов', value: 'response_groups' },
    { label: 'Шаблоны ответов', value: 'answer_templates' }
]

const btn_sort = [
    {
        label: (
            <span>
                A<br />Z
            </span>
        ),
        value: 'answer',
    },
    {
        label: 'По дате создания',
        value: 'created_at',
    },
];


const AnswerTemplates = ({
    groupList,
    selectedGroupId,
    setSelectedGroup,
    setSelectedGroupId,
    editGroup,
    deleteGroup,
    groupTemplates,
    setCurrentId,
    setIsModalDeleteTemplate,
    deleteTemplateInGroup,
    arrCheck,
    setArrCheck,
    deleteTemplate,
    editTemplate,
    setIsModalAddToGroup,
    isEdit,
    setIsEdit,
    setAnswer,
    setSelectedTemplateId,
    sort_component,
    createGroup,
    answer,
    input_search,
    tabs_curr,
    createMassTemplate,
    addToGroupTemplate,
    pageValue3,
    group_list,
    setSearchInput,
    setTemplateInput,
    fetchGroupList,
    setPageValue2,
    setIsModalMassEditing,
    createTemplate,
    setIsModalConfirmationUnloadingl,
    setIsModalConfirmingTemplateDeletion
}) => {

    const [currentTab, setCurrentTab] = useState(tabs?.[0]?.value)

    const [isModal, setIsModal] = useState(false)
    const [currentName, setCurrentName] = useState(null)

    const editFunc = (title) => {
        setCurrentName(title)
        setIsModal(true)
    }

    useEffect(() => {
        setPageValue2('all')
        setSelectedGroupId(-1)

        setSearchInput('')
        setTemplateInput('')
    }, [currentTab])

    return (
        <>
            {isModal && <ModalGroupAnswer
                editGroup={editGroup}
                currentName={currentName}
                answer={answer}
                deleteTemplate={deleteTemplate}
                selectedGroupId={selectedGroupId}
                setIsModal={(e) => setIsModal(e)}
                addToGroupTemplate={addToGroupTemplate}
                fetchGroupList={fetchGroupList}
            />}

            <section className='answer-templates'>
                <TabsGreen
                    btns={tabs}
                    currentBtn={currentTab}
                    setCurrentBtn={(e) => {
                        setCurrentTab(e)
                        setArrCheck([])
                    }}
                    checkValue={true}
                />

                <div className='answer-templates__content'>
                    <header className='answer-templates__header'>
                        <div className='answer-templates__header-top'>
                            {arrCheck.length > 0 ?
                                <div className='answer-templates__header-btn-group'>
                                    <ButtonBasic
                                        green
                                        width="160px"
                                        minWidth="160px"
                                        text={`Скопировать ${arrCheck.length}`}
                                        onClick={(e) => {
                                            setIsModalAddToGroup(true)
                                        }}
                                    />

                                    <ButtonBasic
                                        red
                                        width="160px"
                                        minWidth="160px"
                                        text={`Удалить ${arrCheck.length}`}
                                        onClick={(e) => {
                                            arrCheck.forEach((el) => {
                                                deleteTemplateInGroup(el)
                                            })
                                            setArrCheck([])
                                        }}
                                    />

                                    <ButtonBasic
                                        grey
                                        width="auto"
                                        text={`Отмена`}
                                        style={{
                                            marginLeft: 'auto'
                                        }}
                                        onClick={(e) => setArrCheck([])}
                                    />
                                </div>
                                :
                                <>
                                    {currentTab == 'response_groups'
                                        ?
                                        input_search('group', currentTab)
                                        :
                                        input_search('message', currentTab)}
                                </>
                            }


                        </div>

                        <div className='answer-templates__header-bottom'>

                            <div className="tab-sort">
                                {currentTab == 'response_groups' ? sort_component('group') : sort_component('message')}
                            </div>

                            <div className='answer-templates__header-btn-group-bottom'>
                                {currentTab != 'response_groups' &&
                                    <ButtonBasic
                                        green
                                        width="180px"
                                        minWidth="180px"
                                        text={'+ Массовая загрузка'}
                                        onClick={() => setIsModalMassEditing(true)}
                                    //   disabled={selectedGroupId === -1}
                                    />
                                }

                                {currentTab != 'response_groups' && <button
                                    onClick={(e) =>
                                        setIsModalConfirmationUnloadingl(true)
                                    }
                                    className="btn"
                                >
                                    <img src={xls} />
                                    Выгрузить .xls
                                </button>}

                                <ButtonBasic
                                    green
                                    width="160px"
                                    minWidth="160px"
                                    text={
                                        <p
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <img src={plus_icon} />
                                            {currentTab == 'response_groups' ? 'Новая группа' : 'Новый шаблон'}
                                        </p>
                                    }
                                    //   disabled={(selectedGroupId === -1 && currentTab != 'response_groups') || (pageValue3 == 'true' && currentTab != 'response_groups')}
                                    onClick={(e) => {
                                        if (currentTab == 'response_groups') {
                                            createGroup()
                                        } else {
                                            createTemplate(['Новый шаблон'])
                                        }
                                    }}
                                />
                            </div>
                        </div>

                    </header>

                    <div className='answer-templates__templates-container'>
                        {/* {currentTab == 'response_groups' && <TemplateGroupItem
                            isActiveGroup={selectedGroupId}
                            setIsActiveGroup={(e) => setSelectedGroupId(e)}
                            key={'Все'}
                            title={'Все шаблоны'}
                            id={-1}
                            editGroup={editGroup}
                            deleteGroup={(e) => deleteGroup(e)}
                            selectGroup={(title, id) => {
                                setSelectedGroup({ label: title, value: -1 });
                                setSelectedGroupId(-1)
                            }}
                            editFunc={(e) => setIsModal(true)}
                            hideAll
                        />} */}

                        {currentTab == 'response_groups' ?
                            // groupList?.map(({ group_name, id, created_at, templates_count, updated_at }) => {
                            //    return  <TemplateGroupItem
                            //         isActiveGroup={selectedGroupId}
                            //         setIsActiveGroup={(e) => setSelectedGroupId(e)}
                            //         key={id}
                            //         title={group_name}
                            //         id={id}
                            //         created_at={created_at}
                            //         editGroup={editGroup}
                            //         deleteGroup={(e) => deleteGroup(e)}
                            //         selectGroup={(title, id) => {
                            //             setSelectedGroup({ label: title, value: id });
                            //             setSelectedGroupId(id);
                            //             // selectGroup(id)
                            //         }}
                            //         editFunc={(e) => {
                            //             setCurrentName(group_name)
                            //             setIsModal(true)
                            //         }}
                            //         templates_count={templates_count}
                            //         updated_at={updated_at}
                            //     />  
                            // })
                            group_list(editFunc)
                            :
                            groupTemplates?.map(({ answer, id, created_at, updated_at }) => (
                                <TemplateMessage
                                    updated_at={updated_at}
                                    setCurrentId={(e) => setCurrentId(e)}
                                    setIsModalDeleteTemplate={(e) =>
                                        setIsModalDeleteTemplate(e)
                                    }
                                    selectedGroupId={selectedGroupId}
                                    deleteTemplateInGroup={(e) =>
                                        deleteTemplateInGroup(e, selectedGroupId)
                                    }
                                    // pageValue2={pageValue2}
                                    // pageValue3={pageValue3}
                                    arrCheck={arrCheck}
                                    setArrCheck={(e) => setArrCheck(e)}
                                    // isActiveGroup={isActiveGroup}
                                    key={id}
                                    id={id}
                                    group_id={selectedGroupId}
                                    message={answer}
                                    created_at={created_at}
                                    deleteTemplate={deleteTemplate}
                                    editTemplate={editTemplate}
                                    setIsModalAddToGroup={(e) =>
                                        setIsModalAddToGroup(e)
                                    }
                                    // showTransfer={pageValue3 !== 'in_group'}
                                    globalIsEdit={isEdit}
                                    setGlobalIsEdit={setIsEdit}
                                    setAnswer={setAnswer}
                                    setSelectedTemplateId={setSelectedTemplateId}
                                    view={'Table'}
                                    pageValue3={pageValue3}
                                    setIsModalConfirmingTemplateDeletion={(e) => setIsModalConfirmingTemplateDeletion(e)}
                                />
                            ))
                        }
                    </div>
                </div>
            </section>
        </>

    )
}

export { AnswerTemplates }