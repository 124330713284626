import React, { useState, useEffect } from 'react';
import customHeaders, { getSpace } from '../../common/headers';
import { Link } from 'react-router-dom';
import ModalSearchByInn from '../Modal/ModalSearchByInn/ModalSearchByInn';
import {
  InputDinamycPlaceholder,
  ButtonBasic,
} from '@lk-gtcom/ecomlab-components';
import { ModalChangeOfLegalEntities } from '../Modal/ModalChangeOfLegalEntities/ModalChangeOfLegalEntities';
import { ModalSuccessful } from '../Modal/ModalSuccessful/ModalSuccessful';
import { TooltipMui } from '../TooltipMui/TooltipMui';
import { ButtonCopy } from '../ButtonCopy/ButtonCopy';
import './InformationForBilling.scss';
import { ButtonBase } from '@mui/material';
import {
  ArtemBusinessData2Http,
  ArtemBusinessData2Https,
} from '../../fetchUrls';
import useGeneralStore from '../../store/general';

const InformationForBilling = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const [showModal, setShowModal] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalLegalEntities, setShowModalLegalEntities] = useState(false);
  const [editable, setEditable] = useState(false);

  const [data, setData] = useState(null);
  const [inn, setInn] = useState(null);
  const [haveData, setHaveData] = useState(false);
  const [innData, setInnData] = useState(false);

  // 9718162935

  const [listField, setListField] = useState([
    // {
    //   label: 'Страна организации',
    //   value: 'РФ',
    //   copy: false,
    // },
    // {
    //   label: 'Название организации',
    //   value: data ? data?.['НаимСокр'] : '',
    //   copy: true,
    // },
  ]);

  const fetchGetBusinessData = () => {
    let url = `${ArtemBusinessData2Https}/api/v2/business-data/get`;
    // let url = `${ArtemBusinessData2Http}/api/v2/business-data/permission`;

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setData(json.data);
        console.log(json);
      })
      .catch((err) => console.error(err));
  };

  const fetchGetPermission = () => {
    let url = `${ArtemBusinessData2Https}/api/v2/business-data/permission`;
    // let url = `${ArtemBusinessData2Http}/api/v2/business-data/permission`;

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        console.log(json);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchGetPermission();
    fetchGetBusinessData();
  }, []);

  const fetchGetDataByInn = () => {
    let url = `${ArtemBusinessData2Https}/api/v2/business-data/search`;

    const body = JSON.stringify({
      org_type: 'org',
      tax_num: inn,
    });

    fetch(url, { method: 'POST', headers, body })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        console.log(json);
        setInnData(json?.data);
        setHaveData(true);
      })
      .catch((err) => console.error(err));
  };
  const fetchSaveDataByInn = () => {
    const url = `${ArtemBusinessData2Https}/api/v2/business-data/save?tax_num=${inn}`;

    fetch(url, {
      method: 'POST',
      headers,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        console.log(json);
        setHaveData(false);
        setEditable(false);
        fetchGetBusinessData();
      })
      .catch((err) => console.error(err));
  };

  const fetchDeleteBusinessData = () => {
    const url = `${ArtemBusinessData2Https}/api/v2/business-data/delete`;

    fetch(url, {
      headers,
      method: 'DELETE',
    })
      .then(async (res) => {
        if (res.status === 204) {
          setHaveData(false);
          setEditable(false);
          fetchGetBusinessData();
          console.log('Бизнес-данные успешно удалены');
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {showModal && (
        <ModalChangeOfLegalEntities setShowModal={(e) => setShowModal(e)} />
      )}
      {showModalSuccess && (
        <ModalSuccessful
          title="Ваш запрос успешно отправлен"
          setIsModal={(e) => setShowModalSuccess(e)}
        />
      )}
      <div className="information-for-billing">
        <h3 className="title__main">
          Информация для выставления счетов и актов
        </h3>
        <ButtonBasic
          lilac
          width="302px"
          text={!editable ? 'Редактировать юридические данные' : 'Назад'}
          size="56px"
          onClick={(e) => {
            setEditable(!editable);
            setHaveData(false);
          }}
        />
        <div className="information-for-billing__content">
          <h3 className="title">
            {data?.org_type === 'org' ? 'Юридическое лицо' : 'Физическое лицо'}
          </h3>
          {editable ? (
            <>
              <InputDinamycPlaceholder
                onChange={(e) => setInn(e)}
                type="text"
                classname="autorization__input-field"
                placeholder="Введите ИНН организации*"
                autoComplete={false}
              />

              <button className="btn__blue" onClick={() => fetchGetDataByInn()}>
                Найти по ИНН
              </button>
            </>
          ) : (
            <>
              <div className="non-editable-fields">
                {listField.map(({ label, value, copy }) => (
                  <div className="non-editable-fields__item">
                    <div className="val-box">
                      <span className="placeholder">{label}</span>
                      <p className="text">{value}</p>
                      {copy && <ButtonCopy value={value} />}
                    </div>
                  </div>
                ))}

                <div className="non-editable-fields__item">
                  <div className="val-box">
                    <span className="placeholder">
                      {'Название организации'}
                    </span>
                    <p className="text">{data?.['НаимСокр']}</p>
                    <ButtonCopy value={data?.['НаимСокр']} />
                  </div>
                </div>
                <div className="non-editable-fields__item">
                  <div className="val-box">
                    <span className="placeholder">
                      {'Полное название организации'}
                    </span>
                    <p className="text">{data?.['НаимПолн']}</p>
                    <ButtonCopy value={data?.['НаимПолн']} />
                  </div>
                </div>

                <div className="non-editable-fields-group">
                  <div className="non-editable-fields__item">
                    <div className="val-box">
                      <span className="placeholder">ИНН организации</span>
                      <p className="text">
                        {data?.['ИНН']}
                        {/* 1234597543 */}
                      </p>
                      <ButtonCopy value={data?.['ИНН']} />
                    </div>
                  </div>
                  <div className="non-editable-fields__item">
                    <div className="val-box">
                      <span className="placeholder">КПП организации</span>
                      <p className="text">
                        {data?.['КПП']}
                        {/* 1234597544 */}
                      </p>
                      <ButtonCopy value={data?.['КПП']} />
                    </div>
                  </div>
                </div>
              </div>

              {/* {listField.map(({ label, value, copy }) => (
                <div className="non-editable-fields__item">
                  <div className="val-box">
                    <span className="placeholder">{label}</span>
                    <p className="text">{value}</p>
                    {copy && <ButtonCopy value={value} />}
                  </div>
                </div>
              ))} */}
            </>
          )}
        </div>
      </div>

      {editable && haveData && (
        <div className="information-for-billing">
          <InputDinamycPlaceholder
            type="text"
            classname="autorization__input-field"
            placeholder="Страна организации*"
            autoComplete={false}
            disabled={true}
          />

          <InputDinamycPlaceholder
            changeValue={innData.НаимСокр}
            type="text"
            classname="autorization__input-field"
            placeholder="Название организации*"
            autoComplete={false}
            disabled={true}
          />

          <InputDinamycPlaceholder
            changeValue={innData.НаимПолн}
            type="text"
            classname="autorization__input-field"
            placeholder="Полное название организации*"
            autoComplete={false}
            disabled={true}
          />

          <div className="input-group">
            <InputDinamycPlaceholder
              changeValue={innData.ИНН}
              type="text"
              classname="autorization__input-field"
              placeholder="ИНН организации*"
              autoComplete={false}
              disabled={true}
            />

            <InputDinamycPlaceholder
              changeValue={innData.КПП}
              type="text"
              classname="autorization__input-field"
              placeholder="КПП организации*"
              autoComplete={false}
              disabled={true}
            />
          </div>

          <InputDinamycPlaceholder
            changeValue={''}
            type="text"
            classname="autorization__input-field"
            placeholder="Юридический адрес*"
            autoComplete={false}
            disabled={true}
          />

          <InputDinamycPlaceholder
            type="text"
            classname="autorization__input-field"
            placeholder="Почтовый адрес*"
            autoComplete={false}
            disabled={true}
          />

          <InputDinamycPlaceholder
            type="text"
            classname="autorization__input-field"
            placeholder="Телефон"
            autoComplete={false}
            disabled={true}
          />

          <InputDinamycPlaceholder
            type="text"
            classname="autorization__input-field"
            placeholder="E-mail бухгалтера"
            autoComplete={false}
            disabled={true}
          />

          <div className="group-btn">
            <button className="btn__white" onClick={fetchDeleteBusinessData}>
              Удалить данные
            </button>
            <button
              className="btn__blue"
              // onClick={(e) => setEditable(false)}
              onClick={fetchSaveDataByInn}
            >
              Сохранить данные
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export { InformationForBilling };
