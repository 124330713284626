import ExternalAuthentication from '../ExternalAuthentication/ExternalAuthentication'
import { Link } from 'react-router-dom'
import { Mail } from '../PasswordRecoveryBox/Mail/Mail';
import { useEffect, useState } from 'react'
import { ModalErr } from '../Modal/ModalErr/ModalErr';
import { validationEmail } from '../../common/validationFunc';
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../fetchUrls'
import { InputDinamycPlaceholder, Checkbox, ButtonBasic } from "@lk-gtcom/ecomlab-components"
import "./RegistrationBox.scss"

const RegistrationBox = ({ admin = false }) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [name, setName] = useState('')
    const [personalData, setPersonalData] = useState(false)
    const [newsSubscription, setNewsSubscription] = useState(false)
    const [hidePassword, setHidePassword] = useState(false)
    const [inValid, setInvalid] = useState(false)
    const [errPersonalData, setErrPersonalData] = useState(false)


    const [err, setErr] = useState(false)
    const [errPassword, setErrPassword] = useState(false)
    const [errEmail, setErrEmail] = useState(false)
    const [errName, setErrName] = useState(false)

    const [isModal, setIsModal] = useState(false)
    const [isModalRegistration, setIsModalRegistration] = useState(false)


    const deleteErrNotofication = () => {
        setErrPassword(false)
        setErr(false)
        setErrEmail(false)
        setInvalid(false)
        setErrName(false)
        setErrPersonalData(false)
    }

    const handlerSetEmail = (e) => {
        setEmail(e)
        deleteErrNotofication()
    }

    const handlerSetPassword = (e) => {
        setPassword(e)
        deleteErrNotofication()
    }

    const handlerSetName = (e) => {
        setName(e)
        deleteErrNotofication()
    }


    const activeTypeIput = hidePassword ? "text" : "password"

    // переменная для проверки
    let formBig = {
        name,
        email,
        password,
        mailing_allowed: newsSubscription,
        cookies_allowed: true
    }

    const handlerSubmit = (e) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantionAuthHttps}/web/register`
        } else {
            url = `${KonstantionAuthHttp}/web/register`
        }

        fetch(url,
            {
                method: 'POST',
                body: JSON.stringify(formBig),
                headers: { 'Content-type': 'application/json', accept: 'application/json' }
            })
            .then((response) => response.json())
            .then((data) => {
                if (data?.detail == "REGISTER_USER_ALREADY_EXISTS") {
                    setErr(true)
                }

                if (data?.detail == "Not Found") {
                    setIsModal(true)
                }

                if (data?.message == "User successfully registered") {
                    setIsModalRegistration(true)
                }
            })
            .catch(err => {
                setIsModal(true)
                console.error(err)
            })
    }

    const checkInputField = () => {

        const regexPassword = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/

        if (!regexPassword.test(password)) {
            setErrPassword(true)
        }

        if (!validationEmail(email)) {
            setErrEmail(true)
        }

        if (!name) {
            setErrName(true)
        }

        if (!personalData) {
            setErrPersonalData(true)
        }

        if (!regexPassword.test(password) || !validationEmail(email) || !name || !personalData) {
            return
        } else {
            handlerSubmit()
        }
    }

    // Сохранение query
    const [query, setQuery] = useState('')
    
    useEffect(() => {
        const params = new URL(document.location).searchParams;
        let query_params = params.get('utm_ref');
        setQuery(query_params);
        console.log('utm_ref ' + query_params)
    }, [new URL(document.location)]);

    return (
        <>
            {isModal && <ModalErr isModal={isModal} setIsModal={(e) => setIsModal(e)} />}
            {isModalRegistration && < Mail
                title='Регистрация прошла успешно'
                text={`Проверьте e-mail, мы выслали вам письмо для подтверждения регистрации`}
                setIsModal={(e) => setIsModalRegistration(e)}
            />}
            <form className='autorization' style={{ marginTopp: '150px' }} onSubmit={(e) => e.preventDefault()} spellCheck='false'>
                <div className='title_wrapper' style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
                    <h2 className='title' style={{ marginRight: '1em' }}>Регистрация</h2>
                </div>

                <ExternalAuthentication />

                <p className='text'><span style={{ color: '#020617' }}>Или через </span>e-mail</p>

                <InputDinamycPlaceholder
                    onChange={(e) => handlerSetName(e)}
                    type="text"
                    err={errName}
                    textErr={'Введите имя'}
                    classname='autorization__input-field'
                    placeholder='Введите имя'
                    autoComplete={false}
                />

                <div className={inValid ? 'input-with-btn_active' : 'input-with-btn'}
                >
                    <InputDinamycPlaceholder
                        onChange={(e) => handlerSetEmail(e)}
                        type="e-mail"
                        err={errEmail}
                        textErr={'Неверный email'}
                        classname='autorization__input-field'
                        placeholder='Введите адрес электронной почты'
                        autoComplete={false}
                    />
                </div>

                <InputDinamycPlaceholder
                    onChange={(e) => handlerSetPassword(e)}
                    type={activeTypeIput}
                    classname='autorization__input-field'
                    err={errPassword}
                    textErr={'Пароль не соответствует требованиям'}
                    placeholder='Введите пароль'
                    passwordTooltip
                    autoComplete={false}
                    tooltip='Пароль должен содержать не менее 8 символов, цифры и заглавные буквы'
                />

                {err && <div className='err-box'>
                    <span className='text text_red'>Пользователь с таким логином уже существует</span>
                </div>}

                <div className='checkbox__box_registration'>
                    <label>
                        <Checkbox
                            err={errPersonalData}
                            setErr={(e) => setErrPersonalData(e)}
                            value={personalData}
                            onChange={(e) => setPersonalData(e)}
                        />
                        {/* id="personal-data-checkbox" */}
                        <span className='text'>Я даю согласие на обработку своих персональных данных согласно <Link
                            className='blue'
                            onClick={e => window.open("http://new.ecomru.ru/main/article/Politika_konfidentsialnosti/397", '_blank')}>
                            политике конфиденциальности
                        </Link></span>
                    </label>

                    <label>
                        <Checkbox
                            value={newsSubscription}
                            onChange={(e) => setNewsSubscription(e)} />
                        <span className='text'>Хочу получать полезные новости и специальные предложения от Ecomru.ru</span>
                    </label>
                </div>
                <div className='bottom-box-registration'>
                    <ButtonBasic
                        blue
                        text='Зарегистрироваться'
                        size='40px'
                        width='100%'
                        disabled={!(password?.length > 0 & email?.length > 0 & name?.length > 0 & personalData)}
                        onClick={e => {
                            e.preventDefault()

                            checkInputField()
                        }}
                    />
                    {
                        admin ? <div className='right-header-box'>
                            <span className='text'>Уже есть аккаунт?</span>
                            < Link to="/auth" className='link-box-btn'>
                                < button className='btn__blue'>Войти</button>
                            </Link>
                        </div>
                            : null
                    }

                </div>
                <div className='bottom-box'>
                    <p className='text_grey-blue'>Уже есть аккаунт</p>
                    < Link to="/auth" className='text_blue'>Войти</Link>
                </div>
            </form>
        </>
    )
}


export { RegistrationBox }