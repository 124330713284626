import {useState,useEffect, useRef} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar, getDatasetAtEvent, getElementAtEvent } from 'react-chartjs-2';
import iconBlue from './img/icon_blue.png'


import './BarChartsVer2.scss'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChartsVer2 = ({
  chart_name,
  chart_unit = null,
  chart_size = 'small',
  chart_icons = [],
  display_legend,
  labels_prop = ['10','20','30','40','50','60', '70', '80', '90', '100'],
  datasets_prop = [],
  multiAxis_prop = false,
  title_icon = false,
  colorArr = [],
  data_name = '',
  direction = 'y',
  legendPosition = 'right',
  colorStart
}) => {
  const chartRef = useRef(null)
  const colors = ['#FA9C0F', '#4400B4', '#07D2A2', '#B90159', '#A53100', '#CFEE8D', '#57118E', '#88306D', '#389C08', '#FA9C0F']

  const defaultDatasets = [
    {
        label: 'Нет данных',
        data: [-10,10,-20],
       // borderColor: colorArr[0] ? colorArr[0] : 'rgba(255, 99, 132, 0.5)',
        backgroundColor: colorArr[0] ? colorArr[0] : '#FA9C0F',
        tension: .5
    },
    {
        label: 'Нет данных',
        data: [20,-25,5],
      //  borderColor: colorArr[1] ? colorArr[1] : 'rgba(53, 162, 235, 0.5)',
        backgroundColor: colorArr[1] ? colorArr[1] : '#4400B4',
        tension: .5
    }
  ]

  const defaultOptions = {
    type: 'bar',
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: direction, //'x'
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 2,
      }
    },
    interaction: {
      intersect: false,
      mode: 'index'
    },
    plugins: {
      zoom: {
        zoom: {
          drag: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'xy',
        }
      },
      tooltip: {
        callbacks: {
          label: function(context){
            let label = context.dataset.label || '';

            if (label) {
                label += ': ';
            }
            if(direction === 'y'){
              if (context.parsed.x !== null) {
                label += context.parsed.x?.toLocaleString();
            }
            } else {
              if (context.parsed.y !== null) {
                label += context.parsed.y?.toLocaleString();
              }
            }

            return label;
          }
        }
      },
      legend: {
        display: display_legend,
        position: legendPosition, //top
        labels: {
          boxWidth: 6,
          boxHeight: 6,
          usePointStyle: true
        },
      },
      title: {
        display: false,
        text: 'График',
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: '#F1F5F9',
        },
        ticks: {
          display: true,
          color: '#94A3B8',
          
          font: {
            size: 12,
            family: 'Inter',
          }
        }
      },
      y: {
        grid: {
          display: true,
          color: '#F1F5F9',
          
        },
        ticks: {
          display: true,
          color: '#94A3B8',
          font: {
            size: 12,
            family: 'Inter',
          }
        }
      },
    },
  }


  const datasets = datasets_prop.length > 0 ? datasets_prop.map((el,ind) => {
    return {
        ...defaultDatasets[0],
        data: el,
        label: data_name.length > 1 ? data_name[ind] : data_name,
        backgroundColor: colorStart ? colors[colorStart + ind] : colors[ind],
        borderColor: colorStart ? colors[colorStart + ind] : colors[ind]
      }
  }) : defaultDatasets

  const data = { labels: labels_prop, datasets }

  const options = {
    ...defaultOptions,
    type: 'bar',
    scales: multiAxis_prop ? multiAxisOptions(colorArr.length > 0 ? colorArr : ['rgb(255, 99, 132)','rgb(53, 162, 235)']) : null
  }

  

  const multiAxisOptions = (colorArr) => {
    const [first_color, second_color] = colorArr
    return {
      y: {
        display: true,
        position: 'left',
        grid: {
          drawOnChartArea: false,
          color: first_color,
          borderColor: first_color
        }
      },
      y1: {
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
          color: second_color,
          borderColor: second_color
        }
      }
    }
  }

  
  const printDatasetAtEvent = (dataset) => {
    if (!dataset.length) return;

    const datasetIndex = dataset[0].datasetIndex;

    return data.datasets[datasetIndex].label
  };

  const printElementAtEvent = (element) => {
    if (!element.length) return;

    const { datasetIndex, index } = element[0];

    return [data.labels[index], data.datasets[datasetIndex].data[index]]
  };


  const getChartData = (event) => {
    const { current: chart } = chartRef;

    if (!chart) {
      return;
    }

    const label = printDatasetAtEvent(getDatasetAtEvent(chart, event));
    const [date, value] = printElementAtEvent(getElementAtEvent(chart, event));
    alert([label, date, value?.toLocaleString()]?.join(' | '));
  };

  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  return (
    <div className='bar-chart-ver2'>
      <header className='line_chart_header'>
        <button 
          style={{display: 'none', position: 'absolute', top:'6.5%', right: '-.75em', width: '80px', backgroundColor: 'transparent', boxSizing: 'border-box', padding: '.35em .75em', borderRadius: '.5em', outline: 'none', border: '1.5px solid lightgrey'}}
          onClick={e => handleResetZoom()}
        >
          Сбросить зум
        </button>
        <span className={`line_chart_heading line_chart_heading_${chart_size}`}>
          <span className="chart_name">{chart_name}</span>
          <span className="chart_unit">{chart_unit ? `${chart_size === 'big' ? '' : ","}${chart_unit}` : null}</span>
          {
            title_icon ? 
            <img 
              className="chart_title_icon" 
              src={iconBlue}
              alt='chart_title_icon'
            /> : null
          }
        </span>
        {
          chart_icons.length ? 
          <div className="header_icon_placeholder">
            {
              chart_icons.map((el,ind) => {
                return(
                  <img 
                    className={`chart_header_btn_${el.description}`} 
                    src={el.icon}
                    key={ind+el.description}
                  />
                )
              })
            }
          </div>
          : 
          null
        }
      </header>
      <Bar 
        options={options} 
        data={data}
        ref={chartRef}
        onClick={getChartData}
      />
    </div>
  )
}

export {BarChartsVer2}