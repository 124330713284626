import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonBasic, InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components";
import './ModalExitReadyScenario.scss'

const ModalExitReadyScenario = ({
    setIsModal,
    onClose,
    onSave
}) => {

    return (
        <div className='blackout blackout-modal-2'
            style={{ zIndex: 12 }}
            onClick={(e) => {
                let el = e.target
                if (el.classList.contains('blackout-modal-2')) {
                    setIsModal(false)
                }
            }}>
            <div className='modal-exit-ready-scenario'>
                <h1 className='modal-exit-ready-scenario__title'>У вас есть не сохраненные изменения</h1>
                <div className='modal-exit-ready-scenario__group-btn'>     
                     <ButtonBasic
                        grey
                        text='Продолжить редактирование'
                        width='100%'
                        onClick={(e) => {
                            setIsModal(false)
                        }}
                    />
                    <ButtonBasic
                        green
                        text='Сохранить и выйти'
                        width='100%'
                        onClick={(e) => {
                            onSave()
                        }}
                    />
                    <ButtonBasic
                        red
                        text='Выйти'
                        width='100%'
                        onClick={(e) => {
                            onClose()
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalExitReadyScenario }