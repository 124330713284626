import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import "./SceletonDoughnutChart.scss"

const SceletonDoughnutChart = ({ height = '400' }) => {

    return (
        <div className='sceleton-doughnut-chart'>
            <Stack className='sceleton-doughnut-chart__content'>
            <Skeleton variant="circular" width={160} height={160} />
                {[...Array(1)].map((el) => {
                    return <div className='sceleton-doughnut-chart__col'>
                       <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                       <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                       <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                       <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    </div>
                })}
            </Stack>
        </div>
    )
}

export { SceletonDoughnutChart }