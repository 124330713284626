import { useState, useEffect } from 'react';
import { ButtonBasic, DropDownSelector } from '@lk-gtcom/ecomlab-components';
import './ModalAddToGroup.scss';
import {
  KonstantinFeedbackHttp,
  KonstantinFeedbackHttps,
} from '../../../fetchUrls';
import useGeneralStore from '../../../store/general';
import customHeaders, { getSpace } from '../../../common/headers';

const ModalAddToGroup = ({
  // optionsGroup,
  setIsModal,
  setArrCheck,
  addToGroupTemplate,
  selectedGroupId,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [optionsGroup, setOptionsGroup] = useState([]);

  const current_prop = optionsGroup.filter(
    ({ label, value }) => selectedGroupId != value
  );

  const fetchGroupList = () => {
    let url;
    let query = '';
    // let queryArr = [];

    // if (searchInput?.length > 0) {
    //   queryArr.push(`search_param=${searchInput}`);
    // }
    // if (pageValue2) {
    //   queryArr.push(`template_filter=${pageValue2}`);
    // }
    // if (currentSort) {
    //   queryArr.push(`order_param=${currentSort?.value}`);
    // }
    // if (sort) {
    //   queryArr.push(`order_direction=${sort == 'ASC' ? 'ASC' : 'DESC'}`);
    // }
    // if (queryArr?.length > 0) {
    //   query = `?${queryArr.join('&')}&limit=100&offset=0`;
    // }

    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/groups${query}`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/answers/groups${query}`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        console.log(json);
        const transformedGroups = json.map((group) => ({
          label: group.group_name,
          value: group.id,
        }));
        setOptionsGroup(transformedGroups);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchGroupList();
  }, []);

  return (
    <div
      className="blackout"
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setIsModal(false);
        }
      }}
    >
      <div className="modal-confirmation-unloading">
        <h2 className="modal-confirmation-unloading__title">Добавить в:</h2>
        <DropDownSelector
          width="100%"
          options_prop={current_prop}
          state={selectedGroup}
          setState={(e) => setSelectedGroup(e)}
          placeholder="Выберите группу"
        />
        <ButtonBasic
          green
          text="Переместить"
          width="225px"
          size="40px"
          onClick={(e) => {
            addToGroupTemplate(selectedGroup?.value);
            setArrCheck([]);
            setIsModal(false);
          }}
        />
      </div>
    </div>
  );
};

export { ModalAddToGroup };
