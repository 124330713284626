import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import './ModalTableActions.scss'

const ModalTableActions = ({
    products,
    btns,
    setIsModal
}) => {

    return (
        <div className='modal-table-actions'>
            <p>Выбрано товаров: {products?.length}</p>

            <div className='modal-table-actions__btns'>
                {btns.map(({ label, onClick }) => {
                    return <ButtonBasic
                        grey
                        text={label}
                        width='auto'
                        onClick={onClick}
                    />
                })}
            </div>
        </div>
    )
}

export { ModalTableActions }