import customHeaders, { getSpace } from '../../../common/headers';
import { TabsGreyBtn } from '../../TabsGreyBtn/TabsGreyBtn';
import { useDataTable } from '../../../common/hooks';
import { getFormatDate } from '../../../common/formatters';
import { SingleDatePicker } from '../../SingleDatePicker/SingleDatePicker';
import { useState, useEffect, useRef } from 'react';
import {
  RavshanHttps,
  RavshanHttp,
  OlegHttps,
  OlegCreateAddCampaingHttps,
} from '../../../fetchUrls';
import {
  InputDinamycPlaceholder,
  TableBox,
  ButtonBasic,
  DropDownSelector,
  RangeBox,
} from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../../store/general';
import './ModalCreatingAnAdvertisingCampaignV2.scss';

const btn_tab = [
  {
    label: 'Добавленные',
    value: 'added',
  },
  {
    label: 'Можно добавить',
    value: 'available_to_add',
  },
];

const options_prop = [
  {
    label: 'Трафарет с оплатой за показы',
    value: 2,
  },
  {
    label: 'Трафарет с оплатой за клики',
    value: 3,
  },
  {
    label: 'Продвижение в поиске с оплатой за заказ',
    value: '',
  },
];

const ModalCreatingAnAdvertisingCampaignV2 = ({ ...props }) => {
  const {
    setIsModal,
    isModalChoiceOfStrategy,
    selectedMarketplace,
    selectedApi,
    selectedBrand,
    tableDate,
    tablePageValue = 'all_product',
    selectedCategory,
    isEdit,
    dataTable,
    tempIdTable,
    setResetTableChecked,
  } = props;
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [tableBody, setTableBody] = useState(null);
  const [campaignName, setCampaignName] = useState('');
  const [campaignNameDefault, setCampaignNameDefault] = useState('');
  const [errCampaignName, setErrCampaignName] = useState(false);

  const [strategyOptions, setStrategyOptions] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState([]);
  const [selectedСampaignType, setSelectedСampaignType] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [attributesInput, setAttributesInput] = useState([]);
  const [budget, setBudget] = useState('');
  const [budgetDefault, setBudgetDefault] = useState('');
  const [errBudget, setErrBudget] = useState(false);
  const [date, setDate] = useState(new Date().getTime());
  const [pageValue, setPageValue] = useState(btn_tab[0].value);
  const [activeStep, setActiveStep] = useState(
    selectedStrategy?.id
      ? new Set(['Параметры кампании'])
      : new Set(['Параметры кампании', 'Выбор стратегии'])
  );
  const [tableUrl, setTableUrl] = useState('');

  const [paymentImpressions, setPaymentImpressions] = useState('');
  const [paymentClick, setPaymentClick] = useState('');
  const [percentageOrder, setPercentageOrder] = useState('');
  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort } = setTableFuncs;

  const [checkedProducts, setCheckedProducts] = useState([]);

  const resetTable = async () => {
    paginatorRef.current.reset();
    paginatorRef.current.setTotal(0);
    await setFetchedData([]);
    await setHeaders([]);
    await setSort({});
    await setCheckedProducts([]);
  };

  const fetchEditAdvertisingCampaign = (e) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/campaign/change`;
    } else {
      url = `${RavshanHttp}/api/campaign/change`;
    }

    const [date_from, date_to] = tableDate;

    let api_id_list;

    // if (selectedApi) {
    //   api_id_list = selectedApi.value;
    // }
    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }

    const body = JSON.stringify({
      api_id: api_id_list,
      campaign_id: dataTable,
      dailyBudget: +budget,
      from_date: getFormatDate(+date_from),
      products: checkedProducts?.map((el) => {
        const offer = el['SKU'];
        return offer;
      }),
    });

    fetch(url, { body, method: 'PUT', headers })
      .then((res) => {
        if (res.ok) {
          //   setIsModal(false)
          //   sessionStorage.removeItem('selectedProject')
        } else {
          throw new Error('bad put project response');
        }
      })
      .catch((err) => console.error(err));
  };

  const fetchTableData = (params) => {
    let sort, filters;

    setLoading(true);

    if (params) {
      [sort, filters] = params;
    }
    const url = `${RavshanHttps}/api/campaign/tmp/table/get`;

    let session_id = sessionStorage.getItem('session_id');
    let status = pageValue === 'added';

    const body = JSON.stringify({
      session_id,
      status,
      order_param: sort?.[0],
      order_param_direction: sort?.[1],
      filters,
    });

    fetch(url, { method: 'POST', headers, body })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setTable(json);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchUpdateTable = (action) => {
    const url = `${RavshanHttps}/api/campaign/tmp/table/update/${action}`;

    const body = JSON.stringify({
      session_id: sessionStorage.getItem('session_id'),
      product_id: checkedProducts?.map(({ SKU }) => SKU),
    });

    fetch(url, { method: 'PUT', headers, body })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .then(async () => {
        await resetTable().then(() => {
          fetchTableData();
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchDeleteTable = () => {
    const url = `${RavshanHttps}/api/campaign/tmp/table/delete`;

    const body = JSON.stringify({
      session_id: sessionStorage.getItem('session_id'),
    });

    fetch(url, { method: 'DELETE', headers, body })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(JSON.stringify(res.json()));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // не работает
  const fetchStrategiesAttributes = () => {
    setAttributes([]);
    if (selectedStrategy.value == 'manual_strategy') return;
    const url = `${OlegCreateAddCampaingHttps}/api/v1/strategy/attributes/${selectedStrategy?.id}`;

    fetch(url, { method: 'GET', headers })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error('bad table response');
        }
      })
      .then((json) => {
        setAttributes(json);
      })
      .catch((e) => console.error(e));
  };

  const fetchStrategies = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/strategies/strategies`;
    } else {
      url = `${RavshanHttp}/api/strategies/strategies`;
    }

    let api_id_list;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => +el.value);
    }

    const _headers = {
      ...headers,
      'service-id': 2,
      'api-id': [api_id_list[0]],
    };

    fetch(url, { method: 'GET', headers: _headers })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error('bad table response');
        }
      })
      .then((json) => {
        const options_item = json?.map((el) => ({
          ...el,
          label: el?.name,
          value: el?.id,
        }));
        setStrategyOptions([
          ...options_item,
          { label: 'Ручная стратегия', value: 'manual_strategy' },
        ]);
      })
      .catch((e) => console.error(e));
  };

  const fetchCreatingAdvertisingCampaign = () => {
    if (!selectedStrategy) return;
    //  if (attributes?.length !== attributesInput?.length) return
    const url = `${RavshanHttps}/api/campaign/create_campaign`;

    const [date_from, date_to] = tableDate;

    let api_id_list;

    if (selectedApi) {
      api_id_list = selectedApi.value;
    }

    const body = JSON.stringify({
      api_id: api_id_list,
      name: campaignName,
      strategy_id: selectedStrategy?.id,
      weeklyBudget: +budget,
      // from_date: getFormatDate(+date_from),
      // from_date: '2024-09-09',
      from_date: new Date().toISOString().slice(0, 10),
      to_date: getFormatDate(+date),
      ...(checkedProducts && checkedProducts.length > 0
        ? {
            products: checkedProducts.map((el) => el['SKU']),
          }
        : {
            session_id: tempIdTable,
          }),
    });

    fetch(url, { method: 'POST', headers, body })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const error = await res.json().then((err) => err?.error);
          throw new Error(JSON.stringify(error));
        }
      })
      .then((json) => {
        alert(json);
        setIsModal(false);
        fetchDeleteTable();
        setResetTableChecked(true);
      })
      .catch((e) => {
        console.error(e);
        alert(e);
      })
      .finally(() => setLoading(false));
  };

  const fetchParametrsAdvertisingCampaign = () => {
    if (!dataTable) return;

    const url = `${RavshanHttps}/api/campaign/change`;

    let api_id_list;

    if (selectedApi) {
      api_id_list = selectedApi.value;
    }

    const body = JSON.stringify({
      api_id: api_id_list,
      campaign_id: new String(dataTable),
      session_id: sessionStorage.getItem('session_id'),
    });

    fetch(url, { method: 'POST', headers, body })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const error = await res.json().then((err) => err?.error);
          throw new Error(JSON.stringify(error));
        }
      })
      .then((json) => {
        const { campaign_id, daily_budget, from_date, name, strategy } = json;
        const current_strategy = strategyOptions.filter(
          ({ value }) => (value = strategy)
        );
        setSelectedStrategy(current_strategy);
        if (daily_budget) setBudgetDefault(new String(daily_budget));
        if (from_date) setDate(new Date(from_date).getTime()); // не верная дата
        if (name) setCampaignNameDefault(name);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => setLoading(false));
  };

  const fetchChangeAdvertisingCampaign = () => {
    if (!dataTable) return;

    const url = `${RavshanHttps}/api/campaign/change`;

    let api_id_list;

    if (selectedApi) {
      api_id_list = selectedApi?.value;
    }

    const body = JSON.stringify({
      api_id: api_id_list,
      campaign_id: new String(dataTable),
      dailyBudget: +budget,
      from_date: getFormatDate(+date),
      products: checkedProducts?.map((el) => {
        const offer = el['SKU'];
        return offer;
      }),
    });

    fetch(url, { method: 'PUT', headers, body })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const error = await res.json().then((err) => err?.error);
          throw new Error(JSON.stringify(error));
        }
      })
      .then((json) => {
        console.log(json);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (isEdit) {
      fetchParametrsAdvertisingCampaign();
      // fetchEditAdvertisingCampaign()
    }
  }, [selectedSpace]);

  // useEffect(() => {
  //     let newState
  //     if (selectedStrategy?.id) {
  //         fetchStrategiesAttributes()
  //         newState = activeStep.add('Выбор стратегии')
  //     } else {
  //         fetchStrategiesAttributes()
  //         newState = activeStep.delete('Выбор стратегии')
  //     }
  //     setActiveStep(activeStep)
  // }, [selectedStrategy]);

  useEffect(() => {
    if (!isModalChoiceOfStrategy) {
      const cmpNameLS = localStorage.getItem('campaignName');
      if (cmpNameLS) {
        setCampaignNameDefault(cmpNameLS);
      }

      const budgetLS = localStorage.getItem('budget');
      if (budgetLS) {
        setBudgetDefault(budgetLS);
      }

      const dateLS = localStorage.getItem('date');
      if (dateLS) {
        setDate(+dateLS);
      }
    }
  }, [isModalChoiceOfStrategy]);

  useEffect(() => {
    const cmpNameLS = localStorage.getItem('campaignName');
    if (cmpNameLS !== campaignName) {
      if (campaignName) localStorage.setItem('campaignName', campaignName);
    }

    const budgetLS = localStorage.getItem('budget');
    if (budgetLS !== budget) {
      if (budget) localStorage.setItem('budget', budget);
    }

    const dateLS = localStorage.getItem('date');
    const dateArrToStr = date;
    if (dateLS !== dateArrToStr) {
      if (dateArrToStr) localStorage.setItem('date', dateArrToStr);
    }

    if (budget || budget === 0) {
      setErrBudget(budget < 7000);
    }
  }, [campaignName, budget, date]);

  const onAttrInputHandler = ({ id, value }) => {
    const attrArr = [
      ...attributesInput?.filter((el) => id !== el?.attribute_id),
    ];
    setAttributesInput([
      ...attrArr,
      {
        attribute_id: id,
        attribute_value: Number(value) ? Number(value) : value,
      },
    ]);
  };

  useEffect(() => {
    resetTable();
    const timeoutId = setTimeout(() => {
      fetchTableData();
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [pageValue, tablePageValue, paginatorRef.current?.setTotal]);

  useEffect(() => {
    fetchStrategies();
  }, [selectedSpace]);

  useEffect(() => {
    setResetTableChecked(false);
  }, []);

  return (
    <div
      className="blackout"
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setIsModal(false);
          // fetchDeleteTable();
        }
      }}
    >
      <div className="modal-creating-an-advertising-campaign-v2">
        <header className="modal-creating-an-advertising-campaign-v2__header">
          {isEdit ? (
            <h2 className="title">Редактировать рекламную кампанию</h2>
          ) : (
            <h2 className="title">Создание рекламной кампании</h2>
          )}
          <button
            className="btn-close"
            onClick={(e) => {
              setIsModal(false);
              fetchDeleteTable();
            }}
          ></button>
        </header>

        <div className="modal-creating-an-advertising-campaign-v2__content">
          <aside className="left-panel">
            <section className="steps-content">
              {/* Шаг 2 */}
              <div className="step-content">
                <header className="step-content__header">
                  <h3 className="title_step">Общие параметры</h3>
                </header>

                <div className="step-content__content">
                  <InputDinamycPlaceholder
                    onChange={(e) => setCampaignName(e)}
                    err={errCampaignName}
                    textErr={'Неверное название'}
                    classname="autorization__input-field"
                    placeholder="Название рекламной кампании"
                    autoComplete={false}
                    size="s"
                    changeValue={campaignNameDefault}
                    setChangeValue={setCampaignNameDefault}
                  />

                  <div
                    className="field-group"
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  >
                    <SingleDatePicker
                      tooltip="Дата начала кампании"
                      date={date}
                      setDate={(e) => setDate(e)}
                      style={{
                        border: '1px solid #E0E7F2',
                        borderRadius: '.5em',
                        padding: '.5em 1em',
                        width: '100%',
                      }}
                      minDate={new Date()}
                    />

                    <InputDinamycPlaceholder
                      onChange={(e) => setBudget(e)}
                      err={errBudget}
                      size="s"
                      textErr={'Неверное значение, минимальное значение 7000'}
                      classname="autorization__input-field"
                      placeholder="Недельный бюджет"
                      autoComplete={false}
                      changeValue={budgetDefault}
                      setChangeValue={setBudgetDefault}
                      type="number"
                      tooltip={budget === '' ? '' : 'Мин. сумма- 7000 ₽'}
                      min={7000}
                    />
                  </div>
                </div>
              </div>

              {/* Шаг 3 */}
              <div className="step-content">
                <header className="step-content__header">
                  <h3 className="title_step">Выбор стратегии</h3>
                </header>

                <div className="step-content__content">
                  <div>
                    <DropDownSelector
                      style={{ maxWidth: '100%' }}
                      state={selectedStrategy}
                      options_prop={strategyOptions}
                      setState={(e) => setSelectedStrategy(e)}
                      placeholder="Выберите стратегию"
                      tooltip={selectedStrategy?.description}
                    />
                  </div>
                </div>
              </div>

              {/* Шаг 4 */}
              <div className="step-content" style={{ borderRight: 'none' }}>
                <header className="step-content__header">
                  <h3 className="title_step">Параметры стратегии</h3>
                </header>
                <div className="step-content__content">
                  {attributes?.length > 0 &&
                    attributes?.map(
                      ({
                        AttributeName,
                        Description,
                        ID,
                        KeyAttr,
                        Main,
                        NameForPortal,
                        Required,
                        serviceID,
                        strategyID,
                      }) => {
                        let currValue;

                        return (
                          <div key={ID} className="input-field-item">
                            <InputDinamycPlaceholder
                              key={ID}
                              onChange={(e) => {
                                onAttrInputHandler({ id: ID, value: e });
                                currValue = e;
                              }}
                              err={currValue < 0}
                              textErr={'Неверное значение'}
                              classname="autorization__input-field"
                              size="s"
                              placeholder={NameForPortal}
                              required={Required}
                              autoComplete={false}
                              tooltip={Description}
                            />
                          </div>
                        );
                      }
                    )}

                  {selectedStrategy.value == 'manual_strategy' && (
                    <DropDownSelector
                      style={{ maxWidth: '100%' }}
                      state={selectedСampaignType}
                      options_prop={options_prop}
                      setState={(e) => setSelectedСampaignType(e)}
                      placeholder="Тип запускаемой кампании"
                      tooltip={selectedStrategy?.description}
                    />
                  )}

                  {selectedStrategy.value != 'manual_strategy' &&
                    attributes?.length == 0 &&
                    'Нет аттрибутов по выбранной стратегии'}
                </div>
              </div>

              {/* Шаг 5 */}
              {selectedStrategy.value == 'manual_strategy' && (
                <div className="step-content" style={{ borderRight: 'none' }}>
                  <div className="step-content__content">
                    {selectedСampaignType.value ==
                      'payment_for_impressions' && (
                      <InputDinamycPlaceholder
                        onChange={(e) => setPaymentImpressions(e)}
                        textErr={'Неверное значение'}
                        size="s"
                        placeholder="Ставка за 1000 показов"
                        autoComplete={false}
                      />
                    )}

                    {selectedСampaignType.value == 'payment_for_click' && (
                      <InputDinamycPlaceholder
                        onChange={(e) => setPaymentClick(e)}
                        textErr={'Неверное значение'}
                        size="s"
                        placeholder="Ставка за 1 клик"
                        autoComplete={false}
                      />
                    )}

                    {selectedСampaignType.value == 'percentage_of_order' && (
                      <InputDinamycPlaceholder
                        onChange={(e) => setPercentageOrder(e)}
                        textErr={'Неверное значение'}
                        size="s"
                        placeholder="% от заказа"
                        autoComplete={false}
                      />
                    )}
                  </div>
                </div>
              )}
            </section>

            <div className="btn-container">
              <ButtonBasic
                green
                text="Запустить кампанию"
                size="56px"
                width="326px"
                onClick={(e) => {
                  if (isEdit) {
                    fetchChangeAdvertisingCampaign();
                  } else {
                    fetchCreatingAdvertisingCampaign();
                  }
                }}
              />
            </div>
          </aside>

          <div className="table-content">
            <h3 className="title_table">Выбор товаров в кампанию</h3>
            <TableBox
              onCheck={(e) => setCheckedProducts(e)}
              tableUrl={tableUrl}
              tableBody={tableBody}
              ref={paginatorRef}
              paginator={true}
              {...tableProps}
              fetchCallback={fetchTableData}
              onUncheckAllDependence={[pageValue]}
              child={
                <div className="child-content">
                  <TabsGreyBtn
                    btns={btn_tab}
                    currentBtn={pageValue}
                    setCurrentBtn={(e) => setPageValue(e)}
                    checkValue={true}
                  />
                  <div className="group-btn">
                    <ButtonBasic
                      action
                      text={`${
                        pageValue === 'added' ? 'Удалить' : 'Добавить'
                      } - ${checkedProducts?.length}`}
                      size="32px"
                      minWidth="120px"
                      maxWidth="120px"
                      onClick={(e) =>
                        fetchUpdateTable(
                          pageValue === 'added' ? 'delete' : 'add'
                        )
                      }
                    />

                    <ButtonBasic
                      action
                      text={
                        pageValue === 'added' ? 'Удалить все' : 'Добавить все'
                      }
                      onClick={(e) =>
                        fetchUpdateTable(
                          pageValue === 'added' ? 'delete_all' : 'add_all'
                        )
                      }
                      size="32px"
                      minWidth="120px"
                      maxWidth="120px"
                    />
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ModalCreatingAnAdvertisingCampaignV2 };
