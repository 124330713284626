import customHeaders, { getSpace } from '../../common/headers';
import { useParams } from 'react-router-dom';
import { LineChart } from '../../charts/LineChart/LineChart';
import { SliderRange } from '../Slider/Slider';
import { BarChartsVer2 } from '../../charts/BarChartsVer2/BarChartsVer2';
import { AlphabeticalSearch } from '../AlphabeticalSearch/AlphabeticalSearch';
import { useState, useEffect, useRef } from 'react';
import { useChartData } from '../../common/hooks';
import { AndreySkuHttps, AndreySkuHttp } from '../../fetchUrls';
import {
  Tabs,
  SearchGlobalInput,
  MetricsItem,
  TableBox,
  RangeBox,
  DropContainer,
  DropDownSelector,
  Checkbox,
} from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../store/general';
import './AnalysisBySaleType.scss';

const btn_tab = [
  {
    label: 'Топ 30',
    value: 'top',
  },
  {
    label: 'Товары',
    value: 'goods',
  },
  {
    label: 'Категории',
    value: 'category',
  },
  {
    label: 'Бренды',
    value: 'brands',
  },
  {
    label: 'Продавцы',
    value: 'sellers',
  },
  {
    label: 'По периодам',
    value: 'days',
  },
  {
    label: 'Ценовые сегменты',
    value: 'price_segment',
  },
];

const sales_type = [
  { value: 5, label: 'Все' },
  { value: 1, label: 'FBO' },
  { value: 2, label: 'FBS' },
  { value: 3, label: 'Из-за рубежа' },
  { value: 4, label: 'RFBS' },
];

const AnalysisBySaleType = () => {
  const selectedSpace = useGeneralStore(state => state.selectedSpace)
  const headers = { ...customHeaders, ...getSpace(selectedSpace) }
  const [pageValue, setPageValue] = useState(btn_tab[0].value);
  const [showDropBox, setShowDropBox] = useState(false);

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const [fetchedData, setFetchedData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [sort, setSort] = useState({});
  const [loading, setLoading] = useState(false);
  const [columnSize, setColumnSize] = useState([]);

  const [search, setSearch] = useState('');

  const [selectedMarketplace, setSelectedMarketplace] = useState([]);
  const [tableUrl, setTableUrl] = useState('');
  const [tableBody, setTableBody] = useState(null);
  const [priceRange, setPriceRange] = useState([0, 20000]);
  const [segments, setSegments] = useState(1);

  const [minMaxRange, setMinMaxRange] = useState([0, 0]);

  const [setChartData, chartData, setChartFuncs] = useChartData();
  const { setChartLoading, resetChart } = setChartFuncs;

  const [dateOptions, setDateOptions] = useState([]);
  const [selectedDateOptions, setSelectedDateOptions] = useState([]);
  const [selectedSalesType, setSelectedSalesType] = useState(sales_type?.at(0));

  const [tableMetrics, setTableMetrics] = useState([]);
  const [tableMetric, setTableMetric] = useState('IDC');
  const [isReverse, setIsReverse] = useState(false);
  const [choisePrice, setChoisePrice] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const paginatorRef = useRef();

  const path = useParams()['*'];
  const [mp, setMp] = useState('ozon');
  const activeTab = useParams()['*'];

  const resetTable = () => {
    paginatorRef.current.reset();
    paginatorRef.current.setTotal(0);
    setSort({});
    setTableHeaders([]);
    setFetchedData([]);
  };

  const fetchChartData = (abortController = new AbortController()) => {
    setChartLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v1/ecomru/competitors/ozon_analysis/working_scheme/${pageValue}/graph/`;
    } else {
      url = `${AndreySkuHttp}/api/v1/ecomru/competitors/ozon_analysis/working_scheme/${pageValue}/graph/`;
    }

    let searchId;
    let [date_from, date_to] = date;
    date_from = new Date(date_from + 86401000)?.toISOString()?.split('T')?.[0];
    date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

    const id_arr = selectedItems?.map((el) => {
      if (el) {
        if (pageValue === 'top' && el?.['Схема работы']) {
          if (Array.isArray(el?.['Схема работы'])) {
            const [img, name] = el?.['Схема работы'];
            return el?.['Схема работы'][0];
          }
        }

        if (pageValue === 'goods') {
          return el?.nm_id;
        }

        if (pageValue === 'working_scheme') {
          return el?.working_scheme;
        }
        if (pageValue === 'brands') {
          return el?.brand_name;
        }
        if (pageValue === 'sellers') {
          return el?.seller_name;
        }
        if (pageValue === 'category') {
          return el?.category_name;
        }
      }
    });

    searchId = pageValue == 'top' ? { working_scheme: id_arr } : '';
    let seller_data =
      pageValue == 'top'
        ? ''
        : { working_scheme_filter: selectedSalesType?.value };

    if (pageValue === 'goods') {
      searchId = { nm_id: id_arr };
    }
    if (pageValue === 'working_scheme') {
      searchId = { working_scheme: id_arr };
    }
    if (pageValue === 'brands') {
      searchId = { brand_name: id_arr };
    }
    if (pageValue === 'queries') {
      searchId = { query: id_arr };
    }
    if (pageValue === 'category') {
      searchId = { category_name: id_arr };
    }
    if (pageValue === 'sellers') {
      searchId = { seller_name: id_arr };
    }

    const priceData =
      pageValue == 'price_segment'
        ? {
          min_price: choisePrice[0],
          max_price: choisePrice[1],
        }
        : {};

    const body = JSON.stringify({
      date_from,
      date_to,
      page: 1,
      limit: 300,
      date_period: selectedDateOptions?.value,
      fbofbs_filter: true,
      metrics: [],
      ...searchId,
      ...seller_data,
      ...priceData,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setChartData(json);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setChartLoading(false);
      });
  };

  const fetchAllAccountData = (params) => {
    let sort;
    let filters;

    if (params) {
      [sort, filters] = params;
    }
    setLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v1/ecomru/competitors/ozon_analysis/working_scheme/${pageValue}/table/`;
    } else {
      url = `${AndreySkuHttp}/api/v1/ecomru/competitors/ozon_analysis/working_scheme/${pageValue}/table/`;
    }

    const price =
      pageValue == 'price_segment'
        ? {
          max_price: priceRange[0],
          min_price: priceRange[1],
          number_of_segments: +segments,
        }
        : {};

    const body = JSON.stringify({
      limit: paginatorRef.current.limit,
      page: paginatorRef.current.page,
      sort_name: sort?.[0],
      sort_order: sort?.[1],
      working_scheme_filter: selectedSalesType?.value,
      date_period: selectedDateOptions?.value,
      filters,
      ...price,
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const { data, labels, total, sort, column_size } = json;
        if (total) paginatorRef.current.setTotal(total);
        if (sort) setSort(sort);
        if (labels) setTableHeaders(labels);
        if (column_size) setColumnSize(column_size);
        setFetchedData(data?.length > 0 ? data : [[]]);
        setTableUrl(url);
        setTableBody(body);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const fetchDate = (page = 1) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v1/ecomru/competitors/ozon_analysis/date_period/`;
    } else {
      url = `${AndreySkuHttp}/api/v1/ecomru/competitors/ozon_analysis/date_period/`;
    }

    const body = JSON.stringify({
      limit: 50,
      page,
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const fromattedArr = json?.map(({ period }) => ({
          label: period,
          value: period,
        }));
        setDateOptions(fromattedArr);
        setSelectedDateOptions(fromattedArr?.[0]);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const fetchMinMaxPrice = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v1/ecomru/competitors/ozon_analysis/working_scheme/price_range/`;
    } else {
      url = `${AndreySkuHttp}/api/v1/ecomru/competitors/ozon_analysis/working_scheme/price_range/`;
    }

    const body = JSON.stringify({
      date_period: selectedDateOptions?.value,
      working_scheme_filter: selectedSalesType?.value,
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        if (json) {
          let { min_price, max_price } = json;
          setMinMaxRange([min_price, max_price]);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const fetchMetricsList = (
    abortController = new AbortController(),
    clearOnSuccess = false
  ) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${AndreySkuHttps}/api/v1/ecomru/competitors/ozon_analysis/working_scheme/${pageValue}/metric_list/`;
    } else {
      url = `${AndreySkuHttp}/api/v1/ecomru/competitors/ozon_analysis/working_scheme/${pageValue}/metric_list/`;
    }

    fetch(url, { method: 'GET', headers, signal: abortController.signal })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw Error(JSON.stringify(res.json()));
        }
      })
      .then((json) => {
        setTableMetrics(json);
        setTableMetric(json[0]?.value);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setChartLoading(false);
      });
  };

  const handleCheck = (data) => {
    setSelectedItems(data);
  };

  const onAction = (e) => {
    setChoisePrice([e.min_price, e.max_price]);
  };

  useEffect(() => {
    if (pageValue == 'price_segment') {
      fetchMinMaxPrice();
    }
  }, [pageValue, selectedSalesType, selectedDateOptions]);

  useEffect(() => {
    setMp(selectedMarketplace.value);
  }, [selectedMarketplace]);

  useEffect(() => {
    setPageValue(activeTab);
    resetChart();
    resetTable();
  }, [activeTab]);

  useEffect(() => {
    const abortController = new AbortController();

    if (pageValue !== 'price_segment') {
      fetchAllAccountData([], abortController);
    }
    return () => abortController.abort();
  }, [pageValue, date, selectedSalesType, selectedDateOptions, selectedSpace]);

  useEffect(() => {
    fetchDate();
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    fetchMetricsList(abortController);
    return () => abortController.abort();
  }, [pageValue, selectedSpace]);

  useEffect(() => {
    if (
      pageValue == 'goods' ||
      pageValue == 'category' ||
      pageValue == 'brands' ||
      pageValue == 'sellers' ||
      pageValue == 'days' ||
      pageValue == 'top'
    ) {
      fetchChartData();
    }

    if (pageValue == 'price_segment') {
      if (choisePrice?.length > 0) {
        fetchChartData();
      }
    }
  }, [
    selectedItems,
    date,
    pageValue,
    search,
    tableMetric,
    choisePrice,
    selectedDateOptions,
    selectedSalesType,
    isReverse,
    choisePrice,
    selectedSpace
  ]);

  useEffect(() => {
    resetChart();
    resetTable();
    setTableMetric([]);
    setTableMetrics([]);
  }, [pageValue]);


  return (
    <div className="analysis-platform-by-sale-type">
      <h1 className="title_main">Анализ по типу продаж</h1>

      <div className="top-panel">
        <DropDownSelector
          options_prop={sales_type}
          state={selectedSalesType}
          setState={(e) => setSelectedSalesType(e)}
          placeholder="Тип продаж"
        />
      </div>

      <div className="analysis-platform-by-sale-type">
        <div className="toolbar-medium">
          <Tabs tabs={btn_tab} pageValue={pageValue} />

          <DropDownSelector
            options_prop={dateOptions}
            state={selectedDateOptions}
            setState={(e) => setSelectedDateOptions(e)}
            placeholder="Дата"
            fetchCallback={(e) => fetchDate(e)}
            style={{ minWidth: '275px' }}
          />
          {/* <RangeBox setDate={e => setDate(e)} hideBtnGroup /> */}
        </div>

        <DropContainer
          // name={'Продажи и остатки'}
          showDropBox={showDropBox}
          setShowDropBox={(e) => setShowDropBox(e)}
          name={chartData?.title ? chartData?.title : ''}
        >
          <LineChart {...chartData}
            selectedMetric={tableMetric}
            setSelectedMetric={setTableMetric}
            metricsList={tableMetrics}
            legendPosition="top" />
        </DropContainer>

        {pageValue == 'price_segment' && (
          <SliderRange
            minValue={minMaxRange[0]}
            maxValue={minMaxRange[1]}
            setState={setPriceRange}
            sliderRange={priceRange}
            setSegments={(e) => setSegments(e)}
            segments={segments}
            range={priceRange}
            minMax
            callbackHandler={fetchAllAccountData}
          />
        )}

        <TableBox
          loading={loading}
          fetchedData={fetchedData}
          headers={tableHeaders}
          sort={sort}
          paginator={true}
          onAction={(e) => onAction(e)}
          onCheck={(e) => handleCheck(e)}
          onUncheckAllDependence={[pageValue, search]}
          fetchCallback={(e) => fetchAllAccountData(e)}
          sortingFunc={(e) => fetchAllAccountData(e)}
          ref={paginatorRef}
          onEdit={(e) => e}
          onDelete={(e) => e}
          isInsideTable={false}
          newTable={true}
          columnSize={columnSize}
          tableUrl={tableUrl}
          tableBody={tableBody}
          child={
            pageValue === 'days' && (
              <Checkbox
                value={isReverse}
                onChange={(checked) => setIsReverse(checked)}
              >Перевернуть</Checkbox>
            )
          }
        />
      </div>
    </div>
  );
};

export { AnalysisBySaleType };
