import { useState, useEffect, useRef } from 'react';
import { MoonLoader } from 'react-spinners';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar, getDatasetAtEvent, getElementAtEvent } from 'react-chartjs-2';
import iconBlue from './img/icon_blue.png'


import './BarCharts.scss'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarCharts = ({
  nameChart = false,
  nameX = '',
  nameY = '',
  chart_name,
  chart_unit = null,
  chart_size = 'small',
  chart_icons = [],
  display_legend,
  labels_prop = ['05', '06', '07', '08', '09', '10'],
  datasets_prop = [],
  multiAxis_prop = false,
  title_icon = false,
  colorArr = [],
  data_name = '',
  direction = 'y',
  legendPosition = 'right',
  colorStart,
  loading = false,
  maxHeight = false
}) => {
  const chartRef = useRef(null)
  const colors = [
    '#3300FF',
    '#FFCC00',
    '#FF3366',
    '#339933',
    '#3399FF',
    '#9966FF',
    '#FF9999',
    '#9933CC',
    '#FFFF99',
  ]

  const defaultDatasets = [
    {
      label: 'Нет данных',
      data: [23, 80],
      borderColor: colorArr[0] ? colorArr[0] : 'rgba(255, 99, 132, 0.5)',
      backgroundColor: colorArr[0] ? colorArr[0] : 'rgba(255, 99, 132, 0.5)',
      tension: .5
    },
    {
      label: 'Нет данных',
      data: [23, 20, 60],
      borderColor: colorArr[1] ? colorArr[1] : 'rgba(53, 162, 235, 0.5)',
      backgroundColor: colorArr[1] ? colorArr[1] : 'rgba(53, 162, 235, 0.5)',
      tension: .5
    }
  ]

  const defaultOptions = {
    type: 'bar',
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: direction, //'x'
    // Elements options apply to all of the options unless overridden in a dataset
    // In this case, we are setting the border of each horizontal bar to be 2px wide
    elements: {
      bar: {
        borderWidth: 2,
      }
    },
    interaction: {
      intersect: false,
      mode: 'index'
    },
    plugins: {
      zoom: {
        zoom: {
          drag: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'x',
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y?.toLocaleString();
            }
            return label;
          }
        },
        backgroundColor: 'white',
        borderColor: '#53616E',
        titleColor: '#53616E',
        bodyColor: '#8F99A3',
        usePointStyle: 'circle',
        bodySpacing: 10,
        bodyFont: {
          font: {
            size: 14,
            family: 'IBMPlexSans',
          },
        },
        displayColors: true,
        padding: 12,
        footerSpacing: 4,
        caretPadding: 6,
        boxPadding: 10,
        caretSize: 8,
        borderColor: '#E0E7F2',
        borderWidth: 1,
        borderDash: [2, 2],
        borderRadius: 2,
        displayColors: true,
        boxPadding: 8,
      },
      legend: {
        display: display_legend,
        position: 'bottom',
        labels: {
          boxWidth: 6,
          boxHeight: 6,
          usePointStyle: true
        },
      },
      title: {
        display: false,
        text: '',
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: '#F1F5F9',
        },
        ticks: {
          display: true,
          color: '#94A3B8',

          font: {
            size: 12,
            family: 'Inter',
          }
        },
        title: {
          color: '#020617',
          font: {
            size: 12,
            family: 'Inter',
          },
          display: nameX ? true : false,
          text: nameX ? nameX : ''
        }
      },
      y: {
        grid: {
          display: true,
          color: '#F1F5F9',

        },
        ticks: {
          display: true,
          color: '#94A3B8',
          font: {
            size: 12,
            family: 'Inter',
          }
        },
        title: {
          color: '#020617',
          font: {
            size: 12,
            family: 'Inter',
          },
          display: nameY ? true : false,
          text: nameY ? nameY : ''
        }
      },
    },
  }


  const datasets = datasets_prop.length > 0 ? datasets_prop.map((el, ind) => {
    return {
      ...defaultDatasets[0],
      data: el,
      label: Array.isArray(data_name) ? data_name[ind] : data_name,
      backgroundColor: colorStart ? colors[colorStart + ind] : colors[ind],
      borderColor: colorStart ? colors[colorStart + ind] : colors[ind]
    }
  }) : defaultDatasets

  const data = { labels: labels_prop, datasets }

  const options = {
    ...defaultOptions,
    type: 'bar',
    // scales: multiAxis_prop ? multiAxisOptions(colorArr.length > 0 ? colorArr : ['rgb(255, 99, 132)', 'rgb(53, 162, 235)']) : null
  }



  const multiAxisOptions = (colorArr) => {
    const [first_color, second_color] = colorArr
    return {
      y: {
        display: true,
        position: 'left',
        grid: {
          drawOnChartArea: false,
          color: '#F1F5F9',
          borderColor: '#cc3300'
        }
      },
      y1: {
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
          color: '#F1F5F9',
          borderColor: second_color
        }
      }
    }
  }


  const printDatasetAtEvent = (dataset) => {
    if (!dataset.length) return;

    const datasetIndex = dataset[0].datasetIndex;

    return data.datasets[datasetIndex].label
  };

  const printElementAtEvent = (element) => {
    if (!element.length) return;

    const { datasetIndex, index } = element[0];

    return [data.labels[index], data.datasets[datasetIndex].data[index]]
  };


  const getChartData = (event) => {
    const { current: chart } = chartRef;

    if (!chart) {
      return;
    }

    const label = printDatasetAtEvent(getDatasetAtEvent(chart, event));
    const [date, value] = printElementAtEvent(getElementAtEvent(chart, event));
    alert([label, date, value?.toLocaleString()]?.join(' | '));
  };

  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  return (
    loading
      ?
      <div className="chart_loader_wrapper" >
        <MoonLoader
          color="#7b7b7b"
          speedMultiplier={0.5}
        />
      </div>
      :
      <div className={`line_chart`} >
        {/* <header className='line_chart_header'>
      <button className='btn-reset-zoom' onClick={e => handleResetZoom()} >Сбросить зум</button>
        <span className={`line_chart_heading line_chart_heading_${chart_size}`}>
          <span className="chart_name">{chart_name}</span>
          <span className="chart_unit">{chart_unit ? `${chart_size === 'big' ? '' : ","}${chart_unit}` : null}</span>
          {
            title_icon ? 
            <img 
              className="chart_title_icon" 
              src={iconBlue}
              alt='chart_title_icon'
            /> : null
          }
        </span>
        {
          chart_icons.length ? 
          <div className="header_icon_placeholder">
            {
              chart_icons.map((el,ind) => {
                return(
                  <img 
                    className={`chart_header_btn_${el.description}`} 
                    src={el.icon}
                    key={ind+el.description}
                  />
                )
              })
            }
          </div>
          : 
          null
        }
      </header> */}
        {nameChart && <h3 className='title'>{nameChart}</h3>}
        <div className='chart-box' style={{maxHeight: maxHeight ? maxHeight : '', minHeight: maxHeight ? maxHeight : ''}}>
          <Bar
            options={options}
            data={data}
            ref={chartRef}
            onClick={getChartData}
          />
        </div>
      </div>
  )
}

export { BarCharts }