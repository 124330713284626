import { useEffect, useState } from 'react';
import headers from '../../common/headers';
import { TableTop5 } from '../../components/TableTop5/TableTop5';
import { LineChart } from '../../charts/LineChart/LineChart';
import { useChartData } from '../../common/hooks';
import { DoughnutChart } from '../../charts/DoughnutChart/DoughnutChart';
import { BarCharts } from '../../charts/BarCharts/BarCharts';
import { CardMetricVersion2 } from '../../components/CardMetricVersion2/CardMetricVersion2';
import { KonstantinMasterHttps } from '../../fetchUrls';
import product from './img/product.png';
import ozon from './img/Ozon.png';
import { WrapperLineChart } from '../../charts/WrapperCharts/WrapperLineChart/WrapperLineChart';
import { WrapperDoughnutChart } from '../../charts/WrapperCharts/WrapperDoughnutChart/WrapperDoughnutChart';
import './AdvancedAnalyticsQuestions.scss';

const data_1 = {
  header: ['Ключевая фраза', 'Кол-во упоминаний'],
  type: ['', 'dynamic'],
  data: [
    ['включение', [555555, 100]],
    ['зима', [12, -100]],
    ['нарекание', [55, -20]],
    ['плотно', [5555, -30]],
    ['хватают', [5, 40]],
  ],
};

const data_2 = {
  header: ['Товар', 'К-во отрицательных оценок', 'Магазин'],
  type: ['imgWithText', 'dynamic', 'brand'],
  data: [
    [
      [
        product,
        'Наушники беспроводные Honor Choice Earbuds X5 Pro-Eurasia BTV-ME10, серый',
      ],
      [100, -30],
      [ozon, 'Длинное название бренда'],
    ],
    [
      [
        product,
        'Наушники беспроводные Honor Choice Earbuds X5 Pro-Eurasia BTV-ME10, серый',
      ],
      [100, -30],
      [ozon, 'Длинное название бренда'],
    ],
    [
      [
        product,
        'Наушники беспроводные Honor Choice Earbuds X5 Pro-Eurasia BTV-ME10, серый',
      ],
      [100, -30],
      [ozon, 'Длинное название бренда'],
    ],
    [
      [
        product,
        'Наушники беспроводные Honor Choice Earbuds X5 Pro-Eurasia BTV-ME10, серый',
      ],
      [100, -30],
      [ozon, 'Длинное название бренда'],
    ],
    [
      [
        product,
        'Наушники беспроводные Honor Choice Earbuds X5 Pro-Eurasia BTV-ME10, серый',
      ],
      [100, -30],
      [ozon, 'Длинное название бренда'],
    ],
  ],
};

const AdvancedAnalyticsQuestions = ({
  date,
  selectedBrand,
  selectedCategory,
  selectedApi,
  selectedMarketplace,
  pageValue,
  pageContent,
  allPeriod,
}) => {
  const [topWithoutQuestions, setTopWithoutQuestions] = useState([]);
  const [topAverageResponseTime, setTopAverageResponseTime] = useState([])
  const [metrics, setMetrics] = useState([])
  const [loadingMetrics, setLoadingMetrics] = useState(false)

  const body_obj = () => {

    let api_id_list;
    let mp_id_list;
    let category_id_list;
    let brand_id_list;
    const [date_from, date_to] = date;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }

    if (selectedMarketplace?.length > 0) {
      mp_id_list = selectedMarketplace?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    const body = JSON.stringify({
      mp_id_list,
      api_id_list,
      category_id_list,
      brand_id_list,
      date_from: allPeriod
        ? new Date(0)
        : new Date(date_from + 10)?.toISOString().split('T')?.[0],
      date_to: new Date(date_to)?.toISOString().split('T')?.[0],
    });
    if (selectedApi?.length > 0) {
      return body
    } else {
      return null
    }
  }

  useEffect(() => {
    if (selectedApi?.length > 0) {
      body_obj()
    }
  }, [
    date,
    selectedBrand,
    selectedCategory,
    selectedApi,
    selectedMarketplace,
    allPeriod,
  ]);

  useEffect(() => {
    fetchTable('top-5-without-questions', setTopWithoutQuestions)
    fetchTable('top-5-average-response-time', setTopAverageResponseTime)
    fetchMetric()
  }, []);

  const fetchTable = async (path, set) => {
    if (!selectedApi.length) {
      return;
    }
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/wb/questions/analytics/tables/${path}`;
    } else {
      url = `${KonstantinMasterHttps}/wb/questions/analytics/tables/${path}`;
    }

    let api_id_list;
    let mp_id_list;
    let category_id_list;
    let brand_id_list;
    const [date_from, date_to] = date;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }

    if (selectedMarketplace?.length > 0) {
      mp_id_list = selectedMarketplace?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    const body = JSON.stringify({
      mp_id_list,
      api_id_list,
      category_id_list,
      brand_id_list,
      date_from: allPeriod
        ? new Date(0)
        : new Date(date_from + 10)?.toISOString().split('T')?.[0],
      date_to: new Date(date_to)?.toISOString().split('T')?.[0],
    });

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body,
      });

      if (!response.ok) {
        const err = await response.json();
        throw new Error(JSON.stringify(err));
      }

      const json = await response.json();
      set(json);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchMetric = async () => {
    if (!selectedApi.length) {
      return;
    }
    setMetrics([])
    setLoadingMetrics(true)
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/wb/questions/analytics/plots/total-questions-avg-response-time`;
    } else {
      url = `${KonstantinMasterHttps}/wb/questions/analytics/plots/total-questions-avg-response-time`;
    }

    let api_id_list;
    let mp_id_list;
    let category_id_list;
    let brand_id_list;
    const [date_from, date_to] = date;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }

    if (selectedMarketplace?.length > 0) {
      mp_id_list = selectedMarketplace?.map((el) => el.value);
    }

    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }

    const body = JSON.stringify({
      mp_id_list,
      api_id_list,
      category_id_list,
      brand_id_list,
      date_from: allPeriod
        ? new Date(0)
        : new Date(date_from + 10)?.toISOString().split('T')?.[0],
      date_to: new Date(date_to)?.toISOString().split('T')?.[0],
    });

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body,
      });

      if (!response.ok) {
        const err = await response.json();
        throw new Error(JSON.stringify(err));
      }

      const json = await response.json();
      setMetrics(json);
      setLoadingMetrics(false)
    } catch (err) {
      setLoadingMetrics(false)
      console.error(err);
    }
  };

  useEffect(() => {
    fetchTable('top-5-without-questions', setTopWithoutQuestions)
    fetchTable('top-5-average-response-time', setTopAverageResponseTime)
    fetchMetric()
  }, [
    date,
    selectedBrand,
    selectedCategory,
    selectedApi,
    selectedMarketplace,
    allPeriod,
  ]);

  return (
    <div className="advanced-analytics">
      <div className="advanced-analytics__table-chart-cards-group">
        <WrapperDoughnutChart
          url={`${KonstantinMasterHttps}/wb/questions/analytics/plots/questions-received`}
          body={body_obj()}
          nameChart="Полученные вопросы"
        />

        <WrapperLineChart
          url={`${KonstantinMasterHttps}/wb/questions/analytics/plots/received-per-day`}
          body={body_obj()}
          nameChart="Полученные вопросы"
        />

        <div className="advanced-analytics__table-chart-cards-group_cards">
          {metrics.length > 0 ? metrics.map(({ labels, data }) => {
            return <CardMetricVersion2
              title={labels?.header[0]}
              value={data}
            // type={'rating'}
            />
          })
            :
            [...Array(2)].map((el) => {
              return <CardMetricVersion2
                title={''}
                value={[]}
              // type={'rating'}
              />
            })
          }


          {/* <CardMetricVersion2 title="Всего отзывов" value={[9300, 5321, 100]} /> */}
        </div>
      </div>

      <div className="advanced-analytics__chart-group">
        <div className="advanced-analytics__chart-group_line">
          <WrapperLineChart
            url={`${KonstantinMasterHttps}/wb/questions/analytics/plots/questions-per-day`}
            body={body_obj()}
            nameChart="Вопросов за день"
          />
        </div>
        <div className="advanced-analytics__chart-group_doughnut">
          <WrapperDoughnutChart
            url={`${KonstantinMasterHttps}/wb/questions/analytics/plots/questions-on-marketplaces`}
            body={body_obj()}
            nameChart="Вопросы по маркетплейсам"
          />
        </div>
      </div>

      <div className="advanced-analytics__table-and-chart-group">

        <WrapperLineChart
          url={`${KonstantinMasterHttps}/wb/questions/analytics/plots/avg-answer-time`}
          body={body_obj()}
          nameChart="Среднее время ответа"
        />

      </div>

      <div className="advanced-analytics__table-group">
        <TableTop5
          title="ТОП - 5 товаров, по которым долгое время не было вопросов"
          data={topWithoutQuestions}
        />

        <TableTop5
          title="ТОП - 5 товаров с высоким средним временем ответа"
          data={topAverageResponseTime}
        />
      </div>
    </div>
  );
};

export { AdvancedAnalyticsQuestions };
