import { useState, useEffect } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import { CrmCreateTable } from '../../components/CrmCreateTable/CrmCreateTable';
import { CrmTableDetail } from '../../components/CrmTableDetail/CrmTableDetail';
import { CrmTablesList } from '../../components/CrmTablesList/CrmTablesList';
import './Crm.scss';

const Crm = () => {
  return (
    <Routes>
      <Route path="create-table/*" element={<CrmCreateTable />} />
      <Route path="table-list/*" element={<CrmTablesList />} />
      <Route path="table/:id" element={<CrmTableDetail />} />
    </Routes>
  );
};

export { Crm };
