import { useState, useEffect } from 'react';
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import {
  InputDinamycPlaceholder,
  DropDownSelector,
} from '@lk-gtcom/ecomlab-components';
import './CrmModalDetail.scss';
import useGeneralStore from '../../store/general';
import customHeaders, { getSpace } from '../../common/headers';
import { RavshanHttps } from '../../fetchUrls';
import close_icon from '../CrmCreateTable/img/close_icon.svg';
import settings_icon from '../CrmCreateTable/img/settings.svg';
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import { CrmAttrControlModal } from '../CrmCreateTable/CrmAttrControlModal';
import { width } from '@mui/system';

const CrmModalDetail = ({
  setIsModal,
  selectedRowId,
  table_uid,
  rowData,
  onUpdateTable,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [fieldConfig, setFieldConfig] = useState([]);

  const [currentIndex, setCurrentIndex] = useState(null);
  const [objValuesAttr, setObjValuesAttr] = useState({});
  const [selectedTableType, setSelectedTableType] = useState('');
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [isAttrControlModalOpen, setIsAttrControlModalOpen] = useState(false);

  const onGetData = (table_uid) => {
    const url = `${RavshanHttps}/api/custom_table/get_custom_table_list_field`;
    const body = JSON.stringify({ table_uid });

    fetch(url, { method: 'POST', headers, body })
      .then((res) =>
        res.ok ? res.json() : Promise.reject('ERROR: get_tables_attr request')
      )
      .then((json) => {
        setFieldConfig(json.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    onGetData(table_uid);
  }, [table_uid]);

  useEffect(() => {
    if (!isAttrControlModalOpen) {
      onGetData(table_uid);
    }
  }, [isAttrControlModalOpen]);

  useEffect(() => {
    if (rowData && fieldConfig.length > 0) {
      const updatedInputs = {};
      fieldConfig.forEach((field) => {
        const fieldName = field[1];
        updatedInputs[fieldName] =
          rowData[fieldName] !== undefined ? rowData[fieldName] : '';
      });
      setInputs(updatedInputs);
    }
  }, [rowData, fieldConfig]);

  const handleInputChange = (field, value) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [field]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };

  const handleDeleteField = (fieldName) => {
    const url = `${RavshanHttps}/api/custom_table/delete_custom_table_data`;
    const body = JSON.stringify({
      table_uid,
      row_uid: selectedRowId,
      field_name: fieldName,
    });

    fetch(url, {
      method: 'DELETE',
      headers,
      body,
    })
      .then((res) =>
        res.ok
          ? res.json()
          : Promise.reject('ERROR: delete_custom_table_data request')
      )
      .then((res) => {
        console.log(res);
        setInputs((prevInputs) => ({
          ...prevInputs,
          [fieldName]: ' ',
        }));
        onUpdateTable(table_uid);
      })
      .catch((err) => console.error(err));
  };

  const handleSubmit = () => {
    let hasErrors = false;
    const updatedErrors = { ...errors };

    Object.keys(inputs).forEach((field) => {
      if (!inputs[field]) {
        updatedErrors[field] = 'Это поле обязательно для заполнения';
        hasErrors = true;
      }
    });

    if (hasErrors) {
      setErrors(updatedErrors);
    } else {
      onAddData(table_uid, inputs);
      setInputs(
        Object.keys(inputs).reduce((acc, key) => ({ ...acc, [key]: '' }), {})
      );
      setIsModal(false);
    }
  };

  const onAddData = (table_uid, inputs) => {
    const url = `${RavshanHttps}/api/custom_table/add_custom_table_data`;
    // const data = Object.keys(inputs).map((field) => ({
    //   row_uid: selectedRowId,
    //   value:
    //     selectedList && field === 'City' ? selectedList?.value : inputs[field],
    //   field_name: field,
    // }));
    const data = Object.keys(inputs).map((field) => ({
      row_uid: selectedRowId,
      value: inputs[field],
      field_name: field,
    }));
    const body = JSON.stringify({ table_uid, data });

    fetch(url, {
      method: 'POST',
      headers,
      body,
    })
      .then((res) =>
        res.ok
          ? res.json()
          : Promise.reject('ERROR: add_custom_table_data request')
      )
      .then((res) => {
        console.log(res);
        onUpdateTable(table_uid);
      })
      .catch((err) => console.error(err));
  };

  const onGetFieldList = (table_uid) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/custom_table/get_custom_table_dict_values`;
    } else {
      url = `${RavshanHttps}/api/custom_table/get_custom_table_dict_values`;
    }

    const body = JSON.stringify({
      table_uid,
      field_name: 'City',
    });

    fetch(url, { method: 'POST', headers, body })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('ERROR: get_tables_attr request');
        }
      })
      .then((json) => {
        const formattedArray = json.map((el) => ({
          value: el[0],
          label: el[1],
        }));

        setList(formattedArray);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    onGetFieldList(table_uid);
  }, []);

  const [selectedList, setSelectedList] = useState([]);
  const [list, setList] = useState([]);

  function findElementById(dataArray, id) {
    const element = dataArray.find((item) => item?.value === id);

    if (element) {
      return [{ label: element?.label, value: element?.value }];
    }
  }
  let defValue;

  useEffect(() => {
    defValue = findElementById(list, rowData?.City);
  }, [list]);

  return (
    <div
      className="blackout"
      onClick={(e) => {
        if (e.target.classList.contains('blackout')) {
          setIsModal(false);
        }
      }}
    >
      <div className="modal-crm-detail">
        <CrmAttrControlModal
          objValuesAttr={objValuesAttr}
          setObjValuesAttr={(e) => setObjValuesAttr(e)}
          currentIndex={currentIndex}
          setCurrentIndex={(e) => setCurrentIndex(e)}
          selectedTableType={selectedTableType}
          selectedAttributes={selectedAttributes}
          setSelectedAttributes={setSelectedAttributes}
          isOpen={isAttrControlModalOpen}
          close={() => setIsAttrControlModalOpen(false)}
          tableId={table_uid}
          selectedRowId={selectedRowId}
        />
        <h2 className="title">Изменение данных</h2>
        {fieldConfig.map((field, index) => (
          <div
            key={`${field[1]}-${index}`}
            style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
          >
            {field[2] !== 'dictionary' ? (
              <>
                <InputDinamycPlaceholder
                  classname="autorization__input-field"
                  placeholder={`${field[1]}`}
                  maxLength={150}
                  onChange={(value) => handleInputChange(field[1], value)}
                  type="text"
                  changeValue={inputs[field[1]] || ''}
                  textErr={errors[field[1]]}
                  err={!!errors[field[1]]}
                  autoComplete={false}
                  required
                />
                <button
                  className="modal-crm__btn-close"
                  onClick={() => handleDeleteField(field[1])}
                >
                  <img src={close_icon} alt="удалить" />
                </button>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <DropDownSelector
                  options_prop={list}
                  state={selectedList}
                  // setState={setSelectedList}
                  setState={(selectedOption) => {
                    setSelectedList(selectedOption);
                    handleInputChange('City', selectedOption?.value);
                  }}
                  placeholder="City"
                  // fetchCallback={}
                  // setInputChange={}
                  // defaultValue={[{ label: 'Все', value: 'all' }]}
                  defaultValue={defValue}
                />
                <button
                  className="modal-crm__btn-close"
                  onClick={() => handleDeleteField(field[1])}
                >
                  <img src={close_icon} alt="удалить" />
                </button>
              </div>
            )}
            {/* {field[2] === 'dictionary' && (
              <TooltipLightBlue
                top="24px"
                right="100%"
                position={'absolute'}
                text="Управлять списком"
                child={
                  <button
                    className="modal-crm__btn-settings"
                    onClick={() => {
                      // setCurrentIndex(idx);
                      setIsAttrControlModalOpen(true);
                      setSelectedAttributes([]);
                    }}
                  >
                    <img src={settings_icon} />
                  </button>
                }
              />
            )} */}
          </div>
        ))}

        <ButtonBasic
          violet
          size="56px"
          text="Сохранить изменения"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export { CrmModalDetail };
