import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ButtonBasic, InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components";
import './ModalConfirmingTemplateDeletion.scss'

const ModalConfirmingTemplateDeletion = ({
    setIsModal,
    selectedGroupId,
    deleteTemplate,
    deleteTemplateInGroup,
    selectedTemplateId
}) => {

    return (
        <div className='blackout'
            onClick={(e) => {
                let el = e.target
                if (el.classList.contains('blackout')) {
                    setIsModal(false)
                }
            }}>
            <div className='modal-confirming-template-deletion'>
                <h1 className='modal-confirming-template-deletion__title'>Подтвердите удаление</h1>
                <div className='modal-confirming-template-deletion__group-btn'>
                    {selectedGroupId !== - 1 && <ButtonBasic
                        red
                        text='Удалить шаблон из группы'
                        width='100%'
                        onClick={(e) => {
                            deleteTemplate(selectedTemplateId, selectedGroupId)
                            setIsModal(false)
                        }}
                    />}

                    {<ButtonBasic
                        red
                        text='Удалить шаблон из всех групп'
                        width='100%'
                        onClick={(e) => {
                            deleteTemplateInGroup(selectedTemplateId)
                            setIsModal(false)
                        }}
                    />}

                    <ButtonBasic
                        grey
                        text='Отмена'
                        width='100%'
                        onClick={(e) => setIsModal(false)}
                    />
                </div>
            </div>
        </div>
    )
}

export { ModalConfirmingTemplateDeletion }