import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import "./Skeleton.scss"

const SkeletonLeft = ({ height = '400' }) => {

    return (
        <div className='sceleton-product-data'>
            <Stack className='sceleton-product-data__content'>
                {/* <Skeleton variant="circular" width={160} height={160} /> */}
                <div className='sceleton-product-data__group'>
                    <Skeleton
                        variant="rectangular"
                        sx={{ height: '42px', width: '200px' }} />

                    <Skeleton
                        variant="rectangular"
                        sx={{ height: '47px', width: '116px' }} />
                </div>

                <Skeleton
                    variant="rectangular"
                    sx={{ height: '42px', width: '100%' }} />

                <div className='sceleton-product-data__group'>
                    <Skeleton
                        variant="rectangular"
                        sx={{ height: '22px', width: '33%' }} />

                    <Skeleton
                        variant="rectangular"
                        sx={{ height: '22px', width: '33%' }} />

                    <Skeleton
                        variant="rectangular"
                        sx={{ height: '22px', width: '33%' }} />
                </div>

                <Skeleton
                    variant="rectangular"
                    sx={{ height: '110px', width: '100%' }} />

                {[...Array(2)].map((el, ind) => {
                    return <div className='sceleton-product-data__col'>
                        <Skeleton
                            variant="rectangular"
                            key={el + ind}
                            sx={{ height: '42px', width: '100%' }} />
                    </div>
                })}

                {[...Array(2)].map((el, ind) => {
                    return <div className='sceleton-product-data__group'>
                        <Skeleton
                            variant="rectangular"
                            key={el + ind}
                            sx={{ height: '42px', width: '50%' }} />

                        <Skeleton
                            variant="rectangular"
                            key={el + ind}
                            sx={{ height: '42px', width: '50%' }} />
                    </div>
                })}
            </Stack>
        </div>
    )
}

export { SkeletonLeft }