import customHeaders, { getSpace } from '../../common/headers';
import { MoonLoader } from 'react-spinners';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ArtemBillingHttps } from '../../fetchUrls';
import { useState, useEffect } from 'react';
import useGeneralStore from '../../store/general';
import './BalanceIncrease.scss';

const BalanceIncrease = () => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [requestCounter, setRequestCounter] = useState(0);
  const abortSignal = new AbortController();
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderDetail, setOrderDetail] = useState('');

  useEffect(() => {
    const orderId = searchParams.get('orderId');
    if (orderId) {
      let url;
      if (!window.location.hostname.match('localhost')) {
        // url = `${ArtemBillingHttps}/testenv/check-order?orderId=${orderId}`;
        url = `${ArtemBillingHttps}/check-order?orderId=${orderId}`;
      } else {
        // url = `${ArtemBillingHttps}/testenv/check-order?orderId=${orderId}`;
        url = `${ArtemBillingHttps}/check-order?orderId=${orderId}`;
      }

      const interval = setInterval(() => {
        fetch(url, { method: 'GET', headers, signal: abortSignal.signal })
          .then(async (res) => {
            if (res.ok) {
              return res.json();
            } else {
              const err = await res.json();
              throw new Error(JSON.stringify(err?.detail));
            }
          })
          .then((json) => {
            const { status, orderStatus, orderDetails } = json;
            if (status !== 'ok') {
              setOrderDetail(orderDetails);
              setIsError(true);
            }
            if (status === 'ok' && orderStatus === 2) {
              setOrderDetail('Оплата произведена в полном объеме');
              setIsError(false);
            }
            if (status === 'ok' && orderStatus === 6) {
              setOrderDetail(orderDetails);
              setIsError(true);
            }

            // setOrderDetail(orderDetails);
            clearInterval(interval);
            setIsLoading(false);

            setTimeout(() => {
              navigate('../payment');
            }, 5000);
          })
          .catch((err) => {
            console.log(err);
            console.log(requestCounter);

            setRequestCounter(requestCounter + 1);
            if (requestCounter === 3) {
              setIsLoading(false);
              setIsError(true);
              clearInterval(interval);
              setTimeout(() => {
                navigate('../payment');
              }, 3000);
            }
          });
      }, 3000);
    } else {
      abortSignal.abort();
      navigate('../payment');
    }
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {isLoading ? (
        <>
          <h1 style={{ color: 'grey', fontSize: '37.5px', margin: 0 }}>
            Операция обрабатывается
          </h1>
          <div
            className="loader_wrapper"
            style={{ border: 'none', height: '150px' }}
          >
            <MoonLoader color="#7b7b7b" speedMultiplier={0.5} size={65} />
          </div>
        </>
      ) : isError ? (
        <>
          <h1
            style={{
              textAlign: 'center',
              color: 'red',
              fontSize: '31px',
              margin: 0,
            }}
          >
            {/* Что-то пошло не так, <br /> скоро вы будете перенаправлены */}
            {orderDetail}
          </h1>
        </>
      ) : (
        <>
          <h1
            style={{
              textAlign: 'center',
              color: '#020617',
              fontSize: '31px',
              margin: 0,
            }}
          >
            {/* Операция прошла успешно, <br /> скоро вы будете перенаправлены */}
            {orderDetail}
          </h1>
        </>
      )}
    </div>
  );
};

export { BalanceIncrease };
