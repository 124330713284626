import {
  ButtonBasic,
  InputDinamycPlaceholder,
  InputDynamicWidth,
} from '@lk-gtcom/ecomlab-components';
import customHeaders, { getSpace } from '../../../common/headers';
import { useState, useEffect } from 'react';
import { MessageItem } from '../../AutomationResponses/AutomationResponses';
import useGeneralStore from '../../../store/general';
import {
  KonstantinFeedbackHttps,
  KonstantinFeedbackHttp,
} from '../../../fetchUrls';
import plus_icon from './img/bx-plus-circle.svg';
import minus_icon from './img/bx-minus-circle.svg';
import './ModalGroupAnswer.scss';

const ModalGroupAnswer = ({
  selectedGroupId,
  setIsModal,
  deleteTemplate,
  currentName,
  editGroup,
  addToGroupTemplate,
  fetchGroupList,
}) => {
  const [name, setName] = useState('');
  const [nameGroup, setNameGroup] = useState('');
  const [changeNameGroup, setChangeNameGroup] = useState(currentName);
  const [searchAvailable, setSearchAvailable] = useState('');
  const [searchInGroup, setSearchInGroup] = useState('');
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const [templatesInGroup, setTemplatesInGroup] = useState([]);
  const [templatesAvailable, setTemplatesAvailable] = useState([]);

  const [isUpdate, setIsUpdate] = useState(true);

  const selectGroup = (group_id, status, search) => {
    let query = `out_of_group=${status}`;
    let search_param = search ? `&search_param=` + search : '';

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/groups/${group_id}/templates?${query}${search_param}`;
    } else {
      url = `${KonstantinFeedbackHttps}/feedbacks/answers/groups/${group_id}/templates?${query}${search_param}`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        if (status) {
          setTemplatesInGroup(json);
        } else {
          setTemplatesAvailable(json);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (isUpdate) {
      setTimeout(() => {
        selectGroup(selectedGroupId, true);
        selectGroup(selectedGroupId, false);
      }, 1000);

      setIsUpdate(false);
    }
  }, [isUpdate]);

  useEffect(() => {
    selectGroup(selectedGroupId, false, searchAvailable);
  }, [searchAvailable]);

  useEffect(() => {
    selectGroup(selectedGroupId, true, searchInGroup);
  }, [searchInGroup]);

  const TemplateItem = ({
    id,
    created_at,
    updated_at,
    message,
    handleEdit,
    status,
  }) => {
    const [value, setValue] = useState('');

    return (
      <div className="template-item">
        <ButtonBasic
          red={!status}
          green={status}
          text={<img src={!status ? minus_icon : plus_icon} />}
          size={'auto'}
          width={'40px'}
          minWidth={'40px'}
          onClick={(e) => {
            setIsUpdate(true);
            if (status) {
              addToGroupTemplate(selectedGroupId, [id]);
            } else {
              deleteTemplate([id], selectedGroupId);
            }
            setTimeout(() => fetchGroupList(), 500);
          }}
        />
        <MessageItem
          created_at={created_at}
          updated_at={updated_at}
          message={message}
          setValue={(e) => setValue()}
          handleEdit={(e) => handleEdit(e)}
          value={value}
        />
      </div>
    );
  };

  return (
    <div
      className={'blackout'}
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setIsModal(false);
        }
      }}
    >
      <div className="modal-group-answer">
        <header className="modal-group-answer__header">
          <h1 className="modal-group-answer__title">Группа ответов</h1>
          <InputDinamycPlaceholder
            changeValue={changeNameGroup}
            onChange={(e) => setNameGroup(e)}
            placeholder="Название группы"
            size="s"
            style={{
              maxWidth: '300px',
            }}
          />
          <ButtonBasic
            grey
            text="Сохранить"
            onClick={(e) => {
              editGroup(selectedGroupId, nameGroup);
              setTimeout(() => setIsModal(false));
            }}
          />
        </header>

        <div className="modal-group-answer__search-group">
          <InputDynamicWidth
            position="left"
            maxWidth="100%"
            isOpen={true}
            onChange={(e) => setSearchAvailable(e)}
            placeholder="В группе"
          />

          <InputDynamicWidth
            position="left"
            maxWidth="100%"
            isOpen={true}
            onChange={(value) => {
              setSearchInGroup(value);
            }}
            placeholder="Доступные"
          />
        </div>

        <div className="modal-group-answer__content">
          <div className="modal-group-answer__content-in-group">
            {templatesAvailable.map(
              ({ id, answer, created_at, updated_at }) => {
                return (
                  <TemplateItem
                    id={id}
                    message={answer}
                    created_at={created_at}
                    status={false}
                    updated_at={updated_at}
                  />
                );
              }
            )}
          </div>
          <div className="modal-group-answer__content-available">
            {templatesInGroup.map(({ id, answer, created_at, updated_at }) => {
              return (
                <TemplateItem
                  id={id}
                  message={answer}
                  created_at={created_at}
                  status={true}
                  updated_at={updated_at}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ModalGroupAnswer };
