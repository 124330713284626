import dayjs from 'dayjs'
import parse from 'html-react-parser'
import customHeaders, { getSpace } from '../../common/headers'
import card_img from './img/card_img.svg'
import add_icon from './img/add_icon.svg'
import ModalWarning from '../Modal/ModalWarning/ModalWarning'
import ModalPostChat from '../Modal/ModalPostChat/ModalPostChat'
import { SendMessage } from '../SendMessage/SendMessage';
import { RavshanHttps } from '../../fetchUrls'
import { CarouselSlick } from '../CarouselSlick/CarouselSlick'
import { FilterContainer } from '../FilterContainer/FilterContainer'
import { ModalPlanningPost } from '../Modal/ModalPlanningPost/ModalPlanningPost'
import { useState, useEffect } from 'react'
import { ReactComponent as Card } from './img/tile_view.svg'
import { ReactComponent as Chat } from './img/chat_view.svg'
import { ReactComponent as Table } from './img/list_view.svg'
import { ReactComponent as ArrowDown } from './img/arrow_down.svg'
import { TooltipBlack } from '../Tooltip/TooltipBlack/TooltipBlack'
import { DropDownSelector, ButtonBasic, TableBox, InputDynamicWidth, RangeBox } from "@lk-gtcom/ecomlab-components";
import { ExecutorPlaceholder } from '../OptionsExecutor/OptionsExecutor'
import useGeneralStore from '../../store/general'
import "./Post.scss"

const btn_sort2 = [
    {
        label: <span>A<br />Z</span>,
        value: 'title'
    },
    {
        label: 'По дате создания',
        value: 'created_at'
    },
    {
        label: 'По дате постинга',
        value: 'posted_at'
    },
    {
        label: 'По статусу',
        value: 'status'
    }
]

const comment_list = []

const CardGroup = ({ ...props }) => {
    const { name, account, created_at, posted_at, description, id, image, platforms, status, isDrop, setIsDrop, onClick, setIsModalDelete, setId } = props

    const [isShowAllPlatform, setIsShowAllPlatform] = useState(false)

     const formateDate = (date) => {
        const timeZone = new Date().getTimezoneOffset() / 60 * -3600000
        const formate_date = new Date(new Date(date)?.getTime() + timeZone)?.toUTCString()
        return new Date(formate_date)
    }


    return (
        <div className='card-item'
            onClick={onClick}
        >
            <div className='card-item__top-content'>
                <div className='card-item__header'>
                    <div className='statistic-tasks'>
                        <div className='statistic-tasks__item'>
                            Дата создания:
                            <span>
                                {created_at ? new Date(created_at)?.toLocaleDateString('ru-RU') : 'неизвестная дата'}
                            </span>
                        </div>
                        <div className='statistic-tasks__item'>
                            Дата публикации:
                            <span>
                                {posted_at ? (formateDate(new Date(posted_at)).toLocaleDateString('ru-RU')) : 'неизвестная дата'}
                            </span>
                        </div>
                    </div>

                    {/* <img src={info_icon} onClick={(e) => {
                        e.stopPropagation()
                    }} /> */}
                </div>

                <div className='img-box'>
                    <img className='img' alt='project img preview' src={image ? image : card_img} onError={e => e.target.src = card_img} />
                </div>
            </div>


            <div className='card-item__medium-content'>
                <h3 className='title'>{name}</h3>
                {/* <p className='date_project'>
                    {`${startTime ? new Date(startTime)?.toLocaleDateString('ru-RU') : 'Неизвестное время'} - ${endTime ? new Date(endTime)?.toLocaleDateString('ru-RU') : 'Неизвестное время'}`}
                </p> */}
            </div>

            <div className='card-item__bottom-content'>
                <div className='employees-container'>
                <TooltipBlack
                // position={'absolute'}
                text={account?.name}
                child={
                    <img className='network-groups__icon-group' src={account?.icon} alt='platform icon' />
                }
            />               </div>
            </div>
        </div>

        // <div className='card-group-item' key={id} 
        //     onClick={e => { if(onClick) onClick() } }
        //     onContextMenu={e => {
        //         e.preventDefault()
        //         e.stopPropagation()
        //         if (id == isDrop) {
        //             setIsDrop(null)
        //         } else setIsDrop(id)
        //     }}
        // >
        //     <div className='card-group-item__header'>
        //         <h3 className='title'>{name}</h3>
        //         <div className='drop-and-btn' 
        //             onClick={(e) => {
        //                 e.stopPropagation()
        //                 if (id == isDrop) {
        //                     setIsDrop(null)
        //                 } else setIsDrop(id)
        //             }}
        //         >
        //             <button className='btn-points'>
        //                 <img src={points_icon} />
        //             </button>
        //             {(isDrop == id) && <div className='drop-content' style={{ gap: '.5em' }}>
        //                 <span className='drop-content__item'
        //                     onClick={e => {
        //                         setIsModalDelete(true)
        //                         e.stopPropagation()
        //                         setId(id)
        //                     }}
        //                 >Удалить</span>
        //             </div>}
        //         </div>
        //     </div>
        //     <p className='text_date-create'>{`Дата создания: ${created_at ? new Date(created_at)?.toLocaleDateString('ru-RU') : 'неизвестная дата'}`}</p>
        //     <div className='icon-group'>
        //         {/* {icons?.map((el, ind) =>
        //             <img key={el + ind} className='icon' src={el} />
        //         )} */}
        //     </div>
        //     {status ? <div className='status_active'>Активна</div>
        //         :
        //         <div className='status_disactive'>Неактивна</div>
        //     }

        // </div>
    )
}

const Post = ({ ...props }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const [showModal, setShowModal] = useState(false)
    const [showModalPost, setShowModalPost] = useState(false)
    const [postType, setPostType] = useState('')
    const [posts, setPosts] = useState([])
    const [groups, setGroups] = useState([])
    const [loading, setLoading] = useState(false)
    const [tableHeaders, setTableHeaders] = useState([])
    const [accounts, setAccounts] = useState([])
    const [warningHeader, setWarningHeader] = useState("")
    const [warningMessage, setWarningMessage] = useState("")
    const [warningApproveText, setWarningApproveText] = useState("")
    const [warningCancelText, setWarningCancelText] = useState("")

    const [selectedPlatforms, setSelectedPlatforms] = useState([])
    const [selectedGroup, setSelectedGroup] = useState([])
    const [selectedAccounts, setSelectedAccounts] = useState([])
    const [selectedStatus, setSelectedStatus] = useState({})
    const [postToDelete, setPostToDelete] = useState(false)

    const [statuses, setStatuses] = useState([])
    const [platforms, setPlatforms] = useState([])

    const [date, setDate] = useState([new Date() - 86400000 * 7, new Date().getTime() + 86400000 * 30])
    const [dateFrom, dateTo] = date
    const [view, setView] = useState('card')

    const [isDrop, setIsDrop] = useState(null)

    const [answerValue, setAnswerValue] = useState('')
    const [answer, setAnswer] = useState('')
    const [isEdit, setIsEdit] = useState(false)

    const [selectedPost, setSelectedPost] = useState(-1)
    const [selectedPostContent, setSelectedPostContent] = useState({})


    const [currentSort, setCurrentSort] = useState(null)
    const [sort, setSort] = useState(null)
    const [searchInput, setSearchInput] = useState('')


    const fetchGetGroups = (signal) => {
        const url = `${RavshanHttps}/api/social/filters_posts`

        fetch(url, { method: 'GET', headers, signal })
            .then(res => {
                if (!res?.ok) { throw new Error('bad response') }
                return res.json()
            })
            .then((json) => {
                const { statuses, platforms, groups } = json[0]
                setGroups(groups)
                setPlatforms(platforms)
                setStatuses(statuses)
            })
            .catch(e => console.log(e))
    }

    useEffect(() => {
        fetchGetGroups()
    }, []);


    const fetchPosts = signal => {
        setLoading(true)
        const body = JSON.stringify({
            group_id: selectedGroup?.value,
            status: selectedStatus?.value,
            account_ids: selectedAccounts?.map(el => el?.value),
            platform: selectedPlatforms?.value ? [selectedPlatforms?.value] : [],
            start_date: new Date(dateFrom),
            end_date: new Date(dateTo),
            group_name: selectedGroup?.label,
            order_param: currentSort?.value,
            order_param_direction: sort,
            search: searchInput
        })
        fetch(`${RavshanHttps}/api/social/posts`, { body, method: 'POST', headers, signal })
            .then(res => {
                if (!res?.ok) { throw new Error('bad response') }
                setPosts([])
                return res.json()
            })
            .then(json => {
                console.log(json)
                if (json) {
                    setPosts(json)
                }
            })
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }

    const fetchPost = () => {
        const url = `${RavshanHttps}/api/social/get_detail_post?post_id=${selectedPost}`

        fetch(url, { method: 'GET', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }

            })
            .then(json => {
                if (json) {
                    // setPosts(json)
                    setSelectedPostContent(json)
                }
            })
            .catch(e => console.log(e))
            .finally(() => setLoading(false))
    }

    const fetchAccounts = signal => {
        const url = `${RavshanHttps}/api/social/get_accounts`

        const body = JSON.stringify({
            page: 1,
            limit: 10,
            search: "",
            status: "active",
            mp_name: "all",
            order_param: "name",
            order_param_direction: "asc"
        })

        fetch(url, { body: body, method: 'POST', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad response')
                }
            })
            .then(json => {
                setAccounts(json.map((el) => ({ label: el[0].name, icon: el[0].icon_link, value: el[0].id })))
            })
            .catch(err => {
                console.error(err)
            })
    }

    const onAddPostHandler = (type) => {
        setPostType(type)
        setShowModal(true)
    }

    useEffect(() => {
        fetchAccounts()

        const abortController = new AbortController()
        // fetchFilters(abortController.signal)
        return () => {
            abortController.abort()
        }
    }, [selectedSpace])

    useEffect(() => {
        fetchPosts()
    }, [selectedSpace,
        selectedGroup,
        selectedPlatforms,
        selectedAccounts,
        selectedStatus,
        searchInput,
        currentSort,
        sort,
        date])

    useEffect(() => {
        fetchPost()
    }, [selectedSpace, selectedPost])

   


    return (
        <>
            {showModal && <ModalPlanningPost
                groups={groups}
                selectedGroupFilter={selectedGroup}
                setShowModal={(e) => setShowModal(e)}
                postType={postType}
                onClose={() => {
                    fetchPosts(null)
                    setShowModal(false)
                }}
                status={selectedStatus}
            />}

            {
                showModalPost &&
                <ModalPostChat
                    selectedPostContent={selectedPostContent}
                    comment_list={comment_list}
                    setModal={e => setShowModalPost(e)}
                />
            }

            {Object.keys(postToDelete).length > 0 && <ModalWarning
                headerText={warningHeader}
                messageText={warningMessage}
                approveButtonText={warningApproveText}
                cancelButtonText={warningCancelText}
                onApproveClick={(e) => {
                    setWarningHeader("")
                    setWarningMessage("")
                    setWarningApproveText("")
                    setWarningCancelText("")
                    setPostToDelete({})
                }}
                onCancelClick={() => {
                    setWarningHeader("")
                    setWarningMessage("")
                    setWarningApproveText("")
                    setWarningCancelText("")
                    setPostToDelete({})
                }}
            />}

            <div className='post'>
                <div className='post__header'>
                    <h1 className='title_main'>Посты</h1>
                </div>

                <div className='post__toolbar-top'>
                    <ButtonBasic
                        width='140px'
                        onClick={(e) => onAddPostHandler('post')}
                        text={<div className='btn-content'>Новый пост <img src={add_icon} /></div>}
                    />
                    <div className='tab-sort'>
                        {btn_sort2.map((el) =>
                            <button className={currentSort?.label == el.label ? 'btn_active' : 'btn'}
                                key={el.label}
                                onClick={(e) => {
                                    if (currentSort?.label == el.label) {
                                        setSort(sort == 'asc' ? 'desc' : 'asc')
                                    } else setSort('asc')
                                    setCurrentSort(el)
                                }}
                            >{el.label}
                                <ArrowDown
                                    className={(currentSort?.label == el?.label) ? (sort == 'asc' ? 'arrow' : 'arrow_up') : 'arrow'}
                                    style={(currentSort?.label == el?.label) ? { stroke: 'white' } : { stroke: '#64748B' }}
                                />
                            </button>
                        )}
                    </div>

                    <InputDynamicWidth
                        position='left'
                        maxWidth='100%'
                        isOpen={true}
                        onChange={value => {
                            setSearchInput(value)
                        }}
                        placeholder='Поиск'
                    />
                    <RangeBox
                        hideBtnGroup
                        setDate={e => setDate(e)}
                        hedeGroupBtn />
                </div>

                <div className='toolbar'>
                    <FilterContainer
                        onReset={(e) => {
                            setSelectedPlatforms([])
                            setSelectedGroup([])
                            setSelectedStatus([])
                        }}
                    >
                        <DropDownSelector
                            options_prop={platforms}
                            state={selectedPlatforms}
                            setState={setSelectedPlatforms}
                            placeholder='Площадка'
                        />

                        <DropDownSelector
                            options_prop={groups}
                            state={selectedGroup}
                            setState={setSelectedGroup}
                            placeholder='Группа'
                        />

                        <DropDownSelector
                            options_prop={accounts}
                            state={selectedAccounts}
                            setState={(e) => setSelectedAccounts(e)}
                            placeholder='Аккаунты'
                            multi
                        />

                        <DropDownSelector
                            options_prop={statuses}
                            state={selectedStatus}
                            setState={setSelectedStatus}
                            placeholder='Статус'
                        />
                    </FilterContainer>

                    <div className='tab_view'>
                        <div className={`tab_view__item table-view ${view == 'table' ? 'table-view_active' : ''}`} onClick={(e) => setView('table')}>
                            <Table />
                        </div>
                        <div className={`tab_view__item card-view ${view == 'card' ? 'card-view_active' : ''}`} onClick={(e) => setView('card')}>
                            <Card />
                        </div>
                        <div className={`tab_view__item chat-view ${view == 'chat' ? 'chat-view_active' : ''}`} onClick={(e) => setView('chat')}>
                            <Chat />
                        </div>
                    </div>
                </div>

                {view == 'card' && <div className='card-group'>

                    {posts?.map((props, ind) => {
                        const { author, id, created_at, group, post_img, posted_at, title, icon, name_social, account } = props
                        return <CardGroup
                            key={id}
                            {...props}
                            name={title}
                            image={post_img}
                            account={account}
                            onClick={e => {
                                setSelectedPost(id)
                                setShowModalPost(true)
                            }}
                        />
                        // <div 
                        //     className='card-item' 
                        //     onClick={e => {
                        //         setSelectedPost(id)
                        //         setShowModalPost(true)
                        //     }}
                        //     style={{cursor: 'pointer'}}
                        // >
                        //     <div className='img-box'>
                        //         <img src={post_img ? post_img : card_img} />
                        //     </div>
                        //     <div className='card-item__content'>
                        //         <div className='card-item__header'>
                        //             <h3 className='title'>{title ? title : 'Нет названия'}</h3>
                        //             <div className='drop-and-btn' onClick={(e) => {
                        //                 e.stopPropagation()
                        //                 if (ind == isDrop) {
                        //                     setIsDrop(null)
                        //                 } else setIsDrop(ind)
                        //             }}>
                        //                 <button className='btn-points'>
                        //                     <img src={points_icon} />
                        //                 </button>
                        //                 {(isDrop == ind) && <div className='drop-content' style={{ gap: '.5em' }}>
                        //                     <span className='drop-content__item'>Активировать</span>
                        //                     <span className='drop-content__item'>Редактировать</span>
                        //                     <span className='drop-content__item'>Дублировать</span>
                        //                     <span className='drop-content__item'
                        //                         onClick={e => {
                        //                             e.stopPropagation()
                        //                         }}
                        //                     >Удалить</span>
                        //                 </div>}
                        //             </div>
                        //         </div>

                        //         <p className='text'>Группа: <span>{group}</span></p>
                        //         <p className='text'>Дата создания: <span>{created_at ? ( dayjs(created_at)).format('DD.MM.YYYY HH:mm') : '-'}</span></p>
                        //         <p className='text'>Дата публикации: <span>{posted_at ? ( dayjs(posted_at)).format('DD.MM.YYYY HH:mm') : '-'}</span></p>
                        //         {/* <div className='social-name'>
                        //             <div className='icon-box'>
                        //                 <img src={icon} />
                        //             </div>
                        //             <p className='text'>{name_social}</p>
                        //         </div> */}
                        //         {
                        //             status ?
                        //                 <div className='status_active'>Опубликован</div>
                        //                 :
                        //                 <div className='status_active'>Не опубликован</div>

                        //         }
                        //     </div>
                        // </div>

                    }
                    )}
                </div>}

                {view == 'chat' && <div className='chat-content'>
                    <div className='list-post'>
                        <div className='list-post__header'>
                            <h2 className='title'>Список постов</h2>
                        </div>
                        <div className='list-post__content'>
                            {posts?.map(({ author, id, created_at, group, post_img, posted_at, title, icon, name_social, status }, ind) =>
                                <div
                                    className='card-item'
                                    onClick={e => setSelectedPost(id)}
                                    style={selectedPost === id ? { border: '2px solid #3900B1' } : {}}
                                >
                                    <div className='card-item__content'>
                                        <div className='card-item__header'>
                                            <h3 className='title'>{title ? title : 'Нет названия'}</h3>
                                        </div>
                                        <p className='text'>Группа: <span>{group}</span></p>
                                        <p className='text'>Дата создания: <span>{created_at ? (dayjs(created_at)).format('DD.MM.YYYY HH:mm') : '-'}</span></p>
                                        <p className='text'>Дата публикации: <span>{posted_at ? (dayjs(posted_at)).format('DD.MM.YYYY HH:mm') : '-'}</span></p>
                                        {/* <div className='social-name'>
                                            <div className='icon-box'>
                                                <img src={icon} />
                                            </div>
                                            <p className='text'>{name_social}</p>
                                        </div> */}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='comment-list'>
                        <div className='comment-list__header'>
                            <h2 className='title'>Комментарии</h2>
                        </div>
                        <div className='comment-list__content'>
                            <div className='comment-content'>
                                {comment_list?.length > 0 && comment_list.map(({ author, comment, date_create, avatar, subComment }, ind) =>
                                    <div className='comment-item' key={ind + comment}>
                                        <div className='img-box'>
                                            <img src={avatar} />
                                        </div>
                                        <div className='comment-item__content'>
                                            <h4 className='title'>{author}</h4>
                                            <p className='text'>{comment}</p>
                                            <div className='info-content'>
                                                <p className='text_date-create'>{date_create}</p>
                                                <button className='btn-response'>Ответить</button>
                                            </div>
                                            {subComment?.length > 0 &&
                                                subComment.map(({ author, comment, date_create, avatar, subComment }, ind) =>
                                                    <div className='comment-item' key={ind + comment}>
                                                        <div className='img-box'>
                                                            <img src={avatar} />
                                                        </div>
                                                        <div className='comment-item__content'>
                                                            <h4 className='title'>{author}</h4>
                                                            <p className='text'>{comment}</p>
                                                            <div className='info-content'>
                                                                <p className='text_date-create'>{date_create}</p>
                                                                <button className='btn-response'>Ответить</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )}
                                {comment_list?.length == 0 && <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >Нет данных</div>}
                            </div>
                            <SendMessage
                                frequentAnswers
                                isEdit={isEdit}
                                setIsEdit={(e) => setIsEdit(e)}
                                message={answer}
                                value={answerValue}
                                onChange={e => setAnswerValue(e)}
                                onSendAction={e => (e)} />
                        </div>
                    </div>

                    <div className='post-item'>
                        {
                            selectedPostContent?.date ?
                                <>
                                    <div className='post-item__header'>
                                        <h2 className='title'>{selectedPostContent?.title}</h2>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                            <p className='text_date-create'>{new Date(selectedPostContent?.date)?.toLocaleDateString('ru-RU')}</p>
                                            <img style={{ width: 20, height: 20 }} src={selectedPostContent?.account?.icon_link} alt='account icon' />
                                        </div>
                                    </div>
                                    <div className='post-item__content'>
                                        <p className='text'>{parse(selectedPostContent?.text)}</p>

                                        {
                                            selectedPostContent.media?.length > 0 &&
                                            <CarouselSlick imgArr={selectedPostContent.media?.map(el => el?.link)} />
                                        }
                                    </div>
                                </>
                                :
                                <>
                                    <div className='post-item__header'>
                                        <h2 className='title'>Пост не выбран</h2>
                                        {/* <p className='text_date-create'>{new Date(selectedPostContent?.date)?.toLocaleDateString('ru-RU')}</p> */}
                                    </div>
                                    <div className='post-item__content'>
                                        <p className='text'>Выберите пост, чтобы просмотреть его данные</p>

                                        <CarouselSlick imgArr={[card_img]} />
                                    </div>
                                </>
                        }
                    </div>
                </div>
                }

                {view == 'table' &&
                    <TableBox
                        loading={loading}
                        fetchedData={posts}
                        headers={tableHeaders}
                        paginator={true}
                    />
                }
            </div>
        </>
    )
}

export { Post }