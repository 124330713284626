import customHeaders, { getSpace } from '../../../common/headers';
import { useState, useEffect } from 'react';
import {
  KonstantinFeedbackHttps,
  KonstantinFeedbackHttp,
} from './../../../fetchUrls';
import {
  fetchFeedbacksBrandFilter,
  fetchFeedbacksCategoryFilter,
} from '../../../common/filtersFunc';
import {
  DropDownSelector,
  ButtonBasic,
  TextareaDynamicPlaceholder,
} from '@lk-gtcom/ecomlab-components';
import useGeneralStore from '../../../store/general';
import './ModalScenarioSettings.scss';

const ModalScenarioSettings = ({
  setIsModal,
  selectedCategoryProperties,
  setSelectedCategoryProperties,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [dateFrom, dateTo] = date;

  const [ruleId, setRuleId] = useState(selectedCategoryProperties?.ruleId);
  const [categoryId, setCategoryId] = useState(selectedCategoryProperties?.id);

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [selectedMarketplace, setSelectedMarketplace] = useState([
    { label: 'Ozon', value: 1 },
  ]);
  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');

  const [stopWordList, setStopWordList] = useState([]);
  const [stopWordListValue, setStopWordListValue] = useState([]);
  const [err, setErr] = useState(false)

  const changeRuleCat = () => {
    setErr(false)

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/category-rules/${ruleId}/categories/${categoryId}`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/category-rules/${ruleId}/categories/${categoryId}`;
    }

    const body = JSON.stringify({
      ...selectedCategoryProperties,
      stop_category_id_list: selectedCategory,
      stop_brand_id_list: selectedBrand,
      stop_word_list: stopWordList,
    });

    fetch(url, { body, method: 'PUT', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setIsModal(false);
        setSelectedCategoryProperties({});
      })
      .catch((err) => {
        setErr(true)
        console.error(err);
      });
  };

  const fetchRuleCat = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinFeedbackHttps}/feedbacks/category-rules/${ruleId}/categories/${categoryId}`;
    } else {
      url = `${KonstantinFeedbackHttp}/feedbacks/category-rules/${ruleId}/categories/${categoryId}`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        const { stop_category_id_list, stop_brand_id_list, stop_word_list } =
          json;
        setSelectedCategory(stop_category_id_list);
        setSelectedBrand(stop_brand_id_list);
        setStopWordListValue(stop_word_list);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchRuleCat();
  }, [selectedSpace]);

  useEffect(() => {
    fetchFeedbacksCategoryFilter({
      selectedSpace: selectedSpace?.value,
      page: categoryPage,
      apiData,
      dateFrom,
      dateTo,
      selectedApi,
      pageValue: 'feedbacks',
      selectedBrand,
      selectedCategory,
      setCategoryData: (e) => setCategoryData(e),
      searchParam: categoryQuery,
    });
  }, [
    selectedSpace,
    apiData,
    dateFrom,
    dateTo,
    selectedApi,
    selectedBrand,
    categoryPage,
    categoryQuery,
  ]);

  useEffect(() => {
    fetchFeedbacksBrandFilter({
      selectedSpace: selectedSpace?.value,
      page: brandPage,
      apiData,
      categoryData,
      selectedCategory,
      pageValue: 'feedbacks',
      selectedBrand,
      selectedApi,
      dateFrom,
      dateTo,
      searchParam: brandQuery,
      setBrandData: (e) => setBrandData(e),
    });
  }, [
    selectedSpace,
    categoryData,
    selectedApi,
    selectedCategory,
    selectedBrand,
    brandPage,
    brandQuery,
  ]);

  return (
    <div
      className="blackout"
      style={{ zIndex: 2000000 }}
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setIsModal(false);
        }
      }}
    >
      <div className="modal-scenario-settings">
        <div className="modal-scenario-settings__header">
          <h1 className="title_main">Настройки работы сценариев</h1>
        </div>
        <div className="modal-scenario-settings__content">
          <p className="modal-scenario-settings__text">
            Если стоп слова/категории/бренды встречаются в отзыве , то ответ
            автоматически попадает в ручной режим проверки
          </p>
          {err && <p className="modal-scenario-settings__text_err">Что-то пошло не так</p>}
          <div className="content-group">
            <div className="left-content">
              <div className="left-content__item">
                <h3 className="title">Стоп категории</h3>
                <DropDownSelector
                  state={selectedCategory}
                  options_prop={categoryData}
                  setState={(e) => setSelectedCategory(e)}
                  placeholder="Выберите категории"
                  fetchCallback={(e) => setCategoryPage(e)}
                  setInputChange={(e) => setCategoryQuery(e)}
                  multi
                />
              </div>
              <div className="left-content__item">
                <h3 className="title">Стоп бренды</h3>
                <DropDownSelector
                  state={selectedBrand}
                  options_prop={brandData}
                  setState={(e) => setSelectedBrand(e)}
                  fetchCallback={(e) => setBrandPage(e)}
                  setInputChange={(e) => setBrandQuery(e)}
                  placeholder="Выберите бренд"
                  multi
                />
              </div>
            </div>
            <div className="right-content">
              <div className="right-content__item">
                <h3 className="title">Стоп слова</h3>
                <TextareaDynamicPlaceholder
                  width="100%"
                  changeValue={stopWordListValue?.join(',')}
                  placeholder="Введите слова списком через запятую"
                  onChange={(e) => setStopWordList(e?.split(','))}
                />
              </div>
              <ButtonBasic
                green
                size="56px"
                width="237px"
                text="Сохранить"
                onClick={(e) => {
                  changeRuleCat()
                } }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ModalScenarioSettings };
