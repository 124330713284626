import dayjs from "dayjs";
import { EventElement } from "../EventItem/EventElement";
import { useEffect } from "react";

const CalendarDay = ({
  date,
  time,
  currentDay,
  dateNow,
  setIsModal,
  fetchEventData,
  setAddEvent,
  setReadOnly,
  setNewEvent,
  setEventInfo,
  eventsListObj,
  eventsList }) => {
  const dayNow = new Date().getHours()

  const days = [
    {
      date: '2023-11-24',
      events: [
        {
          time: 0,
          eventsList: [
            {
              type: 'event',
              date_from: '2023-11-24 00:30',
              date_to: '2023-11-24 01:00',
              title: 'Title',
              author: { name: 'name', login: 'login@gmail.com', color: 'rgb()', id: 15 },
              executors: [
                { name: 'name', login: 'login@gmail.com', color: 'rgb()', id: 15 },
                { name: 'name2', login: 'login2@gmail.com', color: 'rgb()', id: 16 },
              ],
              description: 'Desc',
            }
          ]
        }
      ]
    }
  ]


  // Скролл для элемента
  const smoothLinks = () => {
    const elements = [...document.querySelectorAll('td')]
    for (let smoothLink of elements) {
      const id = smoothLink.getAttribute('id')
      if (id) {
        if (id == dayNow) {
          let element = document.querySelector(`[id="${id}"]`)
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }
    };
  }

  useEffect(() => {
    smoothLinks()
  }, []);


  return (
    <div className='calendar-grid'>
      <table className='table-week'>
        <tbody>
          {time?.map((item) =>
            <tr key={item} className='row-week'>
              <td className='time-box'>
                {item + ': 00'}
              </td>

              <td className='row-week__item row-week__item_day' data-time={item - 1}
                id={item}
                onClick={(e) => {
                  const date = new Date(days[0].date)
                  const selectDate = new Date(new Date(days[0].date).setHours(item - 1, 0, 0))
                  if (!e.target.closest('.event-item')) {
                    setIsModal(true)
                    setNewEvent(true)
                    setAddEvent(true)
                    setEventInfo({
                      dateFrom: selectDate,
                      dateTo: new Date(selectDate.getTime() + 3600000)
                    })
                    setReadOnly(false)
                  }
                }}
              >
                {
                  days?.map(({ events }, ind) => {
                    return events?.map(({ eventsList }) => {
                      return eventsList?.map((props) => {
                        const { date_from, date_to, id } = props
                        const height = (new Date(date_to) - new Date(date_from)) / 60000 / 0.6


                        if (item == new Date(date_from).getHours()) {
                          return <EventElement
                            {...props}
                            key={JSON.stringify(props)}
                            fetchEventData={(e) => (e)
                              // fetchEventData(id)
                            }
                            dateFrom={new Date(date_from)}
                            dateTo={new Date(date_to)}
                            setReadOnly={(e) => setReadOnly(e)}
                            style={{
                              top: `${new Date(date_from).getMinutes() / 0.6}%`,
                              height: `${height < 30 ? 30 : height}%`,
                              zIndex: 1,
                            }}
                          />
                        }

                      })
                    })
                  })
                }

                {eventsListObj?.map(({ dateFrom, dateTo, text, id }) => {
                  return (dateFrom.day === currentDay.day) && (dateFrom.hours == (item - 1)) && (dateFrom.month == currentDay.month) ? <EventElement
                    key={id}
                    fetchEventData={(e) => fetchEventData(id)}
                    id={id}
                    text={text}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    style={{
                      top: `${dateFrom.min / 0.6}%`,
                      height: `${(((new Date(dateTo.mil - dateFrom.mil)) / 60000 / 0.6))}%`,
                      zIndex: 1,
                    }}
                    setReadOnly={e => setReadOnly(e)}
                  /> : null
                })}

                {dateNow.map(({ day_now, week_now, month_now, hours_now, minutes_now }) =>
                  (day_now === dayNow) && (hours_now == (item - 1)) && (month_now == currentDay.month) ?
                    <div className='time-now-line'
                      style={{
                        width: `100%`,
                        top: `${minutes_now / 0.6}%`,
                      }}
                    ></div>
                    : null
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default CalendarDay;
