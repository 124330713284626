import { Page404 } from '../Page404/Page404';
import { NotActive } from '../../components/NotActive/NotActive';
import { SidebarMenu } from '../../components/SidebarMenu/SidebarMenu';
import { PromotionRules } from '../../components/PromotionRules/PromotionRules';
import { JoinedPromotions } from '../../components/JoinedPromotions/JoinedPromotions';
import { ListPromotionVer2 } from '../../components/ListPromotionVer2/ListPromotionVer2';
import { ProductsInCampaign } from '../../components/ProductsInCampaign/ProductsInCampaign';
import { useState, useEffect } from 'react';
import { AnalyticalCollectionsOzon } from '../../components/AnalyticalCollectionsOzon/AnalyticalCollectionsOzon';
import { ListOfAdvertisingCampaigns } from '../../components/ListOfAdvertisingCampaigns/ListOfAdvertisingCampaigns';
import { CreatingAnAdvertisingCampaign } from '../../components/CreatingAnAdvertisingCampaign/CreatingAnAdvertisingCampaign';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import './MarketingOzon.scss'


const MarketingOzon = () => {
    const pathMain = useParams()
    const navigate = useNavigate()
    const [sidebarList, setSidebarList] = useState([])
    const [isActive, setIsActive] = useState('Аналитические подборкиРекламные кампании')
    const [isActiveItems, setIsActiveItems] = useState('')
    const modalsPortalId = 'modals-portal'

    const [showTooltipParametr, setShowTooltipParametr] = useState(false)
    const [showTooltipLauncAdvertisingCampaign, setShowTooltipLauncAdvertisingCampaign] = useState(false)
    const [showTooltipLauncAdvertisingCampaignInfo, setShowTooltipLauncAdvertisingCampaignInfo] = useState(false)


    // const [sidebarItems, setSidebarItems] = useState(
    //     [
    //         {
    //             label: 'Рекламные кампании',
    //             subItems: [
    //                 {
    //                     label: 'Аналитические подборки',
    //                     path: 'analytical-collections/all_product'
    //                 },
    //                 {
    //                     label: 'Список рекламных кампаний',
    //                     path: 'list-of-advertising-campaigns/all'
    //                 },
    //                 {
    //                     label: 'Товары в кампании',
    //                     path: 'products-in-campaign/participate_items'
    //                 },
    //             ],
    //         },
    //         {
    //             label: 'Акции',
    //             path: '',
    //             subItems: [
    //                 {
    //                     label: 'Товары в акции',
    //                     path: 'joined-promotions/all'
    //                 },
    //                 {
    //                     label: 'Список акций',
    //                     path: 'list-promotion/all'
    //                 },
    //                 {
    //                     label: 'Правила',
    //                     path: 'promotion-rules/all',
    //                 }
    //             ],
    //         },
           
    //     ]
    // )

    useEffect(() => {

        // После перезагрузки страницы, посвечиваем корректные пункты меню

        // sidebarItems?.forEach(({ label, subItems, path }) => {
        //     const title = label
        //     const comparePaths = path && (pathMain['*'].split('/')[0] == path.split('/')[0])
        //     if (comparePaths) {
        //         setIsActive(null)
        //         setIsActiveItems(label)
        //     } else {
        //         subItems.forEach(({ label, path }) => {
        //             if (comparePaths) {
        //                 setIsActive(title + label)
        //                 setIsActiveItems(null)
        //             }
        //         })
        //     }
        // })

        if (pathMain['*'].split('/')[0] == 'not-active') {
            navigate('analytical-collections/all_product')
            setIsActive('Рекламные кампанииАналитические подборки')
            setIsActiveItems('')
        }

        if (pathMain['*'].split('/')[0] == 'сreating-an-advertising-campaign') {
            navigate('сreating-an-advertising-campaign')
            setIsActive('')
            setIsActiveItems('')
        }
    }, [])

    // useEffect(() => {
    //     setSidebarList([
    //         ...sidebarItems.map((el, idx, { label }) => <SidebarMenu isActiveItems={isActiveItems} setIsActiveItems={e => setIsActiveItems(e)} key={label + '' + idx} {...el} setActive={e => setIsActive(e)} active={isActive} />)
    //     ])
    // }, [isActive])


    return (
        <div id={modalsPortalId} className='settings__content'>
            <div className='flex'>
                {/* <div className='title-and-nav-box'>
                    <div className='sidebar-inside'>
                        <nav className='sidebar-inside__nav'>
                            {sidebarItems.map((el, idx, { label }) => <SidebarMenu {...el} isActiveItems={isActiveItems} setIsActiveItems={e => setIsActiveItems(e)} setActive={e => setIsActive(e)} active={isActive} key={label + '' + idx} />)}
                        </nav>
                    </div>
                </div> */}

                    <Routes>
                        <Route path='analytical-collections/*' element={<AnalyticalCollectionsOzon
                            setShowTooltipParametr={(e) => setShowTooltipParametr(e)}
                            setShowTooltipLauncAdvertisingCampaignInfo={(e) => setShowTooltipLauncAdvertisingCampaignInfo(e)}
                        />} />

                        {/* Список рекламных кампаний */}
                        <Route path='list-of-advertising-campaigns/*' element={<ListOfAdvertisingCampaigns/>} />

                        {/* Товары в кампании */}
                        <Route path='products-in-campaign/*' element={<ProductsInCampaign/>} />

                        {/* Вступление в акцию */}
                        <Route path='joined-promotions/*' element={<JoinedPromotions/>} />

                        {/* Создание рекламной кампании */}
                        <Route path='сreating-an-advertising-campaign' element={<CreatingAnAdvertisingCampaign
                            setShowTooltipLauncAdvertisingCampaign={(e) => setShowTooltipLauncAdvertisingCampaign(e)}
                        />} />

                        {/* Список акций */}
                        <Route path='list-promotion/*' element={<ListPromotionVer2
                            setShowTooltipLauncAdvertisingCampaign={(e) => setShowTooltipLauncAdvertisingCampaign(e)}
                        />} />

                        {/* Правила */}
                        <Route path='promotion-rules/*' element={<PromotionRules />} />

                        {/* Список акций */}
                        {/* <Route path='list-promotion/*' element={<ListPromotion />} /> */}

                        
                        <Route path='not-active' element={<NotActive />} />
                        <Route path='*' element={< Page404 />} />
                    </Routes>
            </div>
        </div>
    )
}

export { MarketingOzon }