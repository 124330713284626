import { EventElement } from "../EventItem/EventElement";
import { TableBox } from "@lk-gtcom/ecomlab-components";
import { useRef } from "react";
import { useState, useEffect } from "react";

const CalendarMonth = ({
    refHeaderTop,
    time,
    fetchEventData,
    dateNow,
    weekHeader,
    setIsModal,
    setAddEvent,
    setReadOnly,
    setNewEvent,
    setEventInfo,
    eventsListObj,
    selectView }) => {

    const days_of_week = [
        'Пн',
        'Вт',
        'Ср',
        'Чт',
        'Пт',
        'Сб',
        'Вс'
    ];

    const months = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'];

        const dayNow = new Date().getDate()
    let current_month = new Date().getMonth() - 1
    let current_year = new Date().getFullYear()
    const d = new Date()
    const [positionElement, setPositionElement] = useState(0)


    const refContainer = useRef()
    const refHeader = useRef()

    const handleScroll = () => {
        const container = refContainer.current
        const header = refHeader.current
        const height_header = refHeaderTop?.current?.getBoundingClientRect()?.height
        const top = container.getBoundingClientRect().top
        const bottom = container.getBoundingClientRect().top

        let height;
        if (header) {
            height = header.getBoundingClientRect().height
        }

        if (top - height_header < 0) {
            setPositionElement(height_header - top)
        } else {
            setPositionElement(0)
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    // Скролл для элемента
    const smoothLinks = () => {
        const elements = [...document.querySelectorAll('.row-week__header')]
        for (let smoothLink of elements) {
            const id = smoothLink.getAttribute('id')
            if (id) {
                if (id == dayNow) {
                    let element = [...document.querySelectorAll(`[id="${id}"]`)]
                    console.log(element[0])
                    element[0].scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                }
            }
        };
    }

    useEffect(() => {
        smoothLinks()
    }, []);


    return (
        <div className='calendar-grid'>
            {selectView == 'Table' ?
                <TableBox
                    loading={null}
                    fetchedData={[]}
                    headers={[]}
                    paginator={true}
                /> :
                <table className='table-week' ref={refContainer}>
                    <thead ref={refHeader} style={{
                        transform: `translateY(${positionElement}px)`,
                        boxShadow: positionElement > 0 ? `0px 2px 8px 0px rgba(236, 234, 249, 0.60)` : ''
                    }}>
                        <tr>
                            <th className="calendar-grid__header_item calendar-grid__header_item_month"></th>
                            {weekHeader.map(({ day, week }, ind) => {
                                let dayWeek;
                                switch (week) {
                                    case 0: dayWeek = 'вс'; break
                                    case 1: dayWeek = 'пн'; break
                                    case 2: dayWeek = 'вт'; break
                                    case 3: dayWeek = 'ср'; break
                                    case 4: dayWeek = 'чт'; break
                                    case 5: dayWeek = 'пт'; break
                                    case 6: dayWeek = 'сб'; break
                                    default: break
                                }
                                return (
                                    <th key={day} className='calendar-grid__header_item calendar-grid__header_item_month'>
                                        <div className='date-box'>
                                            <p className='month_text'>{dayWeek}</p>
                                        </div>
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>

                    <tbody>
                        {time.map((item, ind) =>
                            <tr key={item} className='row-week'>
                                <td className='time-box' >
                                    <div className="count-week">{`${ind + 1}-ая неделя`}</div>
                                </td>

                                {weekHeader.map(({ day, week, month, date }) => {
                                    return <td key={date + day} 
                                        className='row-week__item row-week__item_month'
                                        data-date={date}
                                        data-day={day}
                                        data-time={item - 1}
                                        onClick={(e) => {
                                            const selectDate = new Date(new Date(date).setHours(item - 1, 0, 0))
                                            if (!e.target.closest('.event-item')) {
                                                setIsModal(true)
                                                setNewEvent(true)
                                                setAddEvent(true)
                                                setEventInfo({
                                                    dateFrom: selectDate,
                                                    dateTo: new Date(selectDate.getTime() + 3600000)
                                                })
                                                setReadOnly(false)
                                            }
                                        }}
                                        style={{
                                            outline: (dateNow[0].day_now == day && week == dateNow[0].week_now) ? '1px solid #22C55E' : '',
                                            border: (dateNow[0].day_now == day && week == dateNow[0].week_now) ? '1px solid #22C55E' : '',
                                        }}
                                    >
                                        <div className="row-week__header" id={day}
                                            style={{
                                                background: (dateNow[0].day_now == day && week == dateNow[0].week_now) ? '#22C55E' : '',
                                                color: (dateNow[0].day_now == day && week == dateNow[0].week_now) ? '#fff' : '',
                                               // border: (dateNow[0].day_now == day && week == dateNow[0].week_now) ? '1px solid #22C55E' : '',
                                            }}>
                                            {day}
                                        </div>


                                        {eventsListObj.map(({ dateFrom, dateTo, text, id }) =>
                                            (dateFrom.day === day) && (dateFrom.hours == (item - 1)) && (dateFrom.month == (month))
                                                ?
                                                <EventElement
                                                    key={id}
                                                    fetchEventData={(e) => fetchEventData(id)}
                                                    id={id}
                                                    text={text}
                                                    dateFrom={dateFrom}
                                                    dateTo={dateTo}
                                                    setReadOnly={e => setReadOnly(e)}
                                                    style={{
                                                        height: `30px`, //todo
                                                        zIndex: 1,
                                                    }}
                                                />
                                                :
                                                null
                                        )}
                                    </td>
                                }

                                )}
                            </tr>
                        )}
                    </tbody>
                </table>
            }
        </div>
    )
}

export default CalendarMonth;