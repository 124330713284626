import customHeaders, { getSpace } from '../../../common/headers'
import close from './img/close.svg'
import copy_icon from './img/copy_icon.svg'
import check_green from './img/bx-check.svg'
import low_status from './img/low_status.svg'
import hight_status from './img/hight_status.svg'
import medium_status from './img/medium_status.svg'
import plus_circle from './img/bx-plus-circle.svg'
import close_icon from './img/close_icon.svg'

import { Editor } from '../../Editor/Editor'
import { DateBox } from '../../DateBox/DateBox'
import { Comment } from '../../Comment/Comment'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { CommentItem } from '../../CommentItem/CommentItem'
import { TASK_EVENT_TYPE } from '../../../pages/TaskList/TaskList'
import { useState, useEffect } from 'react'
import { RavshanHttps, RavshanHttp } from '../../../fetchUrls'
import {
    ButtonBasic,
    DropDownSelector,
    Checkbox,
    SelectExecutor,
    TitleContentEditable,
    InputDinamycPlaceholder
} from "@lk-gtcom/ecomlab-components";
import { OptionsExecutor, ExecutorPlaceholder } from '../../OptionsExecutor/OptionsExecutor'
import { TooltipBlack } from '../../Tooltip/TooltipBlack/TooltipBlack'
import { DownloadComponent } from '../../DownloadComponent/DownloadComponent'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import useGeneralStore from '../../../store/general'
import no_data_file from './img/no_data_file.svg'
import './ModalAddTask2.scss'
import { TabsGreen } from '../../TabsGreen/TabsGreen'
import { FileItemComponent } from '../../FileItemComponent/FileItemComponent'
import { ButtonActionDrop } from '../../Buttons/ButtonActionDrop/ButtonActionDrop'
const parse = require('html-react-parser')

// const btns = [
//     { label: 'Исполнитель', value: 'еxecutor', icon: user_icon },
//     { label: 'История', value: 'history', icon: info_circle_icon },
// ]

const btns_2 = [
    { label: 'Комментарии', value: 'comment' },
    { label: 'Файлы', value: 'file' },
    { label: 'Чек-Лист', value: 'task-list' },
    { label: 'История', value: 'history' }
]

const OPTIONS_PRIORITY = [
    {
        label: <p className='option-status' style={{ color: '#E62E2E' }}>Высокий <img src={hight_status} /></p>,
        value: 'Высокий'
    },
    {
        label: <p className='option-status' style={{ color: '#EE9023' }}>Средний <img src={medium_status} /></p>,
        value: 'Средний'
    },
    {
        label: <p className='option-status' style={{ color: '#26AF33' }}>Низкий <img src={low_status} /></p>,
        value: 'Низкий'
    },
    {
        label: 'Не указано',
        value: 'Не назначено'
    }
]

const subtask_list = [
    {
        value: 'Подзадача 1',
        date: '16 окт. 23:33',
        id: 1,
        status: true
    },
    {
        value: 'Подзадача 2',
        date: '16 окт. 23:33',
        id: 2,
        status: false
    },
    {
        value: 'Подзадача 3',
        date: '16 окт. 23:33',
        id: 3,
        status: false
    }
]

export const PRIORITY_TO_COLOR_MAP = {
    'Низкий': '#26AF33',
    'Средний': '#EE9023',
    'Высокий': '#E62E2E',
    'Не назначено': 'blue'
}

const btn_tab = [
    {
        label: 'Описание',
        value: `task-info/description`
    },
    {
        label: 'Комментарии',
        value: `task-info/comments`
    }
]

const colors = {
    'на стопе': '#F1893F',
    'в работе': '#DC17D4',
    'на проверке': '#8F43F0',
    'готово': '#08C104',
    'новые задачи': '#6574FF'
}

const ModalAddTask2 = ({
    setIsModal,
    infoTask = false,
    attendees,
    setInfoTask,
    setTasks,
    selectedColumn,
    allColumns,
    fetchDeleteTask,
    taskId,
    query,
    isLoadInfoTask,
    setIsModalExit,
    searchParams
}) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const email = localStorage.getItem('email')
    const currentUserColor = localStorage.getItem('currentUserColor')
    const currentUserName = localStorage.getItem('currentUserName')

    const [endDate, setEndDate] = useState(new Date())
    const [startDate, setStartDate] = useState(new Date())

    const [endDate2, setEndDate2] = useState(new Date())
    const navigate = useNavigate()

    const [executorSearchValue, setExecutorSearchValue] = useState('')
    const [executors, setExecutors] = useState([])
    const [coExecutors, setCoExecutors] = useState([])
    const [executorOptions, setExecutorOptions] = useState(attendees)

    const [title, setTitle] = useState('')
    const [defaultTitle, setDefaultTitle] = useState(false)
    const [description, setDescription] = useState('')
    const [authorName, setAuthorName] = useState(currentUserName)
    const [authorColor, setAuthorColor] = useState(currentUserColor)
    const [authorEmail, setAuthorEmail] = useState(email)
    const [addTaskId, setAddTaskId] = useState()
    const [comments, setComments] = useState([])
    const [statusTask, setStatusTask] = useState([])
    const [selectedStatusTask, setSelectedStatusTask] = useState({})
    const [selectedProject, setSelectedProject] = useState(null)
    const [selectedPriority, setSelectedPriority] = useState({ label: 'Не указано', value: 'Не назначено' })
    const [isDropMenu, setIstDropMenu] = useState(false)
    const [check, setCheck] = useState(false)
    const [check2, setCheck2] = useState(false)
    const [logsArr, setLogsArr] = useState([])
    const [logsErr, setLogsErr] = useState(false)
    const [files, setFiles] = useState([])
    const [filesdata, setFilesData] = useState([])

    const [isShowHistoryTask, setIsShowHistoryTask] = useState(false)

    const [showOption, setShowOptions] = useState(false)
    const [showOption2, setShowOptions2] = useState(false)
    const [eventId, setEventId] = useState()

    const activeTab = useParams()['*']
    const [pageValue, setPageValue] = useState(btn_tab[0].value)
    const [currentTab2, setCurrentTab2] = useState(btns_2?.[0]?.value)
    const [isLoadingComments, setIsLoadingComments] = useState(false)

    // Подзадачник
    const [isNewSubtask, setIsNewSubtask] = useState(false)
    const [isCheckSubtask, setIsCheckSubtask] = useState(false)

    // Комментарии
    const [currentMessageAnswer, setCurrentMessageAnswer] = useState(null)

    // Для логики модалки с подтверждение выхода
    const [isChanges, setIsChanges] = useState(false)

    const formateDate = (date) => {
        const timeZone = new Date().getTimezoneOffset() / 60 * -3600000
        const formate_date = new Date(new Date(date)?.getTime() + timeZone).toUTCString()
        return new Date(formate_date)
    }



    const getBodyAndHeaders = (withId) => {

        const contentData = JSON.stringify({
            event_id: withId ? eventId : undefined,
            name: title,
            language: 'ru',
            start_time: formateDate(startDate),
            end_time: formateDate(endDate),
            employee_time: check2 ? formateDate(new Date()) : endDate2 ? formateDate(endDate2) : endDate ? formateDate(endDate) : undefined,
            event_type: TASK_EVENT_TYPE,
            task_id: addTaskId ? addTaskId : taskId,
            task_type: selectedStatusTask.value,
            executor: executors[0]?.id,
            priority: selectedPriority.value,
            content: description ? replaceString('</p>', ' </p>', description) : description,
            co_executors: coExecutors?.length > 0 ? coExecutors.map(({ id }) => id) : [],
            show_in_calendar: false,
            files: filesdata
        })


        // const formData = new FormData()
        // formData.append('content_data', new Blob([contentData], { type: 'application/json' }))

        const _headers = Object.assign({}, headers)
        // delete _headers['Content-Type']

        return { body: contentData, headers: _headers }
    }

    const fetchAddNewTask = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/tasks/post_event`
        } else {
            url = `${RavshanHttp}/api/tasks/post_event`
        }

        const { body, headers } = getBodyAndHeaders()

        fetch(url, { body, method: 'POST', headers })
            .then(res => res.json())
            .then(json => setIsModal(false))
            .catch(err => console.error(err))
    }

    const fetchUpdateTask = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/tasks/put_event`
        } else {
            url = `${RavshanHttp}/api/tasks/put_event`
        }

        const { body, headers } = getBodyAndHeaders(true)


        const _headers = Object.assign({}, headers)
        // delete _headers['Content-Type']

        fetch(url, { body: body, method: 'PUT', headers: _headers })
            .then(res => res.json())
            .then(json => setIsModal(false))
            .catch(err => console.error(err))
    }

    const fetchTaskLog = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/v2/tasks/event/log`
        } else {
            url = `${RavshanHttp}/api/v2/tasks/event/log`
        }

        const { headers } = getBodyAndHeaders(true)
        const body = JSON.stringify({
            event_id: eventId
        })

        fetch(url, { body, method: 'POST', headers })
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
            })
            .then(json => {
                if (Array.isArray(json)) {
                    setLogsArr(json)
                    setIsShowHistoryTask(true)
                } else {
                    setLogsErr(true)
                    setTimeout(() => {
                        setLogsErr(false)
                    }, 3000)
                }
            })
            .catch(err => console.error(err))
    }

    const fetchPossibleAttendees = () => {
        const url = `${RavshanHttps}/api/tasks/project_client_team`
        const newHeaders = {
            ...headers,
            'project-id': addTaskId
        }
        fetch(url, { headers: newHeaders })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad attendees response')
                }
            })
            .then(json => {
                setExecutorOptions(json)
            })
            .catch((e) => console.error(e))
    }

    const fetchComments = () => {
        if (eventId) {
            setIsLoadingComments(true)
            const url = `${RavshanHttps}/api/tasks/comment`
            const newHeaders = {
                ...headers,
                'event-id': eventId
            }
            fetch(url, { headers: newHeaders })
                .then(res => {
                    if (res.status === 200) {
                        return res.json()
                    } else {
                        throw new Error('bad comments response')
                    }
                })
                .then(json => {
                    setComments(json)
                })
                .catch((e) => console.error(e))
                .finally(() => setIsLoadingComments(false))
        }
    }

    const btns = [
        {
            btn: 'Удалить', func: (e) => {
                fetchDeleteTask(eventId)
                setTasks([])
                setIsModal(false)
                setInfoTask(null)
                setIstDropMenu(false)
            }
        },
    ];


    const handleMainButtonClick = (update) => {
        if (update) { fetchUpdateTask() }
        else { fetchAddNewTask() }
        setTasks([])
        sessionStorage.removeItem('eventId')
        navigate(`/tasks/task-list/${sessionStorage.getItem('taskProjectId')}`)
        setInfoTask(null)
    }

    const fetchAllColumns = (delayMs, taskId) => {
        if (!taskId & !selectedColumn?.typeId) return
        const url = `${RavshanHttps}/api/tasks/get_task_type`
        const getHeaders = {
            ...headers,
            "project-id": taskId ? taskId : selectedColumn?.typeId,
            // "executor": [],
            // "author": [],
            // "co_executor": [],
            // "task_type": "string"
        }

        const fetchCallback = () => fetch(url, { headers: getHeaders })
            .then(res => {
                if (res.ok) {
                    return res.json()
                } else {
                    throw new Error('bad columns response')
                }
            })
            .then(json => {
                if (!Array.isArray(json)) return
                const statusArr = json
                    .sort((a, b) => a.place - b.place)
                    .map((c, ind) => ({
                        label: <p style={{ color: colors[c?.type_name.toLowerCase()] }}>{c?.type_name}</p>,
                        value: c?.type_id
                    }))
                setStatusTask(statusArr)
            })

        if (delayMs) setTimeout(fetchCallback, delayMs)
        else fetchCallback()
    }

    useEffect(() => {
        if (!infoTask) return

        const {
            id, taskId, eventName, endTime, priority, taskType, author, startTime, executor, coExecutors, content,
            employee_time, files
        } = infoTask

        const priorityToDropdown = OPTIONS_PRIORITY.find(p => p.value === priority)
        const new_coExecutors = coExecutors.map((el) => {
            let elem = el
            elem.email = elem?.login
            return elem
        })
        const curr_files = files?.map((el) => {
            // "label": "screenshot_5_438_11.jpg",
            // "value": "https://dl.ecomru.ru:9001/files/438/screenshot_5_438_11.jpg",
            // "extension": "jpg",
            // "created_at": "2024-09-26T14:02:31.831163",
            // "file_size_kb": "9.395"

            return {
                created_at: el?.created_at,
                file_size_kb: el?.file_size_kb,
                new_filename: el?.label,
                url: el?.url,
                extension: el?.extension
            }
        })

        if (eventName) {
            setTitle(eventName)
            setDefaultTitle(eventName)
        }
        if (priority) {
            setSelectedPriority(priorityToDropdown ?? OPTIONS_PRIORITY.at(-1))
        }
        if (taskType) {
            const { id, name } = taskType
            setSelectedStatusTask({ label: <p style={{ color: colors[name.toLowerCase()] }}>{name}</p>, value: id })
        }
        if (endTime) {
            setStartDate(new Date(endTime))
            setEndDate(new Date(endTime))
        }
        if (startTime) {
            setStartDate(new Date(startTime))
        }
        if (employee_time) {
            setEndDate2(new Date(employee_time))
        } else {
            setEndDate2(new Date(endTime))
        }
        if (author) {
            const { name, color, login } = author
            setAuthorName(name)
            setAuthorColor(color)
            setAuthorEmail(login)
        }
        if (Array.isArray(content)) {
            setDescription(content[0]?.text) // тут падает
        }
        if ((executor && executor?.id)) {
            setExecutors([executor])
        }
        if ((Array.isArray(coExecutors) && coExecutors.length > 0) && coExecutors[0]?.id) {
            setCoExecutors(new_coExecutors)
        }

        if (id) {
            setEventId(id)
        }
        if (taskId) {
            fetchAllColumns(0, taskId)
            setAddTaskId(taskId)
            const selectedProject = allColumns.filter(({ value: { id } }) => id === taskId)?.[0]
            setSelectedProject(selectedProject)
        }
        if (files) {
            setFilesData(curr_files)
        }
    }, [infoTask])

    useEffect(() => {
        fetchPossibleAttendees()
        setPageValue(btn_tab[0].value)

        const hideOptionsBox = (e) => {
            if (!e.target.closest('.search-box-executor')) {
                setShowOptions(false)
            }
            setExecutorSearchValue('')
        }
        document.addEventListener('click', (e) => hideOptionsBox(e))
        return () => document.removeEventListener('click', (e) => hideOptionsBox(e));


    }, [addTaskId, selectedSpace])

    useEffect(() => {
        fetchComments()
    }, [eventId])

    useEffect(() => {
        if ((activeTab != btn_tab[0].value) && (activeTab != btn_tab[1].value)) {
            setPageValue(btn_tab[0].value)
        } else setPageValue(activeTab)
    }, [activeTab])

    useEffect(() => {
        const selProjId = selectedProject?.value?.id
        if (!infoTask?.id) {
            if (+taskId === -1) {
                const selCol = allColumns?.filter(({ label }) => label === selectedColumn?.typeName)?.[0]
                setAddTaskId(selProjId ? selProjId : selCol?.value?.id)
                fetchAllColumns(0, selProjId ? selProjId : selCol?.value?.id)
                setSelectedProject(selCol)
            } else {
                const selCol = allColumns?.filter(({ value: { id } }) => id === +taskId)?.[0]
                setAddTaskId(taskId)
                fetchAllColumns(0, taskId)
                setSelectedProject(selCol)
                setSelectedStatusTask({ label: <p style={{ color: colors[selectedColumn?.typeName.toLowerCase()] }}>{selectedColumn?.typeName}</p>, value: selectedColumn?.typeId })
            }
        } else {
            setAddTaskId(selProjId)
        }
    }, [selectedProject, selectedColumn, allColumns, selectedSpace])

    useEffect(() => {
        fetchTaskLog()
    }, [eventId]);

    function replaceString(oldS, newS, fullS) {
        return fullS.split(oldS).join(newS);
    }

    const current_file_list = () => {
        const extensions = ['jpg', 'jpeg', 'tiff', 'raw', 'bmp', 'psd']
        let list_files_img;
        let list_files_file

        list_files_img = filesdata.filter((el) => extensions?.includes(el?.extension))
        list_files_file = filesdata.filter((el) => !extensions?.includes(el?.extension))
        return filesdata
    }

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                sessionStorage.removeItem('eventId')
                navigate(`/tasks/task-list/${taskId}${query ? `/filter=${query}` : ''}${searchParams ? `?${searchParams}` : ''}`)
                setInfoTask(null)
                if (isChanges) {
                    setIsModalExit(true)
                } else {
                    setIsModal(false)
                }
            }
            if (!e.target.className.includes('search-box-executor')) setShowOptions2(false)
        }}>
            <div className='modal-add-task'>
                <div className='modal-add-task__chat'>
                    <div className='modal-add-task__header modal-add-task__header-comment'
                        style={{
                            minHeight: '58px'
                        }}>
                        <TabsGreen
                            btns={btns_2}
                            currentBtn={currentTab2}
                            setCurrentBtn={(e) => setCurrentTab2(e)}
                            checkValue={true}
                        />

                    </div>

                    <div className='modal-add-task__comment-container'>
                        <div className='modal-add-task__list-message'>
                            {isLoadingComments ?
                                <div className='sceleton-chat'>
                                    <Stack className='sceleton-chat__content'>
                                        <div className='sceleton-chat__message'>
                                            <Skeleton className='round' variant="circular" width={32} height={32} />
                                            <Skeleton variant="rectangular" width={280} height={150} >
                                                <Skeleton variant="circular" width={32} height={32} />
                                            </Skeleton>
                                        </div>

                                        <div className='sceleton-chat__message'>
                                            <Skeleton variant="rectangular" width={280} height={130} />
                                            <Skeleton className='round' variant="circular" width={32} height={32} />
                                        </div>

                                        <div className='sceleton-chat__message'>
                                            <Skeleton className='round' variant="circular" width={32} height={32} />
                                            <Skeleton variant="rectangular" width={280} height={80} >
                                                <Skeleton variant="circular" width={32} height={32} />
                                            </Skeleton>
                                        </div>

                                        <div className='sceleton-chat__message'>
                                            <Skeleton variant="rectangular" width={280} height={40} />
                                            <Skeleton className='round' variant="circular" width={32} height={32} />
                                        </div>
                                    </Stack>
                                </div>
                                :
                                <>
                                    {currentTab2 === 'comment' && (comments.length > 0 ?
                                        comments?.map((props, ind) => {
                                            const { id, date } = props
                                            let date_message = null

                                            if (ind > 0) {
                                                const now_month = new Date(date).getMonth()
                                                const now_day = new Date(date).getDate()
                                                const prev_month = new Date(comments[ind - 1].date).getMonth()
                                                const prev_day = new Date(comments[ind - 1].date).getDate()

                                                if (now_month === prev_month && prev_day === now_day) {
                                                    return <CommentItem
                                                        setCurrentMessageAnswer={(e) => setCurrentMessageAnswer(e)}
                                                        disabled={props.author.login !== email}
                                                        key={id}
                                                        {...props}
                                                        event_id={eventId}
                                                        fetchComments={e => fetchComments(e)} />

                                                } else {
                                                    date_message = date

                                                    return <>
                                                        {date_message && <p className={'modal-add-task__date-message'}>{new Date(date_message)?.toLocaleDateString('ru-RU', { day: '2-digit', month: 'long', year: 'numeric' })}</p>}

                                                        <CommentItem
                                                            setCurrentMessageAnswer={(e) => setCurrentMessageAnswer(e)}
                                                            disabled={props.author.login !== email}
                                                            key={id}
                                                            {...props}
                                                            event_id={eventId}
                                                            fetchComments={e => fetchComments(e)} />
                                                    </>
                                                }
                                            } else {
                                                return <>
                                                    <p className={'modal-add-task__date-message'}>{new Date(date)?.toLocaleDateString('ru-RU', { day: '2-digit', month: 'long', year: 'numeric' })}</p>

                                                    <CommentItem
                                                        setCurrentMessageAnswer={(e) => setCurrentMessageAnswer(e)}
                                                        disabled={props.author.login !== email}
                                                        key={id}
                                                        {...props}
                                                        event_id={eventId}
                                                        fetchComments={e => fetchComments(e)} />
                                                </>
                                            }
                                        })
                                        :
                                        <p className='modal-add-task__no-data-message'>Нет комментариев</p>)
                                    }

                                    {currentTab2 === 'history' &&
                                        <div className='history-actions__content'>
                                            {!isLoadInfoTask ?
                                                logsArr?.map((el, i) => {
                                                    const { author, editor, comment, timestamp } = el
                                                    return <div className='history-item'>
                                                        <div className='history-item__info'>
                                                            {author?.login && <p className='history-item__text-label'>{author?.login}</p>}
                                                            {editor?.login && <p className='history-item__text-label'>{editor?.login}</p>}
                                                            {Array.isArray(comment) ?
                                                                comment?.map(el => <p className='history-item__text-value'>{el}</p>)
                                                                : <p className='history-item__text-value'>{parse(comment)}</p>
                                                            }
                                                        </div>

                                                        {timestamp && <p className='history-item__text-date'>{new Date(timestamp).toLocaleDateString('ru-RU', { day: '2-digit', month: '2-digit', year: '2-digit', hour: 'numeric', minute: 'numeric' })}</p>}
                                                    </div>
                                                })

                                                :
                                                <Stack className='sceleton-file__content'>
                                                    <Skeleton variant="rectangular" width={220} height={44} />
                                                    <Skeleton variant="rectangular" width={220} height={44} />
                                                </Stack>
                                            }
                                        </div>
                                    }

                                    {(currentTab2 == 'file' && current_file_list()?.length > 0) ?
                                        current_file_list() && current_file_list()?.map(({ created_at, file_size_kb, new_filename, url }, ind) => {
                                            return <FileItemComponent
                                                created_at={created_at}
                                                file_size_kb={file_size_kb}
                                                new_filename={new_filename}
                                                url={url}
                                                authorEmail={authorEmail}
                                                filesdata={filesdata}
                                                setFilesData={(e) => setFilesData(e)}
                                            />
                                        })
                                        :
                                        currentTab2 == 'file' && <div className='modal-add-task__no-data-file'>
                                            <img src={no_data_file} />
                                            <p className='modal-add-task__no-data-file-text'>Ничего не найдено</p>
                                            <p className='modal-add-task__no-data-file-description'>Прикрепите изображение, файл, видео в комментариях, описании или нажмите на кнопку выше</p>
                                        </div>
                                    }

                                    {/* Подзадачи */}

                                    {currentTab2 == 'task-list' &&
                                        <div className='subtasks-list'>
                                            <div className='subtasks-list__header'>
                                                {!isNewSubtask ?
                                                    <ButtonBasic
                                                        grey
                                                        width='100%'
                                                        text={
                                                            <div className='subtasks-list__btn'>
                                                                <img src={plus_circle} />
                                                                Новая задача</div>
                                                        }
                                                        onClick={(e) => setIsNewSubtask(true)}
                                                    />
                                                    :
                                                    <div className='subtasks-list__input-box'>
                                                        <InputDinamycPlaceholder
                                                            onChange={(e) => (e)}
                                                            placeholder=''
                                                            size='s'
                                                        />

                                                        <ButtonBasic
                                                            grey
                                                            width='40px'
                                                            minWidth='40px'
                                                            onClick={(e) => setIsNewSubtask(false)}
                                                            text={<img src={check_green} />}
                                                        />
                                                    </div>}
                                            </div>
                                            {subtask_list.length > 0 ?
                                                <div className='subtasks-list__content'>
                                                    {subtask_list.map(({ value, status, date }, ind) => {
                                                        return <div className='subtasks-list__item'
                                                            key={value + ind}
                                                        >
                                                            <Checkbox
                                                                green
                                                                value={status}
                                                                onChange={(e) => setIsCheckSubtask(!isCheckSubtask)}
                                                            />
                                                            <p className={status ? 'subtasks-list__text-through' : 'subtasks-list__text'}
                                                            >{value}</p>
                                                            <p className='subtasks-list__date'>{date}</p>
                                                        </div>
                                                    })}
                                                </div>
                                                :
                                                currentTab2 == 'task-list' && <div className='modal-add-task__no-data-file'>
                                                    <img src={no_data_file} />
                                                    <p className='modal-add-task__no-data-file-text'>Нет задач в таск-листе</p>
                                                    <p className='modal-add-task__no-data-file-description'>Нажмите на кнопку выше для создания первой задачи в таск-листе</p>
                                                </div>
                                            }
                                        </div>
                                    }
                                </>
                            }
                        </div>

                        {currentTab2 === 'comment' && <Comment
                            setIsChanges={(e) => setIsChanges(e)}
                            currentMessageAnswer={currentMessageAnswer}
                            setCurrentMessageAnswer={(e) => setCurrentMessageAnswer(e)}
                            disabled={!infoTask}
                            name={currentUserName}
                            email={email}
                            color={currentUserColor}
                            event_id={eventId}
                            fetchUpdateComms={() => fetchComments()} />}
                    </div>
                </div>

                <div className='modal-add-task__right-content'>
                    <div className='modal-add-task__header'
                        style={{
                            minHeight: '58px'
                        }}
                    >
                        {isLoadInfoTask ?
                            <Stack className='sceleton-select-group__content'>
                                {/* For variant="text", adjust the height via font-size */}
                                <Skeleton variant="rectangular" width={400} height={28} />
                            </Stack>
                            :
                            <>
                                <div className='modal-add-task__header-left-box'>

                                    <h1 className='modal-add-task__task-id'>
                                        {isLoadInfoTask ? 'Загрузка...' :
                                            infoTask ? <> <img src={copy_icon} /> {`ID${eventId}`}</>
                                                : 'ID будет показан после создания задачи'}
                                    </h1>

                                    <DropDownSelector
                                        options_prop={allColumns}
                                        placeholder='Проект'
                                        state={selectedProject}
                                        setState={(e) => setSelectedProject(e)}
                                        disabled={infoTask?.id}
                                    />
                                </div>



                                {(email == authorEmail) &&
                                    <ButtonActionDrop
                                        id={1}
                                        btns={btns} />}

                                <button className='modal-add-task__btn-close'
                                    onClick={(e) => {
                                        sessionStorage.removeItem('eventId')
                                        navigate(`/tasks/task-list/${taskId}${query ? `&${query}` : ''}`)
                                        setInfoTask(null)
                                        if (isChanges) {
                                            setIsModalExit(true)
                                        } else {
                                            setIsModal(false)
                                        }
                                    }}
                                >
                                    <img src={close_icon} />
                                </button>
                            </>
                        }

                    </div>

                    <div className='modal-add-task__header'>
                        {!isLoadInfoTask ?
                            <>
                                <div className='executor'>
                                    <div className='executor-content'>
                                        <ExecutorPlaceholder hideTooltip name={authorName} color={authorColor} email={authorEmail} />
                                        <p className='text_name'>{authorName}</p>
                                    </div>
                                </div>
                                <DropDownSelector
                                    options_prop={OPTIONS_PRIORITY}
                                    placeholder='Приоритет'
                                    isClearable={false}
                                    value={selectedPriority}
                                    defaultValue={selectedPriority}
                                    state={selectedPriority}
                                    setState={(e) => setSelectedPriority(e)}
                                    maxWidth='100px'
                                />

                                <DropDownSelector
                                    options_prop={statusTask}
                                    placeholder='Статус'
                                    isClearable={false}
                                    value={selectedStatusTask}
                                    defaultValue={selectedStatusTask}
                                    state={selectedStatusTask}
                                    setState={(e) => setSelectedStatusTask(e)}
                                    maxWidth='100px'
                                />

                                <div className='select-group'>
                                    <DateBox
                                        disabled={check}
                                        startDate={endDate}
                                        description={'Крайний день выполнения'}
                                        setStartDate={(e) => setEndDate(e)}
                                    />

                                    <label className='checkbox-content'>
                                        <Checkbox
                                            value={check}
                                            onChange={(e) => setCheck(e)}
                                        />
                                        <p className='text'>Без срока</p>
                                    </label>


                                </div>
                            </>
                            :
                            <div className='sceleton-header'>
                                <Stack className='sceleton-header__content'>
                                    {/* For variant="text", adjust the height via font-size */}
                                    <Skeleton variant="circular" width={40} height={40} />
                                    {[...Array(3)].map((el) => {
                                        return <>
                                            <Skeleton variant="rectangular" width={210} height={40} />
                                        </>
                                    })}
                                </Stack>
                            </div>
                        }

                    </div>

                    <div className='modal-add-task__content'>
                        <div className='main-info' >
                            {!isLoadInfoTask ?
                                <>
                                    <TitleContentEditable
                                        setIsChanges={(e) => setIsChanges(e)}
                                        disabled={authorEmail !== email}
                                        autoFocus
                                        placeholder='Добавьте название'
                                        title={title}
                                        setTitle={(e) => setTitle(e)}
                                    />

                                    <Editor
                                        setIsChanges={(e) => setIsChanges(e)}
                                        filesdata={filesdata}
                                        setFiles={(e) => setFiles(e)}
                                        disabled={authorEmail !== email}
                                        bounds='.main-info'
                                        title='Описание'
                                        placeholder='Редактировать описание задачи'
                                        description={description}
                                        setDescription={e => setDescription(e)}
                                        setFilesData={setFilesData}
                                        authorEmail={authorEmail}
                                        hideBtn={true}
                                    />
                                </>
                                :
                                <div className='sceleton-main-info'>
                                    <Stack className='sceleton-main-info__content'>
                                        <Skeleton variant="rectangular" height={48} />
                                        <Skeleton variant="rectangular" width={100} height={16} />
                                        <Skeleton variant="rectangular" height={200} />
                                    </Stack>
                                </div>
                            }
                        </div>

                        <div className='additional-information'>

                            <div className='additional-information__info'>
                                <div className='select-group'>

                                    {!isLoadInfoTask ?
                                        <>
                                            <>
                                                <SelectExecutor
                                                    executors={executors}
                                                    setExecutors={(e) => setExecutors(e)}
                                                    executorOptions={executorOptions}
                                                    title='Исполнитель:'
                                                    placeholder='Назначить'
                                                />

                                                <div className='select-group__date-box'>
                                                    <label className='checkbox-content'>
                                                        <Checkbox
                                                            value={new Date(endDate2)?.toLocaleDateString('ru-RU') === new Date()?.toLocaleDateString('ru-RU')}
                                                            onChange={(e) => setEndDate2(new Date())}
                                                            disabled={new Date(endDate2)?.toLocaleDateString('ru-RU') === new Date()?.toLocaleDateString('ru-RU')}
                                                        />
                                                        <p className='text'>Выполню сегодня</p>
                                                    </label>

                                                    <DateBox
                                                        startDate={endDate2}
                                                        description={'Срок выполнения'}
                                                        setStartDate={(e) => setEndDate2(e)}
                                                    />
                                                </div>

                                                {executors[0]?.id && <SelectExecutor
                                                    executors={coExecutors}
                                                    setExecutors={(e) => setCoExecutors(e)}
                                                    executorOptions={executorOptions?.filter(({ id }) => {
                                                        if (executors[0]?.id) {
                                                            return id != executors[0].id
                                                        }
                                                    })}
                                                    title='Cоисполнители:'
                                                    placeholder='Назначить'
                                                    hideTooltip={false}
                                                    multi
                                                />}
                                            </>
                                        </>
                                        :
                                        <div className='sceleton-select-group'>
                                            <Stack className='sceleton-select-group__content'>

                                                <Skeleton variant="rectangular" width={100} height={16} />

                                                <div className='sceleton-select-group__user'>
                                                    <Skeleton variant="circular" width={40} height={40} />
                                                    <Skeleton variant="rectangular" width={97} height={40} />
                                                </div>

                                                <Skeleton variant="rectangular" width={100} height={16} />
                                                <Skeleton variant="rectangular" width={200} height={32} />
                                                <Skeleton variant="rectangular" width={100} height={16} />

                                                <div className='sceleton-select-group__user'>
                                                    <Skeleton variant="circular" width={40} height={40} />
                                                    <Skeleton variant="rectangular" width={97} height={40} />
                                                </div>
                                                <div className='sceleton-select-group__user'>
                                                    <Skeleton variant="circular" width={40} height={40} />
                                                    <Skeleton variant="rectangular" width={97} height={40} />
                                                </div>
                                            </Stack>
                                        </div>
                                    }

                                    <ButtonBasic
                                        disabled={isLoadInfoTask}
                                        green
                                        width='100%'
                                        style={{
                                            marginTop: 'auto'
                                        }}
                                        text={infoTask ? 'Сохранить задачу' : 'Создать'}
                                        onClick={() => infoTask ? handleMainButtonClick(true) : handleMainButtonClick(false)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export { ModalAddTask2 }