import React, { useState, useEffect } from 'react';
import './CrmAttrControlModal.scss';
import close_icon from './img/close_icon.svg';
import { RavshanHttps } from '../../fetchUrls';
import sort_icon from './img/sort.svg';
import edit_icon from './img/edit.svg';
import {
  InputDinamycPlaceholder,
  Checkbox,
  ButtonBasic,
  TextareaDynamicPlaceholder,
  InputSearch,
} from '@lk-gtcom/ecomlab-components';
import customHeaders, { getSpace } from '../../common/headers';
import useGeneralStore from '../../store/general';

export const CrmAttrControlModal = (props) => {
  const {
    isOpen,
    close,
    selectedAttributes,
    setSelectedAttributes,
    currentIndex,
    setCurrentIndex,
    objValuesAttr,
    setObjValuesAttr,
    tableId,
    selectedRowId,
  } = props;
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [checkedAttr, setCheckedAttr] = useState([]);
  const [nameListPoint, setNameListPoint] = useState('');
  const [listPoints, setListPoints] = useState(
    objValuesAttr[currentIndex]?.attr_values ?? []
  );
  const [checkedValues, setCheckedValues] = useState([]);

  useEffect(() => {
    if (objValuesAttr[currentIndex]?.attr_values) {
      setListPoints(objValuesAttr[currentIndex]?.attr_values);
    }
  }, [currentIndex]);

  const onCheckboxChange = (ind) => {
    const currentIndex = checkedValues.indexOf(ind);
    const newChecked = [...checkedValues];

    if (currentIndex === -1) {
      newChecked.push(ind);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedValues(newChecked);
  };

  // const handleDeleteCheckedAttr = () => {
  //   setSelectedAttributes((it) =>
  //     it.filter((_, index) => !checkedAttr.includes(index))
  //   );
  // };

  const handleDeleteCheckedAttr = () => {
    setListPoints((prevPoints) =>
      prevPoints.filter((_, index) => !checkedValues.includes(index))
    );
    setCheckedValues([]);
  };

  const handleAddPoints = () => {
    if (nameListPoint) {
      setListPoints((prev) => [...prev, nameListPoint]);
    }
    setNameListPoint('');
  };

  const onChangeSaveList = (e, idx) => {
    const obj = objValuesAttr;
    obj[idx].attr_values = e;
    setObjValuesAttr(obj);
  };

  const onGetField = (table_uid) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/custom_table/get_custom_table_dict_values`;
    } else {
      url = `${RavshanHttps}/api/custom_table/get_custom_table_dict_values`;
    }

    const body = JSON.stringify({
      table_uid,
      field_name: 'City',
    });

    fetch(url, { method: 'POST', headers, body })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('ERROR: get_tables_attr request');
        }
      })
      .then((json) => {
        setListPoints(json?.map((item) => item) || []);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchAddValueInDictionaryField = (tableId, fieldName, data) => {
    console.log('in');

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${RavshanHttps}/api/custom_table/add_value_in_dictionary_field`;
    } else {
      url = `${RavshanHttps}/api/custom_table/add_value_in_dictionary_field`;
    }

    const body = JSON.stringify({
      table_uid: tableId,
      field_name: fieldName,
      // data: data.map((item, index) => ({
      //   value: item,
      //   order_num: item.order_num || index + 1,
      // })),
      data: [data[data.length - 1]].map((item, index) => ({
        value: item,
        order_num: item.order_num || index + 1,
      })),
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.statusText);
        }
      })
      .then((json) => {
        console.log(json);
      })
      .catch((err) => {
        console.error('Error adding value:', err);
      });
  };

  const handleDeleteField = (fieldName) => {
    const url = `${RavshanHttps}/api/custom_table/delete_value_in_dictionary_field`;
    const body = JSON.stringify({
      table_uid: tableId,
      // row_uid: selectedRowId,
      field_name: 'City',
      data: [
        {
          value_uid: '3972755775875952982',
        },
      ],
    });

    fetch(url, {
      method: 'DELETE',
      headers,
      body,
    })
      .then((res) => (res.ok ? res.json() : Promise.reject('ERROR')))
      .then((res) => {
        console.log(res);
        setListPoints([]);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    onGetField(tableId);
  }, []);

  if (!isOpen) return null;

  return (
    <div className="modal-crm-attr" onClick={close}>
      <div
        className="modal-crm-attr__main-content"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          flexDirection: 'column',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-crm-attr__header">
          <h2 className="modal-crm-attr__title">Управление списком</h2>
          <button className="modal-crm-attr__btn-close" onClick={() => close()}>
            <img src={close_icon} />
          </button>
        </div>

        <div className="modal-crm-attr__content">
          <div className="modal-crm-attr__item">
            <h3 className="modal-crm-attr__title_min">
              Добавить элементы списка
            </h3>
            <ButtonBasic lilac width="170px" text={'Импортировать .xlx'} />

            <div className="modal-crm-attr__new-el-box">
              <div className="modal-crm-attr__header-new">
                <h4 className="modal-crm-attr__title-new">
                  Новые элементы списка
                </h4>
                <ButtonBasic
                  lilac
                  width="105px"
                  onClick={(e) => {
                    handleAddPoints();
                    //  fetchCreateTable()
                  }}
                  text="Добавить"
                />
              </div>

              <div className="modal-crm-attr__input-item">
                <TextareaDynamicPlaceholder
                  changeValue={nameListPoint}
                  placeholder="Элемент списка"
                  onChange={(e) => setNameListPoint(e)}
                />
              </div>
            </div>
          </div>

          <div className="modal-crm-attr__item">
            <h4>Изменить список</h4>

            <InputSearch />

            <div className="modal-crm-attr__medium-panel">
              <button className="modal-crm-attr__btn-sort">
                Сортировать по алфавиту
                <img src={sort_icon} />
              </button>

              <ButtonBasic
                lilac
                width="100px"
                text={'Удалить'}
                onClick={() => {
                  handleDeleteCheckedAttr();
                  handleDeleteField();
                }}
              />
            </div>
            <div className="modal-crm-attr__checkbox-group">
              {listPoints.map((it, idx) => (
                <label className="modal-crm-attr__label">
                  <button className="modal-crm-attr__btn-edit">
                    <img src={edit_icon} />
                  </button>
                  <Checkbox
                    value={checkedValues.includes(idx)}
                    key={it}
                    onChange={(e) => onCheckboxChange(idx)}
                  />
                  <span className="modal-crm-sub-modal__label-text">{it}</span>
                </label>
              ))}
            </div>
          </div>
        </div>

        <div className="modal-crm-attr__btn-group">
          <ButtonBasic
            green
            width="110px"
            text={'Выбрать'}
            onClick={(e) => {
              // onChangeSaveList(listPoints, currentIndex);
              // let tableId = '12182756186696051379';
              let title = 'City';
              let data = [
                { value: 'Moscow new', order_num: 1 },
                { value: 'Minsk new', order_num: 2 },
                { value: 'Varshava new', order_num: 3 },
                { value: 'Varshava new', order_num: 4 },
              ];
              fetchAddValueInDictionaryField(tableId, title, listPoints);
              setListPoints([]);
              close();
            }}
          ></ButtonBasic>

          <ButtonBasic
            grey
            width="110px"
            text={'Отменить'}
            onClick={() => {
              setListPoints([]);
              close();
            }}
          ></ButtonBasic>
        </div>
      </div>
    </div>
  );
};
