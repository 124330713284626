import customHeaders, { getSpace } from '../../../common/headers';
import { ButtonBasic, InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components";
import { DownloadComponent } from '../../DownloadComponent/DownloadComponent';
import file_icon from './img/bx-file.svg'
import close from './img/close.svg'
import x from './img/bx-x.svg'
import plus_circle from './img/bx-plus-circle.svg'
import './ModalCreateChatBot.scss';
import { useState } from 'react';

const FileItem = ({ name = 'Нет названия' }) => {
    return <div className='file-item'>
        <button className='file-item__btn'>
            <img src={x} />
        </button>
        <img src={file_icon} className='file-item__icon' />
        <p className='file-item__name'>{name}</p>
    </div>
}

const DropZone2 = ({
    isSaveFiles,
    setIsSaveFiles,
    filesdata,
    setFiles
}) => {

    const [fileData, setFilesData] = useState([])

    return <div className='drop-zone-2'>
        <img src={plus_circle} />

        <DownloadComponent
            isSaveFiles={isSaveFiles}
            setIsSaveFiles={(e) => setIsSaveFiles(e)}
            filesdata={filesdata}
            setFilesArr={(e) => setFiles(e)}
            setFilesData={(e) => setFilesData(e)}
        />
    </div>
}

const ModalCreateChatBot = ({
    setIsModal,
}) => {

    const [name, setName] = useState('')
    const [isSaveFiles, setIsSaveFiles] = useState(false)
    const [files, setFiles] = useState([])
    const [filesdata, setFilesData] = useState([])

    return (
        <div
            className="blackout"
            onClick={(e) => {
                let el = e.target;
                if (el.classList.contains('blackout')) {
                    setIsModal(false);
                }
            }}
        >
            <div className="modal-create-chat-bot">
                <div className="modal-create-chat-bot__header">
                    <h1 className="title_main">
                        Создание чат-бота
                    </h1>
                    <button className="modal-create-chat-bot__btn-close"
                        onClick={(e) => setIsModal(false)}
                    >
                        <img src={close} />
                    </button>
                </div>
                <div className="modal-create-chat-bot__content">
                    <div className="modal-create-chat-bot__input-box">
                        <InputDinamycPlaceholder
                            onChange={(e) => setName(e)}
                            placeholder='Название чат-бота'
                            size='s'
                        />
                    </div>
                    <h3 className="modal-create-chat-bot__title">Файлы для чат бота</h3>
                    <div className="modal-create-chat-bot__files-box">
                        <FileItem />
                        <FileItem />
                        <DropZone2
                            isSaveFiles={isSaveFiles}
                            setIsSaveFiles={setIsSaveFiles}
                            filesdata={filesdata}
                            setFiles={setFiles}
                        />
                    </div>
                </div>
                <div className="modal-create-chat-bot__btn-box">
                    <ButtonBasic
                        green
                        width='160px'
                        text='Создать чат-бота'
                    />

                    <ButtonBasic
                        whiteGreen
                        width="auto"
                        text='Отменить'
                    />
                </div>
            </div>
        </div>
    );
};

export { ModalCreateChatBot };
