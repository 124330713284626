import Tools from './pages/Tools';
import Footer from './components/Footer';
import Cookies from 'universal-cookie';
import star_icon from './common/img/star_Icon.svg';
import ProductsList from './components/ProductsList';
import analyticRoutes from './pages/AnalyticsPage/routes';
import marketplace_icon from './common/img/marketplace_icon.svg';
import { Crm } from './pages/Crm/Crm';
import { Hint } from './pages/Hint/Hint';
import { Modal } from './components/Modal/Modal';
import { Landing } from './pages/Landing/Landing';
import { MainMenu, MainMenuMobile } from '@lk-gtcom/ecomlab-components';
import { Calendar } from './pages/Calendar/Calendar';
import { TaskPage } from './pages/TaskPage/TaskPage';
import { Reviews } from './pages/Reviews/Reviews';
import { Page404 } from './pages/Page404/Page404';
import { Settings } from './pages/Settings/Settings';
import { NotActive } from './components/NotActive/NotActive';
import { HeaderMain } from './components/HeaderMain/HeaderMain';
import { EditProduct } from './components/EditProduct/EditProduct';
import { TopKeywords } from './components/TopKeywords/TopKeywords';
import { SocialNetwork } from './pages/SocialNetwork/SocialNetwork';
import { PrivacyPolicy } from './pages/PrivacyPolicy/PrivacyPolicy';
import { TaskListAdmin } from './pages/TaskListAdmin/TaskListAdmin';
import { MarketingOzon } from './pages/MarketingOzon/MarketingOzon';
import { AnalyticsPage } from './pages/AnalyticsPage/AnalyticsPage';
import { CrossAnalytics } from './pages/CrossAnalytics/CrossAnalytics';
import { AddProductVer3 } from './components/AddProductVer3/AddProductVer3';
import { setUpFetchProxy } from './microServices/api/fetch';
import { PriceManagement } from './pages/PriceManagement/PriceManagement';
import { LandingProducts } from './pages/LandingProducts/LandingProducts';
import { AnaliticsReview } from './components/AnaliticsReview/AnaliticsReview';
import { WorkWithReviews } from './components/WorkWithReviews/WorkWithReviews';
import { OrderManagement } from './components/OrderManagement/OrderManagement';
import { ContentTransfer } from './components/ContentTransfer/ContentTransfer';
import { AnalysisByBrand } from './components/AnalysisByBrand/AnalysisByBrand';
import { AnalysisPlatform } from './pages/AnalysisPlatform/AnalysisPlatform';
import { ProductsListAdmin } from './components/ProductsListAdmin/ProductsListAdmin';
import { NotificationsVer2 } from './pages/NotificationsVer2/NotificationsVer2';
import { SetRemainingGoods } from './components/SetRemainingGoods/SetRemainingGoods';
import { AnalysisBySellers } from './components/AnalysisBySellers/AnalysisBySellers';
import { SetPriceAndMargin } from './components/SetPriceAndMarginMaster/SetPriceAndMargin';
import { LandingPerformance } from './pages/LandingPerformance/LandingPerformance';
import { LandingIntegration } from './pages/LandingIntegration/LandingIntegration';
import { AnalysisByCategory } from './components/AnalysisByCategory/AnalysisByCategory';
import { ModalKnowledgeBase } from './components/Modal/ModalKnowledgeBase/ModalKnowledgeBase';
import { AnalysisSearchQuery } from './components/AnalysisSearchQuery/AnalysisSearchQuery';
// import { CompetitorAnalytics } from './pages/CompetitorAnalytics/CompetitorAnalytics';
import { AutomationResponses } from './components/AutomationResponses/AutomationResponses';
import { ProductCardExtended } from './components/ProductCardExtended/ProductCardExtended';
import { Cookie, ACCEPT_COOKIE } from './components/Cookie/Cookie';
import { ProductsListMasterProduct } from './components/ProductsListMasterProduct/ProductsListMasterProduct';
import { DescriptionImprovementLanding } from './components/DescriptionImprovementLanding/DescriptionImprovementLanding';
import { SetRemainingGoodMasterProduct } from './components/SetRemainingGoodMasterProduct/SetRemainingGoodMasterProduct';
import { SetPriceAndMarginMasterProduct } from './components/SetPriceAndMarginMasterProduct/SetPriceAndMarginMasterProduct';
import { refresh, parseJwt, TOKEN_LIFETIME_MS } from './common/authHelper';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import settingsConnectionReducer, {
  defaultState,
} from './components/SettingsConnection/actions';
import { Deliveries } from './components/Deliveries/Deliveries';
import { useState, useEffect, createContext, useReducer } from 'react';
import { AnalysisCategory } from './components/AnalysisCategory/AnalysisCategory';
import { AnalysisPlatformByBrand } from './components/AnalysisPlatformByBrand/AnalysisPlatformByBrand';
import { AnalysisPlatformBySellers } from './components/AnalysisPlatformBySellers/AnalysisPlatformBySellers';
import { NicheSearchVer2 } from './components/NicheSearchVer2/NicheSearchVer2';
import { KeyPerformanceIndicators } from './pages/KeyPerformanceIndicators/KeyPerformanceIndicators';
import { EffectivenessAdvertisingCampaigns } from './pages/EffectivenessAdvertisingCampaigns/EffectivenessAdvertisingCampaigns';
import { NicheSelection } from './components/NicheSelection/NicheSelection';
import { AnalysisBySaleType } from './components/AnalysisBySaleType/AnalysisBySaleType';
import { ModalCreatingNewSpace } from './components/Modal/ModalCreatingNewSpace/ModalCreatingNewSpace';
import { Main } from './pages/Main/Main';
import { ChatMessagePage } from './pages/ChatMessage/ChatMessagePage';
import { MassImprovement } from './pages/MassImprovement/MassImprovement';
import { CalculationDelivery } from './pages/CalculationDelivery/CalculationDelivery';
import { SettingCostPrice } from './pages/SettingCostPrice/SettingCostPrice';
// import useGeneralStore from './store/general';
import { ChatBot } from './pages/ChatBot/ChatBot';
import { NotificationsChat } from '@lk-gtcom/ecomlab-components';
import { RavshanHttps, RavshanHttp } from './fetchUrls';
import customHeaders, { getSpace } from './common/headers';
import useModal from './store/modal';
import { CheckingOVH } from './pages/CheckingOVH/CheckingOVH';
import { ViewCompetitors } from './pages/ViewCompetitors/ViewCompetitors';
import { AddCompetitors } from './pages/AddCompetitors/AddCompetitors';
import { SummaryMatrices } from './pages/SummaryMatrices/SummaryMatrices';
import Wiki from './pages/Wiki/Wiki';
import user_icon from './common/img/bx-user.svg';
import user_circle_icon from './common/img/bx-user-circle.svg';
import useGeneralStore from './store/general';
import { useShallow } from 'zustand/react/shallow';
import { ActService } from './components/Documents/ActService/ActService';
import { ActVerification } from './components/Documents/ActVerification/ActVerification';
import { Invoice } from './components/Documents/Invoice/Invoice';
import { InvoiceWithSignature } from './components/Documents/InvoiceWithSignature/InvoiceWithSignature';
import Customization from './pages/Customization/Customization';
import { ModalAddEmployee } from './components/Modal/ModalAddEmployee/ModalAddEmployee';
import { PromotionsPage } from './pages/StockPage/PromotionsPage';
import SkuSearch from './components/SkuSearch/SkuSearch';

export const BASE_ROUTE = '';

function App() {
  const path = useParams();
  const client_id = localStorage.getItem('client_id');
  const navigate = useNavigate();
  const [connectionIds, setConnectionIds] = useState([]);

  const [isModalFeedback, setIsModalFeedback] = useState(false);

  const [showNotificationBox, setShowNotificationBox] = useState(false);
  const [activeContent, setActiveContent] = useState('');
  const [connectedModal, setConnectedModal] = useState(false);
  const [isOpenMainMenu, setIsOpenMainMenu] = useState(false);
  const [editableConnection, dispatch] = useReducer(
    settingsConnectionReducer,
    defaultState
  );

  const {
    isModalActive,
    setIsModalActive,
    isModalAddEmployee,
    setIsModalAddEmployee,
  } = useModal(
    useShallow((state) => ({
      isModalActive: state.isModalActive,
      setIsModalActive: state.setIsModalActive,
      isModalAddEmployee: state.isModalAddEmployee,
      setIsModalAddEmployee: state.setIsModalAddEmployee,
    }))
  );

  const openConnectionModalById = (ids) => {
    setConnectionIds(ids);
    setIsModalActive(true);
  };

  let pathBread = '';
  const [paths, setPaths] = useState([]);
  const [pathsWebName, setPathsWebName] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [currentArticle, setCurrentArticle] = useState({});

  const [showCookie, setShowCookie] = useState(true);
  const [activeLang, setActiveLang] = useState(
    () => localStorage.getItem('lang') ?? 'ru'
  );
  const { selectedSpace, setSelectedSpace, spaceList, setSpaceList } =
  useGeneralStore(
    useShallow((state) => ({
      selectedSpace: state.selectedSpace,
      setSelectedSpace: state.setSelectedSpace,
      spaceList: state.spaceList,
      setSpaceList: state.setSpaceList,
    }))
  );
const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const sidebarItems = [
    {
      label: 'Аналитика',
      subItems: [
        {
          label: 'Общие графики',
          active: true,
          path: `/${analyticRoutes.analytics}/date`,
        },
        {
          label: 'Реклама',
          active: true,
          path: `/${analyticRoutes.advertising}/date`,
        },
        {
          label: 'Заказы и продажи',
          active: true,
          path: `/${analyticRoutes.ordersAndSales}/list`,
        },
        {
          label: 'Возвраты и отмены',
          active: true,
          path: `/${analyticRoutes.returnAndCancellations}/list`,
        },
        {
          label: 'Цены',
          active: true,
          path: `/${analyticRoutes.prices}/date`,
        },
        {
          label: 'Остатки',
          active: true,
          path: `/${analyticRoutes.stock}/date`,
        },
        {
          label: 'Кросс Аналитика',
          active: true,
          path: '/cross-analytics/charts',
        },
        // {
        //   label: "Динамика рекламных показателей",
        //   active: true,
        //   path: "/dynamics_advertising_indicators",
        // },
        {
          label: 'Подсказочник',
          active: true,
          path: '/hint',
        },
      ],
    },
    {
      label: 'Товары',
      path: '',
      subItems: [
        {
          section_name: 'Мастер товары',
          section_icon: star_icon,
          section: [
            {
              label: 'Список товаров',
              active: true,
              path: '/products_list_master_product/all',
            },
            {
              label: 'Добавить товар',
              active: true,
              path: '/add-product/required',
            },
            {
              label: 'Установка цен',
              active: true,
              path: '/set-price-and-margin-master-product/price_setting',
            },
            {
              label: 'Установка остатков',
              active: true,
              path: '/set-remaining-goods-master-product/setting-balances',
            },
            {
              label: 'Перенос товаров',
              active: true,
              path: '/content-transfer/',
            },
            {
              label: 'Улучшение товара',
              active: true,
              path: '/mass-improvement/all',
            },
            // {
            //   label: 'Рассчет поставки',
            //   active: true,
            //   path: '/calculation-delivery/calculation',
            // },
            {
              label: 'Установка себестоимости',
              path: '/setting-cost-price/setting',
            },
            // {
            //   label: 'Проверка ОВХ',
            //   path: '/checking-OVH/all',
            // },
            // {
            //   label: 'Посмотреть конкурентов',
            //   path: '/view-competitors/all',
            // },
            // {
            //   label: 'Добавить конкурентов',
            //   path: '/add-competitors/all',
            // },
            // {
            //   label: 'Сводные матрицы',
            //   path: '/summary-matrices',
            // },
          ],
        },
        {
          section_name: 'Товары на площадках',
          section_icon: marketplace_icon,
          section: [
            {
              label: 'Список товаров',
              active: true,
              path: '/products_list',
            },
            {
              label: 'Установка цен',
              active: true,
              path: '/set-price-and-margin',
            },
            {
              label: 'Установка остатков',
              active: true,
              path: '/set-remaining-goods',
            },
            {
              label: 'Проверка ОВХ',
              path: '/checking-OVH/all_table',
            },
            {
              label: 'Рассчет поставки',
              active: true,
              path: '/calculation-delivery/calculation',
            },
          ],
        },
      ],
    },
    {
      label: 'Конкуренты',
      subItems: [
        {
          section_name: 'Озон анализ',
          section_icon: marketplace_icon,
          section: [
            {
              label: 'Категорий',
              active: true,
              path: '/analysis-category/top?mp=ozon',
            },
            {
              label: 'Ниш',
              active: true,
              path: '/niche-search/summary?mp=ozon',
            },
            // {
            //   label: 'По артикулу',
            //   active: true,
            //   path: '/analysis-by-SKU/general?mp=ozon',
            // },
            {
              label: 'По артикулу',
              active: true,
              path: '/analysis-by-SKU/search?mp=ozon',
            },
            {
              label: 'Брендов',
              active: true,
              path: '/analysis-by-brand/top?mp=ozon',
            },

            {
              label: 'Продавцов',
              active: true,
              path: '/analysis-by-sellers/top?mp=ozon',
            },
            {
              label: 'По типу продаж',
              active: true,
              path: '/analysis-by-sale-type/top',
            },
          ],
        },
        {
          section_name: 'Wildberries анализ',
          section_icon: marketplace_icon,
          section: [
            {
              label: 'Категорий',
              active: true,
              path: '/analysis-category/top?mp=wb',
            },
            {
              label: 'Ниш',
              active: true,
              path: '/niche-search/summary?mp=wb',
            },
            {
              label: 'По артикулу',
              active: true,
              path: '/analysis-by-SKU/search?mp=wb',
            },
            // {
            //   label: 'По артикулу',
            //   active: true,
            //   path: '/analysis-by-SKU/general?mp=wb',
            // },
            {
              label: 'Брендов',
              active: true,
              path: '/analysis-by-brand/top?mp=wb',
            },
            {
              label: 'Продавцов',
              active: true,
              path: '/analysis-by-sellers/top?mp=wb',
            },
          ],
        },
        {
          section_name: 'Добавить конкурентов',
          section_icon: marketplace_icon,
          section: [
            {
              label: 'Посмотреть конкурентов',
              path: '/view-competitors/all',
            },
            {
              label: 'Добавить конкурентов',
              path: '/add-competitors/all',
            },
            {
              label: 'Сводные матрицы',
              path: '/summary-matrices',
            },
          ],
        },
      ],
    },
    {
      label: 'Заказы',
      active: true,
      subItems: [
        {
          label: 'Заказы',
          active: true,
          path: '/order-management',
        },
        {
          label: 'Поставки',
          active: true,
          path: '/deliveries/awaiting_packaging',
        },
      ],
    },
    {
      label: 'Маркетинг',
      //path: 'not-active',
      subItems: [
        {
          section_name: 'Рекламные кампании',
          section_icon: marketplace_icon,
          section: [
            {
              label: 'Аналитические подборки',
              path: '/marketing-place/analytical-collections/all_product',
            },
            {
              label: 'Список рекламных кампаний',
              path: '/marketing-place/list-of-advertising-campaigns/all',
            },
            {
              label: 'Товары в кампании',
              path: '/marketing-place/products-in-campaign/products_in_campaign_participate_items',
            },
            {
              label: 'Cоздать кампанию из правил',
              path: '',
            },
          ],
        },
        {
          section_name: 'Внешняя реклама',
          section_icon: marketplace_icon,
          section: [
            {
              label: 'Аналитические подборки',
              path: '/out-marketing-place/analytical-collections/all_product',
            },
            {
              label: 'Список рекламных кампаний',
              path: '/out-marketing-place/list-of-advertising-campaigns/all',
            },
            {
              label: 'Товары в кампании',
              path: '/out-marketing-place/products-in-campaign/products_in_campaign_participate_items',
            },
            {
              label: 'Cоздать кампанию из правил',
              path: '',
            },
          ],
        },
      ],
    },
    {
      label: 'Акции',
      path: '',
      subItems: [
        {
          label: 'Товары в акции',
          path: '/marketing-place/promotions/joined-promotions/all',
        },
        {
          label: 'Список акций',
          path: '/marketing-place/promotions/list-promotion/all',
        },
        {
          label: 'Правила',
          path: '/marketing-place/promotions/promotion-rules/all',
        },
      ],
    },

    {
      label: 'Ключевые слова',
      subItems: [
        {
          label: 'По поисковому запросу',
          active: true,
          path: '/analysis-search-query/summary',
        },
        {
          label: 'По категории',
          active: true,
          path: '/analysis-by-category/queries',
        },
        {
          label: 'По продавцу',
          active: true,
          path: '/keywords/analysis-by-sellers/queries',
        },
        {
          label: 'По бренду',
          active: true,
          path: '/keywords/analysis-by-brand/queries',
        },
        {
          label: 'По SKU',
          active: true,
          path: '/keywords/analysis-by-SKU/position',
        },
        {
          label: 'Топ ключевых слов',
          active: true,
          path: '/top-keywords',
        },
      ],
    },
    {
      label: 'Отзывы и вопросы',
      path: '',
      subItems: [
        {
          label: 'Работа с отзывами и вопросами',
          active: true,
          path: '/work-with-review/reviews',
        },
        {
          label: 'Аналитика по отзывам',
          active: true,
          path: '/analitics-review/feedbacks?content=by-product',
        },
        {
          label: 'Аналитика по вопросам',
          active: true,
          path: '/analitics-review/questions?content=by-product',
        },
        {
          label: 'Автоматизация ответов',
          active: true,
          path: '/automation-of-responses/answer_templates',
        },
      ],
    },

    {
      label: 'Social network',
      path: '/social-network',
      subItems: [],
    },
    {
      label: 'Задачи',
      path: '/tasks/',
      subItems: [],
    },
    {
      label: 'Календарь',
      subItems: [],
      path:
          +selectedSpace?.value === 3459
          ? '/calendar'
          : '/not-active',
      notification: 20,
    },
    {
      label: 'CRM',
      subItems: [],
      path:
          +selectedSpace?.value === 3459
          ? '/crm/table-list'
          : '/not-active',
      notification: 20,
    },
    {
      label: 'Wiki',
      subItems: [],
      path:
          +selectedSpace?.value === 3459
          ? '/wiki/main/articles'
          : '/not-active',
    },
    {
      label: 'Чат бот',
      path:
          +selectedSpace?.value === 3459
          ? '/chat-bot/all'
          : '/not-active',
      subItems: [],
    },
  ];

  const [activeItems, setActiveItems] = useState(sidebarItems[0]);
  const [activeSubItems, setActiveSubItems] = useState(
    sidebarItems[0]?.subItems[0]
  );



  // const [spaceList, setSpaceList] = useState([])
  // const [selectedSpace, setSelectedSpace] = useState([])
  const [optionList, setOptionsList] = useState([]);
  const [currentSpace, setCurrentSpace] = useState([]);
  const [isModalUnboardingForSpace, setIsModalUnboardingForSpace] =
    useState(false);

  // Получение пространств
  const fetchGetSpace = () => {
    const abortController = new AbortController();

    const url = `${RavshanHttps}/api/space`;

    fetch(url, { headers, signal: abortController.signal })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        if (json) {
          setSpaceList(json);
        }
      })
      .catch((err) => console.error(err))
      .finally((e) => {
        // setLoad(false)
      });
    return abortController;
  };

  useEffect(() => {
    setOptionsList(
      spaceList?.map(({ id, name, author }) => {
        return {
          label: (
            <p className="option-space">
              <img
                src={client_id == author?.id ? user_circle_icon : user_icon}
              />
              {name}
            </p>
          ),
          label: name,
          value: id,
          author: author,
        };
      })
    );
  }, [spaceList]);

  useEffect(() => {
    if (!selectedSpace?.value && optionList.length < 1) {
      setIsModalUnboardingForSpace(true);
    }
  }, [optionList]);

  // Модальные окна
  const [isModalKnowledgeBase, setIsModalKnowledgeBase] = useState(false);
  const [isModalCreatingNewSpace, setIsModalCreatingNewSpace] = useState(false);

  const [isEditSpace, setIsEditSpace] = useState(false);

  // Устанавливаем язык
  // Достает из сессию сохраненные пункты основного меню
  useEffect(() => {
    localStorage.setItem('lang', 'ru');

    const activeItemStorage = sessionStorage.getItem('menu_isOpenItem');
    const activeSubItemStorage = sessionStorage.getItem('menu_isOpenSubItem');

    if (path['*'] == 'landing' || path['*'].split('/')[0] == 'settings') {
      setActiveItems(null);
      setActiveSubItems(null);
    } else {
      if (activeItemStorage || activeSubItemStorage) {
        setActiveItems(JSON.parse(activeItemStorage));
        setActiveSubItems(
          JSON.parse(activeSubItemStorage ? activeSubItemStorage : null)
        );
      } else {
        setActiveItems(sidebarItems[0]);
        setActiveSubItems(sidebarItems[0]?.subItems[0]);
      }
    }
  }, []);

  // В основном меню убираем активные пункты, если страница им не соответствует
  useEffect(() => {
    if (path['*'] == 'landing' || path['*'].split('/')[0] == 'settings') {
      setActiveItems(null);
      setActiveSubItems(null);
    }
  }, [path, navigate]);

  useEffect(() => {
    document.body.scrollTop = 0;
  }, [navigate]);

  // Записывает в сессию сохраненные пункты основного меню
  useEffect(() => {
    sessionStorage.setItem('menu_isOpenItem', JSON.stringify(activeItems));
    sessionStorage.setItem(
      'menu_isOpenSubItem',
      JSON.stringify(activeSubItems)
    );
    console.log(activeItems, activeSubItems);
  }, [activeItems, activeSubItems, path['*']]);

  useEffect(() => {
    fetchGetSpace();
  }, []);

  const setNavigation = (cookies) => {
    const isAccount = localStorage.getItem('not_one_account');
    const auth_token = cookies.get('auth');

    if (auth_token) {
      if (isAccount) {
        if (path['*'] === '' && activeContent !== 'Admin') {
          navigate(`/${analyticRoutes.analytics}`);
        }

        if (path['*'] === '' && activeContent === 'Admin') {
          navigate('/task-list-admin');
        }
      } else {
        //перенаправляет постоянно на лендинг, что мешает постоянно и препятствует корректной работе меню
        //ломает логику пунктов меню,
        //navigate("/analytics/date");
      }
    } else {
      navigate('../auth');
    }
  };

  useEffect(() => {
    sessionStorage.removeItem('taskProjectId');
    sessionStorage.removeItem('taskProjectName');
    const cookies = new Cookies();

    const userAgent = `userAgent: ${window.navigator.userAgent.replace(
      ',',
      ';'
    )}`;
    const userAgentData = window.navigator?.userAgentData;
    let mobile,
      platform = null;
    if (userAgentData) {
      mobile = userAgentData?.mobile;
      platform = userAgentData?.platform;
    } else {
      platform = userAgent?.platform;
    }
    mobile = `mobile: ${mobile}`;
    platform = `platform: ${platform}`;
    let { height, width } = window.screen;
    height = `height: ${height}`;
    width = `width: ${width}`;
    cookies.set(
      'client_info',
      `${userAgent}, ${mobile}, ${platform}, ${height}, ${width}`
    );

    setUpFetchProxy();

    const authLocal = localStorage.getItem('auth');
    const refreshLocal = localStorage.getItem('refresh');
    const acceptCookie = cookies.get(ACCEPT_COOKIE);

    if (acceptCookie) setShowCookie(false);

    if (authLocal || refreshLocal) {
      cookies.remove('auth', { path: '/' });
      cookies.remove('refresh', { path: '/' });

      let accessTokenExpiryDate = new Date();
      let refreshTokenExpiryDate = new Date();

      const { exp: refreshExpTime } = parseJwt(refreshLocal);

      accessTokenExpiryDate.setTime(
        accessTokenExpiryDate.getTime() + TOKEN_LIFETIME_MS
      );
      refreshTokenExpiryDate.setTime(
        refreshTokenExpiryDate.getTime() + refreshExpTime
      );

      cookies.set('auth', authLocal, {
        expires: accessTokenExpiryDate,
        path: '/',
      });
      cookies.set('refresh', refreshLocal, {
        expires: refreshTokenExpiryDate,
        path: '/',
      });

      localStorage.removeItem('auth');
      localStorage.removeItem('refresh');
      // window.location.reload();
    }

    const authToken = cookies.get('auth');
    const refreshToken = cookies.get('refresh');

    if (!authToken && refreshToken) {
      refresh({
        token: refreshToken,
        onError: () => navigate('../auth'),
        shouldStayOnPage: true,
      });
      return;
    }

    setNavigation(cookies);
  }, []);

  return (
    <>
      {showCookie && <Cookie setIsModal={(e) => setShowCookie(e)} />}

      {isModalKnowledgeBase && (
        <ModalKnowledgeBase
          sidebarItems={sidebarItems}
          isModal={isModalKnowledgeBase}
          setIsModal={(e) => setIsModalKnowledgeBase(e)}
        />
      )}

      {/* Создание нового пространства */}
      {isModalCreatingNewSpace && (
        <ModalCreatingNewSpace
          currentSpace={currentSpace}
          setCurrentSpace={(e) => setCurrentSpace(e)}
          setIsModal={(e) => setIsModalCreatingNewSpace(e)}
          isEdit={isEditSpace}
          setIsEditSpace={(e) => setIsEditSpace(e)}
        />
      )}

      {/* Добавление сотрудника */}
      {isModalAddEmployee && (
        <ModalAddEmployee
          setShowModal={setIsModalAddEmployee}
          spaceList={spaceList}
        />
      )}

      <main className="main">
        <ConnectionsContext.Provider value={{ editableConnection, dispatch }}>
          <div className="content">
            <NotificationContext.Provider
              value={{
                showNotificationBox,
                setShowNotificationBox,
              }}
            >
              {/* Шапка сайта */}
              <HeaderMain
                spaceList={spaceList}
                selectedSpace={selectedSpace}
                setSelectedSpace={(e) => setSelectedSpace(e)}
                activeContent={activeContent}
                setActiveItems={(e) => setActiveItems(e)}
                setActiveSubItems={(e) => setActiveSubItems(e)}
                setIsModalKnowledgeBase={(e) => setIsModalKnowledgeBase(e)}
                setIsModalCreatingNewSpace={(e) =>
                  setIsModalCreatingNewSpace(e)
                }
                setIsModalActive={(e) => setIsModalActive(e)}
                isModalUnboardingForSpace={isModalUnboardingForSpace}
                setIsModalUnboardingForSpace={(e) =>
                  setIsModalUnboardingForSpace(e)
                }
                setIsModalAddEmployee={(e) => {
                  setIsModalAddEmployee(e);
                }}
                isOpenMainMenu={isOpenMainMenu}
                setIsOpenMainMenu={(e) => setIsOpenMainMenu(e)}
              />

              {/* Основное меню */}
              <MainMenu
                menu={sidebarItems}
                activeItems={activeItems}
                setActiveItems={(e) => setActiveItems(e)}
                activeSubItems={activeSubItems}
                setActiveSubItems={(e) => setActiveSubItems(e)}
              />

              {isOpenMainMenu && (
                <MainMenuMobile
                  menu={sidebarItems}
                  activeItems={activeItems}
                  setActiveItems={(e) => setActiveItems(e)}
                  activeSubItems={activeSubItems}
                  setActiveSubItems={(e) => setActiveSubItems(e)}
                />
              )}

              {/* Основной контейнер */}
              <div className="main-container">
                <Routes>
                  {/* Лендинги */}
                  <Route
                    path="/landing"
                    element={
                      <Landing setIsModalActive={(e) => setIsModalActive(e)} />
                    }
                  />

                  <Route path="/chat" element={<ChatMessagePage />} />

                  {/* Настройки */}
                  <Route
                    path="/settings/*"
                    element={
                      <Settings
                        isModalCreatingNewSpace={isModalCreatingNewSpace}
                        setActiveItems={(e) => setActiveItems(e)}
                        setActiveSubItems={(e) => setActiveSubItems(e)}
                        isModalFeedback={isModalFeedback}
                        setIsModalFeedback={(e) => setIsModalFeedback(e)}
                        setConnectedModal={(e) => setConnectedModal(e)}
                        setIsModalActive={(e) => setIsModalActive(e)}
                        setIsModalCreatingNewSpace={(e) =>
                          setIsModalCreatingNewSpace(e)
                        }
                        setIsEditSpace={(e) => setIsEditSpace(e)}
                        isModalActive={isModalActive}
                        fetchGetSpace={(e) => fetchGetSpace(e)}
                        spaceList={spaceList}
                        currentSpace={currentSpace}
                        setCurrentSpace={(e) => setCurrentSpace(e)}
                      />
                    }
                  />

                  {/* Аналитика */}
                  <Route
                    path={`/${analyticRoutes.analytics}/*`}
                    element={
                      <AnalyticsPage
                        activeSubItems={activeSubItems}
                        route={analyticRoutes.analytics}
                      />
                    }
                  />
                  <Route
                    path={`/${analyticRoutes.advertising}/*`}
                    element={
                      <AnalyticsPage
                        activeSubItems={activeSubItems}
                        route={analyticRoutes.advertising}
                      />
                    }
                  />
                  <Route
                    path={`/${analyticRoutes.ordersAndSales}/*`}
                    element={
                      <AnalyticsPage
                        activeSubItems={activeSubItems}
                        route={analyticRoutes.ordersAndSales}
                      />
                    }
                  />
                  <Route
                    path={`/${analyticRoutes.prices}/*`}
                    element={
                      <AnalyticsPage
                        activeSubItems={activeSubItems}
                        route={analyticRoutes.prices}
                      />
                    }
                  />
                  <Route
                    path={`/${analyticRoutes.stock}/*`}
                    element={
                      <AnalyticsPage
                        activeSubItems={activeSubItems}
                        route={analyticRoutes.stock}
                      />
                    }
                  />
                  <Route
                    path={`/${analyticRoutes.returnAndCancellations}/*`}
                    element={
                      <AnalyticsPage
                        activeSubItems={activeSubItems}
                        route={analyticRoutes.returnAndCancellations}
                      />
                    }
                  />

                  <Route
                    path="cross-analytics/*"
                    element={<CrossAnalytics />}
                  />

                  {/* Товары */}
                  <Route path="/add-product/*" element={<AddProductVer3 />} />
                  <Route path="/edit-product/*" element={<EditProduct />} />
                  <Route
                    path="/analysis_mp_product/*"
                    element={
                      <ProductCardExtended
                        content={'mp_product'}
                        title={'Анализ по '}
                      />
                    }
                  />
                  <Route path="/products_list" element={<ProductsList />} />

                  {/* Копия Мастер Товаров */}
                  <Route
                    path="/content-transfer/*"
                    element={
                      <ProductsListMasterProduct
                        isModalActive={isModalActive}
                        setIsModalActive={(e) => setIsModalActive(e)}
                        transfer={true}
                      />
                    }
                  />
                  <Route
                    path="/add-description"
                    element={<DescriptionImprovementLanding />}
                  />
                  <Route
                    path="set-price-and-margin"
                    element={<SetPriceAndMargin />}
                  />
                  <Route
                    path="/set-remaining-goods"
                    element={<SetRemainingGoods />}
                  />
                  <Route
                    path="/mass-improvement/*"
                    element={<MassImprovement />}
                  />

                  <Route
                    path="/setting-cost-price/*"
                    element={<SettingCostPrice />}
                  />

                  <Route path="/checking-OVH/*" element={<CheckingOVH />} />

                  <Route
                    path="/view-competitors/*"
                    element={<ViewCompetitors />}
                  />

                  <Route
                    path="/add-competitors/*"
                    element={<AddCompetitors />}
                  />

                  <Route
                    path="/summary-matrices"
                    element={<SummaryMatrices />}
                  />

                  {/* Мастер товары */}
                  <Route
                    path="/products_list_master_product/*"
                    element={
                      <ProductsListMasterProduct
                        isModalActive={isModalActive}
                        setIsModalActive={(e) => setIsModalActive(e)}
                      />
                    }
                  />
                  <Route
                    path="set-price-and-margin-master-product/*"
                    element={<SetPriceAndMarginMasterProduct />}
                  />
                  <Route
                    path="/set-remaining-goods-master-product/*"
                    element={<SetRemainingGoodMasterProduct />}
                  />
                  <Route
                    path="/analysis_sku/*"
                    element={
                      <ProductCardExtended
                        content="master-product"
                        title="Анализ по "
                      />
                    }
                  />
                  <Route
                    path="/calculation-delivery/*"
                    element={<CalculationDelivery />}
                  />

                  {/* Конкуренты */}
                  <Route
                    path="/analysis-platform/*"
                    element={<AnalysisPlatform />}
                  />

                  {/* <Route
                    path="analysis-by-SKU/*"
                    element={
                      <ProductCardExtended content="" title="Анализ по " />
                    }
                  /> */}
                  <Route
                    path="analysis-by-SKU/*"
                    element={
                      window.location.pathname.includes('search') ? (
                        <SkuSearch />
                      ) : (
                        <ProductCardExtended content="" title="Анализ по " />
                      )
                    }
                  />
                  <Route
                    path="analysis-category/*"
                    element={<AnalysisCategory />}
                  />
                  <Route path="niche-search/*" element={<NicheSelection />} />

                  <Route
                    path="analysis-by-brand/*"
                    element={<AnalysisPlatformByBrand />}
                  />
                  <Route
                    path="analysis-by-sellers/*"
                    element={<AnalysisPlatformBySellers />}
                  />
                  <Route
                    path="analysis-by-sale-type/*"
                    element={<AnalysisBySaleType />}
                  />

                  {/* Инструменты */}
                  <Route
                    path="/price-management/*"
                    element={<PriceManagement />}
                  />

                  {/* Заказы */}
                  <Route
                    path="/order-management/*"
                    element={<OrderManagement />}
                  />
                  <Route path="/deliveries/*" element={<Deliveries />} />

                  {/* Маркетинг */}
                  <Route
                    path="/marketing-place/*"
                    element={<MarketingOzon />}
                  />
                  <Route
                    path="/out-marketing-place/*"
                    element={<MarketingOzon />}
                  />

                  <Route path="/act1" element={<Invoice />} />

                  <Route path="/act2" element={<ActService />} />

                  <Route path="/act3" element={<ActVerification />} />

                  <Route path="/act4" element={<InvoiceWithSignature />} />

                  <Route path="/act5" element={<Invoice />} />

                  {/* Акции */}
                  <Route
                    path="/marketing-place/promotions/*"
                    element={<PromotionsPage route="promotions" />}
                  />

                  {/* Ключевые слова */}
                  {/* <Route path='analysis-by-SKU/*' element={<AnalysisBySKU />} /> */}
                  <Route
                    path="analysis-search-query/*"
                    element={<AnalysisSearchQuery />}
                  />

                  <Route path="/customization" element={<Customization />} />

                  <Route
                    path="keywords/analysis-by-SKU/*"
                    element={
                      <ProductCardExtended
                        content="keyword"
                        title="Анализ по "
                      />
                    }
                  />
                  <Route
                    path="analysis-by-category/*"
                    element={<AnalysisByCategory />}
                  />
                  <Route
                    path="/keywords/analysis-by-brand/*"
                    element={<AnalysisByBrand />}
                  />
                  <Route
                    path="/keywords/analysis-by-sellers/*"
                    element={<AnalysisBySellers />}
                  />
                  <Route path="top-keywords" element={<TopKeywords />} />

                  {/* Отзывы и вопросы */}
                  <Route
                    path="work-with-review/*"
                    element={
                      <WorkWithReviews
                        openConnectionModalById={(e) =>
                          openConnectionModalById(e)
                        }
                        isModalActive={isModalActive}
                      />
                    }
                  />
                  <Route
                    path="analitics-review/*"
                    element={
                      <AnaliticsReview
                        openConnectionModalById={(e) =>
                          openConnectionModalById(e)
                        }
                        isModalActive={isModalActive}
                        setIsModalKnowledgeBase={setIsModalKnowledgeBase}
                      />
                    }
                  />
                  <Route
                    path="automation-of-responses/*"
                    element={
                      <AutomationResponses
                        openConnectionModalById={(e) =>
                          openConnectionModalById(e)
                        }
                      />
                    }
                  />

                  {/* Социальные сети */}
                  <Route path="/social-network/*" element={<SocialNetwork />} />

                  {/* Задачи */}
                  <Route path="/tasks/*" element={<TaskPage />} />

                  {/* Календарь */}
                  <Route path="/calendar/*" element={<Calendar />} />

                  {/* CRM */}
                  <Route path="/crm/*" element={<Crm />} />

                  <Route path="/chat-bot/*" element={<ChatBot />} />

                  {/* Wiki */}
                  {/* <Route
                    path="/*"
                    element={<Main
                      activeLang={activeLang} pathBread={pathBread}
                      currentArticle={currentArticle}
                      setCurrentArticle={setCurrentArticle}
                      pathsWebName={pathsWebName}
                      setPathsWebName={e => setPathsWebName(e)}
                      description={description}
                      setDescription={e => setDescription(e)}
                      setPaths={e => setPaths(e)}
                      paths={paths}
                      title={title}
                      setTitle={e => setTitle(e)}
                    />}
                  /> */}

                  <Route
                    path="wiki/*"
                    element={
                      <Wiki
                        activeLang={activeLang}
                        pathBread={pathBread}
                        currentArticle={currentArticle}
                        setCurrentArticle={setCurrentArticle}
                        pathsWebName={pathsWebName}
                        setPathsWebName={(e) => setPathsWebName(e)}
                        description={description}
                        setDescription={(e) => setDescription(e)}
                        setPaths={(e) => setPaths(e)}
                        paths={paths}
                        title={title}
                        setTitle={(e) => setTitle(e)}
                      />
                    }
                  />

                  {/* Страница в разработке */}
                  <Route path="not-active" element={<NotActive />} />

                  {/* 404 */}
                  <Route path="/*" element={<Page404 />} />

                  {/* Подсказочник */}

                  <Route path="/hint/*" element={<Hint />} />

                  <Route
                    path="/cross-analytics/*"
                    element={<CrossAnalytics />}
                  />

                  <Route path="/reviews/*" element={<Reviews />} />

                  <Route
                    path="/tools/*"
                    element={
                      <Tools setIsModalActive={(e) => setIsModalActive(e)} />
                    }
                  />

                  <Route path="/service/*" element={<NotificationsVer2 />} />

                  <Route path="/privacy-policy/*" element={<PrivacyPolicy />} />

                  <Route
                    path="/task-list-admin/*"
                    element={<TaskListAdmin />}
                  />

                  <Route
                    path="/products-list-admin/*"
                    element={<ProductsListAdmin />}
                  />

                  <Route
                    path="/product-card"
                    element={<ProductCardExtended />}
                  />
                </Routes>
              </div>
            </NotificationContext.Provider>
          </div>
          {isModalActive ? (
            <Modal
              optionList={optionList}
              selectedSpace={selectedSpace}
              setSelectedSpace={(e) => setSelectedSpace(e)}
              setIsModalFeedback={(e) => setIsModalFeedback(e)}
              setIsModalActive={(e) => setIsModalActive(e)}
              showAddConnectionModal={connectedModal}
              setConnectedModal={(e) => setConnectedModal(e)}
              connectionIds={connectionIds}
              setConnectionIds={(e) => setConnectionIds(e)}
            />
          ) : null}
        </ConnectionsContext.Provider>
      </main>
      {activeContent != 'Admin' ? <Footer /> : null}
    </>
  );
}

export const NotificationContext = createContext(false);
export const ConnectionsContext = createContext(false);

export default App;
