import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TextareaAutosize } from '@mui/material';
import { FrequentAnswers } from '../FrequentAnswers/FrequentAnswers';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import smile_icon from './img/smile_icon.svg'
import attach_icon from './img/attach_icon.svg'
import "./SendMessage.scss"

const varaibles = {
    '@brand': {
        ui: 'Бренд',
        value: '@@Brand'
    },
    '@category': {
        ui: 'Категория',
        value: '@@Category'
    },
    '@seller': {
        ui: 'Продавец',
        value: '@@Seller'
    },
    '@product': {
        ui: 'Товар',
        value: '@@Product'
    },
    '@picture': {
        ui: 'Товар',
        value: '@@Product'
    },
    '@name': {
        ui: 'Имя клиента',
        value: '@@Name'
    },
}

const SendMessage = ({
    disabled,
    frequentAnswers = false,
    message,
    value,
    onChange,
    onSendAction,
    isEdit = false,
    setIsEdit = false,
    actionBtnText,
    placeholder
}) => {

    const [isVarModal, setIsVarModal] = useState(false)
    const [currentVar, setCurrentVar] = useState('')
    const [currentVarInd, setCurrentVarInd] = useState(-1)

    useEffect(() => {
        if(isEdit) {
            onChange(message)
        } else {
            onChange('')
        }
    }, [isEdit]);

    return (
        <div className='send-message_container'>

            <div className='send-message'>
                {/* <div className='send-message__btn-group'>
                    <button className='btn-smile'>
                        <img src={smile_icon} />
                    </button>
                    <button className='btn-attach'>
                        <img src={attach_icon} />
                        <input className='input-file' type='file' />
                    </button>
                </div> */}

                
                <div className='field-and-changed-message'>
                    {isEdit && <div className='changed-message'>
                        <span>Редактирование сообщение</span>
                        <button className='btn-close' onClick={(e) => setIsEdit(false)}></button>
                    </div>}
                    {
                        isVarModal && 
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                {
                                    Object.keys(varaibles)
                                        ?.filter(key => key?.toLocaleLowerCase()?.indexOf(currentVar?.replace(' ', '')?.toLocaleLowerCase()) !== -1)
                                        ?.map(el => {
                                            return <span 
                                                onClick={e => {
                                                    setIsVarModal(false)
                                                    const isSpace = currentVar?.indexOf(' ') !== -1
                                                    onChange(value.replace(currentVar, isSpace ? ` ${varaibles[el]?.value}` : varaibles[el]?.value))
                                                }}
                                                style={{cursor: 'pointer'}}
                                            >
                                                {el}: {varaibles[el]?.ui}
                                            </span>
                                        })
                                }
                            </div>
                    }

                    <TextareaAutosize
                        height='40px'
                        style={{ backgroundColor: (disabled && !isEdit) ? '#e0e0e0' : '', overflow: 'auto' }}
                        placeholder={placeholder ? placeholder : 'Напишите Ваш ответ здесь'}
                        value={value}
                        disabled={disabled && !isEdit}
                        onChange={e => {
                            const text = e.target.value
                            const match = text.match(/(^\@[a-zA-Z]+|[^@]\@[a-zA-Z]+|@$)/)
                            
                            if(match?.[0]){
                                const varaible = match?.[0]
                                const index = match?.index
                                setIsVarModal(true)
                                setCurrentVar(varaible)
                                setCurrentVarInd(index)
                            } else {
                                setIsVarModal(false)
                            }
                            onChange(text)
                        }}
                    />
                </div>

                <ButtonBasic
                    green
                    disabled={disabled && !isEdit}
                    minWidth='110px'
                    width='110px'
                    maxWidth='110px'
                    text={actionBtnText ? actionBtnText : 'Отправить' }
                    onClick={e => onSendAction()}
                />
            </div>

            {/* {frequentAnswers && <FrequentAnswers />} */}
        </div>

    )
}

export { SendMessage }