import { WrapperLineChart } from '../../../charts/WrapperCharts/WrapperLineChart/WrapperLineChart';
import { OlegHttps } from '../../../fetchUrls';
import "./CrossAnalyticsCharts.scss"


const CrossAnalyticsCharts = ({
    body_obj
}) => {

    return (
        <div className='cross-analyticsc-charts'>
            <div className='cross-analytics__charts-group'>
                <WrapperLineChart
                    url={`${OlegHttps}/api/v1/analytics/cross/graph/orders`}
                    body={body_obj()}
                    nameChart="Заказы"
                />

                <WrapperLineChart
                    url={`${OlegHttps}/api/v1/analytics/cross/graph/revenue`}
                    body={body_obj()}
                    nameChart="Выручка"
                />

                <WrapperLineChart
                    url={`${OlegHttps}/api/v1/analytics/cross/graph/AIV`}
                    body={body_obj()}
                    nameChart="AIV"
                />
            </div>

            <div className='cross-analytics__charts-group'>
                <WrapperLineChart
                    url={`${OlegHttps}/api/v1/analytics/cross/graph/expense`}
                    body={body_obj()}
                    nameChart="Затраты"
                />

                <WrapperLineChart
                    url={`${OlegHttps}/api/v1/analytics/cross/graph/ad_revenue`}
                    body={body_obj()}
                    nameChart="Выручка с товаров в рекламе"
                />

                <WrapperLineChart
                    url={`${OlegHttps}/api/v1/analytics/cross/graph/total_DRR`}
                    body={body_obj()}
                    nameChart="ДРР общий"
                />
            </div>

            <div className='cross-analytics__charts-group'>
                <WrapperLineChart
                    url={`${OlegHttps}/api/v1/analytics/cross/graph/ad_DRR`}
                    body={body_obj()}
                    nameChart="ДРР реклама"
                />

                <WrapperLineChart
                    url={`${OlegHttps}/api/v1/analytics/cross/graph/ad_revenue_share`}
                    body={body_obj()}
                    nameChart="Процент выручки с товаров в рекламе"
                />

                <WrapperLineChart
                    url={`${OlegHttps}/api/v1/analytics/cross/graph/views`}
                    body={body_obj()}
                    nameChart="Показы"
                />
            </div>

            <div className='cross-analytics__charts-group'>
            
                <WrapperLineChart
                    url={`${OlegHttps}/api/v1/analytics/cross/graph/to_cart`}
                    body={body_obj()}
                    nameChart="Корзины"
                />

                <WrapperLineChart
                    url={`${OlegHttps}/api/v1/analytics/cross/graph/CR_views_to_cart`}
                    body={body_obj()}
                    nameChart="CR показы в корзины"
                />
            </div>

            <div className='cross-analytics__charts-group'>
            
                <WrapperLineChart
                    url={`${OlegHttps}/api/v1/analytics/cross/graph/CR_to_cart_orders`}
                    body={body_obj()}
                    nameChart="CR корзины в заказы"
                />

                <WrapperLineChart
                    url={`${OlegHttps}/api/v1/analytics/cross/graph/CR_views_orders`}
                    body={body_obj()}
                    nameChart="CR показы в заказы"
                />
            </div>
        </div>
    )
}

export { CrossAnalyticsCharts }