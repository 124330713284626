import customHeaders, { getSpace } from '../../common/headers';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
import { ProductData } from '../ProductData/ProductData';
import { CarouselSlick } from '../CarouselSlick/CarouselSlick';
import { ModalCardCreated } from '../Modal/ModalCardCreated/ModalCardCreated';
import { CardVariantProduct } from '../CardVariantProduct/CardVariantProduct';
import { ModalUnsavedProduct } from '../Modal/ModalUnsavedProduct/ModalUnsavedProduct';
import { ModalProductPreview } from '../Modal/ModalProductPreview/ModalProductPreview';
import { useParams, useLocation, useSearchParams, useNavigate } from 'react-router-dom'
import { KonstantinCreateProductHttps } from '../../fetchUrls';
import { useState, useEffect, useReducer, createContext, useCallback } from 'react'
import { MoonLoader } from 'react-spinners';
import useGeneralStore from '../../store/general';
import { SkeletonImg } from './SkeletonImg';
import "./EditProduct.scss"

// information state
export const informationActionTypes = Object.seal({
    requirements: 'requirements',
    error: 'error',
    warning: 'warning',
    advice: 'advice',
    showSuccessModal: 'show success modal',
    showUnsavedChangesModal: 'show unsaved changes modal',
    hideUnsavedChangesModal: 'hide unsaved changes modal',
    reset: 'reset'
})

export const InformationContext = createContext()

export const dataActionTypes = Object.seal({
    createProduct: 'create product',
    assignAttributes: 'assign attributes',
    submitImage: 'submit image',
    submitVideo: 'submit video',
    done: 'done'
})

export const DataContext = createContext({})

const EditProduct = () => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }

    const navigate = useNavigate()
    const productId = sessionStorage.getItem('productId')

    const location = useLocation()
    const activeTab = useParams()['*']
    const [searchParams, setSearchParams] = useSearchParams()
    const [initialReset, setInitialReset] = useState(true)
    const [isDropMenu, setIstDropMenu] = useState(false)
    const [isCopy, setIsCopy] = useState(false)
    const [pageValue, setPageValue] = useState(`required`)
    const [selectedCategories, setSelectedCategories] = useState([])
    const [currentProductId, setCurrentProductId] = useState(productId && productId)
    const [currName, setCurrName] = useState('')
    const [currOfferId, setCurrOfferId] = useState('')
    const [errField, setErrField] = useState(false)



    const [isModalUnsavedProduct, setIsModalUnsavedProduct] = useState(false)
    const [isModalCardCreated, setIsModalCardCreated] = useState(false)
    const [isModalPreview, setIsModalPreview] = useState(false)
    const [previewPhoto, setPreviewPhoto] = useState(null)
    const [productIds, setProductIds] = useState([])
    const [productTemporary, setProductTemporary] = useState({})
    const [isGeneralloading, setIsGeneralloading] = useState(true)
    const [isRightPanelLoading, setIsRightPanelLoading] = useState(true)


    const [arrImg, setArrImg] = useState([])
    const [arrImgLoad, setArrImgLoad] = useState([])
    const [initialLoad, setInitialLoad] = useState(true)



    const dataReducer = (state, action) => {
        switch (action.type) {
            case dataActionTypes.createProduct: {
                const newState = { ...state, product: action.payload }
                return newState
            }
            case dataActionTypes.assignAttributes: {
                const newState = { ...state, attributes: action.payload }
                return newState
            }
            case dataActionTypes.submitImage: {
                const newState = { ...state, images: action.payload }
                return newState
            }
            case dataActionTypes.submitVideo: {
                const newState = { ...state, videos: action.payload }
                return newState
            }
            case dataActionTypes.done: {
                const newState = { ...state, done: true }
                return newState
            }
            default:
                return {}
        }
    }

    const [dataState, dispatchData] = useReducer(dataReducer, {})

    const createProduct = (create = false, product_id = productId) => {
        if (product_id) {
            const url = `${KonstantinCreateProductHttps}/master_products${create ? `?product_id=${product_id}` : ''}`

            const body = JSON.stringify({
                product_id: productId
            })

            const options = create ?
                { method: 'GET', headers } :
                { body, method: 'PUT', headers }

            fetch(url, options)
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    }
                    else {
                        setErrField(true)
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    sessionStorage.removeItem('productId')
                    setCurrentProductId(null)
                    setProductTemporary([])
                    setSelectedCategories([])
                    setArrImg([])

                    if (create) {
                        setCurrentProductId(product_id)
                    } else {
                        fetchProductIds()
                        setIsModalCardCreated(true)
                    }
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => setIsGeneralloading(false))
        }
    }

    const fetchProductIds = (product_id = productId) => {
        const url = `${KonstantinCreateProductHttps}/master_products/temporary?used_to=create`
        fetch(url, { method: 'GET', headers })
            .then(async res => {
                if (res.ok) {
                    return res.json()
                }
                else {
                    const text = await res.json()
                    throw new Error(text?.detail)
                }
            })
            .then(json => {
                if (!product_id) {
                    const prod = json?.[0]
                    const prodId = prod?.product_id
                    setCurrName(prod?.name)
                    setCurrOfferId(prod?.offer_id)

                    sessionStorage.setItem('productId', prodId)
                    setCurrentProductId(prodId)
                }
                setProductIds(json)
            })
            .catch(err => console.error(err))
    }

    const deleteProduct = (product_id) => {
        if (product_id) {
            const url = `${KonstantinCreateProductHttps}/master_products/temporary/${product_id}?used_to=create`

            fetch(url, { method: 'DELETE', headers })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    }
                    else {
                        const text = await res.json()
                        throw new Error(text?.detail)
                    }
                })
                .then(() => {
                    sessionStorage.removeItem('productId')
                    setCurrentProductId(null)
                    setProductTemporary([])
                    setSelectedCategories([])
                    fetchProductIds(false)
                })
                .catch(err => console.error(err))
        }
    }

    const fetchProductAttr = (product_id) => {
        if (product_id && product_id !== 'undefined') {
            setIsRightPanelLoading(true)
            // setProductTemporary([])
            // setArrImg([])
            const url = `${KonstantinCreateProductHttps}/master_products/temporary/${product_id}?used_to=update`


            fetch(url, { method: 'GET', headers })
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    }
                    else {
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    const { category_name_list } = json
                    const categories = category_name_list.map((c, ind) => ({ label: c.category_name, parent_id: ind === 0 ? '' : category_name_list[ind - 1]?.category_id, value: { id: c.category_id, hasChildren: c.has_children } }))
                    setSelectedCategories(categories)
                    setProductTemporary(json)
                })
                .catch(err => console.error(err))
                .finally(() => {
                    setIsRightPanelLoading(false)
                    setIsGeneralloading(false)
                })
        }
    }

    const copyHandler = (product_id) => {
        window.open(`/add-product/required?isEdit=true&copied=true&product_id=${product_id}`, '_blank')
    }

    const copyProduct = (product_id) => {
        if (product_id) {
            const url = `${KonstantinCreateProductHttps}/master_products?product_id=${product_id}&used_to=create`

            const options = { method: 'GET', headers }

            fetch(url, options)
                .then(async res => {
                    if (res.ok) {
                        return res.json()
                    }
                    else {
                        setErrField(true)
                        const err = await res.json()
                        throw new Error(JSON.stringify(err))
                    }
                })
                .then(json => {
                    const { category_name_list } = json
                    const categories = category_name_list.map((c, ind) => ({ label: c.category_name, parent_id: ind === 0 ? '' : category_name_list[ind - 1]?.category_id, value: { id: c.category_id, hasChildren: c.has_children } }))
                    setSelectedCategories(categories)
                    setProductTemporary(json)
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }

    useEffect(() => {
        const onUnload = e => {
            if (Object.keys(dataState.product).length && !dataState.done) {
                e.preventDefault()
                return (e.returnValue = "")
            }
        }

        window.addEventListener('beforeunload', onUnload)

        return () => {
            window.removeEventListener('beforeunload', onUnload)
        }
    }, [location])

    useEffect(() => {
        sessionStorage.removeItem('productId')
        setCurrentProductId('')
        setInitialReset(false)
    }, [])


    useEffect(() => {
        const product_id = searchParams.get('product_id')
        const isCopy = searchParams.get('copied')

        if (isCopy) {
            setIsCopy(isCopy)
        }

        if (product_id && product_id !== 'undefined') {
            createProduct(true, product_id)
        } else {
            if (productId) {
                createProduct(true, productId)
            }
        }
    }, [activeTab])


    useEffect(() => {
        if (currentProductId) {
            if (isCopy) {
                copyProduct(currentProductId)
                sessionStorage.setItem('productId', currentProductId)
            } else {
                fetchProductAttr(currentProductId)
                sessionStorage.setItem('productId', currentProductId)
            }
        }
    }, [currentProductId, activeTab, pageValue])

    useEffect(() => {
        setInitialLoad(true)
    }, [currentProductId]);

    useEffect(() => {
        setErrField(false)
        setProductIds(prev => prev?.map((el) => {
            if (el?.product_id === currentProductId) {
                return { ...el, name: currName, offer_id: currOfferId }
            }
            return el
        }))
    }, [currName, currOfferId])


    const changeName = useCallback((value) => setCurrName(value), [])
    const changeOffer = useCallback((value) => setCurrOfferId(value), [])


    return (
        <>
            {/* Превью товара */}
            {isModalPreview && < ModalProductPreview
                selectedCategories={selectedCategories}
                setIsModal={(e) => setIsModalPreview(e)}
                currentProductId={currentProductId}
                isEdit
            />}

            {/* Модальное окно бкз логики "Выйти без сохраниния" */}
            {isModalUnsavedProduct && <ModalUnsavedProduct setIsModal={(e) => setIsModalUnsavedProduct(e)} />}

            {/* Модальное окно бкз логики "Добавление карточки" */}
            {
                isModalCardCreated && <ModalCardCreated
                    setIsModal={(e) => setIsModalCardCreated(e)}
                    fetchProductIds={e => fetchProductIds(e)}
                />
            }

            {
                false ?
                    <div className='add-product-ver3__loader-box'>
                        <MoonLoader
                            color="#7b7b7b"
                            speedMultiplier={0.5}
                            size={65}
                        />
                    </div>

                    :
                    <DataContext.Provider value={{ dataState, dispatchData }}>
                        <div className='add-product-ver3'
                            onClick={(e) => {
                                let el = e.target
                                if (!el.closest('.context-more')) {
                                    setIstDropMenu(false)
                                }
                            }}
                        >
                            <div className='add-product-ver3__header'>
                                <h2 className='title'>{'Редактирование карточки'}</h2>
                                <div className='btn-group'>
                                    <ButtonBasic
                                        lilac
                                        width='92px'
                                        size='56px'
                                        text='Отмена'
                                        disabled={isGeneralloading || isRightPanelLoading}
                                        onClick={e => navigate('/products_list_master_product/all')}
                                    />

                                    <ButtonBasic
                                        violet
                                        width='130px'
                                        size='56px'
                                        text={'Сохранить'}
                                        disabled={isGeneralloading || isRightPanelLoading}
                                        onClick={(e) => createProduct()}
                                    />
                                </div>
                            </div>

                            <div className='add-product-ver3__content'>
                                <ProductData
                                    isEdit={true}
                                    arrImg={arrImg}
                                    setArrImgLoad={(e) => setArrImgLoad(e)}
                                    setArrImg={(e) => setArrImg(e)}
                                    setPreviewPhoto={(e) => setPreviewPhoto(e)}
                                    selectedCategories={selectedCategories}
                                    setSelectedCategories={(e) => setSelectedCategories(e)}
                                    setIsModalPreview={(e) => setIsModalPreview(e)}
                                    currentProductId={currentProductId}
                                    setCurrentProductId={e => setCurrentProductId(e)}
                                    fetchProductIds={e => fetchProductIds(e)}
                                    productTemporary={productTemporary}
                                    setProductTemporary={e => setProductTemporary(e)}
                                    copyProduct={e => copyHandler(e)}
                                    setName={e => changeName(e)}
                                    name={currName}
                                    setCurrOfferId={e => changeOffer(e)}
                                    errField={errField}
                                    setErrField={(e) => setErrField(e)}
                                    searchParams={searchParams}
                                    pageValue={pageValue}
                                    setPageValue={setPageValue}
                                    isGeneralloading={isGeneralloading}
                                    isRightPanelLoading={isRightPanelLoading}
                                    setIsGeneralloading={setIsGeneralloading}
                                    fetchProductAttr={fetchProductAttr}
                                    setIsRightPanelLoading={setIsRightPanelLoading}
                                    initialLoad={initialLoad}
                                    setInitialLoad={setInitialLoad}
                                />

                                <div className='side-panel'>
                                    {
                                        isGeneralloading ?
                                            <SkeletonImg />
                                            :
                                            pageValue != 'view-product-card' &&
                                            <div className='side-panel__img-box'>
                                                <CarouselSlick imgArr={arrImg} />
                                            </div>
                                    }


                                    <div className='side-panel__content'>
                                        {
                                            productIds?.map(el => {
                                                return <CardVariantProduct
                                                    {...el}
                                                    deleteProduct={e => deleteProduct(e)}
                                                    currentProductId={currentProductId}
                                                    setCurrentProductId={({ name, product_id }) => {
                                                        setCurrentProductId(product_id)
                                                        setCurrName(name)
                                                    }}
                                                    setIsGeneralloading={setIsGeneralloading}
                                                />
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </DataContext.Provider>
            }
        </>

    )
}

export { EditProduct }