import customHeaders, { getSpace } from '../../../common/headers';
import close_icon from './img/close.svg';
import { useState, useEffect } from 'react';
import { ButtonBasic, TableBox } from '@lk-gtcom/ecomlab-components';
import { InputDinamycPlaceholder } from '@lk-gtcom/ecomlab-components';
import import_icon from './img/bx-import.svg';
import refresh_icon from './img/bx-refresh.svg';
import './ModalAddMpSimple.scss';
import useGeneralStore from '../../../store/general';
import { KonstantinCreateProductHttps } from '../../../fetchUrls';

const ModalAddMpSimple = ({
  setIsModal,
  setIsModalAddLink,
  setIsModalAddMassLink,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const [name, setName] = useState('');
  const [offerId, setOfferId] = useState('');

  const [nameError, setNameError] = useState('');
  const [offerIdError, setOfferIdError] = useState('');

  const [isCreate, setIsCreate] = useState(false);
  const [prodId, setProdId] = useState(null);

  const offerIdHint =
    'Разрешены английские и русские буквы (кроме ё), цифры, и символы .,/()[]-=_ (без пробелов). Максимальная длина — 50 знаков';

  const onInputChange = (setError, set) => {
    setError('');
    set();
  };

  const fetchNewMpSimple = () => {
    const url = `${KonstantinCreateProductHttps}/master_products/simple`;

    const body = JSON.stringify({
      offer_id: offerId,
      name: name,
    });

    fetch(url, {
      method: 'POST',
      headers,
      body,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        let id = json.product_id;
        fetchMpProdSimple(id);
      })
      .catch((e) => console.error(e))
      .finally(() => {});
  };

  const fetchChangeMpSimple = () => {
    const url = `${KonstantinCreateProductHttps}/master_products/simple/${prodId}`;

    const body = JSON.stringify({
      name: name,
    });

    fetch(url, {
      method: 'PUT',
      headers,
      body,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        let id = json.product_id;
        fetchMpProdSimple(id);
      })
      .catch((e) => console.error(e))
      .finally(() => {});
  };

  const fetchMpProdSimple = (product_id) => {
    const url = `${KonstantinCreateProductHttps}/master_products/simple/${product_id}`;

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setProdId(json?.product_id);
      })
      .catch((e) => console.error(e))
      .finally(() => {});
  };

  return (
    <div
      className="blackout"
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setIsModal(false);
        }
      }}
    >
      <div className="modal-add-mp-simple">
        <header className="modal-add-mp-simple__header">
          <h2 className="modal-add-mp-simple__title">Создать товар</h2>
          <button
            className="modal-add-mp-simple__btn-close"
            onClick={(e) => setIsModal(false)}
          >
            <img src={close_icon} alt="Закрыть" />
          </button>
        </header>

        <div className="modal-add-mp-simple__header">
          <InputDinamycPlaceholder
            placeholder="Название"
            maxLength={150}
            onChange={(e) => onInputChange(setNameError, () => setName(e))}
            type="text"
            textErr={nameError}
            err={!!nameError}
            autoComplete={false}
            required
            size={'s'}
          />
        </div>

        <div className="modal-add-mp-simple__input-box">
          <InputDinamycPlaceholder
            classname="autorization__input-field"
            placeholder="Артикул"
            onChange={(e) =>
              onInputChange(setOfferIdError, () => setOfferId(e))
            }
            type="text"
            maxLength={50}
            textErr={offerIdError}
            err={!!offerIdError}
            infoHeader="Артикул товара в магазине (нельзя изменить после создания)"
            info={offerIdHint}
            autoComplete={false}
            required
            size={'s'}
          />
        </div>
        {isCreate && (
          <div className="modal-add-mp-simple__content">
            <TableBox
              child={
                <>
                  <ButtonBasic
                    green
                    text={'Добавить ссылки'}
                    size="32px"
                    onClick={(e) => setIsModalAddLink(true)}
                  />

                  <ButtonBasic
                    whiteGreen
                    text={
                      <div className="modal-add-mp-simple__btn">
                        <img src={import_icon} />
                        Импорт ссылок
                      </div>
                    }
                    onClick={(e) => setIsModalAddMassLink(true)}
                    size="32px"
                  />

                  <ButtonBasic
                    whiteGreen
                    minWidth="auto"
                    width="auto"
                    text={
                      <div className="modal-add-mp-simple__btn">
                        <img src={refresh_icon} />
                      </div>
                    }
                    size="32px"
                  />
                </>
              }
            />
          </div>
        )}

        <div className="modal-add-mp-simple__btn-box">
          <ButtonBasic
            green
            width="100%"
            text={!isCreate ? 'Создать' : 'Сохранить'}
            onClick={(e) => {
              if (!isCreate) {
                fetchNewMpSimple();
                setName('');
                setOfferId('');
                setIsCreate(true);
              } else {
                fetchChangeMpSimple();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export { ModalAddMpSimple };
