import customHeaders, { getSpace } from '../../../common/headers';
import { useEffect, useState } from 'react';
import { Tabs, Checkbox, ButtonBasic } from '@lk-gtcom/ecomlab-components';
import spakler_icon from './img/sparkler_icon.svg';
import EmojiPicker from 'emoji-picker-react';
import useGeneralStore from '../../../store/general';
import './ModalProductImprovement.scss';

const btn_tab = [
  {
    label: 'Эмоджи',
    value: 'emoji',
  },
  {
    label: 'Параметры',
    value: 'parameters',
  },
];

const ParameterItem = ({
  title,
  description,
  selectedAttributes,
  setSelectedAttributes,
}) => {
  return (
    <label className="parameter-item" key={title + description}>
      <Checkbox
        value={selectedAttributes.includes(title)}
        disabled={
          selectedAttributes?.length === 3 &&
          !selectedAttributes.includes(title)
        }
        onChange={(e) => {
          setSelectedAttributes((prev) => {
            if (prev?.indexOf(title) !== -1) {
              return prev.filter((el) => el !== title);
            }
            if (prev?.length < 3) {
              return [...prev, title];
            }
            if (prev?.length >= 2) {
              return [...prev];
            }
          });
        }}
      />
      <div className="parameter-item__content">
        <h3 className="title">{title}</h3>
        <p className="text">{description}</p>
      </div>
    </label>
  );
};

const ModalProductImprovement = ({
  setIsModal,
  page,
  productId,
  productList,
  isAll,
  setIsStatus,
  setStatusKey,
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const [selectedItem, setSelectedItems] = useState([]);
  const [pageValue, setPageValue] = useState(
    page == 'emoji' ? btn_tab[0].value : btn_tab[1].value
  );

  const [categoryName, setCategoryName] = useState([]);
  const [parametrItems, setParametrItems] = useState([]);
  const [emojiList, setEmojiList] = useState([]);
  const [selectedEmoji, setSelectedEmoji] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);

  const contentBtn = () => {
    if (page == 'enhance_photo') {
      return 'Сгенерировать фото';
    } else if (page == 'enhance_video') {
      return 'Сгенерировать видео';
    } else return 'Сгенерировать описание';
  };

  const getCategoryByProduct = () => {
    const url = `https://apps0.ecomru.ru:4447/api/products/get_category_by_products`;

    const body = JSON.stringify({
      // product_id_list: [productId],
      product_id_list: isAll ? productList?.map(({ id }) => id) : [productId],
    });

    fetch(url, { body, method: 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setCategoryName(json.map((el) => el?.category_name));
      })
      .catch((err) => console.error(err));
  };

  const getProductByCategory = () => {
    if (page == 'emoji') {
      const url = `https://apps0.ecomru.ru:4447/api/products/get_product_by_category`;

      const body = JSON.stringify({
        // product_id_list: [productId],
        product_id_list: isAll ? productList?.map(({ id }) => id) : [productId],
        category_name: categoryName?.join(''),
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {})
        .catch((err) => console.error(err));
    }
  };

  const getCategoryParams = () => {
    const url = `https://apps0.ecomru.ru:4447/api/products/get_category_params`;

    const body = JSON.stringify({
      category_name: categoryName?.join(''),
    });

    fetch(url, { body, method: 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setParametrItems(json);
      })
      .catch((err) => console.error(err));
  };

  const getEmojiList = () => {
    if (page == 'emoji') {
      const url = `https://apps0.ecomru.ru:4447/api/products/emojis_list`;

      fetch(url, { method: 'GET', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          setEmojiList(
            json.map((el) => ({
              names: [el.name ? el.name : el?.html],
              id: el.html,
              imgUrl:
                'https://e7.pngegg.com/pngimages/374/428/png-clipart-emoji-smiley-emoticon-sticker-emoji-face-smiley.png',
              emoji: el.emoji,
              unified: el.unicode ? el.unicode : el.emoji,
              unifiedWithoutSkinTone: el.unicode ? el.unicode : el.emoji,
              unicode: el.unicode ? el.unicode : el.emoji,
            }))
          );
        })
        .catch((err) => console.error(err));
    }
  };

  const createNewDesc = () => {
    if (page == 'emoji') {
      const url = `https://apps0.ecomru.ru:4447/api/products/generate_new_product_description`;

      const body = JSON.stringify({
        product_id_list: isAll ? productList?.map(({ id }) => id) : [productId],
        attribute_list: selectedAttributes,
        emoji_code: selectedEmoji.join(''),
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          // const { key } = json
          // sessionStorage.setItem(productId, key)
          const { key } = json;
          setIsStatus(true);
          setStatusKey(key);
          setIsModal(false);
        })
        .catch((err) => console.error(err));
    }
  };

  const createNewImages = () => {
    if (page == 'enhance_photo') {
      const url = `https://apps0.ecomru.ru:4447/api/products/generate_photo_product`;

      const body = JSON.stringify({
        product_id_list: isAll ? productList?.map(({ id }) => id) : [productId],
        attribute_list: selectedAttributes,
        emoji_code: selectedEmoji.join(''),
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          console.log(json);
          // const { key } = json
          // sessionStorage.setItem(productId, key)
          const { key } = json;
          setIsStatus(true);
          setStatusKey(key);
          setIsModal(false);
        })
        .catch((err) => console.error(err));
    }
  };

  const createNewVideo = () => {
    if (page == 'enhance_video') {
      const url = `https://apps0.ecomru.ru:4447/api/products/generate_new_product_video`;

      const body = JSON.stringify({
        product_id_list: isAll ? productList?.map(({ id }) => id) : [productId],
        // attribute_list: selectedAttributes,
        // emoji_code: selectedEmoji.join(''),
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          console.log(json);
          // const { key } = json
          // sessionStorage.setItem(productId, key)
          const { key } = json;
          setIsStatus(true);
          setStatusKey(key);
          setIsModal(false);
        })
        .catch((err) => console.error(err));
    }
  };

  useEffect(() => {
    getCategoryByProduct();
    getEmojiList();
  }, [selectedSpace]);

  useEffect(() => {
    getProductByCategory();
    getCategoryParams();
  }, [categoryName, selectedSpace]);

  return (
    <div
      className="blackout"
      onClick={(e) => {
        let el = e.target;
        e.stopPropagation();
        if (el.classList.contains('blackout')) {
          setIsModal(false);
        }
      }}
    >
      <div
        className="modal-product-improvement"
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          maxWidth: 750,
          overflow: 'hidden',
        }}
      >
        <div className="modal-product-improvement__parametrs-left">
          <div>
            <b> Выбранные эмоджи:</b>
            {selectedEmoji?.length ? (
              selectedEmoji.map((el) => (
                <p
                  onClick={(e) =>
                    setSelectedEmoji((prev) =>
                      prev.filter((elem) => elem !== el)
                    )
                  }
                  style={{ cursor: 'pointer' }}
                >
                  {el}
                </p>
              ))
            ) : (
              <p>Пока ничего не выбрано</p>
            )}
          </div>
          <div>
            <b>Выбранные параметры:</b>
            {selectedAttributes?.length ? (
              selectedAttributes.map((el) => (
                <p
                  onClick={(e) =>
                    setSelectedAttributes((prev) =>
                      prev.filter((elem) => elem !== el)
                    )
                  }
                  style={{ cursor: 'pointer' }}
                >
                  {el}
                </p>
              ))
            ) : (
              <p>Пока ничего не выбрано</p>
            )}
          </div>
        </div>
        <div className="modal-product-improvement__parametrs-right">
          {
            <Tabs
              tabs={btn_tab}
              pageValue={pageValue}
              setPageValue={(e) => setPageValue(e)}
              stopRedirect
            />
          }
          <h1 className="title">Выберите параметры</h1>
          {pageValue != 'emoji' && (
            <div
              className="modal-product-improvement__content"
              style={{ height: '100%' }}
            >
              {parametrItems.map(({ attribute_name, description, id }) => (
                <ParameterItem
                  key={attribute_name}
                  title={attribute_name}
                  id={attribute_name}
                  description={attribute_name}
                  setSelectedAttributes={setSelectedAttributes}
                  selectedAttributes={selectedAttributes}
                />
              ))}
            </div>
          )}
          {pageValue == 'emoji' && (
            <EmojiPicker
              style={{ width: '100%' }}
              searchDisabled
              customEmojis={emojiList}
              skinTonesDisabled
              onEmojiClick={(e) => {
                const { emoji } = e;
                setSelectedEmoji([emoji]);
              }}
            />
          )}

          <ButtonBasic
            green
            text={
              <p className="content-btn">
                <img src={spakler_icon} />
                {contentBtn()}
              </p>
            }
            width="100%"
            style={{
              marginTop: '8px',
              minHeight: '40px',
            }}
            size="40px"
            onClick={(e) => {
              createNewDesc();
              createNewImages();
              createNewVideo();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export { ModalProductImprovement };
