import add_icon from './img/add_icon.svg'
import { useMemo, useRef, useState } from 'react'
import { ExecutorPlaceholder } from '../../OptionsExecutor/OptionsExecutor'
import { ReactComponent as SortIcon } from './img/sort_icon.svg'
import { ReactComponent as SortIconTop } from './img/sort_icon_top.svg'
import { InputSearch, Checkbox, Tabs } from "@lk-gtcom/ecomlab-components";
import './ModalListExecutor.scss'

const btn_tab = [
    {
        label: 'Исполнитель',
        value: 'executor'
    },
    {
        label: 'Инициатор',
        value: 'author'
    },
    {
        label: 'Соисполнитель',
        value: 'co_executor'
    }
]

const ModalListExecutor = ({
    executors,
    setIsModal,
    setSelectedExecutor,
    setSelectedCoExecutor,
    selectedExecutor,
    selectedCoExecutor,
    selectedAuthor,
    setSelectedAuthor,
    checkAttendee,
    setColumnsAreUpdating,
    updateData
}) => {
    const [executorOptions, setExecutorOptions] = useState(executors)
    const [executorSearchValue, setExecutorSearchValue] = useState('')
    const [isSort, setIsSort] = useState(false)
    const [direction, setDirection] = useState(null)
    const checkboxRef = useRef()
    const email = localStorage.getItem('email')
    const [pageValue, setPageValue] = useState(btn_tab[0].value)

    const current_list_executors = (id) => {
        if (pageValue == 'executor') {
            return selectedExecutor?.indexOf(id) !== -1
        }
        if (pageValue == 'co_executor') {
            return selectedCoExecutor?.indexOf(id) !== -1
        }

        if (pageValue == 'author') {
            return selectedAuthor?.indexOf(id) !== -1
        }
    }

    const current_className_executors = (id) => {
        if (pageValue == 'executor') {
            return selectedExecutor?.indexOf(id) !== -1 ? 'executor-item_active' : 'executor-item'
        }
        if (pageValue == 'co_executor') {
            return selectedCoExecutor?.indexOf(id) !== -1 ? 'executor-item_active' : 'executor-item'
        }
        if (pageValue == 'author') {
            return selectedAuthor?.indexOf(id) !== -1 ? 'executor-item_active' : 'executor-item'
        }
    }

    const currentListExecuters = useMemo(() => {
        let executor_list = executorOptions?.filter(e => {            
            if(!e?.[pageValue]) return false

            const nameLowerCased = e?.name.toLowerCase()
            return (
                (executorSearchValue ? nameLowerCased.includes(executorSearchValue.toLowerCase()) : true)
            )
        })
        if (direction) {
            return executor_list?.sort((el1, el2) => {
                if (el1.name.toLowerCase() > el2.name.toLowerCase()) {
                    if (direction == 'ASC') {
                        return 1
                    } else return -1
                }
                if (el1.name.toLowerCase() < el2.name.toLowerCase()) {
                    if (direction == 'ASC') {
                        return -1
                    } else return 1
                }
            }).sort((a,b) => current_list_executors(b.id) - current_list_executors(a.id))
        } else return executor_list.sort((a,b) => current_list_executors(b.id) - current_list_executors(a.id))

    }, [executorOptions, direction, executorSearchValue, pageValue])

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal()
                updateData()
            }
        }}>
            <div className='modal-list-executor'>
                <div className='modal-list-executor__header'>
                    <h1 className='title'>Выбор сотрудников</h1>
                </div>
                <div className='modal-list-executor__toolbar'>
                    <button className={isSort ? 'btn-sort_active' : 'btn-sort'} onClick={(e) => {
                        if (!isSort) setIsSort(true)
                        setDirection(direction == 'ASC' ? 'DESC' : 'ASC')
                    }}>
                        {direction == 'ASC' ? <SortIcon /> : <SortIconTop />}
                    </button>
                    <InputSearch
                        placeholder='Найти сотрудника'
                        value={executorSearchValue}
                        onChange={e => setExecutorSearchValue(e)} />
                </div>

                <Tabs
                    tabs={btn_tab}
                    stopRedirect
                    pageValue={pageValue}
                    setPageValue={(e) => setPageValue(e)} />

                <div className='executor-content'>
                    <div className='executor-content__info'>
                        <p className='text'>Cотрудников - <span>{currentListExecuters?.length}</span></p>
                        <p className='text_bluee'>Выбрано - <span>{selectedExecutor?.length}</span></p>
                    </div>

                    {(currentListExecuters?.length <= 0) ?
                        <div className='no-executor'>
                            <img src={add_icon} />
                            <p className='text_grey'>Нет сотрудников</p>
                        </div>
                        :
                        <div className='executors-list-content'>
                            <div className='list-executor'>
                                {
                                    currentListExecuters?.map(({ id, name, login, color, role }) =>
                                        <label
                                            style={{ position: email == login ? 'absolute' : 'static' }}
                                            className={current_className_executors(id)} key={id}
                                            onClick={e => {
                                                e.stopPropagation()
                                                checkboxRef.current.click()
                                            }}
                                        >
                                            <Checkbox
                                                value={current_list_executors(id)}
                                                ref={checkboxRef}
                                                onClick={e => {
                                                    if (pageValue == 'executor') {
                                                        setSelectedExecutor(prev => {
                                                            if (prev?.indexOf(id) === -1) {
                                                                return [...prev, id]
                                                            } else {
                                                                return prev?.filter(el => el !== id)
                                                            }
                                                        })
                                                    }
                                                    if (pageValue == 'co_executor') {
                                                        setSelectedCoExecutor(prev => {
                                                            if (prev?.indexOf(id) === -1) {
                                                                return [...prev, id]
                                                            } else {
                                                                return prev?.filter(el => el !== id)
                                                            }
                                                        })
                                                    }
                                                    if (pageValue == 'author') {
                                                        setSelectedAuthor(prev => {
                                                            if (prev?.indexOf(id) === -1) {
                                                                return [...prev, id]
                                                            } else {
                                                                return prev?.filter(el => el !== id)
                                                            }
                                                        })
                                                    }
                                                    if (typeof setColumnsAreUpdating === 'function') {
                                                        setColumnsAreUpdating(true)
                                                    }
                                                }}

                                            ></Checkbox>
                                            <ExecutorPlaceholder name={name} email={login} color={color} />
                                            <div className='info-content'>
                                                <p className='text'>{name}</p>
                                                {role && <p className='text_grey'>Название должности</p>}
                                                <p className='text_grey'>{login}</p>
                                            </div>
                                        </label>
                                    )
                                }
                            </div>

                        </div>}
                </div>
            </div>
        </div>
    )
}

export { ModalListExecutor }