import customHeaders, { getSpace } from '../../../common/headers';
import { useState, useEffect } from 'react';
import icon from './img/icon.svg'
import { ButtonBasic, TextareaDynamicPlaceholder } from '@lk-gtcom/ecomlab-components';
import './ModalAddMassLink.scss';

const ModalAddMassLink = ({ setIsModal }) => {

    return (
        <div className='blackout blackout-modal-2'
            style={{ zIndex: 12 }}
            onClick={(e) => {
                let el = e.target
                if (el.classList.contains('blackout-modal-2')) {
                    setIsModal(false)
                }
            }}>
              <div className='modal-mass-editing'>
                <div className='modal-mass-editing__header'>
                    <h1 className='title_main'>Импорт ссылок</h1>
                </div>

                <div className='modal-mass-editing__content'>
                    <div className='toolbar'>
                        <p className='text'>Разделение ссылок происходит при нажатии дважды на enter</p>
                        <div className='right-content'>
                            {/* <button className='btn'><img src={icon} />Выгрузить</button> */}
                            <button className='btn'><img src={icon} />Загрузить</button>
                        </div>
                    </div>

                    <TextareaDynamicPlaceholder
                        width='100%'
                        changeValue={''}
                        placeholder="Введите текст"
                        onChange={(e) => (e)}
                    />
                    <ButtonBasic
                        green
                        style={{ marginTop: '12px' }}
                        size='56px'
                        text='Сохранить'
                        width='162px'
                        onClick={e => (e)}
                    />
                </div>
            </div>
        </div>
    );
};

export { ModalAddMassLink };
