import customHeaders, { getSpace } from '../../common/headers';
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { ButtonBasic, TableBox, DropDownSelector, Tabs } from "@lk-gtcom/ecomlab-components";
import plus from './img/plus.svg'
import { FilterContainer } from '../../components/FilterContainer/FilterContainer';
import { ModalCreateChatBot } from '../../components/Modal/ModalCreateChatBot/ModalCreateChatBot';
import './ChatBot.scss'

const btn_tab = [
    {
        label: 'Все',
        value: 'all',
    },
    {
        label: 'Активные',
        value: 'active',
    },
    {
        label: 'Неактивные',
        value: 'disactive',
    },
]


const ChatBot = () => {

    const activeTab = useParams()['*'];
    const [pageValue, setPageValue] = useState(btn_tab[0])
    const [isModal, setIsModal] = useState(false)

    useEffect(() => {
        setPageValue(activeTab)
    }, [activeTab])

    return (
        <>
            {isModal && <ModalCreateChatBot setIsModal={(e) => setIsModal(e)} />}

            <div className='chat-bot'>
                <h1 className='chat-bot__title-main'>Чат-боты</h1>

                <div className='chat-bot__toolbar-top'>
                    <FilterContainer
                        onReset={(e) => (e)}
                    >
                        <DropDownSelector
                            options_prop={[]}
                            state={[]}
                            setState={(e) => (e)}
                            placeholder="Фильтр 1"
                            multi
                        />

                        <DropDownSelector
                            options_prop={[]}
                            state={[]}
                            setState={(e) => (e)}
                            placeholder="Фильтр 2"
                            multi
                        />

                        <DropDownSelector
                            options_prop={[]}
                            state={[]}
                            setState={(e) => (e)}
                            placeholder="Фильтр 3"
                            multi
                        />

                        <DropDownSelector
                            options_prop={[]}
                            state={[]}
                            setState={(e) => (e)}
                            placeholder="Фильтр 4"
                            multi
                        />
                    </FilterContainer>

                    <ButtonBasic
                        green
                        width='170px'
                        onClick={(e) => setIsModal(true)}
                        text={<div className='chat-bot__btn'>Создать бота <img src={plus} /></div>}
                    />
                </div>

                <Tabs tabs={btn_tab} pageValue={pageValue} />

                <TableBox />
            </div>
        </>

    )
}

export { ChatBot }
